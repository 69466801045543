import { createAction } from 'redux-act';

import {
    FilterValueSetPayload,
    IChangeFilterSelection,
    IFilterPayload,
    IGuidePayload,
    ISetFilterPayload,
    ISetFilterSubCategoryId,
    ISetGuide,
    ISetLoading,
    ISetPage,
    IToastPayload,
    IToggleCookiesDrawer,
    IToggleAccountModal,
    IToggleCreator,
    IToggleDrawer,
    IToggleNotification,
    TSwitch,
    types,
    IShareLinks,
} from './types';

export const setPage = createAction<ISetPage>(types.SET_PAGE);
export const setAppLoading = createAction<ISetLoading>(types.SET_APP_LOADING);
export const toggleDrawer = createAction<IToggleDrawer>(types.TOGGLE_APP_DRAWER);
export const setFilter = createAction<ISetFilterPayload>(types.SET_FILTER);
export const changeFilterSelection = createAction<IChangeFilterSelection>(types.CHANGE_FILTER_SELECTION);
export const setFilterSubCategory = createAction<FilterValueSetPayload>(types.SET_FILTER_SUB_SELECTION);
export const clearFilter = createAction(types.CLEAR_FILTER);
export const toggleFiler = createAction<IFilterPayload>(types.TOGGLE_FILTER);
export const setFilterSubCategoryId = createAction<ISetFilterSubCategoryId>(types.SET_FILTER_SUB_CATEGORY);
export const toggleNotification = createAction<IToggleNotification>(types.TOGGLE_NOTIFICATION);
export const toggleAccountModal = createAction<IToggleAccountModal>(types.TOGGLE_ACCOUNT_MODAL);
export const toggleCreatorModal = createAction<IToggleCreator>(types.TOGGLE_CREATOR_MODAL);
export const toggleCookiesModal = createAction<IToggleCookiesDrawer>(types.TOGGLE_COOKIES_DRAWER);
export const toggleToast = createAction<IToastPayload>(types.TOGGLE_TOAST);
export const setShowGuide = createAction<ISetGuide>(types.SET_SHOW_GUIDE);
export const setShowSearchBox = createAction<TSwitch>(types.SET_SHOW_SEARCH_BOX);

export const setGuideLink = createAction<IGuidePayload>(types.SET_GUIDE_LINK);
export const setShowProjectCreatorGuide = createAction<boolean>(types.SET_SHOW_PROJECT_CREATOR_GUIDE);
export const setUsersFirstProjectSuggestionBox = createAction<boolean>(types.SET_SHOW_PROJECT_SUGGESTION_BOX);

export const setShareModalStatus = createAction<IShareLinks>(types.SET_SHARE_MODAL_STATUS);
