import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useThemeContext } from '../../hook/useThemeManager';
import { theme } from '../../constants/theme';
import { Container, Typography } from '@mui/material';
import { FONT_ENUM } from '../../types/global/fonts.types';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    progressBar: {
        transition: 'top 1s ease-in-out',
    },
}));

interface IProgressBar {
    progress?: number;
    handleOnClose?: () => void;
    onCancel?: () => void;
}
export const ProgressBar: React.FC<IProgressBar> = ({ progress, handleOnClose, onCancel }) => {
    const { themeStyle } = useThemeContext();
    const classes = useStyles();

    React.useEffect(() => {
        return () => {
            if (progress && progress >= 100) {
                handleOnClose && handleOnClose();
            }
        };
    }, [handleOnClose, progress]);
    return (
        <Container
            disableGutters
            style={{
                height: 'auto',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                overflow: 'hidden',
                width: 'auto',
                maxWidth: '100%',
            }}
            sx={{
                marginTop: { xs: '0', sm: '40px' },
                mb: { xs: '10px', sm: '0' },
                marginLeft: { xs: '0px', sm: '65px' },
                paddingLeft: { xs: '16px', sm: '40px' },
                paddingRight: { xs: '16px', sm: '40px' },
                overflowX: 'hidden',
            }}>
            <Box
                sx={{
                    width: '100%',
                    border: `0.1px solid ${theme[themeStyle].darkGray60}`,
                    position: 'relative',
                    borderRadius: 3,
                    padding: 3,
                }}>
                <Typography
                    sx={{
                        fontFamily: FONT_ENUM.REGULAR_HEADER,
                        color: theme[themeStyle].tag_white,
                        fontSize: 12,
                        mb: 1,
                    }}>
                    Uploading Project In Progress ... Do NOT refresh page.
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ width: '100%', mr: 1 }}>
                        <LinearProgress
                            variant="determinate"
                            value={Number(progress)}
                            sx={{ borderRadius: 2, color: theme[themeStyle].main_blue }}
                            className={`${classes.progressBar}`}
                        />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                        <Typography
                            style={{
                                fontFamily: FONT_ENUM.MAIN_MEDIUM,
                                color: theme[themeStyle].tag_white,
                                fontSize: 12,
                            }}>{`${Math.round(progress as number)}%`}</Typography>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};
