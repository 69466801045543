import { IUser } from '../../types/global/ListRender.types';
import { DiscoverySearchModel, DiscoverySearchUnparsedResponse } from '../../types/global/generic.types';
import { IContest, IProject, UserSummary } from '../../types/global/helper';
import { ILike } from '../account/types';
import { IVote } from '../contest/types';

export const types = {
    GET_DISCOVERY: 'GET_DISCOVERY',
    GET_DISCOVERY_SUCCESS: 'GET_DISCOVERY_SUCCESS',
    GET_DISCOVERY_FAIL: 'GET_DISCOVERY_FAIL',

    SEARCH_DISCOVERY: 'SEARCH_DISCOVERY',
    SEARCH_DISCOVERY_SUCCESS: 'SEARCH_DISCOVERY_SUCCESS',
    SEARCH_DISCOVERY_FAIL: 'SEARCH_DISCOVERY_FAIL',

    FILTER: 'FILTER',
    FILTER_SUCCESS: 'FILTER_SUCCESS',
    FILTER_FAIL: 'FILTER_FAIL',

    SET_VIEW_ALL: 'SET_VIEW_ALL',
};

export type FilterCategoryType = 'project' | 'contest' | 'talent' | 'select category';
export interface IFilterPayload {
    category: FilterCategoryType;
    params: {
        [key: string]: string | number;
    };
    limit?: number;
    page?: number;
}

export interface IFilterResponse {
    status: 'string';
    type: FilterCategoryType;
    data: IProject[] | IContest[] | IUser[];
    cacheKey?: string;
    currentPage?: number;
    result?: DiscoverySearchUnparsedResponse;
    suggestion?: IProject[] | IContest[] | UserSummary[];
}

export interface ISearchResponse {
    searchTerm: string;
    searchResult: DiscoverySearchModel;
    allSearchResults?: DiscoverySearchUnparsedResponse;
}

export interface ISearchPayload {
    searchTerm: string;
    page?: number;
    limit?: number;
}

export interface IDiscoveryServerResponse {
    status: string;
    data: IDiscoveryResponse;
}

export interface IDiscoveryInitialState {
    discovery: IDiscoveryItem[];
    discoveryCache?: {
        [key: string]: any;
    };
    loadingDiscovery?: boolean;
    searchTerm?: string;
    errMsg?: any;
    searchResults?: DiscoverySearchModel;
    searching?: boolean;
    searchCache?: {
        [key: string]: any;
    };
    filterdResult?: IProject[] | IContest[] | IUser[];
    selectedFilterCategory?: FilterCategoryType;
    filterCache?: {
        [key: string]: any;
    };
    allSearchResults?: DiscoverySearchUnparsedResponse;
    searchResultsInView?: DiscoverySearchUnparsedResponse;
    currentResultPage?: number;
    suggestedTalents?: UserSummary[];
    suggestedProjects?: IProject[];
    suggestedContest?: IContest[];
}

export interface Discovery {
    discovery: any[];
    isCache?: boolean;
}

export type CarouselItem = IProject[] | IContest[] | IUser[] | IFeature[] | ISubmission[] | string[];
export type Carousel = IProject | IContest | IUser | IFeature | ISubmission;

export interface IDiscoveryItem {
    categoryTitle: string;
    categoryDetails: CarouselItem;
}

export interface IDiscoveryResponse {
    contests: Contest[];
    projects: ProjectSummary[];
}

export interface Contest {
    features: IFeature[];
    submissions: ISubmission[];
    genre: Genre[];
    short: Short[];
    comedy: Comedy[];
    monologue: Monologue[];
    coming_soon: ComingSoon[];
}

export interface IFeature {
    _id: string;
    requirements: string[];
    tag: string[];
    date_start: string;
    expiration_date: string;
    contest_phase: string;
    title: string;
    description: string;
    created_by: string;
    feature_image: string;
    rating?: string;
    hash?: string;
    premium?: boolean;
    mobile_feature_image?: string;
}

export interface ISubmission {
    trending_marker: number;
    judging_status?: string;
    _id: string;
    project: Project;
    contest: ContestDetails;
    created_by: PartialUserSummary;
    createdAt: string;
    updatedAt: string;
    id: string;
    host?: IHost;
    partner?: IPartner;
    user_vote?: IVote[];
    has_user_voted?: boolean;
}

export interface IPartner extends IHost {}

export interface ISocial {
    instagram?: string;
    x?: string;
    thspian?: string;
    tik_tok?: string;
    snapchat?: string;
    linkedln?: string;
}

export interface IHost {
    approved: boolean;
    created_at: string;
    _id: string;
    name: string;
    description: string;
    created_by: string;
    createdAt: string;
    updatedAt: string;
    socials?: ISocial;
    __v: number;
    profile_picture: string;
    id: string;
}

export interface Project {
    project_type: string;
    project_gallery: any[];
    visible: boolean;
    team: any[];
    status: string;
    trending_marker: number;
    total_likes: number;
    total_anger: number;
    total_love: number;
    scheduled_published_date: string;
    mentions: any[];
    total_comments: number;
    view_count: number;
    last_seen: string;
    _id: string;
    title: string;
    description: string;
    created_by: PartialUserSummary;
    createdAt: string;
    updatedAt: string;
    __v?: number;
    project: string;
    feature_image: string;
    id: string;
    video_id?: string;
    user_like?: ILike[];
    is_user_liked?: boolean;
    submissions?: ISubmission[];
    thumbnail?: string;
    hash?: string;
}

export interface ContestDetails {
    requirements: string[];
    contest_media: any[];
    voting_required: boolean;
    is_feature: boolean;
    voting_type: string;
    tag: string[];
    date_start: string;
    expiration_date: string;
    contest_phases_enabled: boolean;
    premium: boolean;
    contest_phase: string;
    status: string;
    trending_marker: number;
    total_likes: number;
    total_anger: number;
    total_love: number;
    total_comments: number;
    _id: string;
    title: string;
    description: string;
    created_by: PartialUserSummary;
    contest_phase_dates: ContestPhaseDate[];
    __v: number;
    feature_image: string;
    id: string;
}

export interface PartialUserSummary {
    _id: string;
    location: Location;
    firstName: string;
    lastName: string;
    profile_picture: string;
    userName: string;
}

export interface Location {
    type: string;
    description: string;
    address: string;
    coordinates: number[];
}

export interface Genre {
    _id: string;
    genre: string[];
}

export interface Short {
    _id: string;
    requirements: string[];
    contest_media: any[];
    voting_required: boolean;
    voting_type: string;
    tag: string[];
    date_start: string;
    expiration_date: string;
    contest_phases_enabled: boolean;
    premium: boolean;
    contest_phase: string;
    status: string;
    trending_marker: number;
    title: string;
    description: string;
    created_by: PartialUserSummary;
    contest_phase_dates: ContestPhaseDate[];
    __v: number;
    feature_image?: string;
    is_feature?: boolean;
}

export interface ContestPhaseDate {
    _id: string;
    phase: string;
    date_started: string;
    epxiration_date?: string;
}

export interface Comedy {
    _id: string;
    requirements: string[];
    contest_media: any[];
    voting_required: boolean;
    voting_type: string;
    tag: string[];
    date_start: string;
    expiration_date: string;
    contest_phases_enabled: boolean;
    premium: boolean;
    contest_phase: string;
    status: string;
    trending_marker: number;
    title: string;
    description: string;
    created_by: PartialUserSummary;
    contest_phase_dates: ContestPhaseDate[];
    __v: number;
    feature_image: string;
}

export interface Monologue {
    _id: string;
    requirements: string[];
    contest_media: any[];
    voting_required: boolean;
    voting_type: string;
    tag: string[];
    date_start: string;
    expiration_date: string;
    contest_phases_enabled: boolean;
    premium: boolean;
    contest_phase: string;
    status: string;
    trending_marker: number;
    title: string;
    description: string;
    created_by: PartialUserSummary;
    contest_phase_dates: ContestPhaseDate[];
    __v: number;
    feature_image: string;
}

export interface ComingSoon {
    _id: string;
    requirements: string[];
    tag: string[];
    date_start: string;
    expiration_date: string;
    contest_phase: string;
    title: string;
    description: string;
    created_by: PartialUserSummary;
    feature_image: string;
    video_id?: string;
}

export interface ProjectSummary {
    recently_watched: RecentlyWatched[];
    short_films: ShortFilm[];
}

export interface RecentlyWatched {
    _id: string;
    project_type: string;
    total_likes: number;
    total_love?: number;
    scheduled_published_date?: string;
    total_comments?: number;
    last_seen?: string;
    title: string;
    description: string;
    created_by: PartialUserSummary;
    project: string;
    video_id?: string;
}

export interface ShortFilm {
    _id: string;
    project_type: string;
    total_likes: number;
    total_love?: number;
    scheduled_published_date?: string;
    title: string;
    description: string;
    project: string;
    video_id?: string;
}

export interface Location {
    address: string;
    description: string;
    coordinates: number[];
    type: string;
}

export interface ISetFilter {
    page?: number;
    customCategory?: FilterCategoryType;
}
