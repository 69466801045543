import env from './endpoint.json';

export const ENV = env[process.env.REACT_APP_NODE_ENV as keyof typeof env];
export const ASW_S3_LINK = '';
export const CLOUD_FRONT = ENV.CLOUD_FRONT;
export const SITE_URL = ENV.URL;

const BASE_URL = ENV.BASE;
export const SOCKET = ENV.SOCKET;
export const MEDIA_FORGE = ENV.MEDIA_FORGE;

export const splashUrl = `${BASE_URL}/splash`;

/** Get the global Configuration for ringO. TODO: Set the ring to be picked up based on environment */
export const globalConfigUrl = `${BASE_URL}/globalConfiguration?ring=ring0`;

/** USERS API */
export const getPhoneVerificationCodeUrl = `${BASE_URL}/users/signup`;
export const phoneNumberVerificationUrl = `${BASE_URL}/users/reg-phone-verify`;
export const validateFieldUrl = `${BASE_URL}/users/user-existing-status`;
export const validateUsernameUrl = `${BASE_URL}/users/userName-check`;
export const loginUrl = `${BASE_URL}/users/login`;
export const login2FAVerifyUrl = `${BASE_URL}/users/login-phone-verify`;
export const forgotPasswordUrl = `${BASE_URL}/users/forgotPassword`;
export const resetPasswordUrl = `${BASE_URL}/users/resetPassword`;
export const changePasswordUrl = `${BASE_URL}/users/updateMyPassword`;
export const userConfigurationUrl = `${BASE_URL}/config`;
export const refreshTokenUrl = `${BASE_URL}/users/refresh`;
export const getUserProfileUrl = `${BASE_URL}/users/userdashboard`;
export const userBaseUrl = `${BASE_URL}/users`;
export const updateProfileUrl = `${BASE_URL}/users/updateMe`;

/** The below will take a token `:token in a GET call` */
export const verifyEmailUrl = `${BASE_URL}/users/verifyEmail`;
export const initiateEmailVerificationUrl = `${BASE_URL}/users/initiate-email-verification`;
/** The below API, replaces the accounts api call, that fetched all user information
 * including configuration. This is now a one API call that will do all that work and return the
 * same amount of data. It takes a GET call,
 */
export const userDetailsUrl = `${userBaseUrl}/userdetails`;

/** MOVIES API */
export const getHomeScreenItemsUrl = `${BASE_URL}/movies/preference/home`;
export const getHomePageCategoriesUrl = `${BASE_URL}/movies?fields=genre`;
export const getMoviesUrl = `${BASE_URL}/movies`;
export const getWatchListUrl = `${BASE_URL}/like/get-my-likes`;
/** Accepets a `movieId` string as param `PATCH` request  and `DELETE` */
export const addRemoveMovieLocationUrl = `${BASE_URL}/movies/imagelocation`;
/** Accepets a `movieId` string as param `DELETE` request with a body of `{filekey: name_of_file}` */
export const deleteGalleryImageUrl = `${BASE_URL}/movies/image/`;
export const deleteSingleImageUrl = `${BASE_URL}/movies/singleimage/`;

/** TALENT PROFILE API. CONC Additional Params for search or filter. POST, UPDATE, DELETE */
export const talentProfileBaseUrl = `${BASE_URL}/profiles`;
/** The below takes the `ID` of the profile. It is also used to delete single picture from gallery */
export const uploadTalentImagesUrl = `${BASE_URL}/profiles/upload`;
export const deleteTalentSingleImageUrl = `${BASE_URL}/profiles/singleimage`;
export const getMyTalentProfileurl = `${BASE_URL}/profiles/get-my-profile`;
export const searchTalentUrl = `${BASE_URL}/profiles/general/search`;
export const filterTalentUrl = `${BASE_URL}/profiles/filter`;

/** LIKE API */
export const likeUrl = `${BASE_URL}/like`;
export const deletLikeUrl = `${BASE_URL}/like/delete-favourite`;
export const deleteReviewLikeurl = `${BASE_URL}/like/delete-like`;
/** TO UPDATE A REVIEW, USE PATCH AND HAVE LIKE_TYPE IN THE BODY OF THE REQUEST */
export const reviewUrl = `${BASE_URL}/reviews`;

/** Get The Review Likes and Movies of current user */
export const getMyLikesUrl = `${BASE_URL}/like/get-my-likes`;

/** Search Result yielding both movies and bio */
export const searchUrl = `${BASE_URL}/movies/general/search`;

/** Get Biography Details- Supports POST, GET (WITH ID), GET_ALL, DELETE (WITH ID) */
export const biographyUrl = `${BASE_URL}/bio`;
/** handles both delete and upload patch */
export const singleGalleryAndProfileImagesUrl = `${BASE_URL}/bio/upload`;
export const deleteBioSingleImageUrl = `${BASE_URL}/bio/singleImage`;

/** Get Credits */
export const creditUrl = `${BASE_URL}/credits`;
export const getBioMoviesCredit = `${BASE_URL}/credits?user=`;

/** Get Auditions */
export const getAuditionUrl = `${BASE_URL}/auditions`;
export const getMyAuditionsUrl = `${getAuditionUrl}/get-my-auditions`;

/** Apply to auditions, takes ID of Audition POST or DELETE or UPDATE (To sort Application) */
export const applyToAuditionUrl = `${BASE_URL}/applications/`;

/** Get My Applications */
export const getMyApplicationsUrl = `${BASE_URL}/applications/get-my-applications`;
/** Ensure that you clal `.replace('#id#, payload)` - payload being the audition ID */
export const getApplicationsForOneAuditionUrl = `${BASE_URL}/auditions/#id#/applications/get-audition-applications`;

/** Get Roles */
export const getAuditionRolesUrl = `${BASE_URL}/roles?audition=`;
export const createAuditionRoleUrl = `${BASE_URL}/audition/{{:auditionId}}/roles`;
export const getAuditionRoleUrl = `${BASE_URL}/roles`;

/** Get Reviews */
export const getMyReviewsUrl = `${BASE_URL}/reviews/get-my-reviews`;

/** Payment Methods */
export const addNewPaymentMethodUrl = `${BASE_URL}/payment/add-payment-method`;
export const getMyPaymentMethodsUrl = `${BASE_URL}/payment/get-my-payment-methods`;
/** The Below also serves for delete and update payment method */
export const getPaymentMethod = `${BASE_URL}/payment/`;

/** Subscription API URL */
export const createSubscriptionUrl = `${BASE_URL}/subscribe`;
export const getMySubscriptionUrl = `${BASE_URL}/subscribe/get-my-plan`;
/** Cancel Subscription, takes `Subscription ID` as String */
export const cancelSubscriptionUrl = `${BASE_URL}/subscribe/cancel/`;
/** Update payment, takes `Subscription Code` as a String arguement */
export const updateSubscriptionUrl = `${BASE_URL}/subscribe/updatePayment/`;
export const getSubscriptionPlansUrl = `${BASE_URL}/subscriptionPlan`;

/** Save Items takes an object with `{details: { type: 'Role', model: modelId}}` type can be `Role | Audition | Any collection name` */
export const saveCollectionUrl = `${BASE_URL}/save`;
export const getSavedCollectionUrl = `${BASE_URL}/save/get-my-saved-collection`;

/** Festivals */
export const getFestival = `${BASE_URL}/festivals`;
export const getFestivalHomeItems = `${BASE_URL}/festivals/festivalhome`;
/** Takes a param id as string --- `/?festival=`id`` */
export const getFestivalAwardcategoryUrl = `${BASE_URL}/awardcategory`;

/** Awards */
export const awardsUrl = `${BASE_URL}/award`;
export const awardsCategoryUrl = `${BASE_URL}/awardcategory`;

/** Message Rooms */
export const roomsUrl = `${BASE_URL}/rooms`;
export const getMyRoomsUrl = `${roomsUrl}/my-rooms`;
export const getMessageUrl = `${BASE_URL}/message`;
/** Must pass Id of reciver `/:id` */
export const findUnqiueRoom = `${roomsUrl}/uniqueroom`;

export const webNotificationsUrl = `${BASE_URL}/webnotification`;
export const getCurrentUserWebNotificationsUrl = `${webNotificationsUrl}/user-notifications`;

/** Save Adopter */
export const adopterUrl = `${BASE_URL}/adopter`;

export const discoverUrl = `${BASE_URL}/profiles/talent-facet`;
export const getMycastingDirectorProfileurl = `${BASE_URL}/castingdirector/get-my-profile`;

/**Acts takes `:id` for delete and update*/
export const actsUrl = `${BASE_URL}/acts`;
export const myActsUrl = `${BASE_URL}/acts/get-my-acts`;
export const actsUrlbase = `${BASE_URL}/acts`;
/** Add :id to get the act */
export const actsUrlByIdentifier = `${BASE_URL}/acts/watch`;
export const getMyActsUrl = `${actsUrlbase}/get-my-acts`;

/** Recommendations  GET request*/
export const getRecommendationUrl = `${BASE_URL}/contest/recommendation`;

/** Projects */
export const getMyProjectsUrl = `${BASE_URL}/project/get-my-projects`;
export const projectUrl = `${BASE_URL}/project`;
/** Take an `/id` to upload */
export const uploadProjectMediaUrl = `${BASE_URL}/project/upload`;
export const publishProjectUrl = `${BASE_URL}/project/publish`;
export const unPublishProjecturl = `${BASE_URL}/project/unpublish`;
/** Take an ID */
export const getSignedUrlForProject = `${BASE_URL}/project/getSignedUrl`;
export const getSignedUrlForThumbnail = `${BASE_URL}/project/getSignedUrl/feature`;

/** Take an  `id` as param */
export const watchUrl = `${BASE_URL}/project/watch`;
export const submissionsUrl = `${BASE_URL}/submission`;
export const getMySubmissionsUrl = `${submissionsUrl}/get-my-submissions`;
/** **Important**:  Pass `:id` as the contestId to populate it's submissions
 * To auto assign to all juries use `-POST` `{auto_populate: boolean}`. pass auto_populate as true
 * To manually assign use  `{auto_populate: false, submission: submissionId, user: userId, jury: juryId}`
 */
export const assignSubmissionToJuryurl = `${submissionsUrl}/distribute/`;
/** get my Jury Assignment. Gets assignment for logged in juror */
export const juryAssignmentUrl = `${submissionsUrl}/jury`;
/** Gets Submission for selected juryAssignment. **IMPORTANT** pass :contestId
 * also takes `?limit=0&page=1` for pagination
 */
export const juryAssignmentSubmissionsUrl = `${submissionsUrl}/jury-submisisons/`;
/** `- PATCH` with `submisisonId` and payload of score */
export const scoreSubmissionsUrl = `${submissionsUrl}/score/`;
export const scoreBoardUrl = `${submissionsUrl}/scoreboard`;

/** Dashboard */
export const getUserDashboardUrl = `${BASE_URL}/users/dashboard`;

/** Discovery - Get the discovery home items*/
export const getDiscoveryUrl = `${BASE_URL}/contest/home`;

/** Coming soon*/
export const getComingSoonUrl = `${BASE_URL}/contest/coming-soon`;

/** Search Discovery Screen */
export const searchDiscoveryUrl = `${BASE_URL}/contest/search`;

/** Get Contest Details => Take an `GET` with an `id` to fetch the details of one contest */
export const getContestUrl = `${BASE_URL}/contest`;
/** Take a POST call with key as payload */
export const downloadContestAssetUrl = `${getContestUrl}/download`;
export const publishContestUrl = `${getContestUrl}/publish`;
export const unPublishContesturl = `${getContestUrl}/unpublish`;
/** This takes an :id in its url param */
export const uploadContestVideoUrl = `${getContestUrl}/upload/`;
/** Also takes :id in it's param */
export const getContestMetricsUrl = `${getContestUrl}/metrics/`;

export const winnersEmailUrl = `${getContestUrl}/conclusion-email`;

/** Activity Url
 * The below also accepts ?activity_type=`Check activity redux types to see the different types`. To filter the activity for the logged in user
 * An example for use here is returning users most recent searches pass `?activity_type=initiated a search`. Supports `POST` as well as `GET` requests
 */
export const activityUrl = `${BASE_URL}/activity`;

/** Suggestions. The below returns suggested trending search*/
export const getSuggestionsUrl = `${BASE_URL}/suggestion/search-suggestion`;

/** Filter URL , Accepts `POST` with payload*/
export const filterUrl = `${BASE_URL}/filter`;

/** COmments */
export const commentUrl = `${BASE_URL}/comment`;
/** Takes filter API features */
export const getProjectcommentUrl = `${BASE_URL}/comment/allComments`;

/** Jury - takes `:id` for delete and getOne requests. Also called for creating / inviting jury */
export const juryBaseUrl = `${BASE_URL}/jury`;
/** Pass `-GET /token` to accept jury invite */
export const acceptJuryInviteUrl = `${BASE_URL}/jury/invite/`;

/** View URL. Will allow us fetch views for specific projects. **NOTE** when signed in
 * user ?user=`id_of_signed_in_user` to get the views linked to that specific user. when not signed in
 * user the x-device-info as defined in the api header to fetch the details
 */
export const viewUrl = `${BASE_URL}/views`;

/** Vote URL */
export const voteUrl = `${BASE_URL}/vote`;

/** VoteR url */
export const voterUrl = `${BASE_URL}/voter`;

/** Audience URL
 * /my-watching -GET to get the thspians a user is subscribed to
 * /my-audience -GET to get the thspians subscribed to a user
 * /:id -PATCH to block a user
 * /:id - DELETE to unwatch a user
 */
export const audienceUrl = `${BASE_URL}/audience`;

export const hostUrl = `${BASE_URL}/host`;
export const partnerUrl = `${BASE_URL}/partner`;

/** Redeemable Codes, accepts a contest query param */
export const getRedeemableCodesUrl = `${BASE_URL}/reedemable-code`;
export const assignRedeemableCodeUrl = `${BASE_URL}/reedemable-code/send`;

/** Requests */
export const requestUrl = `${BASE_URL}/request`;

/** Score & Score category */
export const scoreCategoryUrl = `${BASE_URL}/scorecategory`;
export const scoreUrl = `${BASE_URL}/score`;

/** Link */
export const linkUrl = `${BASE_URL}/link`;
export const myLinksUrl = `${BASE_URL}/link/my-links`;

/** Talent */
export const talentUrl = `${BASE_URL}/talent`;
export const searchUserTalentUrl = `${BASE_URL}/talent/search`;
