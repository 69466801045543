/* eslint-disable import/no-cycle */
import { call, all, put, takeLatest, takeLeading, select } from 'redux-saga/effects';

import {
    getUserConfigSuccess,
    getUserConfigFailed,
    createUserConfigSuccess,
    createUserConfigFailed,
    updateUserConfigSuccess,
    updateUserConfigFailed,
    getGlobalConfigSuccess,
    getGlobalConfigFail,
} from './action';
import { IConfigurationInitialState, types } from './types';
import { api } from '../../configurations/api';
import { userConfigurationUrl, globalConfigUrl } from '../../configurations/api/url';
import { IGlobalConfiguration } from '../../constants/types';
import { httpRequest } from '../types';
import { IGenericDocumentModifier } from '../../types/global/helper';
import { RootState } from '../root-reducer';
import { SentryCapture } from '../../analytics/Sentry';

export function* getGlobalConfiguration(): any {
    try {
        const response: IGlobalConfiguration = yield call(api, globalConfigUrl, httpRequest.GET, null, 2);
        const { data } = response;
        yield put(getGlobalConfigSuccess(data.data[0]));
    } catch (error) {
        SentryCapture(error, 'error');
        yield put(getGlobalConfigFail(error));
    }
}

export function* getUserConfiguration({ payload, resolve, reject }: any): any {
    try {
        const requestUrl = `${userConfigurationUrl}/get-my-config`;
        const response = yield call(api, requestUrl, httpRequest.GET, null, 2);
        const { data } = response;
        yield put(getUserConfigSuccess(data));
        if (resolve) {
            resolve(data);
        }
    } catch (error) {
        SentryCapture(error, 'error');
        yield put(getUserConfigFailed(error));
        if (reject) {
            reject(error);
        }
    }
}

function* createUserConfig({ payload, resolve, reject }: any): any {
    try {
        const response = yield call(api, userConfigurationUrl, httpRequest.POST, payload, 1, 0);
        const { data } = response;
        yield put(createUserConfigSuccess(data));
        resolve(data);
    } catch (error: any) {
        SentryCapture(error, 'error');
        const { message } = error.data;
        reject(message);
        yield put(createUserConfigFailed(message));
    }
}

/**
 *
 * @param String `Configuration Id`
 */
function* updateUserConfiguration({ payload }: { payload: IGenericDocumentModifier }): any {
    const state: RootState = yield select();
    const { configuration }: { configuration: IConfigurationInitialState } = state;
    const { userSpecificConfiguration } = configuration;
    try {
        const request = `${userConfigurationUrl}/${userSpecificConfiguration?._id}`;
        const response = yield call(api, request, httpRequest.PATCH, payload.payload, 1, 0);
        const { data } = response;
        yield put(updateUserConfigSuccess(data.data)); // Formatting to Fit the Reducer requirement
    } catch (error) {
        SentryCapture(error, 'error');
        yield put(updateUserConfigFailed(error));
    }
}

interface TaskAction extends IGenericDocumentModifier {
    type: string;
    payload: any;
}

function* updateUserConfigWatcher() {
    yield takeLatest<TaskAction>(types.UPDATE_USER_CONFIG, updateUserConfiguration);
}

function* getUserConfigWatcher() {
    yield takeLatest(types.GET_CONFIGURATION, getUserConfiguration);
}

function* createUserConfigWatcher() {
    yield takeLatest(types.CREATE_CONFIGURATION, createUserConfig);
}

function* getGlobalConfigurationWatcher() {
    yield takeLeading(types.GET_GLOBAL_CONFIGURATION, getGlobalConfiguration);
}

export default function* configurationSaga() {
    yield all([
        getUserConfigWatcher(),
        createUserConfigWatcher(),
        updateUserConfigWatcher(),
        getGlobalConfigurationWatcher(),
    ]);
}
