import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import {
    getDiscoverySuccess,
    getDiscoveryFail,
    searchDiscoverySuccess,
    searchDiscoveryFail,
    filterSuccess,
    filterFail,
} from './actions';
import { globalErrorHandler } from '../error/saga';
import { IDiscoveryServerResponse, IFilterPayload, IFilterResponse, ISearchPayload, ISetFilter, types } from './types';
import { api } from '../../configurations/api';
import { httpRequest } from '../types';
import { filterUrl, getDiscoveryUrl, searchDiscoveryUrl } from '../../configurations/api/url';
import { Action } from 'redux';
import { RootSearchResponse } from './search.types';
import { RootState } from '../root-reducer';
import { FilterItem } from '../../data/filters/discovery.data';
import { MD5, enc } from 'crypto-js';
import { en_config } from '../../config';
import { setCache } from '../cache/action';
import { getCacheByType, getCacheDuration, getNotUniqueCacheByKey, isUseCacheEnabled } from '../cache/saga';
import { CACHE_TYPE, CacheValue, NotUniqueCacheValue } from '../cache/types';
import {
    genericParseSingleDocument,
    parseDiscovery,
    parseDiscoverySearchResult,
    parseGenericCollection,
} from '../../utils/responseProcessor';
import { DiscoverySearchUnparsedResponse } from '../../types/global/generic.types';
import { projectActParse } from '../../utils/reponseProcessorII';
import { IProject } from '../../types/global/helper';
import { SentryCapture } from '../../analytics/Sentry';
import { isEmpty } from '../../utils/lodash';

function* getDiscovery({ resolve, reject }: any): any {
    const defaultUseCache = yield* isUseCacheEnabled();
    const defaultCacheDuration = yield* getCacheDuration();
    /**
     * CACHE EXAMPLE
     * At this level we can check if there's new data and then either return the already saved response
     * or make another api call. This service will call
     * const { newDataAvailable } = yield select() // Yet to be implemneted. If this returns true then we make the api call
     *
     * Right now we are implementing a manual cache based on time, interface wil fetch new response every 30 minutes, if the call is made
     */

    let initialResult: any = null;

    const cache: CacheValue = yield* getCacheByType(CACHE_TYPE.DISCOVERY);

    /** Check if it has been 30 minutes since the last pull. TODO: */
    if (
        cache &&
        cache.key &&
        !isEmpty(cache.value) &&
        defaultUseCache &&
        ((Date.now() - Number(cache.key)) as unknown as number) < defaultCacheDuration // To Temporarily fetch from server directly add `&& false` here to override cache
    ) {
        initialResult = cache.value;
    }

    if (initialResult && initialResult.length > 0 && defaultUseCache) {
        yield put(getDiscoverySuccess({ discovery: initialResult, isCache: true }));
    } else {
        try {
            const response = yield call(api, getDiscoveryUrl, httpRequest.GET, null, 0, 0, false, e => {
                console.log(e);
            });
            const { data } = response as IDiscoveryServerResponse;
            const parsedResult = parseDiscovery(data);
            yield put(getDiscoverySuccess({ discovery: parsedResult, isCache: false }));
            yield put(setCache({ key: Date.now(), value: parsedResult, type: CACHE_TYPE.DISCOVERY, isUnique: true }));
            resolve(data);
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield put(getDiscoveryFail(error));
            yield call(globalErrorHandler, error);
            reject(error);
        }
    }
}

function* initiateSearch({ payload }: { payload: ISearchPayload }): any {
    const defaultUseCache = yield* isUseCacheEnabled();
    let initialResult: any = null;

    const cache: NotUniqueCacheValue = yield* getNotUniqueCacheByKey(CACHE_TYPE.PROJECT, payload.searchTerm);
    if (cache && cache.key) {
        initialResult = cache.value;
    }

    /** Check if this searchterm has already yielded any result then return that same response */
    if (initialResult && defaultUseCache) {
        const { parsedResult, unParsedResult } = initialResult;
        yield put(
            searchDiscoverySuccess({
                searchTerm: payload.searchTerm,
                searchResult: parsedResult,
                allSearchResults: unParsedResult,
            }),
        ); // This allows us cache the search term to return it if the search has already occured
    } else {
        try {
            const response = yield call(api, searchDiscoveryUrl, httpRequest.POST, payload, 0, 0);
            const { data } = response as RootSearchResponse;
            const results = parseDiscoverySearchResult(data);
            /** Also fetching all results so we can display that on teh view all page */
            const { parsedResult, unParsedResult } = results;
            yield put(
                searchDiscoverySuccess({
                    searchTerm: payload.searchTerm,
                    searchResult: parsedResult,
                    allSearchResults: unParsedResult,
                }),
            ); // This allows us cache the search term to return it if the search has already occured
            yield put(
                setCache({
                    key: payload.searchTerm,
                    value: results,
                    type: CACHE_TYPE.SEARCH,
                    isUnique: false,
                }),
            );
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield put(searchDiscoveryFail(error));
            yield call(globalErrorHandler, error);
        }
    }
}

const parseFilterPayload = (data: { [key: number]: FilterItem }) => {
    const parsedObject: any = {};
    Object.keys(data).forEach((d: any) => {
        parsedObject[data[d].field] = data[d].value;
    });
    return parsedObject;
};

const getConsistentHash = (obj: any) => {
    const objStr = JSON.stringify(obj);
    const hashObj = MD5(objStr).toString(enc.Hex);
    return hashObj;
};

function* filter({ payload }: { payload: ISetFilter }): any {
    let initialResult: any = null;
    const state: RootState = yield select();
    const { selectedFilterCategory, selectedFiltersValues } = state.appConfigurator;
    const defaultUseCache = yield* isUseCacheEnabled();
    const dataPayload: IFilterPayload = {
        category: payload.customCategory ?? selectedFilterCategory,
        params: parseFilterPayload(selectedFiltersValues as any),
        limit: en_config.RESULT_LIMIT,
        page: payload.page || 0,
    };

    const cacheKey = getConsistentHash(dataPayload);
    const cache: NotUniqueCacheValue = yield* getNotUniqueCacheByKey(CACHE_TYPE.FILTER, cacheKey);

    if (cache && cache.key) {
        initialResult = cache.value;
    }

    if (initialResult && defaultUseCache) {
        yield put(filterSuccess(initialResult));
    } else {
        try {
            const parsedFilterResult: DiscoverySearchUnparsedResponse = {
                acts: [],
                contests: [],
                projects: [],
                users: [],
                suggestion: [],
            };
            const response: IFilterResponse = yield call(api, filterUrl, httpRequest.POST, dataPayload, 0, 0);
            const { data } = response;
            response.currentPage = payload.page;
            switch (selectedFilterCategory) {
                case 'contest':
                    const parsedContest = parseGenericCollection(data, genericParseSingleDocument);
                    parsedFilterResult.contests = parsedContest;
                    break;
                case 'project':
                    const { acts, projects } = projectActParse(data as IProject[]);
                    parsedFilterResult.projects = projects;
                    parsedFilterResult.acts = acts;
                    break;
                case 'talent':
                    const parsedTalent = parseGenericCollection(data, genericParseSingleDocument);
                    parsedFilterResult.users = parsedTalent;
                    break;
                default:
                    break;
            }
            response.result = parsedFilterResult;
            yield put(filterSuccess(response));
            yield put(
                setCache({
                    key: cacheKey,
                    value: response,
                    type: CACHE_TYPE.FILTER,
                    isUnique: false,
                }),
            );
        } catch (error: any) {
            SentryCapture(error, 'error');
            console.log(error);
            yield put(filterFail(error));
        }
    }
}

interface TaskAction extends Action, ISearchPayload {
    type: string;
    payload: ISearchPayload;
    resolve: any;
    reject: any;
}

function* getDiscoveryWatcher() {
    yield takeLatest(types.GET_DISCOVERY, getDiscovery);
}

function* initiateSearchWatcher() {
    yield takeLatest<TaskAction>(types.SEARCH_DISCOVERY, initiateSearch);
}

function* filterWacther() {
    yield takeLatest<TaskAction>(types.FILTER, filter);
}

export default function* discoverySaga() {
    yield all([getDiscoveryWatcher(), initiateSearchWatcher(), filterWacther()]);
}
