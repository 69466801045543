import produce from 'immer';
import {
    IGetAllProjects,
    IGetFeedResponse,
    IGetProjects,
    IGetSingleProject,
    ILeaveCommentResponse,
    IProjectInitialState,
    IReactToComment,
    IRetryProjectProcesing,
    ISetMedia,
    ISetPlayerQuery,
    nebulaSuggestion,
    types,
} from './types';
import { CommentLike, IGenericDocumentModifier, IProject, ISubmission, TComment } from '../../types/global/helper';
import { IActSummary } from '../../types/global/media.types';
import { types as contestType } from '../contest/types';
import { getIdForIbjects, makeArrayUniqueAndClean, restoreInitialState } from '../../utils/AppUtils';
import { ILikeResponse } from '../account/types';
import { IErrorType } from '../../constants/types';
import { types as storeType } from '../refreshToken/types';
import { types as followTypes } from '../audience/types';
import { IFollowThspian } from '../audience/model';
import { generateTemporaryFollowObject } from '../audience/action';
import { IToggleCreator, types as configurationType } from '../appConfigurator/types';
import { types as contestTypes } from '../contest/types';

const initialState: IProjectInitialState = {
    gettingProjects: false,
    projectErrMsg: null,
    projects: [],
    userProjects: [],
    selectedProjectComments: [],
    loading: false,
    playlist: [],
    isSequential: false,
    isSinglePreview: false,
    playlistType: 'recommendation',
    nebulaSuggestion: nebulaSuggestion,
    selectedSuggestion: nebulaSuggestion[0],
    feed: [],
    gettingFeedErrMsg: null,
    currentFeedLimit: 10,
    currentFeedPage: 1,
    canLoadMoreFeed: true,
    triggeringMediaForge: false,
    retries: {},
};

const reducer = (state: IProjectInitialState = initialState, action: any) => {
    const { type, payload } = action;

    switch (type) {
        case types.GET_PROJECT:
            return produce(state, draftState => {
                const { fromAnotherScreen } = payload as IGetSingleProject;
                draftState.projectErrMsg = null;
                draftState.gettingProject = true;
                if (fromAnotherScreen) {
                    draftState.gettingProjects = true;
                }
            });
        case types.GET_PROJECT_COMMENTS:
            return produce(state, draftState => {
                draftState.projectErrMsg = null;
                draftState.gettingProjectComments = true;
            });
        case types.GET_FEED:
            return produce(state, draftState => {
                draftState.gettingFeed = true;
            });
        case types.GET_FEED_SUCCESS:
            return produce(state, draftState => {
                const { data, page, canLoadMore, firstProjectId } = payload as IGetFeedResponse;
                if (page !== undefined && page < 2) {
                    draftState.feed = makeArrayUniqueAndClean(data);
                } else {
                    draftState.feed = makeArrayUniqueAndClean([...draftState.feed, ...data]);
                }
                draftState.gettingFeed = false;
                draftState.currentFeedPage = page;
                draftState.canLoadMoreFeed = canLoadMore;
                draftState.initialProjectId = firstProjectId;
            });
        case types.GET_FEED_FAIL:
            return produce(state, draftState => {
                draftState.gettingFeedErrMsg = payload;
                draftState.gettingFeed = true;
            });
        case types.GET_MY_PROJECTS:
        case types.GET_PROJECTS:
            return produce(state, draftState => {
                const { page, isFiltered, isPlaylistFetch } = (payload as IGetProjects) || {};
                if (page <= 1) {
                    if (isFiltered) {
                        draftState.playlist = [];
                    } else {
                        draftState.projects = [];
                    }
                }
                if (isPlaylistFetch) {
                    draftState.gettingPlaylist = true;
                } else {
                    draftState.gettingProjects = true;
                }
                draftState.gettingMoreProjects = true;
                draftState.projectErrMsg = null;
            });
        case types.GET_PROJECT_SUCCESS:
            return produce(state, draftState => {
                /** Rremoved from here since SET_MEDIA handles this */
                // draftState.selectedProject = payload;
                draftState.gettingProject = false;
                /** This allows the shimmer to show on the watch screen
                 * Because when we select a new project to watch. The other projects load but
                 * we only want this effect when navigating from another page
                 */
                draftState.gettingProjects = false;
            });
        case types.GET_MY_PROJECTS_FAIL:
        case types.GET_PROJECTS_FAIL:
            return produce(state, draftState => {
                draftState.gettingMoreProjects = false;
            });
        case types.GET_MY_PROJECTS_SUCCESS:
            return produce(state, draftState => {
                draftState.userProjects = payload;
                draftState.gettingProjects = false;
                draftState.gettingMoreProjects = false;
                draftState.gettingPlaylist = false;
            });
        case types.GET_PROJECT_COMMENTS_SUCCESS:
            return produce(state, draftState => {
                draftState.selectedProjectComments = payload;
                draftState.gettingProjectComments = false;
            });
        case types.GET_PROJECTS_SUCCESS:
            return produce(state, draftState => {
                const { data, isFiltered, currentPage, loadMore, currentParam, isPlaylistFetch, isFeedFetch } =
                    payload as IGetAllProjects;
                if (currentPage !== undefined && currentPage < 2) {
                    draftState.playlist = data;
                } else if (isFiltered) {
                    draftState.playlist = [...draftState.playlist, ...data];
                } else {
                    draftState.playlist = data;
                }
                if (isFeedFetch) {
                    draftState.feed = data;
                }

                draftState.loadMore = loadMore;
                draftState.currentPage = currentPage;
                draftState.currentParam = currentParam;
                draftState.gettingMoreProjects = false;
                if (isPlaylistFetch) {
                    draftState.gettingPlaylist = false;
                } else {
                    draftState.gettingProjects = false;
                }
            });
        case types.GET_PROJECT_FAIL:
            return produce(state, draftState => {
                draftState.projectErrMsg = payload;
                draftState.gettingProject = false;
                draftState.gettingProjects = false;
            });
        case types.GET_PROJECT_COMMENTS_FAIL:
            return produce(state, draftState => {
                draftState.projectErrMsg = payload;
                draftState.gettingProjectComments = false;
            });
        case types.SET_MEDIA:
            return produce(state, draftState => {
                const { media, mediaType, submissionObject, playlistType, clearCustomSuggestion } =
                    payload as ISetMedia;

                if (mediaType === 'act') {
                    draftState.selectedAct = media as IActSummary;
                }

                if (mediaType === 'video') {
                    /** We might be callling thsi twice. Please check the  */
                    draftState.selectedProject = media as IProject;
                }

                if (playlistType) {
                    draftState.playlistType = playlistType;
                }

                if (clearCustomSuggestion) {
                    draftState.nebulaSuggestion = draftState.nebulaSuggestion?.filter(
                        suggestion => !suggestion.isCustom,
                    );
                    draftState.selectedSuggestion = nebulaSuggestion[0];
                }

                draftState.userInteraction = {
                    is_user_liked: media?.is_user_liked || false,
                    userLike: media?.user_like && media?.user_like?.length > 0 ? media?.user_like : [],
                };

                draftState.selectedSubmission = submissionObject;
                draftState.selectedMediaType = mediaType;
                draftState.gettingProjects = false;
                draftState.gettingProject = false;
                if (media === null) {
                    draftState.selectedSuggestion = undefined;
                }
            });
        case types.SET_SUBMISSION:
            return produce(state, draftState => {
                draftState.selectedSubmission = payload;
            });
        case types.GET_WATCH_CONTENT:
            return produce(state, draftState => {
                draftState.gettingWatchContent = true;
            });
        case types.GET_WATCH_CONTENT_SUCCESS:
            return produce(state, draftState => {
                draftState.watchContent = payload;
                draftState.gettingWatchContent = false;
            });
        case types.GET_WATCH_CONTENT_FAIL:
            return produce(state, draftState => {
                draftState.gettingWatchContentErrMsg = payload;
                draftState.gettingWatchContent = false;
            });
        case types.GET_ACT:
        case types.GET_ACTS:
            return produce(state, draftState => {
                draftState.gettingActs = true;
                draftState.actsErrMsg = null;
            });
        case types.GET_ACT_SUCCESS:
            return produce(state, draftState => {
                draftState.gettingActs = false;
                draftState.selectedAct = payload;
            });
        case types.GET_ACTS_SUCCESS:
            return produce(state, draftState => {
                draftState.gettingActs = false;
                draftState.actsPlaylist = payload;
            });
        case types.GET_ACT_FAIL:
        case types.GET_ACTS_FAIL:
            return produce(state, draftState => {
                draftState.gettingActs = false;
                draftState.actsErrMsg = payload;
            });
        case types.GET_COMMENTS_FOR_ACT:
            return produce(state, draftState => {
                draftState.gettingActsComments = true;
            });
        case types.GET_COMMENTS_FOR_ACT_SUCCESS:
            return produce(state, draftState => {
                draftState.gettingActsComments = false;
                draftState.selectedActsComments = payload;
            });
        case types.GET_COMMENTS_FOR_ACT_FAIL:
            return produce(state, draftState => {
                draftState.gettingActsComments = false;
                draftState.gettingActsCommentsErrMsg = payload;
            });
        case types.UDPATE_PROJECT_FIELD:
            return produce(state, draftState => {
                draftState.projectInCreation = {
                    ...draftState.projectInCreation,
                    payload,
                };
            });
        case types.CREATE_PROJECT:
        case types.UPDATE_PROJECT:
        case types.PUBLISH_PROJECT:
        case types.UNPUBLISH_PROJECT:
        case types.UPLOAD_PROJECT_MEDIA:
            return produce(state, draftState => {
                draftState.creatingProject = true;
            });
        case types.UPLOAD_PROJECT_MEDIA_FAIL:
            return produce(state, draftState => {
                draftState.creatingProject = false;
            });
        case types.UPLOAD_PROJECT_MEDIA_SUCCESS:
            return produce(state, draftState => {
                draftState.creatingProject = false;
            });
        case types.CREATE_PROJECT_SUCCESS:
            return produce(state, draftState => {
                draftState.creatingProject = false;
                draftState.currentProjectInCreation = payload;
                draftState.userProjects = makeArrayUniqueAndClean([payload, ...draftState.userProjects]);
                if (draftState.mediaForge) {
                    draftState.mediaForge['progress'] = 0;
                }
            });
        case types.UPDATE_PROJECT_SUCCESS:
            return produce(state, draftState => {
                const response: IProject = payload;
                const findIndex = draftState.userProjects.findIndex(el => el._id === response._id);
                draftState.userProjects[findIndex] = response;
                draftState.currentProjectInCreation = response;
                draftState.creatingProject = false;
            });
        case types.UNPUBLISH_PROJECT_SUCCESS:
            return produce(state, draftState => {
                const response: IProject = payload;
                const findIndex = draftState.userProjects.findIndex(el => el._id === response._id);
                draftState.userProjects[findIndex] = response;
                draftState.currentProjectInCreation = undefined;
                draftState.creatingProject = false;
            });
        case types.PUBLISH_PROJECT_SUCCESS:
            return produce(state, draftState => {
                const response: IProject = payload;
                const findIndex = draftState.userProjects.findIndex(el => el._id === response._id);
                draftState.userProjects[findIndex] = response;
                draftState.creatingProject = false;
            });
        case types.CREATE_PROJECT_FAIL:
        case types.PUBLISH_PROJECT_FAIL:
        case types.UNPUBLISH_PROJECT_FAIL:
        case types.UPDATE_PROJECT_FAIL:
            return produce(state, draftState => {
                draftState.creatingProject = false;
                draftState.projectErrMsg = payload;
            });
        case types.DELETE_PROJECT_SUCCESS:
            return produce(state, draftState => {
                const { documentId, additionalPayload } = payload as IGenericDocumentModifier;
                const filterProjects = draftState.userProjects.filter(proj => proj._id !== documentId);
                draftState.userProjects = filterProjects;
                if (additionalPayload) {
                    draftState.creatingProject = false;
                }
            });
        case types.DELETE_PROJECT_FAIL:
            return produce(state, draftState => {
                draftState.creatingProject = false;
                draftState.projectErrMsg = payload;
                draftState.creatingProject = false;
            });
        // case types.PUBLISH_PROJECT_SUCCESS:
        //     return produce(state, draftState => {
        //         /** Index of teh project being published */
        //         const index = draftState.userProjects.indexOf(payload);
        //         draftState.userProjects[index] = payload;
        //     });
        case contestType.SUBMIT_PROJECT_SUCCESS:
            return produce(state, draftState => {
                const { project } = payload as ISubmission;
                const selectedProjectIndex = draftState.userProjects.findIndex(p => p._id === project._id);
                if (selectedProjectIndex > -1) {
                    const updatedObject = { ...draftState.userProjects[selectedProjectIndex] };
                    updatedObject.submitted_contest?.push(payload);
                    const clonedUserProjects = [...draftState.userProjects];
                    clonedUserProjects[selectedProjectIndex] = updatedObject;
                    draftState.userProjects = clonedUserProjects;
                }
            });
        case contestType.RESCIND_SUBMISSION_SUCCESS:
            return produce(state, draftState => {
                const { project, _id } = payload as ISubmission;
                const selectedProjectIndex = draftState.userProjects.findIndex(p => p._id === project._id);
                if (selectedProjectIndex > -1) {
                    const updatedObject = { ...draftState.userProjects[selectedProjectIndex] };
                    updatedObject.submitted_contest = updatedObject.submitted_contest?.filter(sub => sub._id !== _id);
                    const clonedObject = [...draftState.userProjects];
                    clonedObject[selectedProjectIndex] = updatedObject;
                    draftState.userProjects = clonedObject;
                }
            });
        case types.LEAVE_COMMENT:
        case types.UPDATE_COMMENT:
        case types.DELETE_COMMENT:
            return produce(state, draftState => {
                draftState.loading = true;
            });
        case types.LEAVE_COMMENT_SUCCESS:
            return produce(state, draftState => {
                const { comment, isReply, parentComment } = payload as ILeaveCommentResponse;
                if (isReply) {
                    const sought = draftState.selectedProjectComments.find(
                        comment => comment._id === parentComment?._id,
                    );
                    if (sought) {
                        const allResplied = !!sought?.replies ? [...sought.replies] : [];
                        sought.replies = [...allResplied, comment];
                    }
                } else {
                    draftState.selectedProjectComments?.unshift(comment);
                }

                draftState.watchContent?.forEach(content => {
                    content.categoryContent.forEach(project => {
                        if (project._id === comment.project?._id) {
                            project.total_comments! += 1;
                        }
                    });
                });
            });
        case types.LEAVE_COMMENT_FAIL:
        case types.UPDATE_COMMENT_FAIL:
        case types.DELETE_COMMENT_FAIL:
            return produce(state, draftState => {
                draftState.loading = false;
                draftState.projectErrMsg = payload;
            });
        case types.DELETE_COMMENT_SUCCESS:
            return produce(state, draftState => {
                const { documentId } = payload as IGenericDocumentModifier;
                const comment: TComment = payload.payload;
                console.log(comment, 'THE COMMENTETET');
                if (payload.payload && payload.payload.parent_comment) {
                    const selectedProjectComment = draftState.selectedProjectComments.find(
                        comment => comment._id === payload.payload.parent_comment,
                    );
                    if (selectedProjectComment) {
                        selectedProjectComment.replies = selectedProjectComment?.replies?.filter(
                            reply => reply._id !== documentId,
                        );
                    }
                } else {
                    draftState.selectedProjectComments = draftState.selectedProjectComments?.filter(
                        comment => comment._id !== documentId,
                    );
                }
                draftState.watchContent?.forEach(content => {
                    content.categoryContent.forEach(project => {
                        if (project._id === getIdForIbjects(comment.project)) {
                            project.total_comments! -= 1;
                        }
                    });
                });

                draftState.loading = false;
            });
        case types.UPDATE_COMMENT_SUCCESS:
            return produce(state, draftState => {
                /** Type of TComment `addittionalPaload` */
                const { documentId, additionalPayload } = payload as IGenericDocumentModifier;
                if (additionalPayload.parent_comment) {
                    const selectedProjectComment = draftState.selectedProjectComments.find(
                        comment => comment._id === additionalPayload.parent_comment,
                    );
                    const targetReply = selectedProjectComment?.replies?.find(reply => reply._id === documentId);

                    if (targetReply) {
                        targetReply.comment = additionalPayload.comment; // TODO: Load the entire object so we can get the updated time
                    }
                } else {
                    const index = draftState.selectedProjectComments?.findIndex(
                        comment => comment._id === additionalPayload._id,
                    );
                    if (index !== -1 && draftState.selectedProjectComments) {
                        draftState.selectedProjectComments[index as unknown as any] = additionalPayload;
                    }
                }
                draftState.loading = false;
            });
        case types.RESET_PROJECT_DATA:
            return restoreInitialState(state, initialState);
        case types.PROJECT_PROCESSING_STATUS:
            return produce(state, draftState => {
                draftState.mediaForge = payload;
            });
        case types.LIKE_COMMENT:
            return produce(state, draftState => {
                const { commentId } = payload as IReactToComment;
                draftState.liking = true;
                const index = draftState.selectedProjectComments.findIndex(comment => comment._id === commentId);
                if (index !== -1) {
                    draftState.selectedProjectComments[index].is_user_liked = true;
                }
            });
        case types.LIKE_COMMENT_SUCCESS:
            return produce(state, draftState => {
                const { data, documentId } = payload as ILikeResponse;
                draftState.liking = false;
                const index = draftState.selectedProjectComments.findIndex(comment => comment._id === documentId);
                if (index !== -1) {
                    draftState.selectedProjectComments[index].is_user_liked = true;
                    draftState.selectedProjectComments[index].user_like = [data as CommentLike];
                }
            });
        case types.LIKE_COMMENT_FAIL:
            return produce(state, draftState => {
                const { documentId, data } = payload as IErrorType;
                draftState.liking = false;
                const index = draftState.selectedProjectComments.findIndex(comment => comment._id === documentId);
                if (index !== -1) {
                    draftState.selectedProjectComments[index].is_user_liked = false;
                    draftState.selectedProjectComments[index].user_like = [];
                }
                draftState.projectErrMsg = data;
            });
        case types.DELETE_COMMENT_LIKE:
            return produce(state, draftState => {
                const { additionalPayload } = payload as IGenericDocumentModifier;
                draftState.liking = true;
                const index = draftState.selectedProjectComments.findIndex(
                    comment => comment._id === additionalPayload._id,
                );
                if (index !== -1) {
                    draftState.selectedProjectComments[index].is_user_liked = false;
                }
            });
        case types.DELETE_COMMENT_LIKE_SUCCESS:
            return produce(state, draftState => {
                const { additionalPayload } = payload as IGenericDocumentModifier;
                draftState.liking = false;
                const index = draftState.selectedProjectComments.findIndex(
                    comment => comment._id === additionalPayload._id,
                );
                if (index !== -1) {
                    draftState.selectedProjectComments[index].user_like = [];
                }
            });
        case types.DELETE_COMMENT_LIKE_FAIL:
            return produce(state, draftState => {
                const { documentId } = payload as IErrorType;
                draftState.liking = false;
                const index = draftState.selectedProjectComments.findIndex(comment => comment._id === documentId);
                if (index !== -1) {
                    draftState.selectedProjectComments[index].is_user_liked = true;
                }
            });
        case types.DELETE_A_LIKE:
            return produce(state, draftState => {
                draftState.deletingLike = true;
                draftState.userInteraction = {
                    optimistic: true,
                };
            });
        case types.DELETE_A_LIKE_SUCCESS:
            return produce(state, draftState => {
                const { additionalPayload } = payload as IGenericDocumentModifier;
                draftState.userInteraction = {
                    is_user_liked: false,
                    userLike: [],
                    optimistic: false,
                };
                draftState.deletingLike = false;
                draftState.watchContent?.forEach(content => {
                    content.categoryContent.forEach(project => {
                        // Id of the project
                        if (project._id === getIdForIbjects(additionalPayload)) {
                            project.total_likes! -= 1;
                            project.user_like = [];
                            project.is_user_liked = false;
                        }
                    });
                });
            });
        case types.DELETE_A_LIKE_FAIL:
            return produce(state, draftState => {
                draftState.deletingLike = false;
                draftState.userInteraction = {
                    optimistic: false,
                };
            });
        case types.LIKE_A_PROJECT:
            return produce(state, draftState => {
                draftState.liking = true;
                draftState.userInteraction = {
                    optimistic: true,
                    is_user_liked: true,
                };
            });
        case types.LIKE_A_PROJECT_SUCCESS:
            return produce(state, draftState => {
                const { data } = payload as ILikeResponse;
                draftState.userInteraction = {
                    is_user_liked: true,
                    userLike: [data],
                    optimistic: false,
                };
                draftState.liking = false;
                draftState.watchContent?.forEach(content => {
                    content.categoryContent.forEach(project => {
                        if (project._id === getIdForIbjects(data.project)) {
                            project.total_likes! += 1;
                            project.user_like = [data];
                            project.is_user_liked = true;
                        }
                    });
                });
            });
        case types.LIKE_A_PROJECT_FAIL:
            return produce(state, draftState => {
                draftState.liking = false;
                draftState.userInteraction = {
                    optimistic: false,
                    is_user_liked: false,
                };
            });
        case types.SET_PLAYER_QUERY:
            return produce(state, draftState => {
                const { isSequential, isSinglePreview } = payload as ISetPlayerQuery;
                draftState.isSequential = isSequential || false;
                draftState.isSinglePreview = isSinglePreview || false;
            });
        // handle all failed state here when a user is
        case storeType.ENQUEUE_FAILED_ACTIONS:
            return produce(state, draft => {
                switch (payload.type) {
                    case types.LIKE_A_PROJECT:
                        return produce(state, draftState => {
                            draftState.liking = false;
                            draftState.userInteraction = {
                                optimistic: false,
                                is_user_liked: false,
                            };
                        });
                    case types.LIKE_COMMENT:
                        return produce(state, draftState => {
                            const { commentId } = payload.payload as IReactToComment;
                            draftState.liking = false;
                            const index = draftState.selectedProjectComments.findIndex(
                                comment => comment._id === commentId,
                            );
                            if (index !== -1) {
                                draftState.selectedProjectComments[index].is_user_liked = false;
                            }
                        });
                }
            });
        case types.SET_NEBULA_SUGGESTION:
            return produce(state, draft => {
                draft.nebulaSuggestion = [...payload, ...nebulaSuggestion];
                draft.selectedSuggestion = draft.nebulaSuggestion[0];
            });
        case types.SELECT_NEBULA_SUGGESTION:
            return produce(state, draft => {
                draft.selectedSuggestion = payload;
            });
        case followTypes.FOLLOW_A_THSPIAN:
            return produce(state, draftState => {
                const { following } = payload as IFollowThspian;
                const externalProfile =
                    draftState && draftState.selectedProject && draftState.selectedProject.created_by;
                if (externalProfile) {
                    const audience = generateTemporaryFollowObject(following);
                    externalProfile.user_following = true;
                    externalProfile.user_follow_interaction?.push(audience as any);
                }
            });
        case followTypes.FOLLOW_A_THSPIAN_SUCCESS:
            return produce(state, draftState => {
                const externalProfile =
                    draftState && draftState.selectedProject && draftState.selectedProject.created_by;
                if (externalProfile) {
                    externalProfile.user_follow_interaction = [payload];
                }
            });
        case followTypes.FOLLOW_A_THSPIAN_FAIL:
            return produce(state, draftState => {
                const externalProfile =
                    draftState && draftState.selectedProject && draftState.selectedProject.created_by;
                if (externalProfile) {
                    externalProfile.user_follow_interaction = [];
                    externalProfile.user_following = false;
                }
            });
        case followTypes.UNFOLLOW_A_THSPIAN:
            return produce(state, draft => {
                const externalProfile = draft && draft.selectedProject && draft.selectedProject.created_by;
                if (externalProfile) {
                    externalProfile.user_follow_interaction = [];
                    externalProfile.user_following = false;
                }
            });
        case followTypes.UNFOLLOW_A_THSPIAN_SUCCESS:
            return produce(state, draft => {});
        case followTypes.UNFOLLOW_A_THSPIAN_FAIL:
            return produce(state, draft => {
                const { following } = payload as IFollowThspian;
                const externalProfile = draft && draft.selectedProject && draft.selectedProject.created_by;
                if (externalProfile) {
                    const audience = generateTemporaryFollowObject(following);
                    externalProfile.user_following = true;
                    externalProfile.user_follow_interaction?.push(audience as any);
                }
            });
        case types.DOWNLOAD_PROJECT:
            return produce(state, draft => {
                draft.downloading = true;
            });
        case types.DOWNLOAD_PROJECT_SUCCESS:
            return produce(state, draft => {
                draft.downloading = false;
            });
        case types.DOWNLOAD_PROJECT_FAIL:
            return produce(state, draft => {
                draft.downloading = false;
            });
        case types.TRIGGER_MEDIA_FORGE:
            return produce(state, draft => {
                draft.triggeringMediaForge = true;
            });
        case types.TRIGGER_MEDIA_FORGE_FAIL:
        case types.TRIGGER_MEDIA_FORGE_SUCCESS:
            return produce(state, draft => {
                draft.triggeringMediaForge = false;
            });
        case types.RETRY_PROJECT_PROCESSING:
            return produce(state, draft => {
                const { projectId, timeRetried } = payload as IRetryProjectProcesing;
                const record = {
                    ...draft.retries,
                    [projectId]: timeRetried,
                };
                draft.retries = record;
            });
        case configurationType.TOGGLE_CREATOR_MODAL:
            return produce(state, draft => {
                const { location } = payload as IToggleCreator;
                draft.location = location;
            });
        case contestTypes.SUBMIT_PROJECT_SUCCESS:
        case contestTypes.SUBMIT_PROJECT_FAIL:
            return produce(state, draft => {
                draft.location = undefined;
                draft.currentProjectInCreation = undefined;
            });
        default:
            return state;
    }
};

export { reducer };
