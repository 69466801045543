import produce from 'immer';
import { IView, IViewInitialState } from './types';
import { types } from './types';
import { makeArrayUniqueAndClean } from '../../utils/AppUtils';

const initialState: IViewInitialState = {
    creatingView: false,
    gettingViews: false,
    views: [],
    viewsForSelectedProject: [],
    errMsg: null,
};

const reducer = (state = initialState, action: any) => {
    const { type, payload } = action;
    switch (type) {
        case types.CREATE_VIEW:
            return produce(state, draft => {
                draft.creatingView = true;
            });
        case types.CREATE_VIEW_SUCCESS:
            return produce(state, draft => {
                draft.creatingView = false;
                draft.viewsForSelectedProject.push(payload as IView);
            });
        case types.CREATE_VIEW_FAIL:
            return produce(state, draft => {
                draft.creatingView = false;
                draft.errMsg = payload;
            });
        case types.GET_VIEWS:
            return produce(state, draft => {
                draft.gettingViews = true;
            });
        case types.GET_VIEWS_SUCCESS:
            return produce(state, draft => {
                draft.gettingViews = false;
                draft.views = makeArrayUniqueAndClean([...draft.views, ...(payload as IView[])]);
            });
        case types.GET_VIEWS_FAIL:
            return produce(state, draft => {
                draft.gettingViews = false;
                draft.errMsg = payload;
            });
        case types.GET_ONE_VIEW:
            return produce(state, draft => {
                draft.gettingViews = true;
            });
        case types.GET_ONE_VIEW_SUCCESS:
            return produce(state, draft => {
                draft.gettingViews = false;
                draft.views = makeArrayUniqueAndClean([...draft.viewsForSelectedProject, payload as IView]);
            });
        case types.GET_ONE_VIEW_FAIL:
            return produce(state, draft => {
                draft.gettingViews = false;
                draft.errMsg = payload;
            });
        default:
            return state;
    }
};

export { reducer };
