import React from 'react';
import { ErrorBoundaryComponent } from '../../components/ErrorBoundary/ErrorBoundary';
import { Box } from '@mui/material';

const ErrorBoundary = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
            }}>
            <ErrorBoundaryComponent />
        </Box>
    );
};

export { ErrorBoundary };
