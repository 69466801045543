import React from 'react';
import { ThemeContext } from '../../module/ThemeManager';
import { Box, Container, Grid, Skeleton, Stack } from '@mui/material';
import { theme } from '../../constants/theme';

const UserProjectsShimmer = () => {
    return (
        <ThemeContext.Consumer>
            {({ themeStyle, device }) => {
                const value = device.isDesktop ? 5 : 3;
                return (
                    <Container
                        disableGutters
                        sx={{
                            width: '100%',
                            minHeight: '100vh',
                            overflowX: 'hidden',
                        }}
                        maxWidth={false}>
                        <Box sx={{ backgroundColor: 'black' }} width={'100%'} height={'60px'}></Box>
                        <Box
                            sx={{
                                marginLeft: { xs: '15px', sm: '65px' },
                                marginRight: { xs: '15px', sm: '0px', md: '0px' },
                                paddingLeft: { xs: '15px', sm: '40px', lg: '50px' },
                                paddingRight: { xs: '15px', sm: '40px', md: '0px' },
                            }}>
                            <Stack direction="row" spacing={2} marginBottom={2} marginTop={{ xs: 5, lg: 5 }}>
                                {Array.from(new Array(value)).map((_, index) => (
                                    <Skeleton
                                        key={index}
                                        variant="rounded"
                                        width={100}
                                        height={35}
                                        sx={{ borderRadius: 100, backgroundColor: theme[themeStyle].gray_text }}
                                    />
                                ))}
                            </Stack>
                            <Grid container gap={3} direction={{ xs: 'column', sm: 'row', md: 'row', lg: 'row' }}>
                                {Array.from(new Array(value)).map((_, index) => (
                                    <Grid item xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} key={index}>
                                        <Stack direction="column">
                                            <Skeleton
                                                variant="rounded"
                                                height={245}
                                                sx={{
                                                    minWidth: '250px',
                                                    maxWidth: '400px',
                                                    width: {
                                                        xs: '100%',
                                                        sm: '290px',
                                                        md: '350px',
                                                        lg: '250px',
                                                    },
                                                    backgroundColor: theme[themeStyle].gray_text,
                                                    marginTop: '20px',
                                                    marginBottom: '10px',
                                                    borderRadius: '10px',
                                                }}
                                            />
                                            <Stack>
                                                <Skeleton
                                                    variant="text"
                                                    sx={{
                                                        fontSize: '1rem',
                                                        width: '100px',
                                                        marginLeft: '2px',
                                                        backgroundColor: theme[themeStyle].darkGray60,
                                                    }}
                                                />
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>{' '}
                    </Container>
                );
            }}
        </ThemeContext.Consumer>
    );
};

export { UserProjectsShimmer };
