import React from 'react';
import Lottie, { Options, LottieProps } from 'react-lottie';
import { lockAnimation } from '../../assets/animations';

interface IAnimation extends LottieProps {
    height?: number;
    width?: number;
    customOptions?: Options;
    animationData?: any;
    options: any;
    segments?: number[];
}
/**
 *
 * @param height `height of the json data`
 * @param width  `width of the json data`
 * @returns
 */
const LottieAnimation = ({ height, width, animationData, options, customOptions, segments }: IAnimation) => {
    const defaultOptions: Options = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
            hideOnTransparent: true,
            progressiveLoad: true,
        },
        ...options,
        ...customOptions,
    };
    return <Lottie options={defaultOptions} height={width || 50} width={height || 50} segments={segments} />;
};

LottieAnimation.defaultProps = {
    options: {
        loop: true,
        autoplay: true,
        animationData: lockAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
            hideOnTransparent: true,
            progressiveLoad: true,
        },
    },
};

export { LottieAnimation };
