import React from 'react';
import { useSelector } from 'react-redux';
import { currentFilterCategorySelector } from '../../selectors/selectors';
import { SearchGridShimmer } from '../SearchGridShimmer';
import { UserCarouselShimmer } from '../UserCarouselShimmer';
import { WatchGridShimmer } from '../WatchGridShimmer';

const MainCategoryShimmer = () => {
    const categoryType = useSelector(currentFilterCategorySelector);

    if (categoryType === 'project') {
        return <WatchGridShimmer />;
    } else if (categoryType === 'contest') {
        return <SearchGridShimmer />;
    } else if (categoryType === 'talent') {
        return <UserCarouselShimmer />;
    } else return null;
};

export { MainCategoryShimmer };
