import { StyleSheet, View, Text } from 'react-native';
import { FormControl } from '@mui/material';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import './CustomePhoneTextField.css';
import { ColorScheme, theme } from '../../constants/theme';
import { FONT_ENUM } from '../../types/global/fonts.types';
import { ThemeContext } from '../../module/ThemeManager';
import whatsapp from '../../asset/images/icons/whatapp.svg';

export interface ICustomPhoneTextFieldProps {
    onChange?: (e: any, data: CountryData) => void;
    onBlur?: (e: any) => void;
    value?: string;
    isErrorState?: boolean;
    errorMsg?: string;
    instruction?: string;
    onFocus?: () => void;
    isFocused?: boolean;
}

export const CustomPhoneTextField: React.FC<ICustomPhoneTextFieldProps> = ({
    onChange,
    value,
    onBlur,
    isErrorState,
    errorMsg,
    instruction,
    onFocus,
    isFocused,
}) => {
    return (
        <ThemeContext.Consumer>
            {({ themeStyle }) => {
                const style = dynamicStyles(themeStyle, isErrorState, isFocused);

                return (
                    <FormControl sx={{ width: '100%' }}>
                        <PhoneInput
                            onChange={onChange}
                            value={value}
                            country="us"
                            specialLabel=""
                            inputStyle={style.datalist}
                            dropdownStyle={style.dropdown}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            countryCodeEditable={false}
                        />
                        {isErrorState && !!errorMsg && !isFocused && (
                            <View style={style.errHolder}>
                                <Text style={style.errMsg}>{errorMsg}</Text>
                            </View>
                        )}
                        {!!instruction && isFocused && (
                            <View style={style.errHolder}>
                                <Text style={style.whatapp}>{instruction}</Text>
                            </View>
                        )}
                    </FormControl>
                );
            }}
        </ThemeContext.Consumer>
    );
};

const dynamicStyles = (colorScheme: ColorScheme, isErrorState?: boolean, isFocused?: boolean) =>
    StyleSheet.create({
        datalist: {
            width: '100%',
            paddingRight: 10,
            backgroundColor: theme[colorScheme].gray_text,
            border: `1px solid ${
                isErrorState && !isFocused ? theme[colorScheme].secondary_red : theme[colorScheme].placeholderGray50
            }`,
            // outline: 'none',
            borderRadius: 8,
            WebkitCalendarPickerIndicator: 'none',
            fontFamily: FONT_ENUM.MAIN_REGULAR,
            fontSize: 14,
            color: theme[colorScheme].lightWhite,
            position: 'relative',
            backgroundImage: `url(${whatsapp})`,
            backgroundSize: '20px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right',
            backgroundOrigin: 'content-box',
        },
        dropdown: {
            backgroundColor: theme[colorScheme].gray_text,
            color: theme[colorScheme].lightWhite,
            width: '400px',
        },
        errHolder: {
            paddingHorizontal: 5,
            marginTop: 5,
        },
        errMsg: {
            color: theme[colorScheme].secondary_red,
            fontFamily: FONT_ENUM.MEDIUM_TEXT,
            fontSize: 12,
        },
        inst: {
            fontFamily: FONT_ENUM.REGULAR_HEADER,
            fontSize: 12,
            color: theme[colorScheme].tag_white,
        },
        whatapp: {
            maxWidth: 400,
            width: '100%',
            textAlign: 'left',
            fontSize: 12,
            fontStyle: 'normal',
            fontWeight: '400',
            color: theme[colorScheme].form_label,
            fontFamily: FONT_ENUM.MAIN_REGULAR,
        },
    });
