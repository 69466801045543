import { IUserSummary } from '../../types/global/helper';
import { ContestActivity, Like, ProjectActivity } from './model';

export const types = {
    CREATE_USER_PROFILE: 'CREATE_USER_PROFILE',
    CREATE_USER_PROFILE_SUCCESS: 'CREATE_USER_PROFILE_SUCCESS',
    CREATE_USER_PROFILE_FAILED: 'CREATE_USER_PROFILE_FAILED',

    SAVE_USER_DEVICE_TOKEN: 'SAVE_USER_DEVICE_TOKEN',
    SAVE_USER_DEVICE_TOKEN_SUCCESS: 'SAVE_USER_DEVICE_TOKEN_SUCCESS',
    SAVE_USER_DEVICE_TOKEN_FAILED: 'SAVE_USER_DEVICE_TOKEN_FAILED',

    REGISTER_CANCEL: 'REGISTER_CANCEL',

    PHONE_NUMBER_VERIFICATION: 'PHONE_NUMBER_VERIFICATION',
    PHONE_NUMBER_VERIFICATION_SUCCESS: 'PHONE_NUMBER_VERIFICATION_SUCCESS',
    PHONE_NUMBER_VERIFICATION_FAILED: 'PHONE_NUMBER_VERIFICATION_FAILED',

    GET_PHONE_VERIFICATION_CODE: 'GET_PHONE_VERIFICATION_CODE',
    GET_PHONE_VERIFICATION_CODE_SUCCESS: 'GET_PHONE_VERIFICATION_CODE_SUCCESS',
    GET_PHONE_VERIFICATION_CODE_FAILED: 'GET_PHONE_VERIFICATION_CODE_FAILED',

    START_EMAIL_FIELD_VERIFICATION: 'START_EMAIL_FIELD_VERIFICATION',
    EMAIL_FIELD_VERIFICATION_SUCCESS: 'EMAIL_FIELD_VERIFICATION_SUCCESS',
    EMAIL_FIELD_VERIFICATION_FAILED: 'EMAIL_FIELD_VERIFICATION_FAILED',

    START_PHONE_FIELD_VERIFICATION: 'START_PHONE_FIELD_VERIFICATION',
    PHONE_FIELD_VERIFICATION_SUCCESS: 'PHONE_FIELD_VERIFICATION_SUCCESS',
    PHONE_FIELD_VERIFICATION_FAILED: 'PHONE_FIELD_VERIFICATION_FAILED',

    START_EMAIL_FIELD_VERIFICATION2: 'START_EMAIL_FIELD_VERIFICATION2',
    EMAIL_FIELD_VERIFICATION_SUCCESS2: 'EMAIL_FIELD_VERIFICATION_SUCCESS2',
    EMAIL_FIELD_VERIFICATION_FAILED2: 'EMAIL_FIELD_VERIFICATION_FAILED2',

    START_PHONE_FIELD_VERIFICATION2: 'START_PHONE_FIELD_VERIFICATION2',
    PHONE_FIELD_VERIFICATION_SUCCESS2: 'PHONE_FIELD_VERIFICATION_SUCCESS2',
    PHONE_FIELD_VERIFICATION_FAILED2: 'PHONE_FIELD_VERIFICATION_FAILED2',

    START_USERNAME_FIELD_VERIFICATION: 'START_USERNAME_FIELD_VERIFICATION',

    USERNAME_AVAILABLE: 'USERNAME_AVAILABLE',
    USERNAME_TAKEN: 'USERNAME_TAKEN',
    USERNAME_FIELD_VERIFICATION_FAILED: 'USERNAME_FIELD_VERIFICATION_FAILED',
    USERNAME_FIELD_RESET: 'USERNAME_FIELD_RESET',

    GET_USER_PROFILE: 'GET_USER_PROFILE',
    GET_USER_PROFILE_SUCCESS: 'GET_USER_PROFILE_SUCCESS',
    GET_USER_PROFILE_FAILED: 'GET_USER_PROFILE_FAILED',
    RESET_USER_PROFILE: 'RESET_USER_PROFILE',

    UPDATE_USER_PROFILE: 'UPDATE_USER_PROFILE',
    UPDATE_USER_PROFILE_SUCCESS: 'UPDATE_USER_PROFILE_SUCCESS',
    UPDATE_USER_PROFILE_FAILED: 'UPDATE_USER_PROFILE_FAILED',

    GET_RESET_PASSWORD_TOKEN: 'GET_FORGOT_PASSWORD_TOKEN',
    GET_RESET_PASSWORD_TOKEN_SUCCESS: 'GET_FORGOT_PASSWORD_TOKEN_SUCCESS',
    GET_RESET_PASSWORD_TOKEN_FAILED: 'GET_FORGOT_PASSWORD_TOKEN_FAILED',

    RESET_PASSWORD: 'RESET_PASSWORD',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILED: 'RESET_PASSWORD_FAILED',

    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILED: 'CHANGE_PASSWORD_FAILED',

    /** The below will be used to get the profile for any other use besides the user logged in */
    GET_A_USER_PROFILE: 'GET_A_USER_PROFILE',
    GET_A_USER_PROFILE_SUCCESS: 'GET_A_USER_PROFILE_SUCCESS',
    GET_A_USER_PROFILE_FAIL: 'GET_A_USER_PROFILE_FAIL',

    SEARCH_USER: 'SEARCH_USER',
    SEARCH_USER_SUCCESS: 'SEARCH_USER_SUCCESS',
    SEARCH_USER_FAIL: 'SEARCH_USER_FAIL',

    GET_CURRENT_USER_PROFILE: 'GET_CURRENT_USER_PROFILE',
    GET_CURRENT_USER_PROFILE_SUCCESS: 'GET_CURRENT_USER_PROFILE_SUCCESS',
    GET_CURRENT_USER_PROFILE_FAIL: 'GET_CURRENT_USER_PROFILE_FAIL',

    UPDATE_ACTIVITY_LIKE: 'UPDATE_ACTIVITY_LIKE',
    UPDATE_ACTIVITY_LIKE_SUCCESS: 'UPDATE_ACTIVITY_LIKE_SUCCESS',
    UPDATE_ACTIVITY_LIKE_FAILED: 'UPDATE_ACTIVITY_LIKE_FAILED',

    REMOVE_ACTIVITY_LIKE: 'REMOVE_ACTIVITY_LIKE',
    REMOVE_ACTIVITY_LIKE_SUCCESS: 'REMOVE_ACTIVITY_LIKE_SUCCESS',
    REMOVE_ACTIVITY_LIKE_FAILED: 'REMOVE_ACTIVITY_LIKE_FAILED',

    GET_TOP_USER: 'GET_TOP_USER',
    GET_TOP_USER_SUCCESS: 'GET_TOP_USER_SUCCESS',
    GET_TOP_USER_FAIL: 'GET_TOP_USER_FAIL',

    INITIATE_EMAIL_VERIFY: 'INITIATE_EMAIL_VERIFY',
    INITIATE_EMAIL_VERIFY_SUCCESS: 'INITIATE_EMAIL_VERIFY_SUCCESS',
    INITIATE_EMAIL_VERIFY_FAIL: 'INITIATE_EMAIL_VERIFY_FAIL',

    VERIFY_EMAIL: 'VERIFY_EMAIL',
    VERIFY_EMAIL_SUCCESS: 'VERIFY_EMAIL_SUCCESS',
    VERIFY_EMAIL_FAIL: 'VERIFY_EMAIL_FAIL',
};

export interface IGetTopUserPayload {
    user_type: string[];
}

export interface IUserSearchResult {
    _id: string;
    firstName: string;
    lastName: string;
    profile_picture?: string;
    userName: string;
    verified?: boolean;
}

export interface ISearchUserPayload {
    searchTerm: string;
}

export interface ISearchUserResponse {
    result: IUserSummary[];
    total_result_count: number;
}

export interface IGetUserProfile {
    /** This flag makes it that visually, the dashboard does not refresh while we are updating new content
     * Just incase a user s currently looking at it
     */
    isRefresh?: boolean;
}

export interface IVerifyEmail {
    token: string;
}

export interface ILikeActivityPayload {
    activity: ProjectActivity | ContestActivity;
    isExternalProfile: boolean;
    /** The below informs us what page this like is coming from. Allows us to clear proper cache */
    from?: string;
    /**The cache value to clear example the username on whose name this is being liked */
    cache?: string;
}

export interface IRemoveLikeActivity extends ILikeActivityPayload {
    like: Like;
}
