import { useSpring, animated, to as interpolate, createInterpolator } from '@react-spring/web';
import { cubicCoordinates } from 'easing-coordinates';
import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { colors, theme } from '../../constants/theme';

const isAllBlack = true;

interface IGradientBackground {
    children: JSX.Element;
    style: StyleProp<ViewStyle>;
}

const easeMap = {
    'ease-in-out': { x1: 0.42, y1: 0, x2: 0.58, y2: 1 },
    'ease-out': { x1: 0, y1: 0, x2: 0.58, y2: 1 },
    'ease-in': { x1: 0.42, y1: 0, x2: 1, y2: 1 },
    ease: { x1: 0.25, y1: 0.1, x2: 0.25, y2: 1 },
    linear: { x1: 0.25, y1: 0.25, x2: 0.75, y2: 0.75 },
};

const easeCustom = '';

const GradientBackground = ({ children, style }: IGradientBackground) => {
    const { colorFrom, colorMid, colorTo } = useSpring({
        colorFrom: colors.background.start,
        colorMid: colors.background.mid,
        colorTo: colors.background.end,
    });

    const coordinates = React.useMemo(() => {
        let coordinates;
        const customBezier = easeCustom.split(',').map(Number);
        if (customBezier.length <= 1) {
            //@ts-ignore
            const { x1, y1, x2, y2 } = easeMap['ease-in-out'];
            coordinates = cubicCoordinates(x1, y1, x2, y2, 5);
        } else {
            coordinates = cubicCoordinates(customBezier[0], customBezier[1], customBezier[2], customBezier[3], 5);
        }

        return coordinates;
    }, []);

    const allStops = interpolate([colorFrom, colorMid, colorTo], (from, mid, to) => {
        const blend = createInterpolator({
            range: [0, 0.3, 0.4, 0.5, 0.6, 0.7, 1],
            output: [from, mid, mid, mid, mid, mid, to],
        });

        return coordinates.map(({ x, y }) => {
            const color = blend(y);

            return `${color} ${x * 100}%`;
        });
    });

    return (
        <animated.div
            style={{
                backgroundImage: isAllBlack
                    ? theme.light.black
                    : allStops.to((...args) => `linear-gradient(${132}deg, ${args.join(', ')})`),
                height: '100%',
                width: '100%',
                overflowX: 'hidden',
            }}>
            {children}
        </animated.div>
    );
};

export { GradientBackground };
