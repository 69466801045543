export const faqs = [
    {
        question: 'What is Thspian?',
        answer: "At Thspian, we are assembling the largest pool of creative talents across Africa and connecting them with industry experts, companies and opportunities. Our approach involves establishing ourselves as the exclusive AI-powered directory for Afro-creatives. We offer unparalleled access to vast amounts of data on Africa's creative sphere, including film festivals, casting hubs, a cine database, and much more.",
    },
    {
        question: 'What do we do?',
        answer: 'We provide a range of services tailored to your specific needs. For talented individuals, our primary objective is to offer abundant creative opportunities and nurture your artistic passion. We are dedicated to illuminating your path from obscurity to stardom, guiding you every step of the way.',
    },
    {
        question: 'What services do we provide?',
        answer: 'For businesses and investors, we provide exclusive access to our talent pool. Whether you require our premium studio on the go for content creation purposes, catering to both small businesses and large firms, or if you seek to promote your business in general, Thspian is here to assist you. Our pool of talents encompasses filmmakers and art directors who are readily available to bring your advertising campaigns, motion pictures, or promotional concepts to life.',
    },
    {
        question: 'Acts vs Video',
        answer: 'Acts are primarily vertical short form videos, usually less than 2 minutes in length. Videos are long form horizontal contents.',
    },
    {
        question: 'Can anyone participate?',
        answer: 'Unless specified, campaigns are restricted by age ratings. While anyone within the specified age group can participate. Thspian reserves the right to disqualify a contestant for any reason.',
    },
    {
        question: 'What are your age limits?',
        answer: 'Our campaigns carter to various age groups. Please check the age rating before applying',
    },
    {
        question: 'Can my submitted projects be public?',
        answer: 'Unless specified by the host, or required by our partners, Submitted projects can be public',
    },
    {
        question: 'Visible vs Published Projects?',
        answer: 'When a project is visible, it is accessible to the public, in feed and search. (The project also has to be published). A published project is out of draft mode and is eligible for submission to campaigns',
    },
    // Add more FAQ objects as needed
];

export const help = [
    {
        question: 'Project Upload Recommedations',
        answer: 'The following specifications allows for the best upload on our platform',
        details: [
            'File Format: MPEG 4',
            'Video codec: H.264',
            'Audio codec: AAC',
            'Audio bitrate: 128 kbps or better',
            'Video Resolutions: We prefer a 1280*720 resolution',
        ],
    },
    {
        question: 'Vote sourcing recommendation',
        answer: 'Thspian DOES not allow sourcing votes from external third party sources. All votes from be organic.',
    },
    {
        question: 'Share content recommendations',
        answer: 'We recommend that users share content using the designated share button on the content. This allows for  a rich social sharing.',
    },
    // Add more FAQ objects as needed
];
export const lipsumText = [
    {
        header: 'Lorem Ipsum Dolor Sit Amet',
        paragraphs: [
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ac turpis non velit hendrerit volutpat non at odio. Nullam scelerisque, ex sit amet ullamcorper congue, justo erat tempor ante, vel dignissim justo nisi nec justo. Curabitur gravida dolor sit amet felis consequat, eu blandit odio vulputate. Quisque auctor ligula vitae nunc rhoncus, a ultrices elit vestibulum. Nullam bibendum in arcu ac bibendum. Vestibulum dapibus nec odio in bibendum. Integer non finibus leo.',

            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam auctor, eros vel ultrices auctor, eros velit congue libero, at tempus nisi mi eu erat. Proin vitae nunc sed justo gravida euismod vel eu elit. Duis a efficitur ligula. Quisque sollicitudin, odio eu cursus tincidunt, tortor metus ullamcorper elit, in sollicitudin mi elit ut enim. ',

            'Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nullam tristique neque a tortor venenatis tincidunt. Maecenas tincidunt ex id tristique lacinia. Nam euismod, sapien et eleifend fringilla, sapien ex feugiat ante, nec accumsan tortor risus id nisl. Fusce condimentum in nisl ut tincidunt. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vivamus a tortor tortor. Sed gravida lectus et ex iaculis, in bibendum nunc facilisis.',
        ],
    },
    {
        header: 'Pellentesque Sed Nunc',
        paragraphs: [
            'Pellentesque sed nunc eget urna gravida tristique sed eu velit. Curabitur eu libero nec dui porttitor bibendum vel sed nunc. Sed vestibulum hendrerit nisl, sit amet lacinia tortor ultrices ut. Nam quis nulla id quam auctor interdum. Fusce sit amet est et ex malesuada tincidunt ac id ipsum. Sed ultrices ligula quis nulla pharetra, eget eleifend arcu volutpat. Aenean eu semper nisl.',

            'Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nullam tristique neque a tortor venenatis tincidunt. Maecenas tincidunt ex id tristique lacinia. Nam euismod, sapien et eleifend fringilla, sapien ex feugiat ante, nec accumsan tortor risus id nisl. Fusce condimentum in nisl ut tincidunt. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vivamus a tortor tortor. Sed gravida lectus et ex iaculis, in bibendum nunc facilisis.',

            'Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nullam tristique neque a tortor venenatis tincidunt. Maecenas tincidunt ex id tristique lacinia. Nam euismod, sapien et eleifend fringilla, sapien ex feugiat ante, nec accumsan tortor risus id nisl. Fusce condimentum in nisl ut tincidunt. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vivamus a tortor tortor. Sed gravida lectus et ex iaculis, in bibendum nunc facilisis.',
        ],
    },
    {
        header: 'Integer Mollis Ligula',
        paragraphs: [
            'Integer mollis ligula nec quam interdum, a tincidunt erat euismod. Morbi in justo a neque vehicula posuere at sit amet tortor. Fusce sed sapien eu quam ullamcorper laoreet ut sit amet nunc. Sed convallis auctor diam, non euismod risus varius nec.',

            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ac turpis non velit hendrerit volutpat non at odio. Nullam scelerisque, ex sit amet ullamcorper congue, justo erat tempor ante, vel dignissim justo nisi nec justo. Curabitur gravida dolor sit amet felis consequat, eu blandit odio vulputate. Quisque auctor ligula vitae nunc rhoncus, a ultrices elit vestibulum. Nullam bibendum in arcu ac bibendum. Vestibulum dapibus nec odio in bibendum. Integer non finibus leo.',

            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam auctor, eros vel ultrices auctor, eros velit congue libero, at tempus nisi mi eu erat. Proin vitae nunc sed justo gravida euismod vel eu elit. Duis a efficitur ligula. Quisque sollicitudin, odio eu cursus tincidunt, tortor metus ullamcorper elit, in sollicitudin mi elit ut enim. Aliquam non nunc nec elit commodo commodo. Sed et bibendum velit, vel elementum nisl. Sed aliquam dui at hendrerit viverra. Suspendisse potenti.',
        ],
    },
];
