import { call, put, all, takeLatest } from 'redux-saga/effects';
import { ICreateContestRequest, IGetRequests, IResolveRequests, types } from './types';
import {
    createContestRequestFail,
    createContestRequestSucces,
    getMyRequestsFail,
    getMyRequestsSuccess,
    resolveRequestFail,
    resolveRequestSuccess,
    getRequestsSuccess,
    getRequestsFail,
} from './action';
import { globalErrorHandler } from '../error/saga';
import { api } from '../../configurations/api';
import { requestUrl } from '../../configurations/api/url';
import { httpRequest } from '../types';
import { SentryCapture } from '../../analytics/Sentry';
import { Action } from 'redux';
import { showToastMessage } from '../../utils/AppUtils';

function* createRequest({ payload }: { payload: ICreateContestRequest; resolve: any; reject: any }): any {
    try {
        const response = yield call(api, requestUrl, httpRequest.POST, payload, 2, 1000);
        const { data } = response.data;
        yield put(createContestRequestSucces(data));
        yield call(showToastMessage, 'Request sent successfully. Expect an email response', 'success');
    } catch (error: any) {
        SentryCapture(error, 'error');
        const { data } = error || {};
        yield put(createContestRequestFail(error));
        yield call(globalErrorHandler, data.message);
    }
}

function* getContestRequests({ payload }: { payload: IGetRequests }): any {
    const { contestId, limit = 100, page = 0 } = payload;
    const request = `${requestUrl}?metadata.contest=${contestId}&page=${page}&limit=${limit}`;
    try {
        const response = yield call(api, request, httpRequest.GET, null, 5, 0);
        const { data } = response.data;
        yield put(getRequestsSuccess(data));
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(getRequestsFail(error));
        yield call(globalErrorHandler, error);
    }
}

function* getUserRequests(): any {
    try {
        const request = `${requestUrl}/my-requests`;
        const response = yield call(api, request, httpRequest.GET, {}, 3, 0);
        const { data } = response.data;
        yield put(getMyRequestsSuccess(data));
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(getMyRequestsFail(error));
        yield call(globalErrorHandler, error);
    }
}

function* resolveRequest({ payload, resolve, reject }: { payload: IResolveRequests; resolve: any; reject: any }): any {
    try {
        const response = yield call(api, `${requestUrl}/${payload.requestId}`, httpRequest.PATCH, payload, 2, 3000);
        const { data } = response;
        yield put(resolveRequestSuccess(data));
        resolve(data);
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(resolveRequestFail(error));
        yield call(globalErrorHandler, error);
        reject(error);
    }
}

interface ITaskAction extends Action {
    payload: ICreateContestRequest;
    reject: any;
    resolve: any;
}

interface ITaskContestRequests extends Action {
    payload: IGetRequests;
}

interface ITaskResolveRequests extends Action {
    payload: IResolveRequests;
    reject: any;
    resolve: any;
}
function* createRequestWatcher() {
    yield takeLatest<ITaskAction>(types.CREATE_REQUEST, createRequest);
}

function* getContestRequestsWatcher() {
    yield takeLatest<ITaskContestRequests>(types.GET_REQUESTS, getContestRequests);
}

function* getUserRequestsWatcher() {
    yield takeLatest(types.GET_MY_REQUESTS, getUserRequests);
}

function* resolveRequestWatcher() {
    yield takeLatest<ITaskResolveRequests>(types.RESOLVE_REQUEST, resolveRequest);
}

export default function* subscriptionSaga() {
    yield all([createRequestWatcher(), getContestRequestsWatcher(), getUserRequestsWatcher(), resolveRequestWatcher()]);
}
