/* eslint-disable import/no-cycle */
import moment from 'moment';

import { DEFAULT_FESTIVAL_AWS_IMAGE } from '../asset/images';
import { ASW_S3_LINK } from '../configurations/api/url';
import { strings } from '../constants';
import { InitialCardParams } from '../constants/types';
import { IVerifiable } from '../constants/types/api/apiResponse';
import {
    GenericMetric,
    IContest,
    IContestPrize,
    IDashboard,
    IMetrics,
    IProject,
    ISubmission,
} from '../types/global/helper';
import { SearchSuggestion, ActivityDocument, SuggestionDetails } from '../redux/activity/types';
import { IUser } from '../types/global/ListRender.types';
import { SearchResponse } from '../redux/discovery/search.types';
import { Contest, IDiscoveryResponse, ProjectSummary } from '../redux/discovery/types';
import { Audience } from '../redux/user/model';
import { IUserProfile } from '../redux/user/actions';
import {
    DiscoverySearchModel,
    DiscoverySearchUnparsedResponse,
    IAutoSuggestionSearchCell,
} from '../types/global/generic.types';
import { UserDashboard } from '../redux/account/accountDetailsResponse.type';
import { isEmpty } from './lodash';
import dayjs from 'dayjs';

export interface ISplashImage {
    film_poster: string;
    _id: string;
}

export interface ICountries {
    name: string;
    flag: string;
    code: string;
    dial_code: string;
}

type SliderArrayProperty = string[];

type SliderArray = {
    id: SliderArrayProperty;
    title: SliderArrayProperty;
    genre: SliderArrayProperty;
    releasedate: SliderArrayProperty;
    rating: SliderArrayProperty;
    runningTime: SliderArrayProperty;
    posterURI: SliderArrayProperty;
    trailerURI?: SliderArrayProperty;
    about?: SliderArrayProperty;
};

type FestivalFeature = {
    id: string[];
    title: string[];
    year_started: string[];
    posterURI: object[];
    about: string[];
    content_type: Array<string[]>;
};

type FestivalData = {
    categoryTitle: string;
    festival: FestivalFeature[];
};

export interface User {
    _id: string | number;
    name?: string;
    avatar?: string;
}
export interface Reply {
    title: string;
    value: string;
    messageId?: any;
}
export interface QuickReplies {
    type: 'radio' | 'checkbox';
    values: Reply[];
    keepIt?: boolean;
}
export interface IMessage {
    _id: string | number;
    text: string;
    createdAt: Date | number;
    user: User;
    image?: string;
    video?: string;
    audio?: string;
    system?: boolean;
    sent?: boolean;
    received?: boolean;
    pending?: boolean;
    quickReplies?: QuickReplies;
}

/**
 * TOP DOG DEBUG USE THIS METHID HERE
 * @param documentToParse Any document that needs to be parsed TODO: Ad more media files in th elist
 *  !!!!!!!!! CAREFUL WHEN ADJUSTING ANY PARAM HERE AS IT IS USED IN ALOT OF PLACES !!!!!!!!!!!
 */
export const genericParseSingleDocument = (
    documentToParse: any,
    additionalParse?: (arg?: any) => void,
    keyToPass?: any,
) => {
    const MEDIA_FILES = [
        'profile_picture',
        'project_gallery',
        'gallery',
        'feature_image',
        'image',
        'picture',
        'photo',
        'project',
        'act',
        'thumbnail',
        'act_thumbnail',
        'contest_media',
        'video',
        'team',
        'mentions',
        'download_link',
        'hls',
        'resolutions',
        '1080p',
        '720p',
        '480p',
        '360p',
    ];
    const ARRAY_FLAGS = ['likes', 'comments', 'replies'];
    const model: any = {};
    if (!documentToParse) return model;
    Object.keys(documentToParse).forEach(key => {
        if (MEDIA_FILES.includes(key) && typeof documentToParse[key] === 'string') {
            model[key] = `${ASW_S3_LINK}${documentToParse[key]}`;
        } else if (Array.isArray(documentToParse[key]) && MEDIA_FILES.includes(key)) {
            const tempArray: any = [];
            documentToParse[key].forEach((k: any) => {
                if (typeof k === 'string') {
                    tempArray.push(`${ASW_S3_LINK}${k}`);
                }

                if (typeof k === 'object') {
                    tempArray.push(genericParseSingleDocument(k));
                }
            });
            model[key] = tempArray;
        } else if (
            !Array.isArray(documentToParse[key]) &&
            typeof documentToParse[key] === 'object' &&
            documentToParse[key] !== null
        ) {
            model[key] = genericParseSingleDocument(documentToParse[key]);
            /** The below are are an example of arrays inside an object, Add more flags here if they meet the criteria */
        } else if (ARRAY_FLAGS.includes(key)) {
            model[key] = parseGenericCollection(documentToParse[key], genericParseSingleDocument);
        } else {
            model[key] = documentToParse[key];
        }

        if (additionalParse && keyToPass) {
            /** Add multiple objects to parse example when you wish to pass contest and project in the same response*/
            if (Array.isArray(keyToPass)) {
                keyToPass.forEach((key: string) => {
                    model[key] = additionalParse(documentToParse[key]);
                });
            } else {
                model[keyToPass] = additionalParse(documentToParse[keyToPass]);
            }
        }
    });
    return model;
};

export const parseMessage = (rawData: any) => {
    const messageModel: IMessage = {
        _id: '',
        text: '',
        createdAt: Date.now(),
        user: {
            _id: '',
            name: '',
            avatar: '',
        },
        received: false,
    };

    if (!rawData) return messageModel;
    if (rawData._id) messageModel._id = rawData._id;
    if (rawData.message_content) messageModel.text = rawData.message_content;
    if (rawData.createdAt) messageModel.createdAt = rawData.createdAt; // THE TIME FOR MESSAGE
    if (rawData.created_by) {
        const { _id, firstName, lastName, profile_picture } = rawData.created_by;
        if (_id) messageModel.user._id = _id;
        if (firstName || lastName) messageModel.user.name = `${firstName} ${lastName}`;
        if (profile_picture) messageModel.user.avatar = `${ASW_S3_LINK}${profile_picture}`;
    }
    if (rawData.received) messageModel.received = rawData.received;
    return messageModel;
};

export const parseMessages = (rawData: any[]) => {
    const messagesArray: any[] = [];
    rawData.forEach(message => {
        messagesArray.push(parseMessage(message));
    });
    return messagesArray;
};

export const parseGenericCollection = (
    object: Array<any>,
    methodCall: (...arg: any) => any,
    additionalParse?: (arg?: any) => void,
    keyToPass?: any,
): Array<any> => {
    const finalArray: Array<any> = [];
    object.forEach(item => finalArray.push(methodCall(item, additionalParse, keyToPass)));
    return finalArray;
};

export const parsePaymentMethod = (rawPaymentMethod: IVerifiable): InitialCardParams => {
    const paymentMethodModel: InitialCardParams = {
        id: '',
        cardNumber: '',
        expiryMonth: '',
        expiryYear: '',
        cardType: '',
        authorizationCode: '',
    };
    if (!rawPaymentMethod) return paymentMethodModel;
    if (rawPaymentMethod.id) paymentMethodModel.id = rawPaymentMethod.id;
    if (rawPaymentMethod.authorization.last4) paymentMethodModel.cardNumber = rawPaymentMethod.authorization.last4;
    if (rawPaymentMethod.authorization.exp_month)
        paymentMethodModel.expiryMonth = rawPaymentMethod.authorization.exp_month;
    if (rawPaymentMethod.authorization.exp_year)
        paymentMethodModel.expiryYear = rawPaymentMethod.authorization.exp_year;
    if (rawPaymentMethod.authorization.card_type)
        paymentMethodModel.cardType = rawPaymentMethod.authorization.card_type;
    if (rawPaymentMethod.authorization.authorization_code)
        paymentMethodModel.authorizationCode = rawPaymentMethod.authorization.authorization_code;

    return paymentMethodModel;
};

export const parsePaymentMethods = (rawPaymentMethods: IVerifiable[]) => {
    const paymentMethods: InitialCardParams[] = [];
    rawPaymentMethods.forEach((paymentMethod: IVerifiable) => {
        const parseSinglePaymentMethod = parsePaymentMethod(paymentMethod);
        paymentMethods.push(parseSinglePaymentMethod);
    });
    return paymentMethods;
};

export const parseSplashImages = (data: any) => {
    const parsedImages: ISplashImage = {
        film_poster: '',
        _id: '',
    };

    if (data.film_posters) parsedImages.film_poster = data.film_posters[0];
    if (data._id) parsedImages._id = data._id;

    return parsedImages;
};

export const convertMinuteSecondsDays = (time: any) => {
    if (!time) {
        return ''; // Return empty string if no time is provided
    }

    const currentTime = dayjs().local();
    const createdTime = dayjs(time).local();
    const duration = currentTime.diff(createdTime, 'minutes');

    // Convert duration to various units
    const years = Math.floor(duration / (365 * 24 * 60));
    const months = Math.floor((duration % (365 * 24 * 60)) / (30 * 24 * 60));
    const weeks = Math.floor((duration % (30 * 24 * 60)) / (7 * 24 * 60));
    const days = Math.floor((duration % (7 * 24 * 60)) / (24 * 60));
    const hours = Math.floor((duration % (24 * 60)) / 60);
    const minutes = duration % 60;

    // Determine the appropriate unit and return the result
    if (years > 0) {
        return `${years}y`;
    } else if (months > 0) {
        return `${months}mo`;
    } else if (weeks > 0) {
        return `${weeks}w`;
    } else if (days > 0) {
        return `${days}d`;
    } else if (hours > 0) {
        return `${hours}h`;
    } else {
        return `${minutes}m`;
    }
};

export const parseSingleContest = (rawData: IContest): IContest => {
    const model: any = {};

    if (!rawData) return model;
    Object.keys(rawData).forEach(key => {
        if (rawData[key as keyof IContest]) {
            if (key === 'feature_image') {
                model[key as never] = `${ASW_S3_LINK}${rawData[key as keyof IContest]}`;
            } else if (key === 'contest_media') {
                const media_array: any = [];
                rawData['contest_media'].forEach(media => media_array.push(`${ASW_S3_LINK}${media}`));
                model['contest_media'] = media_array;
            } else {
                model[key as never] = rawData[key as keyof IContest];
            }

            if (key === 'prizes') {
                model['prizes'] = parseGenericCollection(rawData.prizes as any[], genericParseSingleDocument);
            }

            if (key === 'submissions' && Array.isArray(rawData.submissions)) {
                model['submissions'] = parseGenericCollection(rawData.submissions, parseSingleSubmission);
            }

            if (key === 'host') {
                model['host'] = genericParseSingleDocument(rawData.host);
            }
        }
    });

    return model;
};

/** Parse Single Audition */
export const parseSingleAudition = (rawData: any, myApplications?: any) => {
    const auditionModel: any = {
        id: '',
        title: '',
        imageURI: '',
        location: '',
        production_company: '',
        synopsis: '',
        total_submissions: 0,
        applied: false,
        paid: false,
        timeToExpiration: 0,
        audition_type: '',
        expiration: '',
        applicants: [],
        created: '',
        attachment: '',
        rehearsal_date: '',
        rehearsal_date_unformatted: '',
        tags: [],
        production_personel: '',
        roles: [],
        interest: 0,
        status: '',
        shoot_location: {},
    };

    if (!rawData) return auditionModel;
    if (rawData._id) auditionModel.id = rawData._id;
    if (rawData.title) auditionModel.title = rawData.title;
    if (rawData.created_by?.profile_picture)
        auditionModel.imageURI = `${ASW_S3_LINK}${rawData.created_by.profile_picture}`;
    if (rawData.shoot_location?.description) auditionModel.location = rawData.shoot_location.description;
    if (rawData.shoot_location) auditionModel.shoot_location = rawData.shoot_location;
    if (rawData.production_company) auditionModel.production_company = rawData.production_company;
    if (rawData.plot) auditionModel.synopsis = rawData.plot;
    if (rawData.submissions_total_count) auditionModel.total_submissions = rawData.submissions_total_count;
    if (rawData.compensation) auditionModel.paid = rawData.compensation;
    if (rawData.status) auditionModel.status = rawData.status;
    if (rawData.timeToExpiration)
        auditionModel.timeToExpiration = moment(rawData.timeToExpiration).format('MMM. D, YYYY');
    if (rawData.audition_type) auditionModel.audition_type = rawData.audition_type;
    if (rawData.casting_expiration) auditionModel.expiration = rawData.casting_expiration;
    if (rawData.created) auditionModel.created = convertMinuteSecondsDays(rawData.created);
    if (rawData.attachment) auditionModel.attachment = `${ASW_S3_LINK}${rawData.attachment}`;
    if (rawData.rehearsal_date) auditionModel.rehearsal_date = moment(rawData.rehearsal_date).format('MMM. D, YYYY');
    if (rawData.rehearsal_date) auditionModel.rehearsal_date_unformatted = rawData.rehearsal_date;
    if (rawData.tags) auditionModel.tags = rawData.tags;
    if (rawData.production_personel) auditionModel.production_personel = rawData.production_personel;
    if (rawData.roles) auditionModel.roles = parseAuditionRoles(rawData.roles);
    if (myApplications)
        auditionModel.applied = myApplications.some((application: any) => application.audition === rawData._id);
    if (rawData.saves) auditionModel.interest = rawData.saves;

    return auditionModel;
};

export const parseAuditionRole = (rawRole: any) => {
    const roleModel = {
        id: '',
        character: '',
        description: '',
        age_range: '',
        gender: '',
        total_submissions: '',
        required_media: [],
        min_age: '',
        max_age: '',
    };

    if (!rawRole) return roleModel;
    if (rawRole._id) roleModel.id = rawRole._id;
    if (rawRole.character) roleModel.character = rawRole.character;
    if (rawRole.description) roleModel.description = rawRole.description;
    if (rawRole.age_range) roleModel.age_range = rawRole.age_range;
    if (rawRole.gender) roleModel.gender = rawRole.gender;
    if (rawRole.total_submissions) roleModel.total_submissions = rawRole.total_submissions;
    if (rawRole.required_media) roleModel.required_media = rawRole.required_media;
    if (rawRole.min_age) roleModel.min_age = rawRole.min_age;
    if (rawRole.max_age) roleModel.max_age = rawRole.max_age;

    return roleModel;
};

/** Parse Biography Details */
export const parseBiographyDetails = (rawData: any) => {
    const biographyModel: any = {
        id: '',
        fullName: '',
        dob: '',
        biography: '',
        country: '',
        gallery: [],
        imageURI: '',
        filmography: [],
        awards: {
            won: [],
            nominated: [],
        },
        contact: [],
        creator: '',
        skillset: [],
        height: 150,
    };

    if (!rawData) return biographyModel;
    if (rawData._id) biographyModel.id = rawData._id;
    if (rawData.fullName) biographyModel.fullName = rawData.fullName;
    if (rawData.date_of_birth) biographyModel.dob = moment(rawData.date_of_birth).format('MMM. D, YYYY');
    if (rawData.biography) biographyModel.biography = rawData.biography;
    if (rawData.country_of_birth && rawData.country_of_birth.address)
        biographyModel.country = rawData.country_of_birth.address;
    if (rawData.gallery) {
        rawData.gallery.forEach((image: any) => {
            biographyModel.gallery.push({ url: `${ASW_S3_LINK}${image}` });
        });
    }
    if (rawData.profile_picture && !rawData.profile_picture.startsWith('http://placehold'))
        biographyModel.imageURI = `${ASW_S3_LINK}${rawData.profile_picture}`;
    if (rawData.filmography) {
        const keys: any = [];
        const sortedFilmography: any = {};
        rawData.filmography.forEach((movie: any) => {
            if (movie.credit_type && movie.credit_type === 'crew' && movie.credit_role) keys.push(movie.credit_role);
        });
        const uniqueRoles: any = [...new Set(keys), 'Cast'];
        // eslint-disable-next-line no-restricted-syntax
        for (const key of uniqueRoles) {
            sortedFilmography[key as any] = [];
            rawData.filmography.forEach((movie: any) => {
                if ((movie.credit_role && key === movie.credit_role) || key.toLowerCase() === movie.credit_type) {
                    const filmographyModel: any = { title: '', role: '', year: '', type: '' };
                    filmographyModel.title = movie.movie.title;
                    filmographyModel.year = moment(movie.movie.date_of_release).format('YYYY');
                    filmographyModel.role = movie.credit_role;
                    filmographyModel.type = movie.credit_type;
                    sortedFilmography[key as any].push(filmographyModel);
                }
            });
        }

        biographyModel.filmography = Object.entries(sortedFilmography);
    }
    if (rawData.awards) {
        rawData.awards.forEach((award: any) => {
            const awardsModel = {
                awardYear: '',
                awardCategory: '',
                project: { id: '', title: '' },
                festival: { id: '', name: '', logo: '' },
            };
            awardsModel.awardYear = moment(award.award_year).format('YYYY');
            awardsModel.awardCategory = award.award_category.category_title;
            awardsModel.project.id = award.award_project._id;
            awardsModel.project.title = award.award_project.title;
            awardsModel.festival.id = award.festival._id;
            awardsModel.festival.name = award.festival.name;
            awardsModel.festival.logo = `${ASW_S3_LINK}${award.festival.festival_profile_picture}`;
            biographyModel.awards[award.award_status].push(awardsModel);
        });
    }
    if (rawData.contact) biographyModel.contact = rawData.contact;
    if (rawData.created_by) biographyModel.creator = rawData.created_by;
    if (rawData.skillset) biographyModel.skillset = rawData.skillset;
    if (rawData.height) biographyModel.height = rawData.height;

    return biographyModel;
};

const parseBioSearchResult = (rawBio: any) => {
    const biographyModel = {
        id: '',
        fullName: '',
        dob: '',
        skillset: '',
        country_of_birth: '',
        type: 'bio',
        imageURI: '',
    };

    if (!rawBio) return biographyModel;
    if (rawBio._id) biographyModel.id = rawBio._id;
    if (rawBio.fullName) biographyModel.fullName = rawBio.fullName;
    if (rawBio.date_of_birth) biographyModel.dob = moment(rawBio.date_of_birth).format('YYYY');
    if (rawBio.profile_picture && !rawBio.profile_picture.startsWith('http://placehold'))
        biographyModel.imageURI = `${ASW_S3_LINK}${rawBio.profile_picture}`;
    if (rawBio.skillset) biographyModel.skillset = rawBio.skillset;
    if (rawBio.country_of_birth && rawBio.country_of_birth.address)
        biographyModel.country_of_birth = rawBio.country_of_birth.address;

    return biographyModel;
};

export const parseFestivalHome = (rawFestivalDataObject: any) => {
    const festivalHomeData: any = [];
    const featuresArray: FestivalFeature = {
        id: [],
        title: [],
        year_started: [],
        posterURI: [{}],
        about: [],
        content_type: [],
    };

    Object.keys(rawFestivalDataObject).forEach((category: any) => {
        const festivalData: FestivalData = { categoryTitle: '', festival: [] };
        festivalData.categoryTitle = category.replace('_', ' ');

        if (category === 'features') {
            rawFestivalDataObject[category].forEach((feature: any, index: number) => {
                if (feature._id) featuresArray.id[index] = feature._id;
                if (feature.name) featuresArray.title[index] = feature.name;
                if (feature.year_started) featuresArray.year_started[index] = feature.year_started;
                if (feature.about) featuresArray.about[index] = feature.about;
                if (feature.content_type) featuresArray.content_type[index] = feature.content_type;
                if (feature.festival_profile_picture)
                    featuresArray.posterURI[index] = { image: `${ASW_S3_LINK}${feature.festival_profile_picture}` };
            });
            festivalData.festival.push(featuresArray);
        } else {
            rawFestivalDataObject[category].forEach((innerCategory: any) => {
                festivalData.festival.push(parseFestivalDetails(innerCategory));
            });
        }
        festivalHomeData.push(festivalData);
    });

    return festivalHomeData;
};

const parseFestivalSearchResult = (rawFest: any) => {
    const festivalModel: any = {
        id: '',
        fullName: '',
        imageURI: '',
        year_started: '',
        type: 'events',
        festival_history: '',
        festival_website: '',
    };

    if (!rawFest) return festivalModel;
    if (rawFest._id) festivalModel.id = rawFest._id;
    if (rawFest.name) festivalModel.fullName = rawFest.name;
    if (rawFest.festival_profile_picture) festivalModel.imageURI = `${ASW_S3_LINK}${rawFest.festival_profile_picture}`;
    if (rawFest.year_started) festivalModel.year_started = moment(rawFest.year_started).format('YYYY');
    if (rawFest.festival_website) festivalModel.festival_website = rawFest.festival_website;
    if (rawFest.festival_country) festivalModel.festival_country = rawFest.festival_country;

    return festivalModel;
};

export const parseFestivalDetails = (rawFest: any) => {
    const festivalModel: any = {
        id: '',
        title: '',
        posterURI: '',
        year_started: '',
        type: 'events',
        festival_history: '',
        about: '',
        festival_website: '',
        festival_coordinator: '',
        festival_gallery: [],
        festival_country: '',
        festival_location: '',
        sliderData: null,
    };

    const sliderArray: SliderArray = {
        id: [],
        title: [],
        genre: [],
        releasedate: [],
        rating: [],
        runningTime: [],
        posterURI: [],
        about: [],
    };

    if (!rawFest) return festivalModel;
    if (rawFest._id) {
        festivalModel.id = rawFest._id;
        sliderArray.id = rawFest._id;
    }
    if (rawFest.name) {
        festivalModel.title = rawFest.name;
        sliderArray.title.push(rawFest.name);
    }
    if (rawFest.festival_profile_picture) {
        festivalModel.posterURI = `${ASW_S3_LINK}${rawFest.festival_profile_picture}`;
        sliderArray.posterURI.push(`${ASW_S3_LINK}${rawFest.festival_profile_picture}`);
    } else {
        festivalModel.posterURI = DEFAULT_FESTIVAL_AWS_IMAGE;
        sliderArray.posterURI.push(DEFAULT_FESTIVAL_AWS_IMAGE);
    }
    if (rawFest.year_started) {
        festivalModel.year_started = moment(rawFest.year_started).format('YYYY');
        sliderArray.releasedate.push(`SINCE ${moment(rawFest.year_started).format('YYYY')}`);
    }
    if (rawFest.festival_history) festivalModel.festival_history = rawFest.festival_history;
    if (rawFest.about) festivalModel.about = rawFest.about;
    if (rawFest.festival_website) festivalModel.festival_website = rawFest.festival_website;
    if (rawFest.festival_country) festivalModel.festival_country = rawFest.festival_country;
    if (rawFest.festival_location) festivalModel.festival_location = rawFest.festival_location;
    if (rawFest.festival_gallery && rawFest.festival_gallery.length > 0) {
        rawFest.festival_gallery.forEach((image: string) => {
            festivalModel.festival_gallery.push({ url: `${ASW_S3_LINK}${image}` });
        });
    }

    return festivalModel;
};

/** Parse Single Festival Award, Great for single events like fetching one single award */
export const parseFestivalAwardCategory = (rawCategory: IContestPrize) => {
    const awardCatgeoryModel = {
        id: '',
        year_started: '',
        created_at: '',
        contest: '',
        category_title: '',
        created_by: '',
        prize: '',
        _id: '',
    };

    if (!rawCategory) return awardCatgeoryModel;
    if (rawCategory._id) awardCatgeoryModel._id = rawCategory._id;
    if (rawCategory.category_title) awardCatgeoryModel.category_title = rawCategory.category_title;
    if (rawCategory.contest) awardCatgeoryModel.contest = rawCategory.contest;
    if (rawCategory.prize) awardCatgeoryModel.prize = rawCategory.prize;
    if (rawCategory.year_started) awardCatgeoryModel.year_started = moment(rawCategory.year_started).format('YYYY');

    return awardCatgeoryModel;
};

/** Parse Multiple Award Categories */
export const parseFestivalAwardCategories = (rawCategories: object[]) => {
    const awardCategoryArray: object[] = [];
    rawCategories.forEach((category: any) => awardCategoryArray.push(parseFestivalAwardCategory(category)));
    return awardCategoryArray;
};

/** Parse Single Award Recipient */
export const parseAwardRecipient = (rawRecipient: any) => {
    const recipientModel = {
        id: '',
        award_year: '',
        person_awarded: {
            fullName: '',
            id: '',
            profile_picture: '',
        },
        award_description: '',
        award_project: {
            id: '',
            title: '',
        },
        award_status: '',
        bioId: '',
    };

    if (!rawRecipient) return recipientModel;
    if (rawRecipient._id) recipientModel.id = rawRecipient._id;
    if (rawRecipient.award_year) recipientModel.award_year = moment(rawRecipient.award_year).format('YYYY');
    if (rawRecipient.person_awarded) {
        const { _id, fullName, profile_picture } = rawRecipient.person_awarded;
        recipientModel.bioId = _id;
        recipientModel.person_awarded.fullName = fullName;
        recipientModel.person_awarded.id = _id;
        recipientModel.person_awarded.profile_picture = `${ASW_S3_LINK}${profile_picture}`;
    }
    if (rawRecipient.award_description) recipientModel.award_description = rawRecipient.award_description;
    if (rawRecipient.award_project) {
        const { id, title } = rawRecipient.award_project;
        recipientModel.award_project.id = id;
        recipientModel.award_project.title = title;
    }
    if (rawRecipient.award_status) recipientModel.award_status = rawRecipient.award_status;

    return recipientModel;
};

export const parseAwardRecipients = (rawRecipients: object[]) => {
    const recipientsArray: object[] = [];
    rawRecipients.forEach((recipent: any) => recipientsArray.push(parseAwardRecipient(recipent)));

    return recipientsArray;
};

const parseSearchResultMovie = (rawMovie: any) => {
    const searchResultMovieModel = {
        id: '',
        title: '',
        language: '',
        releaseDate: '',
        imageURI: '',
        credits: [],
        type: 'movie',
        movieRating: '',
        movieType: '',
    };

    if (!rawMovie) return searchResultMovieModel;
    if (rawMovie._id) searchResultMovieModel.id = rawMovie._id;
    if (rawMovie.title) searchResultMovieModel.title = rawMovie.title;
    if (rawMovie.language) searchResultMovieModel.language = rawMovie.language;
    if (rawMovie.date_of_release) searchResultMovieModel.releaseDate = moment(rawMovie.date_of_release).format('YYYY');
    if (rawMovie.movie_poster) searchResultMovieModel.imageURI = `${ASW_S3_LINK}${rawMovie.movie_poster}`;
    if (rawMovie.movie_rating) searchResultMovieModel.movieRating = rawMovie.movie_rating;
    if (rawMovie.movie_type) searchResultMovieModel.movieType = rawMovie.movie_type;

    return searchResultMovieModel;
};

export const parseCountries = (data: any) => {
    const _countryArray: any = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < data.length; i++) {
        _countryArray.push({
            label: data[i].name,
            value: data[i].name,
            flag: data[i].flag,
            dial_code: data[i].dial_code,
            code: data[i].code,
        });
    }

    return _countryArray;
};

export const parsePasswordResetLinktext = (email: string) => {
    const _email = `${email.split('').splice(0, 5).join('')}**********`;
    const final_message = strings.reset_link.replace('your_email', _email);
    return final_message;
};

export const parseUserProfile = (rawProfile: any) => {
    const userProfile: IUserProfile = {
        firstName: '',
        lastName: '',
        email: '',
        _id: '',
        role: 'regular',
        userName: '',
        phoneNumber: '',
        phone_verified: true,
        email_verified: false,
        profile_picture: '',
        user_profile: {
            id: '',
            skills: [],
            demo_reel: [],
            audio_reel: [],
            about: '',
            birthDay: '',
            age_from: 0,
            age_to: 0,
            hair_color: '',
            weight: '',
            body_type: '',
            photos: [],
        },
        application_limit: 0,
        casting_call_limit: 0,
        permissions: [],
        user_type: [],
        audience: [],
        intro: '',
        job_description: '',
        total_audience_count: 0,
        verified: false,
        education: '',
        home_town: {
            coordinates: [],
            type: 'Point',
            address: '',
            description: '',
        },
        active_subscription: '',
        projects: [],
        project_activities: [],
        contest_activities: [],
        feature_image: '',
    };

    if (!rawProfile) return userProfile;
    if (rawProfile.firstName) userProfile.firstName = rawProfile.firstName;
    if (rawProfile.lastName) userProfile.lastName = rawProfile.lastName;
    if (rawProfile.email) userProfile.email = rawProfile.email;
    if (rawProfile._id) userProfile._id = rawProfile._id;
    if (rawProfile.location) userProfile.location = { ...rawProfile.location };
    if (rawProfile.role) userProfile.role = rawProfile.role;
    if (rawProfile.userName) userProfile.userName = rawProfile.userName;
    if (rawProfile.phoneNumber) userProfile.phoneNumber = rawProfile.phoneNumber;
    if (rawProfile.phone_verified) userProfile.phone_verified = rawProfile.phone_verified;
    if (rawProfile.email_verified) userProfile.email_verified = rawProfile.email_verified;
    if (rawProfile.profile_picture) userProfile.profile_picture = `${ASW_S3_LINK}${rawProfile.profile_picture}`;
    if (rawProfile.active_subscription) userProfile.active_subscription = rawProfile.active_subscription;
    if (rawProfile.userName) userProfile.userName = rawProfile.userName;
    if (rawProfile.application_limit) userProfile.application_limit = rawProfile.application_limit;
    if (rawProfile.casting_call_limit) userProfile.casting_call_limit = rawProfile.casting_call_limit;
    if (rawProfile.permissions) userProfile.permissions = rawProfile.permissions;
    if (rawProfile.user_type) userProfile.user_type = rawProfile.user_type;
    if (rawProfile.profile) {
        userProfile.user_profile = parseProfile(rawProfile.profile[0]);
    } else {
        userProfile.user_profile = parseProfile();
    }
    userProfile.audience = rawProfile.audience?.filter((x: Audience) => !x.blocked);
    userProfile.intro = rawProfile.intro ?? '';
    userProfile.job_description = rawProfile.job_description ?? '';
    userProfile.education = rawProfile.education ?? '';
    userProfile.home_town = rawProfile.home_town ?? '';
    userProfile.total_audience_count = rawProfile.total_audience_count ?? 0;
    userProfile.verified = rawProfile.verified;
    userProfile.projects = rawProfile.projects;
    userProfile.project_activities = rawProfile.project_activities;
    userProfile.contest_activities = rawProfile.contest_activities;
    return userProfile;
};

export const parseProfile = (profileObject?: any) => {
    const profileModel: any = {
        id: '',
        skills: [],
        demo_reel: [],
        audio_reel: [],
        about: '',
        birthDay: '',
        age_from: 0,
        age_to: 0,
        age_range: '',
        hair_color: '',
        weight: '',
        body_type: '',
        height: '',
        sex: '',
        photos: [],
        profile_picture: '',
        stage_name: '',
        socials: {
            boxofix: '',
            tik_tok: '',
            instagram: '',
            twitter: '',
        },
        past_work: [],
        filmography: [],
        location: '',
        user: '',
    };

    if (!profileObject) return profileModel;
    if (profileObject.id) profileModel.id = profileObject.id;
    if (profileObject.skills) profileModel.skills = profileObject.skills;
    if (profileObject.demo_reel) profileModel.demo_reel = profileObject.demo_reel;
    if (profileObject.audio_reel) profileModel.audio_reel = profileObject.audio_reel;
    if (profileObject.about) profileModel.about = profileObject.about;
    if (profileObject.birth_data) profileModel.birthDay = moment(profileObject.birth_data).format('MMM. D, YYYY');
    if (profileObject.age_from && profileObject.age_to) {
        profileModel.age_range = `${profileObject.age_from} - ${profileObject.age_to}`;
    }
    if (profileObject.hair_color) profileModel.hair_color = profileObject.hair_color;
    if (profileObject.weight) profileModel.weight = profileObject.weight;
    if (profileObject.body_type) profileModel.body_type = profileObject.body_type;
    if (profileObject.height) profileModel.height = profileObject.height;
    if (profileObject.sex) profileModel.sex = profileObject.sex;
    if (profileObject.profile_picture) profileModel.profile_picture = `${ASW_S3_LINK}${profileObject.profile_picture}`;
    if (profileObject.stage_name) profileModel.stage_name = profileObject.stage_name;
    if (profileObject.location && profileObject.location.address) {
        profileModel.location = profileObject.location.address;
    }
    if (profileObject.socials) {
        const { boxofix, tik_tok, instagram } = profileObject.socials;
        if (boxofix) profileModel.socials.boxofix = boxofix;
        if (tik_tok) profileModel.socials.tik_tok = tik_tok;
        if (instagram) profileModel.socials.instagram = instagram;
    }
    if (profileObject.photos)
        profileObject.photos.forEach((photo: any) => {
            profileModel.photos.push(`${ASW_S3_LINK}${photo}`);
        });

    if (profileObject.filmography) {
        const keys: any = [];
        const sortedFilmography: any = {};
        profileObject.filmography.forEach((movie: any) => {
            if (movie.credit_type && movie.credit_type === 'crew' && movie.credit_role) keys.push(movie.credit_role);
        });
        const uniqueRoles: any = [...new Set(keys), 'Cast'];
        // eslint-disable-next-line no-restricted-syntax
        for (const key of uniqueRoles) {
            sortedFilmography[key as any] = [];
            profileObject.filmography.forEach((movie: any) => {
                if ((movie.credit_role && key === movie.credit_role) || key.toLowerCase() === movie.credit_type) {
                    const filmographyModel: any = { title: '', role: '', year: '', type: '' };
                    filmographyModel.title = movie.movie.title;
                    filmographyModel.year = moment(movie.movie.date_of_release).format('YYYY');
                    filmographyModel.role = movie.credit_role;
                    filmographyModel.type = movie.credit_type;
                    sortedFilmography[key as any].push(filmographyModel);
                }
            });
        }
        if (profileObject.created_by) profileModel.user = profileObject.created_by;

        profileModel.filmography = Object.entries(sortedFilmography);
    }

    return profileModel;
};

export const parseProfiles = (rawData: any[]) => {
    const profileArray: any[] = [];
    rawData.forEach(profile => profileArray.push(parseProfile(profile)));
    return profileArray;
};

export const parseReview = (rawData: any, userId?: any) => {
    const reviewModel: any = {
        _id: '',
        rating: '',
        created_at: '',
        created_by: '',
        review_subject: '',
        review_text: '',
        total_likes: 0,
        total_dislikes: 0,
        total_reactions: 0,
        user: {
            _id: '',
            firstName: '',
            lastName: '',
        },
        likes: [],
        liked: false,
        disliked: false,
        movie_poster: '',
        movie_title: '',
    };

    if (!rawData) return reviewModel;
    if (rawData._id) reviewModel._id = rawData._id;
    if (rawData.rating) reviewModel.rating = rawData.rating;
    if (rawData.created_at) reviewModel.created_at = rawData.created_at;
    if (rawData.created_by) reviewModel.created_by = rawData.created_by;
    if (rawData.review_subject) reviewModel.review_subject = rawData.review_subject;
    if (rawData.review_text) reviewModel.review_text = rawData.review_text;
    if (rawData.total_likes) reviewModel.total_likes = rawData.total_likes;
    if (rawData.total_dislikes) reviewModel.total_dislikes = rawData.total_dislikes;
    if (rawData.total_reactions) reviewModel.total_reactions = rawData.total_reactions;
    if (rawData.user) {
        reviewModel.user._id = rawData.user?._id;
        reviewModel.user.firstName = rawData.user.firstName;
        reviewModel.user.lastName = rawData.user.lastName;
    }
    if (rawData.likes) {
        reviewModel.likes = rawData.likes;
        reviewModel.liked = rawData.likes.some((el: any) => el.user === userId && el.like_type === 'like');
        reviewModel.disliked = rawData.likes.some((el: any) => el.user === userId && el.like_type === 'dislike');
    }
    if (rawData.movie?.movie_poster) reviewModel.movie_poster = `${ASW_S3_LINK}${rawData.movie.movie_poster}`;
    if (rawData.movie?.title) reviewModel.movie_title = rawData.movie.title;
    return reviewModel;
};

export const parseReviews = (reviewsObject: any, userId: any) => {
    const reviewArray: any = [];
    reviewsObject.forEach((review: any) => reviewArray.push(parseReview(review, userId)));
    return reviewArray;
};

/** The Below Parses reviews Displayed on Profile Screen */
const parseUserReviewAndRoles = (rawData: any) => {
    /** Also parses applications. TODO: SPlit this method into two groups to keep it's single responsibility */
    const reviewModel: any = {
        id: '',
        movie_poster: '',
        casting_agency_profile: '',
    };
    if (!rawData) return reviewModel;
    if (rawData._id) reviewModel.id = rawData._id;
    if (rawData?.movie?.movie_poster) reviewModel.movie_poster = `${ASW_S3_LINK}${rawData.movie.movie_poster}`;
    if (rawData.role?.audition?.created_by?.profile_picture)
        reviewModel.casting_agency_profile = `${ASW_S3_LINK}${rawData.role.audition.created_by.profile_picture}`;

    return reviewModel;
};

export const parseTalentProfile = (rawData: any) => {
    const model = {
        id: '',
        location: '',
        photos: [],
        skills: [],
        demo_reel: [],
        age_to: '',
        age_from: '',
        hair_color: '',
        birth_data: '',
        height: '',
        body_type: '',
        sex: '',
        profile_picture: '',
        age_range: '',
        stage_name: '',
        filmography: [],
    };

    if (!rawData) return model;
    if (rawData.id) model.id = rawData.id;
    if (rawData.location && rawData.location.address) model.location = rawData.location.address;
    if (rawData.photos && rawData.photos.length > 0) {
        const photosFormat: string[] = [];
        rawData.photos.forEach((photo: string) => {
            photosFormat.push(`${ASW_S3_LINK}${photo}`);
        });
        model.photos = photosFormat as any;
    }
    if (rawData.skills) model.skills = rawData.skills;
    if (rawData.demo_reel) model.demo_reel = rawData.demo_reel;
    if (rawData.age_from && rawData.age_to) {
        model.age_range = `${rawData.age_from} - ${rawData.age_to}`;
    }
    if (rawData.hair_color) model.hair_color = rawData.hair_color;
    if (rawData.birth_data) model.birth_data = moment(rawData.birth_data).format('MMM. D, YYYY');
    if (rawData.height) model.height = rawData.height;
    if (rawData.body_type) model.body_type = rawData.body_type;
    if (rawData.sex) model.sex = rawData.sex;
    if (rawData.profile_picture) model.profile_picture = `${ASW_S3_LINK}${rawData.profile_picture}`;
    if (rawData.stage_name) model.stage_name = rawData.stage_name;
    if (rawData.filmography) {
        const keys: any = [];
        const sortedFilmography: any = {};
        rawData.filmography.forEach((movie: any) => {
            if (movie.credit_type && movie.credit_type === 'crew' && movie.credit_role) keys.push(movie.credit_role);
        });
        const uniqueRoles: any = [...new Set(keys), 'Cast'];
        // eslint-disable-next-line no-restricted-syntax
        for (const key of uniqueRoles) {
            sortedFilmography[key as any] = [];
            rawData.filmography.forEach((movie: any) => {
                if ((movie.credit_role && key === movie.credit_role) || key.toLowerCase() === movie.credit_type) {
                    const filmographyModel: any = { title: '', role: '', year: '', type: '' };
                    filmographyModel.title = movie.movie.title;
                    filmographyModel.year = moment(movie.movie.date_of_release).format('YYYY');
                    filmographyModel.role = movie.credit_role;
                    filmographyModel.type = movie.credit_type;
                    sortedFilmography[key as any].push(filmographyModel);
                }
            });
        }
        model.filmography = Object.entries(sortedFilmography) as any;
    }

    return model;
};

/** Duplicate of the parse Application With Better Naming */
export const parseSingleAuditionApplication = (rawData: any) => {
    const model = {
        id: '',
        audition: '',
        filing_status: '',
        created_at: '',
        actor: {
            id: '',
            location: '',
            profile_picture: '',
            firstName: '',
            role: '',
        },
        profile: {
            profile_picture: '',
            stage_name: '',
        },
        role: {
            total_submissions: 0,
            min_age: 0,
            max_age: 100,
            id: '',
            character: '',
            gender: '',
            description: '',
        },
    };

    if (!rawData) return model;
    if (rawData.id) model.id = rawData.id;
    if (rawData.audition) model.audition = rawData.audition;
    if (rawData.filing_status) model.filing_status = rawData.filing_status;
    if (rawData.created_at) model.created_at = moment(rawData.created_at).format('MMM. D, YYYY');
    if (rawData.actor) {
        const { location, role, id, firstName, profile_picture } = rawData.actor;
        model.actor.firstName = firstName;
        model.actor.location = location && location.address && location.address;
        model.actor.role = role;
        model.actor.id = id;
        model.actor.profile_picture = `${ASW_S3_LINK}${profile_picture}`;
    }

    if (rawData.profile) model.profile = parseProfile(rawData.profile);

    if (rawData.role) {
        const { total_submissions, min_age, max_age, id, character, description, gender } = rawData.role;
        model.role.character = character;
        model.role.total_submissions = total_submissions;
        model.role.max_age = max_age;
        model.role.min_age = min_age;
        model.role.id = id;
        model.role.description = description;
        model.role.gender = gender;
    }

    if (model.profile?.stage_name && model.profile?.stage_name === '') {
        model.profile.stage_name = rawData.actor.firstName;
    }

    if (model.profile.profile_picture && model.profile.profile_picture === '') {
        model.profile.profile_picture = `${ASW_S3_LINK}${rawData.actor.profile_picture}`;
    }

    return model;
};

/** Pasring array of Audition Applications */
export const parseAuditionApplications = (applications: any[]) => {
    const applicationArray: any[] = [];
    applications.forEach((application: any) => applicationArray.push(parseSingleAuditionApplication(application)));
    return applicationArray;
};

export const parseApplication = (application: any) => {
    const applicationModel = {
        id: '',
        character: '',
        gender: '',
        submitted: '',
        agency: '',
        casting_agency_profile: '',
        audition_title: '',
        auditionId: '',
        agencyId: '',
        roleId: '',
        applicantProfileId: '',
        applicantLocation: '',
        applicantProfilePicture: '',
        applicantAgeRange: '',
        applicantHeight: '',
        applicantsName: '',
        filingStatus: '',
        profile: {},
    };

    if (!application) return applicationModel;
    if (application._id) applicationModel.id = application._id;
    if (application.created_at) applicationModel.submitted = moment(application.created_at).format('MMM. D, YYYY');
    if (application.profile) {
        applicationModel.profile = application.profile;
        const { id, age_from, age_to, height, profile_picture, location, user, stage_name } = application.profile;
        applicationModel.applicantProfileId = id;
        if (profile_picture && profile_picture !== '') {
            applicationModel.applicantProfilePicture = `${ASW_S3_LINK}${profile_picture}`;
        } else if (user.profile_picture && user.profile_picture !== '') {
            applicationModel.applicantProfilePicture = `${ASW_S3_LINK}${user.profile_picture}`;
        }
        if (age_from && age_to) {
            applicationModel.applicantAgeRange = `${age_from} - ${age_to}`;
        }
        if (location && location.address) {
            applicationModel.applicantLocation = location.address;
        }
        applicationModel.applicantHeight = height;

        if (stage_name && stage_name !== '') {
            applicationModel.applicantsName = stage_name;
        } else if (user.firstName || user.lastName) {
            applicationModel.applicantsName = `${user.firstName} ${user.lastName}`;
        }
    }
    if (application.role) {
        const { id, audition, gender, character } = application.role;
        applicationModel.roleId = id;
        applicationModel.gender = gender;
        applicationModel.character = character;

        if (audition) {
            const { title, _id, created_by } = audition;
            applicationModel.audition_title = title;
            applicationModel.auditionId = _id;

            if (created_by) {
                const { firstName, lastName, profile_picture } = created_by;
                applicationModel.agency = `${firstName} ${lastName}`;
                applicationModel.agencyId = created_by.id;
                applicationModel.casting_agency_profile = `${ASW_S3_LINK}${profile_picture}`;
            }
        }
    }

    if (application.filing_status) applicationModel.filingStatus = application.filing_status;

    return applicationModel;
};

export const parseApplications = (applications: any[]) => {
    const applicationArray: any[] = [];
    applications.forEach((application: any) => applicationArray.push(parseApplication(application)));
    return applicationArray;
};

// This only Parses User Roles
export const parseUserReviewsAndRoles = (reviewsObject: any) => {
    const reviewArray: any = [];
    reviewsObject.forEach((review: any) => reviewArray.push(parseUserReviewAndRoles(review)));
    return reviewArray;
};

export const parseUserReviews = (reviewsObject: any) => {
    const reviewArray: any = [];
    reviewsObject.forEach((review: any) => reviewArray.push(parseReview(review)));
    return reviewArray;
};

export const runningTimeFormat = (value: number) => {
    const format = value > 60 ? `${Math.floor(value / 60)}h ${value % 60}m` : `${value}m`;
    return format;
};

export const parseMovieDetails = (rawData: any) => {
    const movieModel = {
        _id: '',
        title: '',
        movieRating: '',
        imageURI: '',
        posterURI: '',
        releaseDateFormatted: '',
        trailerURI: '',
        display_tag: '',
        genre: '',
        runningTime: '',
        sliderData: null,
        numberOfReviews: 0,
        budget: 0,
        gross: 0,
        openingWeekend: 0,
        likes: [],
        created_by: '',
        liked: null,
        synopsis: '',
        id: '',
    };
    // INFO CREATING THE SLIDER OBJECT
    const sliderArray: any = {
        id: [],
        title: [],
        genre: [],
        releasedate: [],
        rating: [],
        runningTime: [],
        posterURI: [],
        trailerURI: [],
    };

    if (!rawData) return movieModel;
    if (rawData._id) {
        movieModel._id = rawData._id;
        movieModel.id = rawData._id; // Added this to fix a bug where other files are names id and not _id
        sliderArray.id.push(rawData._id);
    }
    if (rawData.title) {
        sliderArray.title.push(rawData.title);
        movieModel.title = rawData.title;
    }
    if (rawData.movie_rating) {
        movieModel.movieRating = rawData.movie_rating.toFixed(1);
        sliderArray.rating.push(rawData.movie_rating.toFixed(1));
    }
    if (rawData.movie_poster) movieModel.imageURI = `${ASW_S3_LINK}${rawData.movie_poster}`;
    if (rawData.number_of_reviews) movieModel.numberOfReviews = rawData.number_of_reviews;
    if (rawData.budget) movieModel.budget = rawData.budget;
    if (rawData.gross) movieModel.gross = rawData.gross;
    if (rawData.opening_weekend) movieModel.openingWeekend = rawData.opening_weekend;
    if (rawData.movie_poster) {
        movieModel.posterURI = `${ASW_S3_LINK}${rawData.movie_poster}`;
        sliderArray.posterURI.push({ image: `${ASW_S3_LINK}${rawData.movie_poster}` });
    }
    if (rawData.date_of_release) {
        movieModel.releaseDateFormatted = moment(rawData.date_of_release).format('MMM. D, YYYY');
        sliderArray.releasedate.push(moment(rawData.date_of_release).format('YYYY'));
    }
    if (rawData.movie_trailer) {
        movieModel.trailerURI = `${ASW_S3_LINK}${rawData.movie_trailer}`;
        sliderArray.trailerURI.push(`${ASW_S3_LINK}${rawData.movie_trailer}`);
    }
    if (rawData.display_tag) movieModel.display_tag = rawData.display_tag;
    if (rawData.genre) {
        movieModel.genre = rawData.genre;
        sliderArray.genre.push(rawData.genre.slice(0, 2).join(', '));
    }
    if (rawData.technical_spec) {
        movieModel.runningTime = runningTimeFormat(rawData.technical_spec?.runtime);
        sliderArray.runningTime.push(runningTimeFormat(rawData.technical_spec?.runtime));
    }
    if (rawData.likes) movieModel.likes = rawData.likes;
    if (rawData.created_by) movieModel.created_by = rawData.created_by;
    if (rawData.synopsis) movieModel.synopsis = rawData.synopsis;

    movieModel.sliderData = sliderArray;

    return movieModel;
};

export const parseSingleFavouriteMovie = (rawObject: any) => {
    const favModel = {
        rating: '',
        id: '',
        title: '',
        imageURI: '',
        releaseDate: '',
    };

    if (!rawObject) return favModel;
    if (rawObject.movie_rating) favModel.rating = rawObject.movie_rating;
    if (rawObject._id) favModel.id = rawObject._id;
    if (rawObject.title) favModel.title = rawObject.title;
    if (rawObject.date_of_release) favModel.releaseDate = rawObject.date_of_release;

    return favModel;
};

export const parseSingleFavouriteReview = (rawObject: any) => {
    const favModel = {
        reviewSubject: '',
        reviewText: '',
        id: '',
        rating: '',
        movieId: '',
        movieTitle: '',
        moviePoster: '',
    };

    if (!rawObject) return favModel;
    if (rawObject.review_subject) favModel.reviewSubject = rawObject.review_subject;
    if (rawObject.review_text) favModel.reviewText = rawObject.review_text;
    if (rawObject._id) favModel.id = rawObject._id;
    if (rawObject.movie.title) favModel.movieTitle = rawObject.movie.title;
    if (rawObject.movie.movie_poster) favModel.moviePoster = rawObject.movie.movie_poster;
    if (rawObject.movie._id) favModel.movieId = rawObject.movie._id;

    return favModel;
};

export const parseFavouriteCollection = (rawData: object[]) => {
    const reviewsArray: object[] = [];
    const moviesArray: object[] = [];

    rawData.forEach((favoriteItem: any) => {
        if (favoriteItem.movie) {
            const parsedFavMovie = parseSingleFavouriteMovie(favoriteItem.movie);
            moviesArray.push(parsedFavMovie);
        }

        if (favoriteItem.review) {
            const parsedFavReview = parseSingleFavouriteReview(favoriteItem.review);
            reviewsArray.push(parsedFavReview);
        }
    });

    return { reviews: reviewsArray, movies: moviesArray };
};
export const parseCrewCredit = (rawData: any) => {
    const creditModel = {
        id: '',
        created_at: '',
        owner: '',
        owner_id: '',
        profile_picture: '',
        credit_type: '',
    };

    if (!rawData) return creditModel;
    if (rawData.id) creditModel.id = rawData.id;
    if (rawData.created_at) creditModel.created_at = moment(rawData.created_at).format('MMM. D, YYYY');
    if (rawData.user) {
        const { fullName, profile_picture, id } = rawData.user;
        if (fullName) creditModel.owner = fullName;
        if (profile_picture) creditModel.profile_picture = `${ASW_S3_LINK}${profile_picture}`;
        if (id) creditModel.owner_id = id;
    }
    if (rawData.credit_type) creditModel.credit_type = rawData.credit_type;

    return creditModel;
};

export const parseCrewCredits = (rawData: any) => {
    const CreditArray: object[] = [];
    rawData.forEach((credit: any) => {
        CreditArray.push(parseCrewCredit(credit));
    });
    return CreditArray;
};

export const parseSingleMovie = (rawData: any) => {
    const movieDetails: any = {
        movieType: '',
        movieFormat: '',
        language: [],
        companyCredits: [],
        budget: 0,
        gross: 0,
        openingWeekend: '',
        audienceRating: '',
        synopsis: '',
        plot: '',
        storyline: '',
        locations: [],
        totalLikes: 0,
        cast: [],
        crew: [],
        other: [],
        reviews: [],
        awards: [],
        formattedgenre: '',
        director: '',
        writer: '',
    };

    const firstParse = parseMovieDetails(rawData);

    if (!rawData) return movieDetails;
    if (rawData.movie_type) movieDetails.movieType = rawData.movie_type;
    if (rawData.movie_format) movieDetails.movieFormat = rawData.movie_format;
    if (rawData.language) movieDetails.language = rawData.language;
    if (rawData.companyCredits) movieDetails.companyCredits = rawData.companyCredits;
    if (rawData.budget) movieDetails.budget = rawData.budget;
    if (rawData.gross) movieDetails.gross = rawData.gross;
    if (rawData.opening_weekend) movieDetails.openingWeekend = rawData.opening_weekend;
    if (rawData.audience_rating) movieDetails.audienceRating = rawData.audience_rating;
    if (rawData.synopsis) movieDetails.synopsis = rawData.synopsis;
    if (rawData.plot) movieDetails.plot = rawData.plot;
    if (rawData.storyline) movieDetails.storyline = rawData.storyline;
    if (rawData.locations) movieDetails.locations = rawData.locations;
    if (rawData.total_likes) movieDetails.totalLikes = rawData.total_likes;
    if (rawData.credit)
        rawData.credit.forEach((el: any) => {
            if (el.credit_type === 'cast') movieDetails.cast.push(el);
            if (el.credit_type === 'crew') movieDetails.crew.push(el);
            if (el.credit_type === 'others') movieDetails.other.push(el);
            if (el.credit_role.toLowerCase() === 'director') movieDetails.director = el.user?.fullName;
            if (el.credit_role.toLowerCase() === 'writer') movieDetails.writer = el.user?.fullName;
        });
    if (rawData.awards) movieDetails.awards = rawData.awards;
    if (rawData.reviews) movieDetails.reviews = rawData.reviews;

    const parsedMovie = { ...firstParse, ...movieDetails };

    return parsedMovie;
};

/** INFO PARSE SIMILAR SELECTION */
export const parseSimilarSelection = (rawData: any, headerTitle: any, currentlySelectedItemId: any) => {
    const MovieDataArray: object[] = [];
    const movieData: any = { categoryTitle: headerTitle, movie: [] };
    rawData.forEach((el: any) => {
        if (el._id !== currentlySelectedItemId) {
            movieData.movie.push(parseMovieDetails(el));
        }
    });
    MovieDataArray.push(movieData);
    return MovieDataArray;
};

/** INFO Parse Movies On Other Screen eg Profile Screen */
export const parseMovieCollection = (rawData: any, headerTitle: any) => {
    const MovieDataArray: object[] = [];
    const movieData: any = { categoryTitle: headerTitle, movie: [] };
    rawData.forEach((el: any) => {
        if (el.movie) {
            movieData.movie.push(parseSingleMovie(el.movie));
        }
    });
    MovieDataArray.push(movieData);
    return MovieDataArray;
};

export const parseMovies = (rawData: any[]) => {
    const movieArray: any[] = [];
    rawData.forEach(movie => movieArray.push(parseSingleMovie(movie)));
    return movieArray;
};

/** INFO PARSE HOME SCREEN - ALL CATEGORIES */
export const parseHomeScreen = (rawMovieDataObject: any) => {
    const MovieDataArray: object[] = [];
    const featuresArray: any = {
        id: [],
        title: [],
        genre: [],
        releasedate: [],
        rating: [],
        runningTime: [],
        posterURI: [{}],
        trailerURI: [],
    };
    Object.keys(rawMovieDataObject).forEach((el: any) => {
        const movieData: any = { categoryTitle: '', movie: [] };
        movieData.categoryTitle = el.replace('_', ' ');
        if (el === 'features') {
            // INFO PARSING DATA DESIGNED ONLY FOR THE HOME SCREEN SLIDER
            rawMovieDataObject[el].forEach((el_: any, i: number) => {
                if (el_._id) featuresArray.id[i] = el_._id;
                if (el_.title) featuresArray.title[i] = el_.title;
                if (el_.genre) featuresArray.genre[i] = el_.genre.join(',');
                if (el_.date_of_release) featuresArray.releasedate[i] = moment(el_.date_of_release).format('YYYY');
                if (el_.movie_rating) featuresArray.rating[i] = el_.movie_rating.toFixed(1);
                if (el_.technical_spec) featuresArray.runningTime[i] = runningTimeFormat(el_.technical_spec.runtime);
                if (el_.movie_poster) featuresArray.posterURI[i] = { image: `${ASW_S3_LINK}${el_.movie_poster}` };
                if (el_.movie_trailer) featuresArray.trailerURI[i] = `${ASW_S3_LINK}${el_.movie_trailer}`;
            });
            movieData.movie.push(featuresArray);
        } else {
            // INFO PARSING DATA DESIGNED FOR OTHER CATEGORIES
            rawMovieDataObject[el].forEach((el2: any) => {
                movieData.movie.push(parseMovieDetails(el2));
            });
        }
        MovieDataArray.push(movieData);
    });

    return MovieDataArray;
};

/** INFO PARSE HOME SCREEN GENRE SELECTION */
type Data = [{ genre: [string][] }];
export const parseHomeScreenGenres = (data: Data) => {
    const genreArray: any = [];
    data.forEach((el: any) => {
        el.genre.forEach((el2: string[]) => genreArray.push(el2));
    });
    return [...new Set(genreArray)];
};

/** INFO PARSE SEARCH RESULTS FOR BOTH MOVIE AND BIO */
export const parseSearchResult = (rawData: any) => {
    const movieResult = rawData.movies[0].results;
    const bioResults = rawData.biography[0].results;
    const festivalResult = rawData.festivals[0].results;
    const searchResultArray: any = [];
    const movies: any[] = [];
    const biography: any[] = [];
    const festivals: any[] = [];
    movieResult.forEach((movie: any) => {
        const parse = parseSearchResultMovie(movie);
        searchResultArray.push(parse);
        movies.push(parse);
    });

    bioResults.forEach((bio: any) => {
        const parse = parseBioSearchResult(bio);
        searchResultArray.push(parse);
        biography.push(parse);
    });

    festivalResult.forEach((festival: any) => {
        const parse = parseFestivalSearchResult(festival);
        searchResultArray.push(parse);
        festivals.push(parse);
    });

    return { results: searchResultArray, movies, biography, festivals };
};

const parseCredits = (rawData: any) => {
    const creditModel = {
        id: '',
        title: '',
        imageURI: '',
        credit_role: '',
        releaseDate: '',
        credit_type: '',
    };

    if (!rawData) return creditModel;
    if (rawData.movie.id) creditModel.id = rawData.movie.id;
    if (rawData.movie.title) creditModel.title = rawData.movie.title;
    if (rawData.movie.movie_poster) creditModel.imageURI = `${ASW_S3_LINK}${rawData.movie.movie_poster}`;
    if (rawData.movie.date_of_release) creditModel.releaseDate = moment(rawData.movie.date_of_release).format('YYYY');
    if (rawData.credit_role) creditModel.credit_role = rawData.credit_role;
    if (rawData.credit_type) creditModel.credit_type = rawData.credit_type;

    return creditModel;
};

// const checkTitle = (movie: any, title: any) => movie.some((el: any) => el.title !== title);
export const parseSelectedBioMovieCredits = (rawData: any) => {
    const credits: any = [];
    const flag: any = {};
    rawData.forEach((credit: any) => {
        if (!flag[credit.movie.title]) {
            flag[credit.movie.title] = true;
            credits.push(parseCredits(credit));
        }
    });
    return credits;
};

/** Parse Audition Results */
export const parseAuditions = (rawData: any, myApplications?: any) => {
    const auditions: any = [];
    rawData.forEach((audition: any) => {
        auditions.push(parseSingleAudition(audition, myApplications));
    });
    return auditions;
};

export const parseAuditionRoles = (rawData: any) => {
    const roles: any = [];
    rawData.forEach((role: any) => {
        roles.push(parseAuditionRole(role));
    });
    return roles;
};

export const parseSubscription = (rawData: any) => {
    const subscriptionModel: any = {
        id: '',
        subscriptionTitle: '',
        subscriptionCost: '',
        lastAmountPaid: '',
        authorization: null,
        cardNumber: '',
        nextBillingDate: '',
        lastPayment: '',
        paid: false,
        emailToken: '',
        subscriptionCode: '',
        status: '',
        planCode: '',
        description: '',
        start_date: '',
        paystack_customer_code: '',
    };

    if (!rawData) return subscriptionModel;
    if (rawData.id) subscriptionModel.id = rawData.id;
    if (rawData.plan?.name) subscriptionModel.subscriptionTitle = rawData.plan.name;
    if (rawData.plan?.amount || rawData.plan?.amount === 0) {
        const currencyFormat =
            rawData.plan.amount % 100000 === 0
                ? rawData.plan.amount / 100000
                : (rawData.plan.amount / 100000).toFixed(1);
        subscriptionModel.subscriptionCost = `N${currencyFormat}K`;
        subscriptionModel.lastAmountPaid = `N${currencyFormat}K`;
        if (rawData.plan?.amount === 0) {
            subscriptionModel.subscriptionCost = `FREE`;
            subscriptionModel.lastAmountPaid = `FREE`;
        }
    }
    if (rawData.authorization) subscriptionModel.authorization = rawData.authorization;
    if (rawData.authorization.last4) subscriptionModel.cardNumber = `*** ${rawData.authorization.last4}`;
    if (rawData.next_payment_date) {
        subscriptionModel.nextBillingDate = moment(new Date(rawData.next_payment_date)).format('MMM. D, YYYY');
        if (rawData.status === 'non-renewing') {
            subscriptionModel.nextBillingDate = 'non-renewing';
        }
    }
    if (rawData.last_payment_date) {
        const rawMoment = moment(rawData.last_payment_date);
        subscriptionModel.lastPayment = moment(rawMoment, 'YYYY-MM-DDTHH:mm:ss.SSSSZ').format('MMM. D, YYYY');
    } else if (rawData.start_date) {
        const rawMoment = moment(rawData.start_date);
        subscriptionModel.lastPayment = moment(rawMoment, 'YYYY-MM-DDTHH:mm:ss.SSSSZ').format('MMM. D, YYYY');
    }
    if (rawData.paid) subscriptionModel.paid = rawData.paid;
    if (rawData.email_token) subscriptionModel.emailToken = rawData.email_token;
    if (rawData.subscription_code) subscriptionModel.subscriptionCode = rawData.subscription_code;
    if (rawData.status) subscriptionModel.status = rawData.status;
    if (rawData.plan?.plan_code) subscriptionModel.planCode = rawData.plan.plan_code;
    if (rawData.plan?.description) subscriptionModel.description = rawData.plan.description;
    if (rawData.start_date) subscriptionModel.start_date = rawData.start_date;
    if (rawData.paystack_customer_code) subscriptionModel.paystack_customer_code = rawData.paystack_customer_code;

    return subscriptionModel;
};

const parseSubscriptionPlan = (rawData: any) => {
    const subscriptionPlanModel = {
        id: '',
        name: '',
        price: '',
        recurrence: '',
        details: '',
        plan_code: '',
    };
    if (!rawData) return subscriptionPlanModel;
    if (rawData._id) subscriptionPlanModel.id = rawData._id;
    if (rawData.name) subscriptionPlanModel.name = rawData.name;
    if (rawData.amount) {
        const currencyFormat =
            rawData.amount % 100000 === 0 ? rawData.amount / 100000 : (rawData.amount / 100000).toFixed(1);
        subscriptionPlanModel.price = `N${currencyFormat}K`;
    } else {
        subscriptionPlanModel.price = `FREE`;
    }
    if (rawData.interval) subscriptionPlanModel.recurrence = rawData.interval;
    if (rawData.description) subscriptionPlanModel.details = rawData.description;
    if (rawData.plan_code) subscriptionPlanModel.plan_code = rawData.plan_code;

    return subscriptionPlanModel;
};

export const parseSubscriptionPlans = (rawData: object[]) => {
    type PlanModel = {
        CD: object[];
        Talent: object[];
        Regular: object[];
    };

    const planModel: PlanModel = {
        CD: [],
        Talent: [],
        Regular: [],
    };

    if (!rawData) return planModel;
    rawData.forEach((plan: any) => {
        const parsedPlan = parseSubscriptionPlan(plan);
        if (!plan.name.startsWith('Test')) {
            if (plan.name.startsWith('CD')) {
                planModel.CD.push(parsedPlan);
            } else if (plan.name !== 'Regular') {
                planModel.Talent.push(parsedPlan);
            } else {
                planModel.Regular.push(parsedPlan);
            }
        }
    });

    return planModel;
};

export const parseConteWatchlistForDashboard = (rawData: { _id: string; details: IContest }) => {
    return parseSingleContest(rawData.details);
};

/** Parse Saved Items */
export const parseSavedItem = (rawData: any) => {
    const savedItemModel = {
        id: '',
        type: '',
        model: {},
        created_at: '',
    };

    if (!rawData) return savedItemModel;
    if (rawData._id) savedItemModel.id = rawData._id;
    if (rawData.details.type) savedItemModel.type = rawData.details.type;
    if (rawData.details.model) {
        if (rawData.details.type === 'Audition') {
            savedItemModel.model = parseSingleAudition(rawData.details.model);
        } else if (rawData.details.type === 'Role') {
            savedItemModel.model = parseAuditionRole(rawData.details.model);
        } else {
            savedItemModel.model = parseSingleContest(rawData.details.model);
        }
    }
    if (rawData.created_at) savedItemModel.created_at = rawData.created_at;

    return savedItemModel;
};

export const parseSavedItems = (rawData: object[]) => {
    const savedItemObject: any = { role: [], audition: [], contest: [] };
    rawData.forEach((savedItem: any) => {
        const typeInLowerCase = savedItem.details.type.toLowerCase();
        savedItemObject[typeInLowerCase].push(parseSavedItem(savedItem));
    });

    return savedItemObject;
};

export const parseNotification = (rawData: any) => {
    const notificationModel = {
        id: '',
        title: '',
        body: '',
        created_at: '',
    };

    if (!rawData) return notificationModel;
    if (rawData.id) notificationModel.id = rawData.id;
    if (rawData.payload) {
        const { title, body } = rawData.payload;
        notificationModel.title = title;
        notificationModel.body = body;
    }
    if (rawData.createdAt) {
        notificationModel.created_at = moment(rawData.createdAt).format('MMM D');
    }

    return notificationModel;
};

export const parseNotifications = (rawData: any[]) => {
    const notifications: any = [];
    rawData.forEach((notification: any) => {
        notifications.push(parseNotification(notification));
    });
    return notifications;
};

export const parseProject = (project: IProject) => {
    const model: any = genericParseSingleDocument(project);
    return model;
};

export const parseSingleSubmission = (submission: ISubmission) => {
    const model: any = {
        _id: '',
        contest: {},
        created_by: {},
        createdAt: '',
        judging_status: 'submitted',
        project: {},
        updatedAt: '',
    };

    if (!submission) return model;
    Object.keys(submission).forEach(key => {
        if (key === 'contest' && typeof submission[key] !== 'string') {
            model.contest = parseSingleContest(submission[key] as IContest);
        } else if (key === 'project' && typeof submission[key] !== 'string') {
            model.project = parseProject(submission[key] as IProject);
        } else if (key === 'created_by') {
            model.created_by = genericParseSingleDocument(submission[key]);
        } else {
            model[key as keyof ISubmission] = submission[key as keyof ISubmission];
        }
    });

    return model;
};

export const parseDashboardItems = (rawData: IDashboard) => {
    const model: any = {
        contest: [],
        project: [],
        submission: [],
        watchlist: [],
        metrics: {
            contest: [],
            project: [],
            submission: [],
            watchlist: [],
        },
    };

    if (!rawData) return model;

    if (rawData.contest) {
        model.contest = parseGenericCollection(rawData.contest.contest_recommendation, parseSingleContest);
        model.metrics['contest'] = rawData.contest.metrics;
    }

    if (rawData.project) {
        model.project = parseGenericCollection(rawData.project.user_projects, parseProject);
        model.metrics['project'] = rawData.project.metrics;
    }

    if (rawData.submission) {
        model.submission = parseGenericCollection(rawData.submission.user_submissions, parseSingleSubmission);
        model.metrics['submission'] = rawData.submission.metrics;
    }

    if (rawData.watch_list) {
        model.watchlist = parseGenericCollection(rawData.watch_list.watch_list, parseConteWatchlistForDashboard);
        model.metrics['watchlist'] = rawData.watch_list.metrics;
    }
    return model;
};

const COLOR = ['#E38627', '#C13C37', '#6A2135'];

export const parseSingleMetric = (singleMetric: GenericMetric, color: number = 0, title?: string) => {
    const model = {
        title: '',
        value: 0,
        color: '',
    };
    if (!singleMetric) return model;
    if (singleMetric._id) model.title = singleMetric._id;
    if (title) model.title = title;
    if (singleMetric.count) model.value = singleMetric.count;
    model.color = COLOR[color];
    return model;
};

export const parseMetrics = (metrics: IMetrics) => {
    const includedMetricKeys = ['project', 'submission', 'watchlist'];
    const model: { title: string; value: number; color: string }[] = [];
    let currentColoIndex = 0;
    Object.keys(metrics).forEach(key => {
        if (currentColoIndex > COLOR.length - 1) {
            currentColoIndex = 0;
        }
        if (includedMetricKeys.includes(key)) {
            metrics[key as keyof IMetrics]?.forEach((metric: GenericMetric) => {
                const modelPush = parseSingleMetric(metric, currentColoIndex);
                model.push(modelPush);
                currentColoIndex++;
            });
        }
    });
    return model;
};

export const currencyConverter = (value: number) =>
    typeof value === 'number' ? new Intl.NumberFormat('en-US', {}).format(value) : value;

export const NAIRA = `&#8358`;

export const parseSingleActivity = (rawData: ActivityDocument) => {
    const model: any = {};
    if (!rawData) return model;
    Object.keys(rawData).forEach(key => {
        if (key === 'activity') {
            const type = rawData['activity']?.type;
            if (type === 'Project') {
                model[key] = {
                    type,
                    model: parseProject(rawData['activity']?.model as IProject),
                };
            }
            if (type === 'Like') {
                model[key] = {
                    type,
                    model: parseProject(rawData['activity']?.model as IProject),
                };
            }

            if (type === 'Contest') {
                model[key] = {
                    type,
                    model: parseSingleContest(rawData['activity']?.model as unknown as IContest),
                };
            }
            if (type === 'User') {
                model[key] = {
                    type: 'Talent',
                    model: parseUserProfile(rawData['activity']?.model as unknown as IUser),
                };
            }
        } else {
            model[key] = rawData[key as keyof ActivityDocument];
        }
    });

    return model;
};

export const parseSingleSuggestionActivity = (rawData: ActivityDocument): ActivityDocument => {
    const model: ActivityDocument = {};
    if (!rawData) return model;
    Object.keys(rawData).forEach(key => {
        // @ts-ignore
        model[key as keyof ActivityDocument] = rawData[key as keyof ActivityDocument];
    });

    return model;
};

export const parseSingleSuggestion = (rawData: SearchSuggestion) => {
    const model: any = {
        _id: '',
    };
    if (!rawData) return model;
    if (rawData._id) model._id = rawData._id;
    if (rawData.count) model.count = rawData.count;
    if (rawData.documents) model.documents = parseSingleSuggestionActivity(rawData.documents);
    if (rawData.details) {
        Object.keys(rawData.details).forEach(key => {
            if (rawData.details && !isEmpty(rawData.details[key as keyof SuggestionDetails])) {
                if (key === 'contest') {
                    model.suggestionType = 'contest';
                    model.suggestion = parseSingleContest(
                        rawData.details[key as keyof SuggestionDetails][0] as IContest,
                    );
                }

                if (key === 'projects') {
                    model.suggestionType = 'projects';
                    model.suggestion = parseProject(
                        rawData.details[key as keyof SuggestionDetails][0] as unknown as IProject,
                    );
                }

                if (key === 'user') {
                    model.suggestionType = 'talent';
                    model.suggestion = parseUserProfile(
                        rawData.details[key as keyof SuggestionDetails][0] as unknown as IUser,
                    );
                }
            }
        });
    }

    return model;
};

export const parseDiscoverySearchResult = (
    rawData: SearchResponse,
): { parsedResult: DiscoverySearchModel; unParsedResult: DiscoverySearchUnparsedResponse } => {
    const model: DiscoverySearchModel = {
        item: [],
        type: 'suggestion',
        label: 'Search',
    };

    const allResults: DiscoverySearchUnparsedResponse = {
        acts: [],
        users: [],
        contests: [],
        projects: [],
        suggestion: [],
    };

    /**
     * type => contest | user | project
     * featureImage =>
     * title =>
     * id =>
     *
     */

    const { contests, projects, user } = rawData;

    contests.result[0].results.forEach(contest => {
        const searchModel: IAutoSuggestionSearchCell = {
            type: 'contest',
            searchImage: '',
            title: '',
            id: '',
        };

        if (contest._id) searchModel.id = contest._id;
        if (contest.feature_image) searchModel.searchImage = `${ASW_S3_LINK}${contest.feature_image}`;
        if (contest.title) searchModel.title = contest.title;
        model.item.push(searchModel);
        const allResultsContestParsed = genericParseSingleDocument(contest as any);
        allResults.contests.push(allResultsContestParsed);
    });

    projects.result[0].results.forEach(project => {
        const searchModel: IAutoSuggestionSearchCell = {
            type: 'project',
            searchImage: '',
            title: '',
            id: '',
        };

        if (project._id) searchModel.id = project.video_id;
        if (project.feature_image) searchModel.searchImage = `${ASW_S3_LINK}${project.feature_image}`;
        if (project.title) searchModel.title = project.title;
        model.item.push(searchModel);
        if (project.project_type === 'act') {
            const allResultsActsParsed = genericParseSingleDocument(project as any);
            allResults.acts.push(allResultsActsParsed);
        } else {
            const allResultsProjectsParsed = genericParseSingleDocument(project as any);
            allResults.projects.push(allResultsProjectsParsed);
        }
    });

    user.result[0].results.forEach(user => {
        const searchModel: IAutoSuggestionSearchCell = {
            type: 'talent',
            searchImage: '',
            title: '',
            id: '',
        };

        if (user.userName) searchModel.id = user.userName;
        if (user.profile_picture) searchModel.searchImage = `${ASW_S3_LINK}${user.profile_picture}`;
        if (user.firstName && user.lastName) searchModel.title = `${user.firstName} ${user.lastName.charAt(0)}`;
        model.item.push(searchModel);
        const allResultsUsersParsed = genericParseSingleDocument(user as any);
        allResults.users.push(allResultsUsersParsed);
    });

    return { parsedResult: model, unParsedResult: allResults };
};

export const parseDiscovery = (response: IDiscoveryResponse) => {
    const finalArray: any[] = [];

    Object.keys(response).forEach(responseKey => {
        switch (responseKey) {
            case 'contests':
                Object.keys(response['contests'][0]).forEach(innerReponseKey => {
                    const model: any = {
                        categoryTitle: '',
                        categoryDetails: [],
                    };
                    switch (innerReponseKey) {
                        case 'comedy':
                            model.categoryTitle = 'comedy';
                            model.categoryDetails = response['contests'][0][innerReponseKey];

                            break;
                        case 'coming_soon':
                            model.categoryTitle = 'coming soon';
                            model.categoryDetails = response['contests'][0][innerReponseKey];
                            break;
                        case 'features':
                            model.categoryTitle = 'features';
                            model.categoryDetails = response['contests'][0][innerReponseKey];
                            break;
                        case 'genre':
                            model.categoryTitle = 'genre';
                            model.categoryDetails = response['contests'][0][innerReponseKey][0].genre;
                            break;
                        case 'monologue':
                            model.categoryTitle = 'monologue';
                            model.categoryDetails = response['contests'][0][innerReponseKey];

                            break;
                        case 'short':
                            model.categoryTitle = 'shorts';
                            model.categoryDetails = response['contests'][0][innerReponseKey];
                            break;
                        case 'submissions':
                            model.categoryTitle = 'top submissions';
                            const existingProj: any = {};
                            Object.keys(response['contests'][0][innerReponseKey]).forEach((submisisonKey: any) => {
                                const innerObject = response['contests'][0][innerReponseKey][submisisonKey];
                                if (
                                    innerObject &&
                                    innerObject.project &&
                                    innerObject.project._id &&
                                    !existingProj[innerObject.project._id]
                                ) {
                                    existingProj[innerObject.project._id] = true;
                                    innerObject.project['created_by'] = innerObject.created_by;
                                    // Changed this for ts
                                    // innerObject.judging_status = innerObject.judging_status;
                                    model.categoryDetails.push(innerObject);
                                }
                            });
                            break;
                        default:
                            model.categoryTitle = innerReponseKey;
                            model.categoryDetails = response['contests'][0][innerReponseKey as keyof Contest];
                            break;
                    }
                    finalArray.push(model);
                });
                break;
            case 'projects':
                Object.keys(response['projects'][0]).forEach(innerReponseKey => {
                    const model: any = {
                        categoryTitle: '',
                        categoryDetails: [],
                    };
                    switch (innerReponseKey) {
                        case 'recently_watched':
                            model.categoryTitle = 'recently watched';
                            model.categoryDetails = response['projects'][0][innerReponseKey];

                            break;
                        case 'short_films':
                            model.categoryTitle = 'short films';
                            model.categoryDetails = response['projects'][0][innerReponseKey];
                            break;
                        default:
                            model.categoryTitle = innerReponseKey;
                            model.categoryDetails = response['projects'][0][innerReponseKey as keyof ProjectSummary];
                            break;
                    }
                    finalArray.push(model);
                });
                break;
            default:
                break;
        }
    });

    return finalArray;
};

export const parseProfileImages = (user_dashboard: UserDashboard) => {
    if (!isEmpty(user_dashboard.profile_picture && !user_dashboard.profile_picture.startsWith(ASW_S3_LINK))) {
        user_dashboard.profile_picture = `${ASW_S3_LINK}${user_dashboard.profile_picture}`;
    }
    user_dashboard.audience.forEach(a => {
        if (!isEmpty(a.follower.profile_picture) && !a.follower.profile_picture?.startsWith(ASW_S3_LINK)) {
            a.follower.profile_picture = `${ASW_S3_LINK}${a.follower.profile_picture}`;
        }
    });
    user_dashboard.projects.forEach(p => {
        if (!isEmpty(p.feature_image) && !p.feature_image.startsWith(ASW_S3_LINK)) {
            p.feature_image = `${ASW_S3_LINK}${p.feature_image}`;
        }
    });
};
