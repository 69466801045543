import invert from 'invert-color';

export const colors = {
    background: {
        start: '#4b2e38',
        mid: '#030203',
        end: '#a77319',
    },
    primary: '#fff',
    dark_grey: '#4b4b4b',
    button: {
        start: '#799db7',
        mid: '#2e4653',
        midII: '#39446e',
        end: '#8897ea',
    },
};

type Gradient = {
    start: string;
    mid: string;
    midII?: string;
    end: string;
};

export const COLORS = [
    '#E38627',
    '#C13C37',
    '#6A2135',
    '#F5C34A',
    '#D6E28E',
    '#92E0C1',
    '#92E0C1',
    '#F5C34A',
    '#D6E28E',
    '#92E0C1',
    '#92E0C1',
    '#F5C34A',
    '#D6E28E',
    '#92E0C1',
];

export type ColorScheme = 'light' | 'dark';
export type Colors = {
    primary: string;
    primary_dark: string;
    secondary: string;
    secondary_dark: string;
    secondary_white: string;
    black: string;
    grey: string;
    disabledGray: string;
    lightWhite: string;
    shadowColor: string;
    primary_red: string;
    secondaryGrey: string;
    darkGray: string;
    darkGray60: string;
    placeholderGray: string;
    placeholderGray50: string;
    primaryGreen: string;
    peachYellow: string;
    accountGray: string;
    switchGreen: string;
    primary_background: string;
    primary_button: string;
    tertiary_dark: string;
    main_blue: string;
    tertiary_grey: string;
    tertiary_white: string;
    tertiary_blue: string;
    secondary_red: string;
    tertiary_button: string;
    tertiary_green: string;
    light_blue: string;
    highlight: string;
    prisma_dark: string;
    zeus_dark: string;
    true_white: string;
    button_gradient: Gradient;
    background_gradient: Gradient;
    acceptance_gradient: Gradient;
    error_gradient: Gradient;
    primary_orange: string;
    transparent: string;
    gray_text: string;
    light_gray_text: string;
    form_black: string;
    form_label: string;
    primary_white: string;
    gray_message: string;
    fillDarkGrey: string;
    pure_black: string;
    gray_sidebar: string;
    dropdown_black: string;
    dropdown_text: string;
    form_divider: string;
    filters_white: string;
    tag_white: string;
    tag_white_light: string;
    background_gray: string;
    button_background: string;
    handle: string;
    another_gray_color: string;
    deep_grey: string;
    deep_grey_lighter: string;
};

export const light: Colors = {
    // I commented the below out as it is causing issues in light devices, when we support liht, we can comment that out
    // primary: 'rgba(0,139,200, 1)',
    // primary_dark: 'rgba(0,92,150, 1)',
    // secondary: 'rgba(0,55,100, 1)',
    // secondary_dark: 'rgb(21, 34, 56)',
    // secondary_white: 'rgb(249,250,254)',
    // black: 'rgb(11,17,23)',
    // grey: 'rgba(112,112,112,1)',
    // main_blue: 'rgb(38,97,183)',
    // secondaryGrey: 'rgba(19,21,23, 1)',
    // disabledGray: 'rgb(213,213,215)',
    // lightWhite: 'rgb(242, 242, 246)',
    // shadowColor: 'rgba(0,0,0,0.27)',
    // primary_red: 'rgba(214,51,5,1)',
    // secondary_red: 'rgba(254, 101, 79, 1)',
    // darkGray: 'rgba(112,112,112,1)',
    // darkGray60: 'rgba(112,112,112,0.3)',
    // placeholderGray: 'rgba(186,186,186,1)',
    // placeholderGray50: 'rgba(186,186,186,0.5)',
    // primaryGreen: 'rgba(0,133,31,1.0)',
    // peachYellow: 'rgba(255, 242, 222, 1)',
    // accountGray: invert([45, 45, 45]),
    // switchGreen: 'rgba(68,219,94,1)',
    // primary_background: 'rgb(242, 242, 246)',
    // primary_button: 'rgba(45,45,45,1)',
    // tertiary_dark: 'rgba(53,55,57, 1)',
    // tertiary_grey: 'rgba(228,222,210, 1)',
    // tertiary_blue: 'rgba(193,228,240, 1)',
    // tertiary_white: 'rgba(255,255,255, 1)',
    // tertiary_button: 'rgba(45,45,45,1)',
    // tertiary_green: 'rgba(97,197,104, 1)',
    // light_blue: 'rgba(243,246,255,1)',
    // highlight: 'rgba(299,156,40,1)',
    // prisma_dark: invert([27, 27, 30]),
    // zeus_dark: 'rgba(0,0,0,0.3)',
    // true_white: 'rgb(242, 242, 246)',
    // primary_orange: 'rgba(251, 188, 85, 1)',
    // transparent: 'rgba(255, 255, 255, 0)',
    // gray_text: 'rgba(19, 21, 23, 1)',
    // light_gray_text: 'rgba(103, 110, 118, 1)',
    // form_black: 'rgba(0, 0, 0, 0.50)',
    // form_label: 'rgba(158, 165, 173, 1)',
    // primary_white: 'rgba(255, 255, 255, 1)',
    // gray_message: 'rgba(206, 210, 214, 1)',
    // pure_black: 'rgba(0,0,0,1)',
    // gray_sidebar: 'rgba(156, 164, 171, 1)',
    // dropdown_black: 'rgba(36, 41, 46, 1)',
    // dropdown_text: 'rgba(246, 247, 249, 1)',
    // form_divider: 'rgba(56, 63, 69, 1)',
    // filters_white: 'rgba(250, 250, 250, 1)',
    // tag_white: 'rgba(229, 231, 234, 1)',
    // tag_white_light: 'rgba(229, 231, 234, .3)',
    // background_gray: 'rgba(120, 130, 138, 1)',
    // button_background: 'rgba(39, 43, 48, 1)',
    // handle: 'rgba(89, 96, 102, 1)',
    // another_gray_color: 'rgba(103, 110, 118, 1)',
    // button_gradient: {
    //     start: '#799db7',
    //     mid: '#2e4653',
    //     midII: '#39446e',
    //     end: '#8897ea',
    // },
    // background_gradient: {
    //     start: '#4b2e38',
    //     mid: '#030203',
    //     end: '#a77319',
    // },
    // acceptance_gradient: {
    //     start: '#de9c65',
    //     mid: '#afdea5',
    //     midII: '#0b1a15',
    //     end: '#d3eb88',
    // },
    // error_gradient: {
    //     start: '##de6464',
    //     mid: '#dea4a4',
    //     midII: '#1a0b11',
    //     end: '#be212d',
    // },
    // fillDarkGrey: 'rgba(132, 129,138,1)',
    // deep_grey: 'rgb(31, 31, 31)',
    // deep_grey_lighter: 'rgb(24, 24, 24)',
    primary: 'rgba(0,139,200, 1)',
    primary_dark: 'rgba(0,92,150, 1)',
    secondary: 'rgba(0,55,100, 1)',
    secondary_dark: 'rgb(21, 34, 56)',
    secondary_white: 'rgba(37,43,48, 0.5)',
    black: 'rgb(15,15,15)', //'rgb(11,17,23)', // App Background Color
    grey: 'rgba(112,112,112,1)',
    disabledGray: 'rgb(213,213,215)',
    secondaryGrey: 'rgba(19,21,23, 1)',
    lightWhite: 'rgb(242, 242, 246)',
    shadowColor: 'rgba(0,0,0,0.27)',
    primary_red: 'rgba(214,51,5,1)',
    secondary_red: 'rgba(254, 101, 79, 1)',
    darkGray: 'rgba(112,112,112,1)',
    darkGray60: 'rgba(112,112,112,0.3)',
    placeholderGray: 'rgba(186,186,186,1)',
    placeholderGray50: 'rgba(186,186,186,0.5)',
    primaryGreen: 'rgba(0,133,31,1.0)',
    peachYellow: 'rgba(255, 242, 222, 1)',
    accountGray: 'rgba(45,45,45,1)',
    switchGreen: 'rgba(68,219,94,1)',
    primary_background: 'rgba(32,33,36,1)',
    primary_button: 'rgb(242, 242, 246)',
    tertiary_dark: invert([53, 55, 57]),
    tertiary_grey: invert([228, 222, 210]),
    tertiary_blue: 'rgba(193,228,240, 1)', //#c1e4f0
    tertiary_white: invert([255, 255, 255]),
    main_blue: 'rgb(38,97,183)',
    tertiary_button: 'rgba(45,45,45,1)',
    tertiary_green: 'rgba(97,197,104, 1)',
    light_blue: 'rgba(243,246,255,1)',
    highlight: 'rgba(299,156,40,1)',
    prisma_dark: 'rgba(27,27,30,1)',
    zeus_dark: 'rgba(0,0,0,0.3)',
    true_white: 'rgb(242, 242, 246)',
    primary_orange: 'rgba(251, 188, 85, 1)',
    transparent: 'rgba(255, 255, 255, 0)',
    gray_text: 'rgba(19, 21, 23, 1)',
    light_gray_text: 'rgba(103, 110, 118, 1)',
    form_black: 'rgba(0, 0, 0, 0.50)',
    form_label: 'rgba(158, 165, 173, 1)',
    primary_white: 'rgba(255, 255, 255, 1)',
    gray_message: 'rgba(206, 210, 214, 1)',
    pure_black: 'rgba(0,0,0,1)',
    gray_sidebar: 'rgba(156, 164, 171, 1)',
    dropdown_black: 'rgba(36, 41, 46, 1)',
    dropdown_text: 'rgba(246, 247, 249, 1)',
    form_divider: 'rgba(56, 63, 69, 1)',
    filters_white: 'rgba(250, 250, 250, 1)',
    tag_white: 'rgba(229, 231, 234, 1)',
    tag_white_light: 'rgba(229, 231, 234, .3)',
    background_gray: 'rgba(120, 130, 138, 1)',
    button_background: 'rgba(39, 43, 48, 1)',
    handle: 'rgba(89, 96, 102, 1)',
    another_gray_color: 'rgba(103, 110, 118, 1)',
    button_gradient: {
        start: '#799db7',
        mid: '#2e4653',
        midII: '#39446e',
        end: '#8897ea',
    },
    background_gradient: {
        start: '#4b2e38',
        mid: '#030203',
        end: '#a77319',
    },
    acceptance_gradient: {
        start: '#7cb879',
        mid: '#afdea5',
        midII: '#0b1a15',
        end: '#d3eb88',
    },
    error_gradient: {
        start: '##de6464',
        mid: '#dea4a4',
        midII: '#1a0b11',
        end: '#be212d',
    },
    fillDarkGrey: 'rgba(132, 129,138,1)',
    deep_grey: 'rgb(31, 31, 31)',
    deep_grey_lighter: 'rgb(24, 24, 24)',
};

export const dark: Colors = {
    primary: 'rgba(0,139,200, 1)',
    primary_dark: 'rgba(0,92,150, 1)',
    secondary: 'rgba(0,55,100, 1)',
    secondary_dark: 'rgb(21, 34, 56)',
    secondary_white: 'rgba(37,43,48, 0.5)',
    black: 'rgb(15,15,15)', // App Background Color
    grey: 'rgba(112,112,112,1)',
    disabledGray: 'rgb(213,213,215)',
    secondaryGrey: 'rgba(19,21,23, 1)',
    lightWhite: 'rgb(242, 242, 246)',
    shadowColor: 'rgba(0,0,0,0.27)',
    primary_red: 'rgba(214,51,5,1)',
    secondary_red: 'rgba(254, 101, 79, 1)',
    darkGray: 'rgba(112,112,112,1)',
    darkGray60: 'rgba(112,112,112,0.3)',
    placeholderGray: 'rgba(186,186,186,1)',
    placeholderGray50: 'rgba(186,186,186,0.5)',
    primaryGreen: 'rgba(0,133,31,1.0)',
    peachYellow: 'rgba(255, 242, 222, 1)',
    accountGray: 'rgba(45,45,45,1)',
    switchGreen: 'rgba(68,219,94,1)',
    primary_background: 'rgba(32,33,36,1)',
    primary_button: 'rgb(242, 242, 246)',
    tertiary_dark: invert([53, 55, 57]),
    tertiary_grey: invert([228, 222, 210]),
    tertiary_blue: 'rgba(193,228,240, 1)', //#c1e4f0
    tertiary_white: invert([255, 255, 255]),
    main_blue: 'rgb(38,97,183)',
    tertiary_button: 'rgba(45,45,45,1)',
    tertiary_green: 'rgba(97,197,104, 1)',
    light_blue: 'rgba(243,246,255,1)',
    highlight: 'rgba(299,156,40,1)',
    prisma_dark: 'rgba(27,27,30,1)',
    zeus_dark: 'rgba(0,0,0,0.3)',
    true_white: 'rgb(242, 242, 246)',
    primary_orange: 'rgba(251, 188, 85, 1)',
    transparent: 'rgba(255, 255, 255, 0)',
    gray_text: 'rgba(19, 21, 23, 1)',
    light_gray_text: 'rgba(103, 110, 118, 1)',
    form_black: 'rgba(0, 0, 0, 0.50)',
    form_label: 'rgba(158, 165, 173, 1)',
    primary_white: 'rgba(255, 255, 255, 1)',
    gray_message: 'rgba(206, 210, 214, 1)',
    pure_black: 'rgba(0,0,0,1)',
    gray_sidebar: 'rgba(156, 164, 171, 1)',
    dropdown_black: 'rgba(36, 41, 46, 1)',
    dropdown_text: 'rgba(246, 247, 249, 1)',
    form_divider: 'rgba(56, 63, 69, 1)',
    filters_white: 'rgba(250, 250, 250, 1)',
    tag_white: 'rgba(229, 231, 234, 1)',
    tag_white_light: 'rgba(229, 231, 234, .3)',
    background_gray: 'rgba(120, 130, 138, 1)',
    button_background: 'rgba(39, 43, 48, 1)',
    handle: 'rgba(89, 96, 102, 1)',
    another_gray_color: 'rgba(103, 110, 118, 1)',
    button_gradient: {
        start: '#799db7',
        mid: '#2e4653',
        midII: '#39446e',
        end: '#8897ea',
    },
    background_gradient: {
        start: '#4b2e38',
        mid: '#030203',
        end: '#a77319',
    },
    acceptance_gradient: {
        start: '#7cb879',
        mid: '#afdea5',
        midII: '#0b1a15',
        end: '#d3eb88',
    },
    error_gradient: {
        start: '##de6464',
        mid: '#dea4a4',
        midII: '#1a0b11',
        end: '#be212d',
    },
    fillDarkGrey: 'rgba(132, 129,138,1)',
    deep_grey: 'rgb(31, 31, 31)',
    deep_grey_lighter: 'rgb(24, 24, 24)',
};

export const theme = { dark, light };
