export const af_strings = {
    signin: "Ek het al 'n rekening",
    signup_text: 'Ek is nuut hier',
    create_account: 'Skep Rekening',
    sign_in_now: 'Teken nou in',
    create_an_account: "Skep 'n rekening",
    name: 'Naam (Volle naam)',
    your_email_address: 'Jou e-pos adres',
    phone_number: 'Telefoonnommer',
    create_a_password: "Skep 'n wagwoord",
    already_have_an_account: "Het jy reeds 'n rekening?",
    terms: 'Deur in te teken, stem jy in tot',
    conditions: 'Thspian se Gebruiksvoorwaardes',
    splashScreenSubText: 'Oudit. Flieks. Resensies',
    boxofix: 'Boxofix',
    sign_in: 'Teken aan',
    password: 'Wagwoord',
    new_to_thspian: 'Nuut by Thspian?',
    forgot_password: 'Het jy jou wagwoord vergeet?',
    select_country_code: 'Kies jou landkode',
    email_address: 'E-pos adres',
    reset_password: 'Herstel Wagwoord',
    enter_new_password: 'Voer nuwe wagwoord in',
    confirm_password: 'Bevestig wagwoord',
    submit: 'Indien',
    reset_successful: 'Herstel Skakel Gestuur',
    reset_link: "Ons het 'n e-pos aan jou e-pos gestuur met 'n skakel om jou wagwoord te herstel",
    if_you_have_not:
        "As jy nie 'n e-pos ontvang het na 10 minute nie, kontroleer asseblief jou spam-vouer of probeer weer.",
    confirm: 'Bevestig',
    enter_phone_code: 'Voer die ses (6) syfer verifikasiekode in wat aan jou foon gestuur is',
    email_error: 'e-pos is nie geldig nie',
    phone_error: 'telefoonnommer is nie geldig nie',
    name_error: 'voer asseblief jou eerste en laaste naam in',
    password_confirm_error: 'wagwoorde stem nie ooreen nie, probeer asseblief weer',
    existing_account:
        "Daar is reeds 'n bestaande rekening met daardie e-pos of telefoonnommer, teken asseblief in of het jy jou wagwoord vergeet?",
    sign_in_with_email: 'Teken aan met e-pos',
    sign_in_with_phone_number: 'Teken aan met telefoonnommer',
    phone_sign_in_error_msg: 'telefoonnommer nie geldig nie',
    email_sign_in_error_msg: 'e-pos nie geldig nie',
    resend_code: 'Stuur Kode Weer',
    similar_movies: 'Soortgelyke flieks',
    watch: 'Kyk',
    trailer: 'BEMARKER',
    summary: 'Opsomming',
    release_date: 'Vrystellingsdatum',
    director: 'Regisseur',
    writer: 'Skrywer',
    budget: 'Begroting',
    opening_weekend: 'Opening Naweek',
    gross: 'Bruto',
    add_review: "Voeg 'n resensie by",
    like_this: 'Voeg by kyklys',
    share: 'Deel',
    see_all_reviews: 'Sien alle gebruikersresensies',
    cast: 'Rolverdeling',
    user_review: 'Gebruikersresensies',
    boxofix_rating: 'Boxofix-gradering',
    your_rating: 'Jou gradering',
    rate_this: 'Beoordeel dit',
    liked: 'By kyklys gevoeg',
    search: 'Soek',
    searchPlaceHolder: 'Soek flieks, toekennings en akteurs',
    movies: 'Flieks',
    actors: 'Akteurs & Aktrises',
    awards: 'Toekennings & Gebeure',
    talent: 'Talent',
    search_talents: 'Soek Talente',
    type_message: 'Tik jou boodskap hier...',
    contribute: 'Dra by tot hierdie bladsy',
    edit_page: 'Wysig bladsy',
    select_audition_role: "Kies 'n oudisie rol, bv. Video Vixen...",
    select_audition_location: "Kies 'n plek...",
    age: 'Ouderdom',
    required_media: 'Vereiste Media',
    gender: 'Geslag',
    character_description: 'Karakterbeskrywing',
    apply_role: 'Aansoek doen vir rol',
    profile: 'Profiel',
    watchlist: 'Kyklys',
    force_signin:
        'Teken aan om BOXOFIX-funksies toegang te verkry, insluitend rolverdeling, nuutste fliekopdaterings, fliek resensies en meer',
    sign_in_sign_up: 'Teken aan / Registreer',
    dismiss: 'Verwerp',
    default_button_name: 'Knoppie Titel',
    account: 'Rekening',
    my_profile: 'Rolprofiel',
    productions: 'Produksies',
    contributions: 'Bydraes',
    trial: '7 dae gratis proef met enige plan!',
    unlimited: 'Onbeperkte rolplaasings',
    secure: 'Toegang tot talente en rolverdelings',
    t_four: '24/7 prioriteitsondersteuning',
    no_ad: 'Geen aansoeklimiete nie',
    preference: 'Voorkeur',
    filter: 'Filtreer',
    no_card: 'Oops!!! Nog geen kaart nie',
    add_card_sub: "Voeg 'n nuwe kaart by om jou transaksies op Boxofix te aktiveer",
    add_new_card: 'Voeg nuwe kaart by',
    wallet: 'Beursie',
    delete_card: 'Verwyder Kaart',
    new_card: 'Nuwe Kaart Bygevoeg',
    card_already_exists: 'Kaart bestaan reeds',
    action_cancelled: 'Aksie is gekanselleer',
    next_billing_date: 'Volgende Faktuurdag',
    payment_method: 'Betaalmetode',
    last_payment: 'Laaste Betaling',
    change: 'Verander',
    cancel_subscription: 'Kanselleer Subskripsie',
    cancel_warning: 'Jou huidige intekening sal gekanselleer word as jy voortgaan',
    go_back: 'Gaan terug',
    proceed: 'Gaan voort',
    subscription_details: 'Subskripsiebesonderhede',
    subscription_cancel: 'Jou #plan intekening is suksesvol gekanselleer. Jou plan sal voortduur tot #date',
    cancelBackButton: 'Terug na Rekeninge',
    subscription_action: 'Subskripsie aksie',
    subscription_caution: 'Wil jy voortgaan?',
    subscription_success:
        'Jy het suksesvol op Boxofix geintekeneer. Enige aktiewe intekening sal gedeaktiveer en vervang word',
    subscribe: 'Inteken',
    max_application: 'Aansoeke Uitgeput.',
    max_app_body:
        "Jy het die maksimum toelaatbare aansoeke met 'n Reelmatige intekening bereik, voel vry om op te gradeer.",
    no_subscription: 'Geen Intekening',
    no_sub_body: 'Jy het geen aktiewe intekening nie',
    no_actor_profile_body: 'Voltooi asseblief jou talentprofiel om vir oudisies aansoek te doen',
    no_actor_profile: 'Geen Talentprofiel',
    complete_profile: 'Voltooi Profiel',
    save_role: 'Berg Rol',
    apply: 'Dien aansoek in',
    applicants: 'aansoekers',
    production_description: 'Produksiebeskrywing',
    casting_roles: 'Rolverdeling',
    job_details: 'Werkbesonderhede',
    language: 'Taal',
    expires: 'Verstryk',
    rehearsal_date: 'Oefendatum',
    tags: 'Etikette',
    compensation: 'Kompensasie',
    tbd: 'Nog te bepaal',
    casting_agency: 'Rolverdelingsagentskap',
    attachments: 'Bylaes',
    similar_roles: 'Soortgelyke Rolle',
    saved: 'Gestoor',
    applied: 'Aansoek gedoen',
    add_new_festival: 'Voeg nuwe toekenning / fees by',
    search_movies: 'Soek Flieks, TV-programme ...',
    search_actors: 'Soek Akteurs, Aktrises, TV-persoonlikhede ...',
    search_awards: 'Soek Gebeure, Fees, Toekennings ...',
    boxofix_page: 'Boxofix bladsy',
    boxofix_page_description:
        "Skep 'n flieksbiografie, insluitend rolverdeling en bemanning, toekennings, kwessies en nuus rondom die projek.",
    casting_call: 'Rolverdeling Oproep',
    casting_call_description:
        "Publiseer 'n oudisie kennisgewing. Bereik duisende nuwe talente en kry jou projek van die grond af",
    actors_biography: 'Akteurbiografie',
    actors_biography_description:
        "Skep 'n akteur of aktrise se biografie, insluitend vorige werk, toekennings, fotos, prestasies. ens.",
    talent_profile: 'Talentprofiel',
    talent_profile_description: 'Skep jou talentprofiel sodat rolverdelingsagentskappe en regisseurs jou kan vind',
    search_crew: 'Soek mense, bemanningsrolle bv. beligter ...',
    search_award: 'Soek toekenningkategorie, mense bv. Beste prent ...',
    login_or_signup: 'Teken aan / Registreer',
    firstName: 'Voornaam',
    lastName: 'Van',
    upload_video: 'Laai Video Op',
    apply_filter: 'Pas filter toe',
    clear_filters: 'Maak filters skoon',
    all_filters: 'Alle filters',
    page_not_found: 'Dit lyk of jy verlore is in die ruimte',
    back: 'Terug',
    my_rating: 'My gradering',
    continue: 'Aanhou',
    contest_description: 'Kompetisiebeskrywing',
    judges: 'Regters',
    create_contest: 'Skep kompetisie',
    more: 'Meer',
    no_submissions: 'Geen indienings nog nie',
    intro: 'Inleiding',
    edit_bio: 'Wysig biografie',
    joined_this_contest: 'het aan hierdie kompetisie deelgeneem',
    recent_activities: 'Onlangse aktiwiteite',
    commented_on_this_video: 'het op hierdie video kommentaar gelewer',
    coming_soon: 'Binnekort',
    live_contest: 'Lewende kompetisie',
    my_videos: 'My videos',
    recent_submissions: 'Onlangse indienings',
    recommendations: 'Aanbevelings',
    top_performers: 'Toppresteerders',
    recently_watched: 'Onlangs gekyk',
    statistics: 'Statistiek',
    total_no_of_votes: 'Totale aantal stemme',
    submissions: 'Indienings',
    duration: 'Tydsduur',
    edit_profile: 'Wysig profiel',
    add_cover: 'Voeg omslag toe',
    create_project: 'Skep projek',
    comedy: 'Komedie',
    pg_13: 'PG-13',
    friends: 'vriende',
    protected_page: "Hierdie bladsy word beskerm deur Google reCAPTCHA om te verseker dat jy nie 'n robot is nie.",
    learn_more: 'Leer meer.',
    set_new_password: 'Stel nuwe wagwoord',
    password_change_success: 'Wagwoordverandering suksesvol!',
    password_changed: 'Jou wagwoord is verander! Jy sal na die aanmeldingsskerm geskuif word',
    reset_link_sent: "Ons het 'n herstel skakel gestuur",
    email_me: "Stuur my 'n e-pos",
    enter_email_to_reset_password:
        "Voer die e-posadres in wat aan jou rekening gekoppel is en ons sal vir jou 'n skakel stuur om jou wagwoord te herstel",
    current_password: 'Huidige wagwoord',
    settings: 'Instellings',
    change_password: 'Verander wagwoord',
    use_secure_password:
        "Wanneer jy jou wagwoord verander, beveel ons sterk aan om 'n veilige wagwoord te gebruik wat jy nie elders gebruik nie.",
    notifications: 'Kennisgewings',
    subscriptions: 'Intekeninge',
    recommended_videos: 'Aanbevole videos',
    mentions: 'Erwähnungen',
    shared_content: 'Gedeelde inhoud',
    filter_by: 'Filtreer deur',
    reset: 'Herstel',
    show_results: 'Wys resultate',
    see_all: 'Sien alles',
    menu: 'MENU',
    personal: 'PERSOONLIK',
    username: 'Gebruikersnaam',
    date_of_birth: 'Geboortedatum',
    sign_up: 'Teken aan',
    otp_verify_vote: "Ons sal 'n OTP na jou nommer stuur om jou stem te verifieer",
    questions_contact_us: 'Vrae? Kontak ons',
    playlist: 'Afspeellys',
    cookie_preference: 'Koekievoorkeur',
    drop_to_create_project: "Laat hier om 'n projek te skep",
    faq: 'Veelgestelde vrae',
    help_center: 'Hulp sentrum',
    privacy_policy: 'Privaatheidsbeleid',
    shop: 'Winkel',
    terms_of_use: 'Gebruiksvoorwaardes',
    recent_projects: 'onlangse projekte',
    submission_status: 'indieningsstatus',
    my_projects: 'My Projekte',
    select_judges: 'Kies Regters',
    requirements: 'Vereistes',
    prize: 'Prys',
    drop_file_to_upload: 'Laai lêer hier af om op te laai',
    trending: 'Tendens',
};
