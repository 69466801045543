import produce from 'immer';

import { IConfigurationInitialState, IShowProjectGuide, types } from './types';
import { restoreInitialState } from '../../utils/AppUtils';

const initialState: IConfigurationInitialState = {
    fetchingConfig: false,
    configError: '',
    creatingConfig: false,
    updatingConfig: false,
    userSpecificConfiguration: undefined,
    globalConfiguration: undefined,
    fetchingGlobalConfig: false,
    fetchingUserLocation: false,
    userLocationErrMsgs: null,
    notification_token: '',
    guidedTourIndex: 0,
};

const reducer = (state = initialState, action: any) => {
    const { type, payload } = action;

    switch (type) {
        case types.GET_CONFIGURATION:
            return produce(state, draftState => {
                draftState.fetchingConfig = true;
            });
        case types.GET_CONFIGURATION_SUCCESS:
            return produce(state, draftState => {
                draftState.userSpecificConfiguration = payload;
                draftState.fetchingConfig = false;
            });
        case types.GET_CONFIGURATION_FAILED:
            return produce(state, draftState => {
                draftState.fetchingConfig = false;
                draftState.configError = payload;
            });
        case types.CREATE_CONFIGURATION:
            return produce(state, draftState => {
                draftState.creatingConfig = true;
            });
        case types.CREATE_CONFIGURATION_SUCCESS:
            return produce(state, draftState => {
                draftState.userSpecificConfiguration = payload.data;
                draftState.creatingConfig = false;
            });
        case types.CREATE_CONFIGURATION_FAILED:
            return produce(state, draftState => {
                draftState.configError = payload;
            });
        case types.UPDATE_USER_CONFIG:
            return produce(state, draftState => {
                draftState.updatingConfig = true;
            });
        case types.UPDATE_USER_CONFIG_SUCCESS:
            return produce(state, draftState => {
                draftState.updatingConfig = false;
                draftState.userSpecificConfiguration = payload;
            });
        case types.UPDATE_USER_CONFIG_FAILED:
            return produce(state, draftState => {
                draftState.updatingConfig = false;
                draftState.configError = payload;
            });
        case types.GET_GLOBAL_CONFIGURATION:
            return produce(state, draftState => {
                draftState.fetchingGlobalConfig = true;
            });
        case types.GET_GLOBAL_CONFIGURATION_SUCCESS:
            return produce(state, draftState => {
                draftState.fetchingGlobalConfig = false;
                draftState.globalConfiguration = payload;
            });
        case types.GET_GLOBAL_CONFIGURATION_FAIL:
            return produce(state, draftState => {
                draftState.fetchingGlobalConfig = false;
            });

        case types.SHOW_PROJECT_GUIDE:
            return produce(state, draftState => {
                const { on } = payload as IShowProjectGuide;
                if (draftState.userSpecificConfiguration) draftState.userSpecificConfiguration.show_project_guide = on;
                draftState.showProjectGuideTutorial = on;
            });
        case types.GET_USER_LOCATION:
            return produce(state, draftState => {
                draftState.fetchingUserLocation = true;
            });
        case types.GET_USER_LOCATION_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingUserLocation = false;
            });
        case types.GET_USER_LOCATION_FAIL:
            return produce(state, draftState => {
                draftState.fetchingUserLocation = false;
                draftState.userLocationErrMsgs = payload;
            });
        case types.STORE_NOTIF_TOKEN:
            return produce(state, (draftState: typeof initialState) => {
                draftState.notification_token = payload;
            });
        case types.SET_GUIDED_TOUR_INDEX:
            return produce(state, (draftState: typeof initialState) => {
                draftState.guidedTourIndex = payload;
            });
        case types.RESET_USER_CONFIG:
            return restoreInitialState(state, initialState);
        default:
            return state;
    }
};

export { reducer };
