import { createAction } from 'redux-act';
import {
    IAssignJuryDuty,
    IGetJuryCampaignSubmissions,
    IInviteJuryPayload,
    IJuryDuties,
    IAcceptJuryInvite,
    IScoreSubmission,
    ISubmissionResultsForContest,
    types,
    IGetJurorForContest,
    IAssignedSubmissionToJudge,
    IGetScoreBoard,
    IScoreBoardResponse,
} from './types';
import { IErrorType } from '../../constants/types';

export const inviteJury = createAction<IInviteJuryPayload>(types.INVITE_JURY);
export const inviteJurySuccess = createAction<string[]>(types.INVITE_JURY_SUCCESS);
export const inviteJuryFail = createAction<IErrorType>(types.INVITE_JURY_FAIL);

export const acceptJury = createAction<IAcceptJuryInvite>(types.ACCEPT_JURY_INVITE);
export const acceptJurySuccess = createAction<string>(types.ACCEPT_JURY_INVITE_SUCCESS);
export const acceptJuryFail = createAction<string>(types.ACCEPT_JURY_INVITE_FAIL);

export const getJuryCampaigns = createAction(types.GET_JURY_CAMPAIGNS);
export const getJuryCampaignsSuccess = createAction<IJuryDuties[]>(types.GET_JURY_CAMPAIGNS_SUCCESS);
export const getJuryCampaignsFail = createAction<IErrorType>(types.GET_JURY_CAMPAIGNS_FAIL);

export const getCampaignSubmissions = createAction<IGetJuryCampaignSubmissions>(types.GET_CAMPAIGN_SUBMISSIONS);
export const getCampaignSubmissionsSuccess = createAction<ISubmissionResultsForContest>(
    types.GET_CAMPAIGN_SUBMISSIONS_SUCCESS,
);
export const getCampaignSubmissionsFail = createAction<IErrorType>(types.GET_CAMPAIGN_SUBMISSIONS_FAIL);

export const scoreSubmission = createAction<IScoreSubmission>(types.SCORE_SUBMISSION);
export const scoreSubmissionSuccess = createAction<any>(types.SCORE_SUBMISSION_SUCCESS);
export const scoreSubmissionFail = createAction<IErrorType>(types.SCORE_SUBMISSION_FAIL);

export const distributeSubmissionToJuries = createAction<IAssignJuryDuty>(types.DISTRIBUTE_SUBMISSIONS_TO_JURIES);
export const distributeSubmissionToJuriesSuccess = createAction<string>(types.DISTRIBUTE_SUBMISSIONS_TO_JURIES_SUCCESS);
export const distributeSubmissionToJuriesFail = createAction<IErrorType>(types.DISTRIBUTE_SUBMISSIONS_TO_JURIES_FAIL);

export const getJurorForContest = createAction<IGetJurorForContest>(types.GET_JURORS_FOR_CONTEST);
export const getJurorForContestSuccess = createAction<IJuryDuties[]>(types.GET_JURORS_FOR_CONTEST_SUCCESS);
export const getJurorForContestFail = createAction<IErrorType>(types.GET_JURORS_FOR_CONTEST_FAIL);

export const assignSubmisisonToJuror = createAction<IAssignedSubmissionToJudge>(types.ASSIGN_SUBMISSION_TO_JUROR);
export const assignSubmisisonToJurorSuccess = createAction<IJuryDuties>(types.ASSIGN_SUBMISSION_TO_JUROR_SUCCESS);
export const assignSubmisisonToJurorFail = createAction<IErrorType>(types.ASSIGN_SUBMISSION_TO_JUROR_FAIL);

export const getScoreBoard = createAction<IGetScoreBoard>(types.GET_SCORE_BOARD);
export const getScoreBoardSuccess = createAction<IScoreBoardResponse>(types.GET_SCORE_BOARD_SUCCESS);
export const getScoreBoardFail = createAction<IErrorType>(types.GET_SCORE_BOARD_FAIL);
