import produce from 'immer';
import {
    IGetJuryCampaignSubmissions,
    IJuryDuties,
    IJuryInitialState,
    ISubmission,
    ISubmissionResultsForContest,
    types,
} from './types';
import { ITalentData, types as talentTypes } from '../talent/types';

const initialState: IJuryInitialState = {
    campaigns: [],
    campaignSubmisisons: [],
    errorMsg: null,
    loading: false,
    invites: [],
    currentSubmissionPage: 0,
    gettingCampaignSubmissions: false,
    selectedJuries: [],
    juryMessage: '',
};

const reducer = (state = initialState, action: any) => {
    const { type, payload } = action;
    switch (type) {
        case types.ACCEPT_JURY_INVITE:
        case types.DELETE_JURY:
        case types.DISTRIBUTE_SUBMISSIONS_TO_JURIES:
        case types.INVITE_JURY:
        case types.GET_JURY_CAMPAIGNS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.loading = true;
            });
        case types.GET_CAMPAIGN_SUBMISSIONS:
            return produce(state, (draftState: typeof initialState) => {
                const { page } = payload as IGetJuryCampaignSubmissions;
                draftState.gettingCampaignSubmissions = true;
                draftState.currentSubmissionPage = page as number;
                if (page < 2) {
                    draftState.campaignSubmisisons = [];
                }
            });
        case types.GET_JURY_CAMPAIGNS_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.loading = false;
                draftState.campaigns = payload;
            });
        case types.GET_CAMPAIGN_SUBMISSIONS_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                const { submissions, limit, page } = payload as ISubmissionResultsForContest;
                const LIMIT_TO_USE = limit ?? 10; // TODO get this value from config
                if (page < 2) {
                    draftState.campaignSubmisisons = submissions;
                } else {
                    draftState.campaignSubmisisons = [...draftState.campaignSubmisisons, ...submissions];
                }
                draftState.currentSubmissionPage = page as number;
                draftState.canLoadMoreJurySubmissions = submissions.length >= LIMIT_TO_USE;
                draftState.gettingCampaignSubmissions = false;
            });
        case talentTypes.SAVE_TALENT_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                const { talent } = payload as ITalentData;
                const submissionIndex = draftState.campaignSubmisisons.findIndex(
                    //@ts-ignore TODO: FIX this
                    ({ created_by }) => !!created_by && created_by._id === talent,
                );
                if (submissionIndex > -1) {
                    const currentSubmission = draftState.campaignSubmisisons[submissionIndex];
                    currentSubmission.created_by = {
                        //@ts-ignore TODO: FIX this
                        ...currentSubmission.created_by,
                        talent_saved: true,
                        saved_talent: [payload],
                    };
                    draftState.campaignSubmisisons[submissionIndex] = currentSubmission;
                }
                draftState.loading = false;
            });
        case types.ACCEPT_JURY_INVITE_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.loading = false;
                draftState.juryMessage = payload;
            });
        case types.DISTRIBUTE_SUBMISSIONS_TO_JURIES_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.loading = false;
            });
        case types.INVITE_JURY_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.loading = false;
            });
        case types.SCORE_SUBMISSION_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                const response = payload as ISubmission;
                draftState.loading = false;
                const index = draftState.campaignSubmisisons.findIndex(e => e._id === response._id);
                /** Replace the submission with the scored version */
                draftState.campaignSubmisisons[index] = {
                    ...response,
                    created_by: draftState.campaignSubmisisons[index].created_by,
                };
            });
        case types.ACCEPT_JURY_INVITE_FAIL:
        case types.DISTRIBUTE_SUBMISSIONS_TO_JURIES_FAIL:
        case types.GET_CAMPAIGN_SUBMISSIONS_FAIL:
        case types.GET_JURY_CAMPAIGNS_FAIL:
        case types.SCORE_SUBMISSION_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.loading = false;
                draftState.gettingCampaignSubmissions = false;
                draftState.errorMsg = payload;
                draftState.juryMessage = payload;
            });
        case types.GET_JURORS_FOR_CONTEST:
            return produce(state, draft => {
                draft.gettingJurorForContest = true;
            });
        case types.GET_JURORS_FOR_CONTEST_SUCCESS:
            return produce(state, draft => {
                draft.gettingJurorForContest = false;
                draft.selectedJuries = payload;
            });
        case types.GET_JURORS_FOR_CONTEST_FAIL:
            return produce(state, draft => {
                draft.gettingJurorForContest = false;
                draft.errorMsg = payload;
            });
        case types.ASSIGN_SUBMISSION_TO_JUROR:
            return produce(state, draft => {
                draft.assigningJurorSubmission = true;
            });
        case types.ASSIGN_SUBMISSION_TO_JUROR_SUCCESS:
            return produce(state, draft => {
                draft.assigningJurorSubmission = false;
                const { _id } = payload as IJuryDuties;
                const juryIndex = draft.selectedJuries?.findIndex(jury => jury._id === _id);
                if (juryIndex && juryIndex > -1) {
                    draft.selectedJuries[juryIndex] = payload;
                }
            });
        case types.ASSIGN_SUBMISSION_TO_JUROR_FAIL:
            return produce(state, draft => {
                draft.assigningJurorSubmission = false;
                draft.errorMsg = payload;
            });
        case types.GET_SCORE_BOARD:
            return produce(state, draft => {
                draft.gettingScoreBoard = true;
            });
        case types.GET_SCORE_BOARD_SUCCESS:
            return produce(state, draft => {
                draft.gettingScoreBoard = false;
                draft.scoreBoard = payload;
            });
        case types.GET_SCORE_BOARD_FAIL:
            return produce(state, draft => {
                draft.gettingScoreBoard = false;
                draft.errorMsg = payload;
            });
        default:
            return state;
    }
};

export { reducer };
