import React, { createContext, useState, useMemo, useCallback } from 'react';
import {
    ColorSchemeName,
    StatusBar,
    StyleProp,
    useColorScheme,
    ViewStyle,
    useWindowDimensions,
    View,
} from 'react-native';
import { Container, createTheme, ThemeProvider as MaterialTheme } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { dark, light } from '../constants/theme';
import { Sizes } from './types';
import { FONT_ENUM } from '../types/global/fonts.types';
import { SnackbarProvider } from 'notistack';

export const themes = {
    light: {
        ...light,
    },
    dark: {
        ...dark,
    },
};

export type Theme = {
    disabled: boolean;
    primaryText: string;
    secondaryText: string;
    primaryBackground: string;
    secondaryBackground: string;
    line: string;
    error: string;
    androidDisable: boolean;
};

export type ThemeStyle = 'dark' | 'light';
export type ScreenTypes = {
    isMobile: boolean;
    isTablet: boolean;
    isDesktop: boolean;
};

type State = {
    darkModeEnabled: boolean;
    theme: Theme;
    toggleTheme: (value: boolean) => void;
    themeStyle: ThemeStyle;
    osTheme: ColorSchemeName;
    flexStyle: StyleProp<ViewStyle>;
    rowStyle: StyleProp<ViewStyle>;
    device: ScreenTypes;
    sizes: Sizes;
    width: number;
    height: number;
};

export const ThemeContext = createContext({} as State);

interface IOwnProps {
    children?: any;
    advertisementRight?: any;
    advertisementLeft?: any;
}

const MOBILE_WIDTH_MAX = 767;
const DESKTOP_WIDTH = 992;

export const uiTheme = createTheme({
    palette: {
        primary: {
            main: dark.highlight,
        },
        secondary: {
            main: light.lightWhite,
        },
    },
    custom: {
        primary: 'rgba(0,139,200, 1)',
        primary_dark: 'rgba(0,92,150, 1)',
        secondary: 'rgba(0,55,100, 1)',
        secondary_dark: 'rgb(21, 34, 56)',
        secondary_white: 'rgba(37,43,48, 0.5)',
        black: 'rgb(11,17,23)',
        grey: 'rgba(112,112,112,1)',
        disabledGray: 'rgb(213,213,215)',
        secondaryGrey: 'rgb(35,38,49)',
        lightWhite: 'rgb(242, 242, 246)',
        shadowColor: 'rgba(0,0,0,0.27)',
        primary_red: 'rgba(214,51,5,1)',
        secondary_red: 'rgba(254, 101, 79, 1)',
        darkGray: 'rgba(112,112,112,1)',
        darkGray60: 'rgba(112,112,112,0.3)',
        placeholderGray: 'rgba(186,186,186,1)',
        placeholderGray50: 'rgba(186,186,186,0.5)',
        primaryGreen: 'rgba(0,133,31,1.0)',
        peachYellow: 'rgba(255, 242, 222, 1)',
        accountGray: 'rgba(45,45,45,1)',
        switchGreen: 'rgba(68,219,94,1)',
        primary_background: 'rgba(32,33,36,1)',
        primary_button: 'rgb(242, 242, 246)',
        tertiary_dark: '#c1e4f0',
        tertiary_grey: '#c1e4f0',
        tertiary_blue: 'rgba(193,228,240, 1)', //#c1e4f0
        tertiary_white: '#c1e4f0',
        tertiary_button: 'rgba(45,45,45,1)',
        tertiary_green: 'rgba(97,197,104, 1)',
        light_blue: 'rgba(243,246,255,1)',
        highlight: 'rgba(299,156,40,1)',
        prisma_dark: 'rgba(27,27,30,1)',
        zeus_dark: 'rgba(23,23,23,255)',
        true_white: 'rgb(242, 242, 246)',
    },
    typography: {
        h1: {
            fontSize: '3rem',
            fontWeight: 600,
            fontFamily: FONT_ENUM.BOLD_HEADER,
        },
        h2: {
            fontSize: '1.75rem',
            fontWeight: 600,
            fontFamily: FONT_ENUM.MEDIUM_HEADER,
        },
        h3: {
            fontSize: '1.5rem',
            fontWeight: 600,
            fontFamily: FONT_ENUM.MEDIUM_TEXT,
        },
        body1: {
            fontFamily: FONT_ENUM.MEDIUM_TEXT,
        },
        body2: {
            fontFamily: FONT_ENUM.MEDIUM_TEXT,
            fontSize: '0.7rem',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
});

const ThemeProvider = ({ children, advertisementRight, advertisementLeft }: IOwnProps) => {
    const osTheme = useColorScheme();

    const [theme, setTheme] = useState(themes.light);
    const [isDarkModeEnabled, setIsDarkModeEnabled] = useState(false);
    const [themeStyle, setThemeStyle] = useState<ColorSchemeName>(osTheme);
    const [currentSystemTheme, setCurrentSystemTheme] = useState<ColorSchemeName>(osTheme);
    const [innerWidth, setInnerWidth] = React.useState<number>(window.innerWidth);
    const { height, width } = useWindowDimensions();
    const isMobile = innerWidth <= MOBILE_WIDTH_MAX;
    const isTablet = innerWidth <= DESKTOP_WIDTH && innerWidth >= MOBILE_WIDTH_MAX;
    const isDesktop = innerWidth >= DESKTOP_WIDTH;
    const guidelineBaseWidth = 1920;
    const guidelineBaseHeight = 1080;

    const scale = (size: number) => (width / guidelineBaseWidth) * size;
    const verticalScale = (size: number) => (height / guidelineBaseHeight) * size;
    const moderateScale = (size: number, factor = 0.4) => size + (scale(size) - size) * factor;

    const getSize = () => {
        setInnerWidth(window.innerWidth);
    };
    React.useEffect(() => {
        window.addEventListener('resize', getSize);
        return () => {
            window.removeEventListener('resize', getSize);
        };
    }, [innerWidth]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const sizes = {
        font: {
            _fontSize6: moderateScale(6),
            _fontSize8: moderateScale(8),
            _fontSize10: moderateScale(10),
            _fontSize12: moderateScale(12),
            _fontSize14: moderateScale(14),
            _fontSize16: moderateScale(16),
            _fontSize18: moderateScale(18),
            _fontSize20: moderateScale(20),
            _fontSize24: moderateScale(24),
            _fontSize30: moderateScale(30),
            _fontSize32: moderateScale(32),
            _fontSize40: moderateScale(40),
            _fontSize96: moderateScale(96),
            _fontSize120: moderateScale(120),
        },
        horizontalMargin: {
            _horizontalMargin8: scale(8),
            _horizontalMargin12: scale(12),
            _horizontalMargin16: scale(16),
            _horizontalMargin24: scale(24),
            _horizontalMargin28: scale(28),
            _horizontalMargin30: scale(30),
            _horizontalMargin50: scale(50),
        },
        verticalMargin: {
            _verticalMargin2: verticalScale(2),
            _verticalMargin8: verticalScale(8),
            _verticalMargin12: verticalScale(12),
            _verticalMargin16: verticalScale(16),
            _verticalMargin24: verticalScale(24),
            _verticalMargin40: verticalScale(40),
            _verticalMargin60: verticalScale(60),
        },
        genericSize: {
            _size1: moderateScale(1),
            _size2: moderateScale(2),
            _size4: moderateScale(4),
            _size5: moderateScale(5),
            _size8: moderateScale(8),
            _size10: moderateScale(10),
            _size12: moderateScale(12),
            _size14: moderateScale(14),
            _size16: moderateScale(16),
            _size17: moderateScale(17),
            _size18: moderateScale(18),
            _size19: moderateScale(19),
            _size20: moderateScale(20),
            _size24: moderateScale(24),
            _size26: moderateScale(26),
            _size28: moderateScale(28),
            _size30: moderateScale(30),
            _size35: moderateScale(35),
            _size40: moderateScale(40),
            _size45: moderateScale(45),
            _size50: moderateScale(50),
            _size55: moderateScale(55),
            _size60: moderateScale(60),
            _size70: moderateScale(70),
            _size80: moderateScale(80),
            _size85: moderateScale(85),
            _size90: moderateScale(90),
            _size95: moderateScale(95),
            _size100: moderateScale(100),
            _size110: moderateScale(110),
            _size120: moderateScale(120),
            _size130: moderateScale(130),
            _size141: moderateScale(141),
            _size150: moderateScale(150),
            _size160: moderateScale(160),
            _size170: moderateScale(170),
            _size180: moderateScale(180),
            _size200: moderateScale(200),
            _size220: moderateScale(220),
            _size240: moderateScale(240),
            _size250: moderateScale(250),
            _size280: moderateScale(280),
            _size300: moderateScale(300),
            _size320: moderateScale(320),
            _size350: moderateScale(350),
            _size360: moderateScale(360),
            _size370: moderateScale(370),
            _size375: moderateScale(375),
            _size400: moderateScale(400),
            _size420: moderateScale(420),
            _size450: moderateScale(450),
            _size500: moderateScale(500),
            _size550: moderateScale(550),
            _size600: moderateScale(600),
            _size800: moderateScale(800),
            _size900: moderateScale(900),
        },
    };

    const setThemeAction = useCallback(
        (arg: boolean) => {
            setIsDarkModeEnabled(arg);
            const selecteTheme = isDarkModeEnabled ? themes.dark : themes.light;
            setTheme(selecteTheme);
            setThemeStyle(isDarkModeEnabled ? 'dark' : 'light');
            StatusBar.setBarStyle(isDarkModeEnabled ? 'light-content' : 'dark-content', true);
            setCurrentSystemTheme(osTheme);
        },
        [isDarkModeEnabled, osTheme],
    );

    const themeProvider: any = useMemo(
        () => ({
            theme,
            darkModeEnabled: isDarkModeEnabled,
            toggleTheme: setThemeAction,
            themeStyle,
            osTheme: currentSystemTheme,
            flexStyle: { flex: 1 },
            rowStyle: { flexDirection: 'row', alignItems: 'center' },
            device: {
                isMobile,
                isTablet,
                isDesktop,
            },
            sizes: sizes,
            height,
            width,
        }),
        [
            currentSystemTheme,
            height,
            isDarkModeEnabled,
            isDesktop,
            isMobile,
            isTablet,
            setThemeAction,
            sizes,
            theme,
            themeStyle,
            width,
        ],
    );

    return (
        <ThemeContext.Provider value={themeProvider}>
            <SnackbarProvider maxSnack={3}>
                <MaterialTheme theme={uiTheme}>
                    <View style={{ width: '100%', backgroundColor: theme.black, flexDirection: 'row' }}>
                        <View style={{ flex: 1 }}>{advertisementLeft}</View>
                        <Container
                            sx={{ paddingRight: { xs: 0, sm: 0 }, paddingLeft: { xs: 0, sm: 0 }, maxWidth: '100%' }}
                            maxWidth={false}>
                            {children}
                        </Container>
                        <View style={{ flex: 1 }}>{advertisementRight}</View>
                    </View>
                    <ToastContainer />
                </MaterialTheme>
            </SnackbarProvider>
        </ThemeContext.Provider>
    );
};

export { ThemeProvider };
