import { Box, IconButton } from "@mui/material"
import { CropperModal } from "../ImageCropper/ImageCropperModal"
import AddPhotoAlternateRoundedIcon from '@mui/icons-material/AddPhotoAlternateRounded';
import { BaseSyntheticEvent, Dispatch, SetStateAction, useRef, useState } from "react";

interface IProfileImageUploadProps {
    setPreview: Dispatch<SetStateAction<string | null>>;
}


export const ProfileImageUpload = ({ setPreview }: IProfileImageUploadProps) => {
    const [src, setSrc] = useState<string | null>(null);
    const [modalOpen, setModalOpen] = useState(false);
    const handleUploadClick = () => {
        const uploadInput = document.getElementById('uploadInput');
        uploadInput?.click();
    }
    const imageUploader = useRef(null);
    const handleImgChange = (e: BaseSyntheticEvent) => {
        e.preventDefault();
        setSrc(URL.createObjectURL(e.target.files[0]));
        setModalOpen(true);
      };
    return (
        <>
            <CropperModal
                modalOpen={modalOpen}
                src={src!}
                setPreview={setPreview}
                setModalOpen={setModalOpen}
            />
            <Box
                onClick={handleUploadClick}
                id='uploaderContainer'
                sx={{
                    backgroundColor: 'black',
                    height: '100%',
                    width: '100%',
                    zIndex: 2,
                    position: 'relative',
                    opacity: 0.6,
                    '&:hover': {
                        opacity: 0.9,
                    },
                    borderRadius: '100%',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Box sx={{ alignSelf: 'center', display: 'flex', flexDirection: 'column'}}>
                    <IconButton aria-label='upload profile picture'>
                        <AddPhotoAlternateRoundedIcon sx={{ 'color': 'white', fontSize: 40 }} /><br/>
                        <input type='file' accept='image/*' ref={imageUploader} id='uploadInput' multiple={false} onChange={handleImgChange} hidden />
                    </IconButton>
                </Box>
            </Box></>
    )
}