import { IUser } from '../../types/global/ListRender.types';
import { IGlobalConfiguration } from '../../types/global/helper';

export const types = {
    GET_CONFIGURATION: 'GET_CONFIGURATION',
    GET_CONFIGURATION_SUCCESS: 'GET_CONFIGURATION_SUCCESS',
    GET_CONFIGURATION_FAILED: 'GET_CONFIGURATION_FAILED',

    CREATE_CONFIGURATION: 'CREATE_CONFIGURATION',
    CREATE_CONFIGURATION_SUCCESS: 'CREATE_CONFIGURATION_SUCCESS',
    CREATE_CONFIGURATION_FAILED: 'CREATE_CONFIGURATION_FAILED',

    UPDATE_USER_CONFIG: 'UPDATE_USER_CONFIG',
    UPDATE_USER_CONFIG_SUCCESS: 'UPDATE_USER_CONFIG_SUCCESS',
    UPDATE_USER_CONFIG_FAILED: 'UPDATE_USER_CONFIG_FAILED',

    GET_GLOBAL_CONFIGURATION: 'GET_GLOBAL_CONFIGURATION',
    GET_GLOBAL_CONFIGURATION_SUCCESS: 'GET_GLOBAL_CONFIGURATION_SUCCESS',
    GET_GLOBAL_CONFIGURATION_FAIL: 'GET_GLOBAL_CONFIGURATION_FAIL',

    GET_USER_LOCATION: 'GET_USER_LOCATION',
    GET_USER_LOCATION_SUCCESS: 'GET_USER_LOCATION_SUCCESS',
    GET_USER_LOCATION_FAIL: 'GET_USER_LOCATION_FAIL',

    STORE_NOTIF_TOKEN: 'STORE_NOTIF_TOKEN',
    RESET_USER_CONFIG: 'RESET_USER_CONFIG',

    SET_GUIDED_TOUR_INDEX: 'SET_GUIDED_TOUR_INDEX',

    SHOW_PROJECT_GUIDE: 'SHOW_PROJECT_GUIDE',
};

export interface IConfigurationInitialState {
    fetchingConfig: boolean;
    configError: any;
    creatingConfig: boolean;
    updatingConfig: boolean;
    /**
     * User specific preferences, IP Address, Notifictaion Settings e.t.c
     */
    userSpecificConfiguration?: IUserConfiguration;
    /** Controls App Settings and Avaulable Features, such as
     * Contest rules
     */
    globalConfiguration?: IGlobalConfiguration;
    fetchingGlobalConfig: boolean;
    fetchingUserLocation: boolean;
    userLocationErrMsgs: any;
    notification_token?: string;
    guidedTourIndex?: number;
    showProjectGuideTutorial?: boolean;
}

export interface IUserConfiguration {
    user_location: UserLocation;
    notifications: Notifications;
    web_notifications_settings: WebNotificationsSettings;
    web_communication_preference: WebCommunicationPreference;
    language_pref: string;
    phone_number_code: string;
    timezone: string;
    devices: any[];
    unread_notifications: number;
    unread_web_notifications: number;
    current_profile: string;
    _id: string;
    deviceId: string;
    __v: number;
    os: string;
    push_notification_id: string;
    user: IUser;
    user_biometric_rsa: string;
    default_payment_method: string;
    show_introduction?: boolean;
    show_app_guide?: boolean;
    show_project_guide?: boolean;
    already_created_project?: boolean;
}

export interface UserLocation {
    type: string;
    coordinates: any[];
}

export interface Notifications {
    messages: boolean;
    coming: boolean;
    casting: boolean;
    features: boolean;
    applications: boolean;
}

export interface WebNotificationsSettings {
    project_recommendation: boolean;
    mentions: boolean;
    campaign_activities: boolean;
    shared_content: boolean;
    project_activities: boolean;
    application_activity: boolean;
}

export interface WebCommunicationPreference {
    sms: boolean;
    email: boolean;
    phone: boolean;
}

export interface Location {
    type: string;
    address: string;
    description: string;
    coordinates: number[];
}

export interface IUpdateUserConfig {
    payload: any;
}

export interface IShowProjectGuide {
    on: boolean;
}
