import React from 'react';
import { StyleSheet } from 'react-native';
import { Typography, Box, Link } from '@mui/material';
import { useThemeContext } from '../../hook/useThemeManager';
import { ColorScheme, theme } from '../../constants/theme';
import { FONT_ENUM } from '../../types/global/fonts.types';
import { EsPlayButton } from '../EsButtons/EsPlayButton';
import { IContest } from '../../types/global/helper';
import { strings } from '../../constants';
import CustomLazyLoadImage from '../Image/CustomLazyLoadImage';

export interface ITrendingCardProps {
    trendingItem: IContest;
    onClickTrendingCard?: (data: IContest) => void;
}

const EsTrendingCard: React.FC<ITrendingCardProps> = props => {
    const { trendingItem, onClickTrendingCard } = props;
    const { themeStyle } = useThemeContext();
    const styles = dynamicStyles(themeStyle);

    return (
        <Box
            onClick={() => onClickTrendingCard && onClickTrendingCard(trendingItem)}
            sx={{
                flexDirection: { xs: 'column', sm: 'column', lg: 'column', md: 'column', xl: 'column' },
                maxHeight: 'fit-content',
                alignItems: {
                    xs: 'center',
                    sm: 'center',
                    lg: 'flex-start',
                },
                justifyContent: 'center',
                gap: {
                    md: '10px',
                },
                marginBottom: '20px',
            }}>
            <Link
                sx={{
                    width: '100%',
                    // maxWidth: '345px',
                    height: '245px',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    borderRadius: '10px',
                    border: 'none',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    // minWidth: { xs: '320px', sm: '345px' },
                    textDecoration: 'none',
                    overflow: 'hidden',
                }}
                key={trendingItem._id}>
                <CustomLazyLoadImage
                    effect="blur"
                    src={trendingItem.feature_image}
                    alt={`${trendingItem.title}_image`}
                    showGradient
                    width={'100%'}
                    height={'100%'}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        objectFit: 'cover',
                        height: '245px',
                        zIndex: -1,
                    }}
                />
                {/* Play button */}
                <EsPlayButton />
            </Link>

            <Box style={styles.flexColumn} sx={{ gap: '10px', mt: { xs: '10px', sm: '10px' } }}>
                <Typography
                    sx={{
                        fontSize: 16,
                        fontWeight: '600',
                        fontFamily: FONT_ENUM.MAIN_BOLD,
                        color: theme[themeStyle].filters_white,
                        textTransform: 'capitalize',
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                    }}>
                    {trendingItem.title}
                </Typography>
                <Typography
                    style={styles.text}
                    sx={{
                        color: theme[themeStyle].gray_message,
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 3,
                    }}>
                    {trendingItem.description}
                    <Link
                        onClick={() => onClickTrendingCard && onClickTrendingCard(trendingItem)}
                        sx={{
                            color: theme[themeStyle].primary_orange,
                            textDecoration: 'none',
                            '&:hover': { textDecoration: 'underline', cursor: 'pointer' },
                        }}>
                        {strings.more}
                    </Link>
                </Typography>

                {/* {trendingData.tag && trendingData.tag.length > 0 && <TagsRenderer trendingData={trendingData} />} */}
            </Box>
        </Box>
    );
};

const dynamicStyles = (colorScheme: ColorScheme) =>
    StyleSheet.create({
        bgImage: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            width: 'inherit',
            height: 'inherit',
            zIndex: -1,
        },
        flexColumn: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
        },
        title: {
            fontSize: 30,
            fontWeight: '600',
            fontFamily: FONT_ENUM.MAIN_BOLD,
            color: theme[colorScheme].filters_white,
        },
        text: {
            fontSize: 14,
            fontWeight: '500',
            fontFamily: FONT_ENUM.MAIN_REGULAR,
        },
    });

export { EsTrendingCard };
