import React from 'react';
import { Box } from "@mui/material";
import background from '../../asset/images/image/es_login_bg.png';
import { ColorScheme } from '../../constants/theme';
import { StyleSheet } from 'react-native';
import { useThemeContext } from '../../hook/useThemeManager';

const EsBackground = () => {
  const { themeStyle } = useThemeContext();
  const styles = dynamicStyles(themeStyle);
  
  return (
    <Box style={styles.container} sx={{position: 'fixed', top: 0}}>
    </Box>
  )
}

const dynamicStyles = (colorScheme: ColorScheme) =>
StyleSheet.create({
  container: {
    backgroundImage: `url(${background})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100vw',
    height: '100vh',
    zIndex: 0
  },
})

export {EsBackground}