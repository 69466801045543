import { IUserSummary } from '../../types/global/helper';

export const types = {
    /** The below is designed to create a view. In the backend, it also updates a view if it already exists */
    CREATE_VIEW: 'CREATE_VIEW',
    CREATE_VIEW_SUCCESS: 'CREATE_VIEW_SUCCESS',
    CREATE_VIEW_FAIL: 'CREATE_VIEW_FAIL',

    GET_VIEWS: 'GET_VIEWS',
    GET_VIEWS_SUCCESS: 'GET_VIEWS_SUCCESS',
    GET_VIEWS_FAIL: 'GET_VIEWS_FAIL',

    GET_ONE_VIEW: 'GET_ONE_VIEW',
    GET_ONE_VIEW_SUCCESS: 'GET_ONE_VIEW_SUCCESS',
    GET_ONE_VIEW_FAIL: 'GET_ONE_VIEW_FAIL',
};

type ViewType = 'act' | 'project';

export interface ICreateView {
    projectId: string;
    duration_watched: number;
    view_type?: ViewType;
}

export interface IGetViewForOneProject {
    projectId: string;
}

export interface IView {
    duration_watched: number;
    created_at: string;
    view_type: string;
    _id: string;
    project: string;
    device_id: string;
    ip_address: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
    user: IUserSummary;
    id: string;
}

export interface IViewInitialState {
    views: IView[];
    gettingViews: boolean;
    viewsForSelectedProject: IView[];
    creatingView: boolean;
    errMsg: any;
}
