import React from 'react';
import { ThemeContext } from '../../module/ThemeManager';
import { Stack, Skeleton, Grid } from '@mui/material';
import { theme } from '../../constants/theme';

const TrendingCardShimmer = () => {
    return (
        <ThemeContext.Consumer>
            {({ themeStyle, device }) => {
                const value = device.isDesktop ? 8 : 3;

                return (
                    <Stack>
                        <Skeleton
                            variant="text"
                            width={100}
                            sx={{
                                fontSize: '2rem',
                                backgroundColor: theme[themeStyle].gray_text,
                                marginBottom: { xs: 6, sm: 8, lg: 8 },
                                marginLeft: 1,
                            }}
                        />
                        <Grid container spacing={3}>
                            {Array.from(new Array(value)).map((_, index) => (
                                <Grid item xs={12} sm={6} md={4} lg={'auto'} key={index}>
                                    <Stack
                                        direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'column' }}
                                        spacing={1}>
                                        <Skeleton
                                            variant="rounded"
                                            height={245}
                                            sx={{
                                                width: {
                                                    xs: '100%',
                                                    sm: 'auto',
                                                    md: 'auto',
                                                    lg: '320px',
                                                },
                                                backgroundColor: theme[themeStyle].gray_text,
                                                borderRadius: '10px',
                                            }}
                                        />
                                        <Stack direction="column">
                                            <Skeleton
                                                variant="text"
                                                sx={{
                                                    fontSize: '2rem',
                                                    width: 150,
                                                    backgroundColor: theme[themeStyle].darkGray60,
                                                    height: '20px',
                                                }}
                                            />
                                            <Skeleton
                                                variant="text"
                                                sx={{
                                                    width: 170,
                                                    backgroundColor: theme[themeStyle].darkGray60,
                                                    height: '20px',
                                                }}
                                            />
                                            <Skeleton
                                                variant="text"
                                                sx={{
                                                    width: 200,
                                                    backgroundColor: theme[themeStyle].darkGray60,
                                                    height: '20px',
                                                    marginBottom: { xs: 5, lg: 10 },
                                                }}
                                            />
                                        </Stack>
                                    </Stack>
                                </Grid>
                            ))}
                        </Grid>
                    </Stack>
                );
            }}
        </ThemeContext.Consumer>
    );
};

export { TrendingCardShimmer };
