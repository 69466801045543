import { StyleSheet } from 'react-native';
import { useCallback, useState } from 'react';
import { useThemeContext } from '../../hook/useThemeManager';
import { ColorScheme, theme } from '../../constants/theme';
import { FormControl, Box, Link } from '@mui/material';
import { EsLoginWrapper } from './EsLoginWrapper';
import question from '../../asset/images/icons/es_question_mark.svg';
import { FONT_ENUM } from '../../types/global/fonts.types';
import { CustomTextField } from '../TextInput/CustomTextField';
import { strings } from '../../constants/strings';
import { validate } from '../../utils/validators';
import { isEmpty } from '../../utils/lodash';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CustomLazyLoadImage from '../Image/CustomLazyLoadImage';

type Field = 'email' | 'password';

export type TUsernamePassword = {
    email: string;
    password: string;
    token?: string | null;
};

type UserFormPassword = TUsernamePassword & {
    updateFields: (fields: Partial<TUsernamePassword>) => void;
};

const EsSigninData = ({ email, password, updateFields }: UserFormPassword) => {
    const { themeStyle } = useThemeContext();
    const styles = dynamicStyles(themeStyle);
    const [fieldType, setFieldType] = useState('password');

    const [, setIsFormValid] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const [errorState, setErrorState] = useState({
        email: {
            focused: false,
            isErrorState: false,
            errorMessage: '',
        },
        password: {
            focused: false,
            isErrorState: false,
            errorMessage: '',
        },
    });
    const [passwordIsValid, setPasswordIsValid] = useState({
        passwordHasLength: false,
        passwordHasCase: false,
        passwordHasNumber: false,
        errorMessage: '',
    });

    const _validatePasswordCharacters = (pass: any) => {
        const passwordLength = validate.validatePasswordLength(pass);
        setPasswordIsValid(prevState => ({
            ...prevState,
            passwordHasLength: passwordLength,
        }));

        const passwordhasNumber = validate.validatePasswordNumber(pass);
        setPasswordIsValid(prevState => ({
            ...prevState,
            passwordHasNumber: passwordhasNumber,
        }));

        const passwordHascase = validate.validatePasswordCase(pass);
        setPasswordIsValid(prevState => ({
            ...prevState,
            passwordHasCase: passwordHascase,
        }));
        validateFormListener();
    };

    const validateFormListener = useCallback(() => {
        const isFormNowValid =
            validate.validateEmail(formData.email) &&
            passwordIsValid.passwordHasCase &&
            passwordIsValid.passwordHasLength &&
            passwordIsValid.passwordHasNumber;
        setIsFormValid(isFormNowValid);
    }, [
        formData.email,
        passwordIsValid.passwordHasCase,
        passwordIsValid.passwordHasLength,
        passwordIsValid.passwordHasNumber,
    ]);

    const handleTextChange = (text: string, field: 'email' | 'password') => {
        setFormData(prevData => ({
            ...prevData,
            [field]: text,
        }));
        if (field === 'password') {
            _validatePasswordCharacters(text);
        }
        validateFormListener();
    };

    const handleOnBlur = (field: Field, errorMsg?: string) => {
        switch (field) {
            case 'email':
                if (isEmpty(formData[field]) || !validate.validateEmail(formData[field])) {
                    setErrorState(prevState => ({
                        ...prevState,
                        [field]: {
                            focused: false,
                            isErrorState: true,
                            errorMessage: errorMsg ?? `Enter valid ${field}`,
                        },
                    }));
                } else {
                    setErrorState(prevState => ({
                        ...prevState,
                        [field]: {
                            focused: false,
                            isErrorState: false,
                            errorMessage: ``,
                        },
                    }));
                }
                break;
            case 'password':
                const isPasswordValid =
                    passwordIsValid.passwordHasCase &&
                    passwordIsValid.passwordHasLength &&
                    passwordIsValid.passwordHasNumber;

                if (isEmpty(formData[field]) || !isPasswordValid) {
                    setErrorState(prevState => ({
                        ...prevState,
                        [field]: {
                            focused: false,
                            isErrorState: true,
                            errorMessage: errorMsg ?? `Enter a valid ${field}`,
                        },
                    }));
                } else {
                    setErrorState(prevState => ({
                        ...prevState,
                        [field]: {
                            focused: false,
                            isErrorState: false,
                            errorMessage: ``,
                        },
                    }));
                }
                break;

            default:
                return;
        }
    };

    const handleOnFocus = (field: Field) => {
        setErrorState(prevState => ({
            ...prevState,
            [field]: {
                focused: false,
                isErrorState: false,
                errorMessage: ``,
            },
        }));
    };

    return (
        <EsLoginWrapper title="Sign In">
            <FormControl style={{ width: '100%' }}>
                <Box sx={{ marginTop: '40px' }}>
                    <CustomTextField
                        label="Email"
                        onChange={e => {
                            handleTextChange(e.target.value, 'email');
                            updateFields({ email: e.target.value });
                        }}
                        value={formData.email}
                        keyboardType="email-address"
                        numberOfLines={1}
                        maxLength={60}
                        importantForAutofill="no"
                        placeholderTextColor={theme[themeStyle].darkGray60}
                        placeholder={strings.email_address}
                        autoComplete="off"
                        onFocus={() => handleOnFocus('email')}
                        onBlur={() => handleOnBlur('email')}
                        isErrorState={errorState.email.isErrorState}
                        errorMsg={errorState.email.errorMessage}
                        isFilledState={!isEmpty(formData.email)}
                        isFocused={errorState.email.focused}
                        textFieldProps={{
                            InputProps: {
                                disableUnderline: true,
                                name: 'email',
                                type: 'email',
                            },
                            required: true,
                        }}
                    />
                </Box>

                <Box sx={{ marginTop: '20px', marginBottom: '40px' }}>
                    <CustomTextField
                        label="Password"
                        onChange={e => {
                            handleTextChange(e.target.value, 'password');
                            updateFields({ password: e.target.value });
                        }}
                        value={formData.password}
                        numberOfLines={1}
                        caretHidden
                        secureTextEntry
                        maxLength={60}
                        importantForAutofill="no"
                        placeholderTextColor={theme[themeStyle].darkGray60}
                        placeholder={strings.password}
                        autoComplete="off"
                        onFocus={() => handleOnFocus('password')}
                        onBlur={() => handleOnBlur('password')}
                        isFilledState={!isEmpty(formData.password)}
                        isFocused={errorState.password.focused}
                        enableCustomButton={!isEmpty(formData.password)}
                        customButton={
                            fieldType === 'password' ? (
                                <VisibilityIcon onClick={() => setFieldType('text')} color="primary" />
                            ) : (
                                <VisibilityOffIcon onClick={() => setFieldType('password')} color="primary" />
                            )
                        }
                        textFieldProps={{
                            InputProps: {
                                disableUnderline: true,
                                name: 'password',
                                type: fieldType,
                            },
                            required: true,
                        }}
                    />
                    <Box
                        sx={{
                            marginTop: '8px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            gap: '8px',
                        }}>
                        <CustomLazyLoadImage src={question} alt="question mark icon" style={{ cursor: 'pointer' }} />
                        <Link href="/reset-password" style={styles.link} sx={{ textDecoration: 'none' }}>
                            Forgot password ?
                        </Link>
                    </Box>
                </Box>
            </FormControl>
        </EsLoginWrapper>
    );
};

const dynamicStyles = (colorScheme: ColorScheme) =>
    StyleSheet.create({
        text: {
            maxWidth: 400,
            width: '100%',
            textAlign: 'left',
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: '400',
            color: theme[colorScheme].form_label,
            fontFamily: FONT_ENUM.MAIN_REGULAR,
        },
        link: {
            color: theme[colorScheme].primary_orange,
            width: 'fit-content',
            textAlign: 'left',
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '20px',
            cursor: 'pointer',
            fontFamily: FONT_ENUM.MAIN_REGULAR,
        },
        input: {
            width: '100%',
            height: '44px',
            paddingLeft: 14,
            paddingRight: 14,
            paddingTop: 10,
            paddingBottom: 10,
            boxShadow:
                '0px 1px 1px 0px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgba(103, 110, 118, 0.16), 0px 2px 5px 0px rgba(103, 110, 118, 0.08)',
            backgroundColor: theme[colorScheme].gray_text,
            borderRadius: 8,
            marginTop: 8,
        },
    });

export { EsSigninData };
