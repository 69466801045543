import { createAction } from 'redux-act';
import { ICreateView, IGetViewForOneProject, IView, types } from './types';
import { IErrorType } from '../../constants/types';
import { IGenericDocumentModifier } from '../../types/global/helper';

export const createView = createAction<ICreateView>(types.CREATE_VIEW);
export const createViewSuccess = createAction<IView>(types.CREATE_VIEW_SUCCESS);
export const createViewFail = createAction<IErrorType>(types.CREATE_VIEW_FAIL);

export const getViewsForProject = createAction<IGetViewForOneProject>(types.GET_ONE_VIEW);
export const getViewsForProjectSuccess = createAction<IView[]>(types.GET_ONE_VIEW_SUCCESS);
export const getViewsForProjectFail = createAction<IErrorType>(types.GET_ONE_VIEW_FAIL);

export const getViews = createAction<IGenericDocumentModifier>(types.GET_VIEWS);
export const getViewsSuccess = createAction<IView[]>(types.GET_VIEWS_SUCCESS);
export const getViewsFail = createAction<IErrorType>(types.GET_VIEWS_FAIL);
