import React from 'react';
import { SuggestedContestsShimmer } from '../SuggestedContestsShimmer';
import { SuggestedUsersShimmer } from '../SuggestedUsersShimmer';
import { SuggestedProjectsShimmer } from '../SuggestedProjectsShimmer';
import { useSelector } from 'react-redux';
import { currentFilterCategorySelector } from '../../selectors/selectors';
import { useThemeContext } from '../../hook/useThemeManager';

const CategorySuggestionShimmer = () => {
    const categoryType = useSelector(currentFilterCategorySelector);
    const { device } = useThemeContext();

    if (device.isMobile) return null;
    if (categoryType === 'project') {
        return <SuggestedProjectsShimmer />;
    } else if (categoryType === 'contest') {
        return <SuggestedContestsShimmer />;
    } else if (categoryType === 'talent') {
        return <SuggestedUsersShimmer />;
    } else return null;
};
export { CategorySuggestionShimmer };
