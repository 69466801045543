import produce from 'immer';
import { ICreateContestPayload } from '../../redux/contest/types';

export const initialState: ICreateContestPayload = {
    title: '',
    date_start: '',
    description: '',
    expiration_date: '',
    requirements: [],
    contest_media: [],
    contest_phase_dates: [],
    contest_phases_enabled: false,
    contest_video: '',
    host: '',
    is_feature: false,
    partner: [],
    premium: false,
    rating: 'PG-13',
    submission_cost: '',
    tag: [],
    technical_spec: {
        max_duration: 2,
        media_type: 'video',
        min_duration: 1,
        project_format: 'video',
    },
    voting_required: false,
    voting_type: 'daily',
    genre: [],
    prize: [],
    jury: [],
};

const actions = {
    FIELD: 'FIELD',
    RESET: 'RESET',
};

export function reducer(state: any, action: any) {
    switch (action.type) {
        case actions.FIELD:
            return produce(state, (draftState: { [x: string]: any }) => {
                draftState[action.field] = action.value;
            });
        case actions.RESET:
            return produce(state, () => {
                return initialState;
            });
        default:
            return state;
    }
}
