import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { persistor, store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from './module/ThemeManager';
import { enableMapSet } from 'immer';
import { ChakraProvider } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ClearCacheProvider } from 'react-clear-cache';
import mixpanel from 'mixpanel-browser';
var { registerObserver } = require('react-perf-devtool');

if (process.env.REACT_APP_NODE_ENV === 'dev') {
    function callback(measures: any) {
        console.log(measures);
        // do something with the measures
    }
    registerObserver({}, callback);
}
enableMapSet();

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    debug: false,
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
        }),
        new Sentry.Replay(),
        new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            ),
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN as string, {
    debug: false,
    track_pageview: process.env.NODE_ENV === 'production',
    autotrack: process.env.NODE_ENV === 'production',
    cookie_name: 'thspian-x-cookie',
    secure_cookie: true,
    persistence: 'cookie',
    persistence_name: 'thspain_persist',
    disable_persistence: true,
});

require('react-web-vector-icons/fonts');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <HelmetProvider>
        <ClearCacheProvider duration={60000} auto={false}>
            <ChakraProvider resetCSS>
                <Provider store={store}>
                    <ThemeProvider>
                        <PersistGate loading={null} persistor={persistor}>
                            <App />
                        </PersistGate>
                    </ThemeProvider>
                </Provider>
            </ChakraProvider>
        </ClearCacheProvider>
    </HelmetProvider>,
);

reportWebVitals();
