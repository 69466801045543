import { all, call, delay, put, takeLatest, select } from 'redux-saga/effects';
import {
    getMyProjectsSuccess,
    getMyProjectsFail,
    getProjectSuccess,
    getProjectFail,
    getProjectsSuccess,
    getProjectsFail,
    getProjects,
    getProjectCommentsSuccess,
    getProjectCommentsFail,
    getProjectComments,
    setmedia,
    getWatchContentSuccess,
    getWatchContentFail,
    getActSuccess,
    getActFail,
    getActCommentsSuccess,
    getActCommentsFail,
    getActComments,
    createProjectSuccess,
    createProjectFail,
    uploadProjectMediaSuccess,
    uploadProjectMediaFail,
    updateProjectSuccess,
    updateProjectFail,
    updateProject as updateProjectAction,
    uploadProjectMedia as uploadMediaAction,
    publishProjectSuccess,
    publishProjectFail,
    unPublishProjectSuccess,
    unPublishProjectFail,
    deleteProject as deleteProjectAction,
    deleteProjectSuccess,
    deleteProjectFail,
    likeProjectFail,
    deleteLikeFail,
    likeProjectSuccess,
    deleteLikeSuccess,
    leaveCommentSuccess,
    leaveCommentFail,
    updateCommentSuccess,
    updateCommentFail,
    deleteCommentSuccess,
    deleteCommentFail,
    likeCommentSuccess,
    likeCommentFail,
    mediaForgeWorker,
    deleteCommentLikeSuccess,
    deleteCommentLikeFail,
    getFeedSuccess,
    getFeedFail,
    downloadProjectSuccess,
    downloadProjectFail,
    triggerMediaForgeSuccess,
    triggerMediaForgeFail,
    mediaForgeTrigger,
    resumeUploadSuccess,
} from './actions';
import { globalErrorHandler } from '../error/saga';
import { api } from '../../configurations/api';
import {
    MEDIA_FORGE,
    // MEDIA_FORGE,
    actsUrl,
    actsUrlByIdentifier,
    commentUrl,
    getContestUrl,
    getMyProjectsUrl,
    getProjectcommentUrl,
    getSignedUrlForProject,
    getSignedUrlForThumbnail,
    likeUrl,
    projectUrl,
    publishProjectUrl,
    unPublishProjecturl,
    watchUrl,
} from '../../configurations/api/url';
import { httpRequest } from '../types';
import { IGenericDocumentModifier, IProject } from '../../types/global/helper';
import {
    DownloadProjectAction,
    ICreateProject,
    IGetAct,
    IGetActComments,
    IGetActPlaylist,
    IGetComments,
    IGetFeed,
    IGetProjects,
    IGetSingleProject,
    IGetWatchContent,
    ILeaveComment,
    IProjectInitialState,
    IReactToComment,
    IReactToProject,
    ITriggerMediaForge,
    IUpdateProject,
    IUploadProject,
    types,
} from './types';
import { Action } from 'redux';
import { CACHE_TYPE, CacheValue, NotUniqueCacheValue } from '../cache/types';
import { getCacheByType, getCacheDuration, getNotUniqueCacheByKey, isUseCacheEnabled } from '../cache/saga';
import { clearCache, destroyOneCache, setCache } from '../cache/action';
import { genericParseSingleDocument, parseGenericCollection, parseProject } from '../../utils/responseProcessor';
import { en_config } from '../../config';
import { IActSummary, IWatchResponse } from '../../types/global/media.types';
import { parseWatchContent } from '../../utils/reponseProcessorII';
import { getActs, getActsFail, getActsSuccess } from './actions';
import { navigate } from '../navigator/action';
import { ROUTES } from '../../types/global/routes.types';
import { filterOutNotAllowed, getJWtDetails, showToastMessage } from '../../utils/AppUtils';
import axios from 'axios';
import { store } from '../store';
import { isEmpty } from '../../utils/lodash';
import { SentryCapture } from '../../analytics/Sentry';
import { refreshUserDashboard } from '../account/actions';
import { dataURIToBlob, determineVideoOrientation } from '../../utils/mediaHelper';
import dayjs from 'dayjs';
import { RootState } from '../root-reducer';
import { IContestInitialState } from '../contest/types';
import { setSubmissionPrompt, submitProject } from '../contest/actions';

/**
 *
 * Create Project
 *
 * First Create Project with title, description,
 * Then Uplaod file to project
 * then publish
 */

/** This Saga will only create a project and does not upload content.
 * Use the upload project for any uploads.
 */

function extractVideoKey(url: string) {
    // Regular expression to extract the video key
    const regex = /user-(.*?\.mp4)/;
    const match = url.match(regex);
    if (match && match.length > 1) {
        return 'user-' + match[1]; // Adding "user-" prefix
    } else {
        return null; // If no match found
    }
}
function* downloadProject({ payload, resolve, reject }: DownloadProjectAction): Generator<any, any, any> {
    try {
        const response = yield call(fetch, `${payload}`);
        yield put(downloadProjectSuccess(response));
        resolve(response);
    } catch (error: any) {
        yield put(downloadProjectFail(error));
        yield call(globalErrorHandler, error);
        reject(error);
    }
}
function* createProject({ payload }: { payload: ICreateProject }): any {
    const filteredBody = filterOutNotAllowed(payload, 'project', 'feature_image');
    try {
        const response = yield call(api, `${projectUrl}`, httpRequest.POST, filteredBody, 0, 1000, false);
        const { data } = response.data;
        const uploadPayload = {
            projectId: data._id,
            project: payload.project,
            feature_image: payload.feature_image,
            size: determineVideoOrientation(payload.metadata),
        };
        /** Trigger Upload */
        yield put(uploadMediaAction(uploadPayload));
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(createProjectFail(error));
        yield call(globalErrorHandler, error);
    }
}

function* triggerMediaForge({ payload }: { payload: ITriggerMediaForge }) {
    try {
        const { user } = getJWtDetails();
        const project = yield* getProject({
            payload: {
                id: payload.projectId as string,
                skipCache: true,
                noPlaylistRefetch: true,
                skipMediaSet: true,
            },
        });
        if (project && !project.processing_complete) {
            const forgePayload = {
                videoKey: extractVideoKey(payload.projectKey),
                userName: user?.userName,
                environment: process.env.REACT_APP_NODE_ENV,
                size: payload.size,
                withImage: payload.withImage,
            };

            // Call mediaForge health check here first
            yield axios.post(
                MEDIA_FORGE,
                {
                    ...forgePayload,
                },
                {
                    withCredentials: false,
                },
            );
            yield put(triggerMediaForgeSuccess());
            const now = dayjs();
            yield put(
                mediaForgeTrigger({
                    projectId: payload.projectId as string,
                    timeRetried: now as unknown as string,
                }),
            );
        }
        yield call(showToastMessage, 'Project processing triggered successully', 'success');
    } catch (error: any) {
        yield call(globalErrorHandler, error);
        yield put(triggerMediaForgeFail(error));
    }
}

/** This saga only uploads projects file and nothing else */
function* uploadProjectMedia({ payload }: { payload: IUploadProject }): any {
    const { user } = getJWtDetails();
    let featureKey;
    try {
        /** Get Signed URL from Service to upload */
        const awsUrl = yield call(api, `${getSignedUrlForProject}/${payload.projectId}`, httpRequest.GET, {}, 2, 2000);
        const { videoKey, url } = awsUrl.data;
        if (videoKey && url) {
            const response = yield axios.put(url, dataURIToBlob(payload.project), {
                onUploadProgress: progress => {
                    uploadListerner(progress, 'Uploading video file ...');
                },
                maxContentLength: Infinity,
                withCredentials: false,
            });
            const { statusText } = response;
            // Upload Feature Image
            if (payload.feature_image) {
                featureKey = yield call(
                    api,
                    `${getSignedUrlForThumbnail}/${payload.projectId}`,
                    httpRequest.GET,
                    {},
                    2,
                    2000,
                );
                const res = yield axios
                    .put(featureKey.data.url, dataURIToBlob(payload.feature_image), {
                        onUploadProgress: progress => {
                            uploadListerner(progress, 'Uploading thumbnail ...');
                        },
                        maxContentLength: Infinity,
                        withCredentials: false,
                        'axios-retry': {
                            retries: 2,
                        },
                    })
                    .catch(e => {
                        SentryCapture(e, 'error');
                    });
                if (res.statusText === 'OK') {
                    yield put(
                        updateProjectAction({
                            body: {
                                feature_image: featureKey.data.videoKey,
                            },
                            projectId: payload.projectId,
                            fromUpload: true,
                        }),
                    );
                }
            }
            if (statusText === 'OK') {
                yield put(
                    updateProjectAction({
                        body: {
                            project: videoKey,
                        },
                        projectId: payload.projectId,
                        fromUpload: true,
                    }),
                );
                /** Initiate MediaForge Request */
                const forgePayload = {
                    videoKey,
                    userName: user?.userName,
                    environment: process.env.REACT_APP_NODE_ENV,
                    size: payload.size,
                    withImage: !payload.feature_image,
                };
                // Call MedieForgeHealth Check First here Then proceed after
                yield axios
                    .post(
                        MEDIA_FORGE,
                        {
                            ...forgePayload,
                        },
                        {
                            withCredentials: false,
                        },
                    )
                    .catch(e => {
                        SentryCapture(e, 'error');
                    });
            } else {
                yield put(
                    deleteProjectAction({
                        documentId: payload.projectId,
                        additionalPayload: 'Failed Upload',
                    }),
                );
            }
        }
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(
            mediaForgeWorker({
                status: 'failed',
                mediaForgeMessage: 'Project Processing Failed , We are looking into it.',
            }),
        );
        /** Removing this here, so that we do not delete the process if the project actually completed upload */
        // yield put(
        //     deleteProjectAction({
        //         documentId: payload.projectId,
        //         additionalPayload: 'Failed Upload',
        //     }),
        // );
        yield put(uploadProjectMediaFail(error));
        yield call(globalErrorHandler, error);
    }
}

/** Currently this works to cancel the upload */
function* resumeUpload(): any {
    /** Get the currentProjectInCreation
     * Get the current progress
     */
    const state: RootState = yield select();
    const { project }: { project: IProjectInitialState } = state;
    const { currentProjectInCreation, mediaForge } = project || {};
    if (
        currentProjectInCreation &&
        mediaForge &&
        mediaForge.progress &&
        mediaForge.progress > 0 &&
        mediaForge.progress < 100 &&
        currentProjectInCreation.metadata
    ) {
        const uploadPayload: any = {
            projectId: currentProjectInCreation._id,
            project: currentProjectInCreation,
            // feature_image: currentProjectInCreation.feature_image, Check if this will work
            size: determineVideoOrientation(currentProjectInCreation.metadata),
        };
        /** Trigger Upload */
        yield put(uploadMediaAction(uploadPayload));
        yield put(resumeUploadSuccess('Upload caught and triggered'));
    } else {
        yield put(resumeUploadSuccess('No upload currently going on'));
    }
}

/** This route here will update a project including it's feature and project
 *
 */
function* updateProject({ payload }: { payload: IUpdateProject }): any {
    const body = payload.body;
    try {
        const response = yield call(
            api,
            `${projectUrl}/${payload.projectId}`,
            httpRequest.PATCH,
            body,
            0,
            1000,
            (!!payload.body.feature_image && !payload.fromUpload) || payload.body.project_gallery,
        );
        const { data } = response.data;
        const parsedProject = genericParseSingleDocument(data);
        yield put(updateProjectSuccess(parsedProject));
        yield put(destroyOneCache({ cacheType: CACHE_TYPE.USER_PROJECTS }));
        /** Calling this here to complete our project upload flow */
        if (payload.fromUpload) {
            yield put(
                mediaForgeWorker({
                    status: 'completed',
                    mediaForgeMessage: 'Upload Completed....🚀🚀🚀🚀🚀.',
                    project: parsedProject,
                }),
            );
            yield put(uploadProjectMediaSuccess(parsedProject));
            yield put(createProjectSuccess(parsedProject));
            /** Check for autoSubmit */
            const state: RootState = yield select();
            const { project, contest }: { project: IProjectInitialState; contest: IContestInitialState } = state;
            const { currentProjectInCreation } = project;
            const { autoPublishAndSubmit, focusCampaign } = contest;
            if (
                !!currentProjectInCreation &&
                !!focusCampaign &&
                autoPublishAndSubmit
                // Ensure that the project has been uploaded successfully
            ) {
                if (currentProjectInCreation._id && currentProjectInCreation.project) {
                    yield publishProject({
                        payload: {
                            documentId: currentProjectInCreation._id!,
                        },
                    });
                    yield delay(1000);

                    yield put(
                        submitProject({
                            contest: focusCampaign._id!,
                            project: currentProjectInCreation._id!,
                        }),
                    );
                } else {
                    yield call(
                        showToastMessage,
                        'Video could not be submitted to the campaign. We are lookin into the issue',
                        'info',
                    );
                }
            }
        }
    } catch (error: any) {
        SentryCapture(error, 'error');
        if (payload.fromUpload) {
            yield put(
                deleteProjectAction({
                    documentId: payload.projectId,
                    additionalPayload: 'Failed Upload',
                }),
            );
            yield put(
                mediaForgeWorker({
                    status: 'failed',
                    mediaForgeMessage: 'Project Processing Failed , We are looking into it.',
                }),
            );
        }
        yield put(updateProjectFail(error));
        yield call(globalErrorHandler, error);
    }
}

/** Use the below to publish a project */
function* publishProject({ payload }: { payload: IGenericDocumentModifier }): any {
    try {
        const response = yield call(api, `${publishProjectUrl}/${payload.documentId}`, httpRequest.PATCH, {}, 2, 1000);
        const { data } = response;
        const parsedProject = genericParseSingleDocument(data);
        yield put(publishProjectSuccess(parsedProject));
        yield put(destroyOneCache({ cacheType: CACHE_TYPE.USER_PROJECTS }));
        if (payload.additionalPayload) {
            yield call(showToastMessage, 'Project published successfully', 'success');
        }
        /** Trigger submisison prompt when a project was created from campaign submitter */
        const state: RootState = yield select();
        const { project, contest }: { project: IProjectInitialState; contest: IContestInitialState } = state;
        const { location, currentProjectInCreation } = project;
        const { selectedContestForSubmisison, autoPublishAndSubmit } = contest;
        if (
            location === 'submissions' &&
            !!currentProjectInCreation &&
            !!selectedContestForSubmisison &&
            !autoPublishAndSubmit
        ) {
            yield put(setSubmissionPrompt(true));
        }

        yield put(refreshUserDashboard());
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(publishProjectFail(error));
        yield call(globalErrorHandler, error);
    }
}

/** Unpublish a project with the below */
function* unpublishProject({ payload }: { payload: IGenericDocumentModifier }): any {
    try {
        const response = yield call(
            api,
            `${unPublishProjecturl}/${payload.documentId}`,
            httpRequest.PATCH,
            {},
            2,
            1000,
        );
        const { data } = response;
        const parsedProject = genericParseSingleDocument(data);
        yield put(unPublishProjectSuccess(parsedProject));
        if (payload.additionalPayload) {
            yield call(showToastMessage, 'Project unpublished successfully', 'success');
        }
        yield put(destroyOneCache({ cacheType: CACHE_TYPE.USER_PROJECTS }));
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(unPublishProjectFail(error));
        yield call(globalErrorHandler, error);
    }
}

function* getFeed({ payload }: { payload: IGetFeed }): any {
    const defaultUseCache = yield* isUseCacheEnabled();
    const { firstProjectId, page } = payload;
    const key = `${firstProjectId}?limit=${en_config.RESULT_LIMIT}&page=${page}`;
    let initialResult: any = null;
    const cache: NotUniqueCacheValue = yield* getNotUniqueCacheByKey(CACHE_TYPE.FEED, key);
    if (cache && cache.key) {
        initialResult = cache.value;
    }

    if (initialResult && defaultUseCache) {
        yield put(
            getFeedSuccess({
                data: initialResult,
                firstProjectId,
                limit: en_config.RESULT_LIMIT,
                page,
                canLoadMore: initialResult.length > 1,
            }),
        );
        if (payload.setMedia) {
            yield put(
                setmedia({
                    media: initialResult[0],
                    mediaType: payload.type,
                }),
            );
        }
    } else {
        try {
            const request = `${projectUrl}/watch/feed/${key}`;
            const response = yield call(api, request, httpRequest.GET, null, 3, 2000, false);
            const { data } = response;
            yield put(
                getFeedSuccess({
                    data,
                    firstProjectId,
                    limit: en_config.RESULT_LIMIT,
                    page,
                    canLoadMore: data.length > 1,
                }),
            );

            yield put(
                setCache({
                    key: `${key}`,
                    value: data,
                    type: CACHE_TYPE.FEED,
                    isUnique: false,
                }),
            );
            if (payload.setMedia) {
                yield put(
                    setmedia({
                        media: data[0],
                        mediaType: payload.type,
                    }),
                );
            }
        } catch (error: any) {
            SentryCapture(error);
            yield put(getFeedFail(error));
        }
    }
}

function* likeAProject({ payload }: { payload: IReactToProject }): any {
    try {
        const { like_category, like_type, projectId, like_origin } = payload;
        const request = `${projectUrl}/${projectId}/like`;
        const response = yield call(api, request, httpRequest.POST, { like_category, like_type }, 0, 2000, false);
        const { data } = response.data;
        const parsedLike = genericParseSingleDocument(data);
        yield put(likeProjectSuccess({ data: parsedLike, like_origin: like_origin }));
        yield all([
            put(
                destroyOneCache({
                    cacheType: CACHE_TYPE.DISCOVERY,
                }),
            ),
            put(
                destroyOneCache({
                    cacheType: CACHE_TYPE.DISCOVERY,
                }),
            ),
            put(
                destroyOneCache({
                    cacheType: CACHE_TYPE.USER_PROJECT_LIKES,
                }),
            ),
            put(refreshUserDashboard()),
        ]);
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(
            likeProjectFail({
                documentId: payload.projectId,
                ...error,
            }),
        );
    }
}

function* likeAComment({ payload }: { payload: IReactToComment }): any {
    try {
        const { like_type, commentId, like_category } = payload;
        const request = `${commentUrl}/${commentId}/like`;
        const response = yield call(api, request, httpRequest.POST, { like_category, like_type }, 0, 0, false);
        const { data } = response.data;
        yield put(likeCommentSuccess({ data, documentId: commentId }));
        // TODO: Attempt to clear just the cache of the specified like.
        yield put(
            destroyOneCache({
                cacheType: CACHE_TYPE.USER_COMMENT_LIKES,
            }),
        );
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(likeCommentFail({ ...error, documentId: payload.commentId }));
    }
}

function* deleteALike({ payload }: { payload: IGenericDocumentModifier }): any {
    try {
        const { documentId } = payload;
        const request = `${likeUrl}/${documentId}`;
        yield call(api, request, httpRequest.DELETE, {}, 2, 2000, false);
        yield put(deleteLikeSuccess(payload));

        yield all([
            put(
                destroyOneCache({
                    cacheType: CACHE_TYPE.DISCOVERY,
                }),
            ),
            put(
                destroyOneCache({
                    cacheType: CACHE_TYPE.DISCOVERY,
                }),
            ),
            put(
                destroyOneCache({
                    cacheType: CACHE_TYPE.USER_PROJECT_LIKES,
                }),
            ),
        ]);
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(deleteLikeFail(error));
    }
}

function* deleteCommentLike({ payload }: { payload: IGenericDocumentModifier }): any {
    try {
        const { documentId } = payload;
        const request = `${likeUrl}/${documentId}`;
        yield call(api, request, httpRequest.DELETE, {}, 2, 2000, false);
        yield put(deleteCommentLikeSuccess(payload));
        yield put(
            destroyOneCache({
                cacheType: CACHE_TYPE.USER_COMMENT_LIKES,
            }),
        );
        yield put(
            destroyOneCache({
                cacheType: CACHE_TYPE.DISCOVERY,
            }),
        );
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(deleteCommentLikeFail({ ...error, documentId: payload.additionalPayload._id }));
    }
}

/** Deletes Project */
function* deleteProject({ payload }: { payload: IGenericDocumentModifier }): any {
    try {
        yield call(api, `${projectUrl}/${payload.documentId}`, httpRequest.DELETE, {}, 2, 1000);
        yield put(deleteProjectSuccess(payload));
        yield put(destroyOneCache({ cacheType: CACHE_TYPE.USER_PROJECTS }));
        yield put(refreshUserDashboard());
        if (payload.additionalPayload) {
            yield call(showToastMessage, 'Project Failed to upload, So we deleted it. Please try again', 'info');
        }
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(deleteProjectFail(error));
        yield call(globalErrorHandler, error);
    }
}

function uploadListerner(progressEvent: axios.AxiosProgressEvent, message?: string) {
    const { loaded, total } = progressEvent;
    const progress = (loaded / Number(total)) * 100;
    store.dispatch({
        type: types.PROJECT_PROCESSING_STATUS,
        payload: {
            status: 'awaiting processing',
            progress: progress.toFixed(0),
            message: message,
        },
    });
}

function* getAct({ payload }: { payload: IGetAct }): any {
    const defaultUseCache = yield* isUseCacheEnabled();
    let initialResult: any = null;
    const cache: NotUniqueCacheValue = yield* getNotUniqueCacheByKey(CACHE_TYPE.ACT, payload.id);
    if (cache && cache.key) {
        initialResult = cache.value;
    }
    if (initialResult && defaultUseCache) {
        yield put(getActSuccess(initialResult));
        yield put(getActs({ param: `?label=${'acts'}&limit=10&page=${1}` }));
        yield put(getActComments({ actId: initialResult._id }));
        yield put(
            setmedia({
                media: initialResult,
                mediaType: 'act',
            }),
        );
    } else {
        try {
            const response = yield call(
                api,
                `${actsUrlByIdentifier}/${payload.id}`,
                httpRequest.GET,
                {},
                2,
                2000,
                false,
            );
            const { data }: { data: IActSummary } = response.data;
            const parsedProject: IActSummary = genericParseSingleDocument(data);
            yield put(getActSuccess(parsedProject));
            yield put(getActComments({ actId: parsedProject._id }));
            yield put(getActs({ param: `?label=${'acts'}&limit=10&page=${1}` }));
            yield put(
                setmedia({
                    media: data,
                    mediaType: 'act',
                }),
            );
            yield put(
                setCache({
                    key: payload.id,
                    value: parsedProject,
                    type: CACHE_TYPE.ACT,
                    isUnique: false,
                }),
            );
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield put(getActFail(error));
            yield call(globalErrorHandler, error);
        }
    }
}

function* getActsAction({ payload }: { payload?: IGetActPlaylist }): any {
    const defaultUseCache = yield* isUseCacheEnabled();
    let initialResult: any = null;
    const cache: NotUniqueCacheValue = yield* getNotUniqueCacheByKey(CACHE_TYPE.ACT, payload?.param as string);
    if (cache && cache.key) {
        initialResult = cache.value;
    }

    if (initialResult && defaultUseCache) {
        yield put(getActsSuccess(initialResult));
    } else {
        try {
            const response = yield call(
                api,
                `${actsUrlByIdentifier}?${payload?.param}`,
                httpRequest.GET,
                {},
                2,
                2000,
                false,
            );
            const { data }: { data: IActSummary[] } = response.data;
            const parsedActs = parseGenericCollection(
                data,
                genericParseSingleDocument,
                genericParseSingleDocument,
                'user',
            );
            yield put(getActsSuccess(parsedActs));
            yield put(
                setCache({
                    key: payload?.param,
                    value: parsedActs,
                    type: CACHE_TYPE.ACT,
                    isUnique: false,
                }),
            );
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield put(getActsFail(error));
            yield call(globalErrorHandler, error);
        }
    }
}

function* getCommentsForActs({ payload }: { payload: IGetActComments }): any {
    let initialResult: any = null;
    const cache: NotUniqueCacheValue = yield* getNotUniqueCacheByKey(CACHE_TYPE.COMMENTS, payload?.actId as string);
    if (cache && cache.key) {
        initialResult = cache.value;
    }
    if (initialResult) {
        yield put(getActCommentsSuccess(initialResult));
    } else {
        try {
            const response = yield call(
                api,
                `${actsUrl}/${payload.actId}/comment/all`,
                httpRequest.GET,
                {},
                2,
                2000,
                false,
            );
            const { data } = response.data;
            const parsedActComments = parseGenericCollection(
                data,
                genericParseSingleDocument,
                genericParseSingleDocument,
                'user',
            );
            yield put(getActCommentsSuccess(parsedActComments));
            yield put(
                setCache({
                    key: payload.actId,
                    value: parsedActComments,
                    type: CACHE_TYPE.COMMENTS,
                    isUnique: false,
                }),
            );
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield put(getActCommentsFail(error));
        }
    }
}

function* getWatchContentAction({ payload }: { payload: IGetWatchContent }): any {
    const defaultUseCache = yield* isUseCacheEnabled();
    let initialResult: any = null;
    const cache: NotUniqueCacheValue = yield* getNotUniqueCacheByKey(CACHE_TYPE.PROJECT, payload.category);
    if (cache && cache.key) {
        initialResult = cache.value;
    }

    if (initialResult && defaultUseCache) {
        yield put(getWatchContentSuccess(initialResult));
    } else {
        try {
            const response = yield call(
                api,
                `${watchUrl}?match=${payload.category}`,
                httpRequest.GET,
                {},
                2,
                2000,
                false,
            );
            const { data }: { data: IWatchResponse } = response;
            const parsedContent = parseWatchContent(data);
            yield put(getWatchContentSuccess(parsedContent));
            yield put(
                setCache({
                    key: payload.category,
                    value: parsedContent,
                    type: CACHE_TYPE.PROJECT,
                    isUnique: false,
                }),
            );
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield put(getWatchContentFail(error));
            yield call(globalErrorHandler, error);
        }
    }
}
function* getMyProjects(): any {
    const defaultUseCache = yield* isUseCacheEnabled();
    const defaultCacheDuration = yield* getCacheDuration();
    let initialResult: any = null;
    const cache: CacheValue = yield* getCacheByType(CACHE_TYPE.USER_PROJECTS);
    if (cache && cache.key) {
        initialResult = cache.value;
    }

    if (
        !!initialResult &&
        defaultUseCache &&
        ((Date.now() - Number(cache.key)) as unknown as number) < defaultCacheDuration
    ) {
        yield put(getMyProjectsSuccess(initialResult));
    } else {
        try {
            const response = yield call(api, getMyProjectsUrl, httpRequest.GET, {}, 2, 2000, false);
            const { data }: { data: IProject[] } = response.data;
            const parsedProjects = parseGenericCollection(data, parseProject);
            yield put(getMyProjectsSuccess(parsedProjects));
            yield put(
                setCache({
                    key: Date.now(),
                    value: parsedProjects,
                    type: CACHE_TYPE.USER_PROJECTS,
                    isUnique: true,
                }),
            );
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield put(getMyProjectsFail(error));
            yield call(globalErrorHandler, error);
        }
    }
}
export function* getProject({ payload }: { payload: IGetSingleProject }): Generator<any, IProject | null, any> {
    const defaultUseCache = yield* isUseCacheEnabled();
    let initialResult: any = null;
    const cache: NotUniqueCacheValue = yield* getNotUniqueCacheByKey(CACHE_TYPE.PROJECT, payload.id);
    if (cache && cache.key) {
        initialResult = cache.value;
    }
    if (initialResult && defaultUseCache && !payload.skipCache) {
        yield put(getProjectSuccess(initialResult));
        yield put(getProjectComments({ params: `?project=${initialResult._id}` }));
        /** Get projects similar to the one called */
        if (!payload.noPlaylistRefetch) {
            yield put(
                getProjects({
                    params: `?project_type=${initialResult.project_type}&limit=${en_config.RESULT_LIMIT}&page=${1}`,
                    page: 1,
                    limit: en_config.RESULT_LIMIT,
                    isFiltered: true,
                    isPlaylistFetch: true,
                    isFeedFetch: payload.withFeedFetch,
                }),
            );
        }
        if (!payload.skipMediaSet) {
            yield put(
                setmedia({
                    media: initialResult,
                    mediaType: payload.mediaType ?? 'video',
                }),
            );
        }
        if (payload.withNavigate) {
            yield put(
                navigate({
                    routes: `${ROUTES.ESWATCH_VIDEO}?id=${initialResult.video_id}`,
                    skipAuthentication: payload.skipAuthentication,
                    params: {
                        project: initialResult,
                    },
                }),
            );
        }
        return initialResult;
    } else {
        try {
            const response = yield call(
                api,
                `${watchUrl}/${payload.id}${payload.params ? payload.params : ''}`,
                httpRequest.GET,
                {},
                2,
                2000,
                false,
            );
            const { data }: { data: IProject } = response;
            const parsedProject = data;
            yield put(getProjectSuccess(parsedProject));
            yield put(getProjectComments({ params: `?project=${data._id}` }));
            if (!payload.noPlaylistRefetch) {
                yield put(
                    getProjects({
                        params: `?project_type=${parsedProject.project_type}&limit=10&page=${1}`,
                        page: 1,
                        limit: en_config.RESULT_LIMIT,
                        isFiltered: true,
                        isPlaylistFetch: true, // Tells us that this fetching playlist for watch
                        isFeedFetch: payload.withFeedFetch,
                    }),
                );
            }
            if (payload.params) {
                /** If there is payload then clear search suggestion. Currently the payload being passed is ?search=true
                 * This should reset our search suggestion to get the most recent search result when the search box is tapped
                 * again
                 *
                 * TODO: Check for that params is specifically for search
                 */
                yield put(destroyOneCache({ cacheType: CACHE_TYPE.SEARCH_SUGGESTION }));
            }
            if (!payload.skipMediaSet) {
                yield put(
                    setmedia({
                        media: parsedProject,
                        mediaType: payload.mediaType ?? 'video',
                    }),
                );
            }
            yield put(
                setCache({
                    key: payload.id,
                    value: parsedProject,
                    type: CACHE_TYPE.PROJECT,
                    isUnique: false,
                }),
            );
            if (payload.withNavigate) {
                yield put(
                    navigate({
                        routes: `${ROUTES.ESWATCH_VIDEO}?id=${parsedProject.video_id}`,
                        skipAuthentication: payload.skipAuthentication,
                        params: {
                            project: parsedProject,
                        },
                    }),
                );
            }
            return data;
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield put(getProjectFail(error));
            yield call(globalErrorHandler, error);
            return null;
        }
    }
}

function* getProjectCommentAction({ payload }: { payload: IGetComments }): any {
    const defaultUseCache = yield* isUseCacheEnabled();
    let initialResult: any = null;
    const cache: NotUniqueCacheValue = yield* getNotUniqueCacheByKey(CACHE_TYPE.COMMENTS, payload.params as string);
    if (cache && cache.key) {
        initialResult = cache.value;
    }
    if (initialResult && defaultUseCache) {
        yield put(getProjectCommentsSuccess(initialResult));
    } else {
        try {
            const response = yield call(
                api,
                `${getProjectcommentUrl}${payload.params}`,
                httpRequest.GET,
                {},
                2,
                2000,
                false,
            );
            const { data } = response;
            const parsedProjectComments = parseGenericCollection(data, genericParseSingleDocument);
            yield put(getProjectCommentsSuccess(parsedProjectComments));
            yield put(
                setCache({
                    key: payload.params,
                    value: parsedProjectComments,
                    type: CACHE_TYPE.COMMENTS,
                    isUnique: false,
                }),
            );
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield put(getProjectCommentsFail(error));
            yield call(globalErrorHandler, error);
        }
    }
}

/**
 *
 * @param typeof IGetProject This will also work to get playlists
 */
function* getAllProjects({ payload }: { payload: IGetProjects }): any {
    const defaultUseCache = yield* isUseCacheEnabled();
    let initialResult: any = null;
    const cache: NotUniqueCacheValue = yield* getNotUniqueCacheByKey(
        CACHE_TYPE.PROJECT,
        (payload.params as string) ?? Date.now(), // TODO CHeck the dat expiration here
    );
    if (cache && cache.key) {
        initialResult = cache.value;
    }

    if (initialResult && defaultUseCache) {
        yield put(
            getProjectsSuccess({
                data: initialResult,
                isFiltered: payload.isFiltered,
                currentParam: payload.params,
                isPlaylistFetch: payload.isPlaylistFetch,
                loadMore: initialResult.length === en_config.RESULT_LIMIT,
                currentPage: payload.page,
                isFeedFetch: payload.isFeedFetch,
            }),
        );
        if (payload.shouldSetMedia && !isEmpty(initialResult)) {
            yield put(
                setmedia({
                    media: initialResult[0],
                    mediaType: 'video',
                    playlistType: payload.playlistType,
                }),
            );
        }
    } else {
        const requestUrl =
            payload.isSubmissionList && payload.params ? `${getContestUrl}/submissions/` : `${projectUrl}/`;
        try {
            const response = yield call(api, `${requestUrl}${payload.params}`, httpRequest.GET, null, 2, 1000);
            const { data } = response.data;
            const parsedResponse = parseGenericCollection(data, parseProject);
            yield put(
                getProjectsSuccess({
                    data: parsedResponse,
                    isFiltered: payload.isFiltered ?? !!payload.params,
                    currentPage: payload.page,
                    loadMore: parsedResponse.length === en_config.RESULT_LIMIT,
                    currentParam: payload.params,
                    isPlaylistFetch: payload.isPlaylistFetch,
                    isFeedFetch: payload.isFeedFetch,
                }),
            );
            yield put(
                setCache({
                    key: requestUrl ?? Date.now(),
                    value: parsedResponse,
                    type: CACHE_TYPE.PROJECT,
                    isUnique: false,
                }),
            );
            if (payload.shouldSetMedia && !isEmpty(parsedResponse)) {
                yield put(
                    setmedia({
                        media: parsedResponse[0],
                        mediaType: 'video',
                        playlistType: payload.playlistType,
                    }),
                );
            } else {
                // Handle the case where teh playlist is returned as null
            }
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield put(getProjectsFail(error));
        }
    }
}

/** The method here is currently hard wired to work for projects alone
 * By changing the URL you can make this more generic to accept comments for other
 * collection such as contest.
 * `BASEURL/comment` can be used for this. then pass project id and comment in the body or
 * contestid and comment as you wish
 */
function* leaveAComment({ payload }: { payload: ILeaveComment }): any {
    const { comment, project, isReply, parentComment } = payload || {};
    let request;
    const body: any = {
        comment,
        // project: project._id, Removed this as it was creating another parent comment
    };
    if (isReply) {
        request = `${commentUrl}/${parentComment?._id}/reply`;
    } else {
        request = `${projectUrl}/${project._id}/comment`;
    }
    try {
        const response = yield call(api, request, httpRequest.POST, { ...body, created_at: dayjs() }, 2, 2000);
        const { data } = response.data;
        const parsedResponse = genericParseSingleDocument(data);
        yield put(
            leaveCommentSuccess({
                comment: parsedResponse,
                isReply: payload.isReply,
                parentComment: payload.parentComment,
                commentOrigin: payload.commentOrigin,
            }),
        );
        yield put(
            clearCache({
                cacheType: CACHE_TYPE.COMMENTS,
                key: `?project=${project._id}`,
            }),
        );
        yield delay(1000);
        yield put(refreshUserDashboard());
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(leaveCommentFail(error));
    }
}

function* updateComment({ payload }: { payload: IGenericDocumentModifier }): any {
    const url = `${commentUrl}/${payload.documentId}`;
    try {
        const response = yield call(api, url, httpRequest.PATCH, { comment: payload.payload }, 2, 2000);
        const { data } = response.data;
        const parsedResponse = genericParseSingleDocument(data);
        yield put(updateCommentSuccess({ documentId: payload.documentId, additionalPayload: parsedResponse }));
        yield put(
            clearCache({
                cacheType: CACHE_TYPE.COMMENTS,
                key: `?project=${parsedResponse._id}`,
            }),
        );
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(updateCommentFail(error));
    }
}

function* deleteComment({ payload }: { payload: IGenericDocumentModifier }): any {
    const url = `${commentUrl}/${payload.documentId}`;
    try {
        yield call(api, url, httpRequest.DELETE, {}, 2, 2000);
        yield put(deleteCommentSuccess(payload));
        yield put(
            clearCache({
                cacheType: CACHE_TYPE.COMMENTS,
                key: `?project=${payload?.payload?.project._id}`,
            }),
        );
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(deleteCommentFail(error));
    }
}

interface TaskAction extends Action, IGetComments, ICreateProject {
    payload: any;
}

function* getMyProjectsWatcher() {
    yield takeLatest(types.GET_MY_PROJECTS, getMyProjects);
}

function* getProjectWatcher() {
    yield takeLatest<TaskAction>(types.GET_PROJECT, getProject);
}

function* getAllProjectsWatcher() {
    yield takeLatest<TaskAction>(types.GET_PROJECTS, getAllProjects);
}

function* getProjectCOmmentsWatcher() {
    yield takeLatest<TaskAction>(types.GET_PROJECT_COMMENTS, getProjectCommentAction);
}

function* getWatchContentWatcher() {
    yield takeLatest<TaskAction>(types.GET_WATCH_CONTENT, getWatchContentAction);
}

function* getActWatcher() {
    yield takeLatest<TaskAction>(types.GET_ACT, getAct);
}

function* getActsWatcher() {
    yield takeLatest<TaskAction>(types.GET_ACTS, getActsAction);
}

function* getActsComments() {
    yield takeLatest<TaskAction>(types.GET_COMMENTS_FOR_ACT, getCommentsForActs);
}

function* createProjectWatcher() {
    yield takeLatest<TaskAction>(types.CREATE_PROJECT, createProject);
}

function* uploadProjectMediaWatcher() {
    yield takeLatest<TaskAction>(types.UPLOAD_PROJECT_MEDIA, uploadProjectMedia);
}

function* updateProjectWatcher() {
    yield takeLatest<TaskAction>(types.UPDATE_PROJECT, updateProject);
}

function* publishProjectWatcher() {
    yield takeLatest<TaskAction>(types.PUBLISH_PROJECT, publishProject);
}

function* unPublishProjectWatcher() {
    yield takeLatest<TaskAction>(types.UNPUBLISH_PROJECT, unpublishProject);
}

function* deleteProjectWatcher() {
    yield takeLatest<TaskAction>(types.DELETE_PROJECT, deleteProject);
}

function* likeAProjectWatcher() {
    yield takeLatest<any>(types.LIKE_A_PROJECT, likeAProject);
}

function* unlikeAprojectWatcher() {
    yield takeLatest<any>(types.DELETE_A_LIKE, deleteALike);
}

function* leaveACommentWatcher() {
    yield takeLatest<any>(types.LEAVE_COMMENT, leaveAComment);
}

function* updateCommentWatcher() {
    yield takeLatest<any>(types.UPDATE_COMMENT, updateComment);
}

function* deleteCommentWatcher() {
    yield takeLatest<any>(types.DELETE_COMMENT, deleteComment);
}

function* likeCommentWatcher() {
    yield takeLatest<any>(types.LIKE_COMMENT, likeAComment);
}

function* deleteCommentLikeWatcher() {
    yield takeLatest<any>(types.DELETE_COMMENT_LIKE, deleteCommentLike);
}

function* getFeedWatcher() {
    yield takeLatest<any>(types.GET_FEED, getFeed);
}

function* downloadProjectWatcher() {
    yield takeLatest<any>(types.DOWNLOAD_PROJECT, downloadProject);
}

function* triggerMediaForgeWatcher() {
    yield takeLatest<any>(types.TRIGGER_MEDIA_FORGE, triggerMediaForge);
}

function* resumeUploadWatcher() {
    yield takeLatest(types.RESUME_UPLOAD, resumeUpload);
}

export default function* projectsSaga() {
    yield all([
        createProjectWatcher(),
        deleteCommentWatcher(),
        deleteProjectWatcher(),
        getActsComments(),
        getActsWatcher(),
        getActWatcher(),
        getAllProjectsWatcher(),
        getMyProjectsWatcher(),
        getProjectCOmmentsWatcher(),
        getProjectWatcher(),
        getWatchContentWatcher(),
        leaveACommentWatcher(),
        likeAProjectWatcher(),
        likeCommentWatcher(),
        publishProjectWatcher(),
        unlikeAprojectWatcher(),
        unPublishProjectWatcher(),
        updateCommentWatcher(),
        updateProjectWatcher(),
        uploadProjectMediaWatcher(),
        deleteCommentLikeWatcher(),
        getFeedWatcher(),
        downloadProjectWatcher(),
        triggerMediaForgeWatcher(),
        resumeUploadWatcher(),
    ]);
}
