import { call, put, all, takeLatest } from 'redux-saga/effects';
import { ICreateView, IGetViewForOneProject, types } from './types';
import { api, deviceIdentifier } from '../../configurations/api';
import {
    createViewFail,
    createViewSuccess,
    getViewsFail,
    getViewsForProjectFail,
    getViewsForProjectSuccess,
    getViewsSuccess,
} from './action';
import { IGenericDocumentModifier } from '../../types/global/helper';
import { projectUrl, viewUrl } from '../../configurations/api/url';
import { httpRequest } from '../types';
import { clearCache, destroyOneCache, setCache } from '../cache/action';
import { CACHE_TYPE, NotUniqueCacheValue } from '../cache/types';
import { getJWtDetails } from '../../utils/AppUtils';
import { genericParseSingleDocument } from '../../utils/responseProcessor';
import { getNotUniqueCacheByKey, isUseCacheEnabled } from '../cache/saga';
import { SentryCapture } from '../../analytics/Sentry';

/** Created new view. Please note that the backend works in such fashion that
 * if this specific device and ip address already have a view linked to it.
 * it will update the  `duration_watched`. so Call this when a project is open.
 *
 */
function* createView({ payload }: { payload: ICreateView }): any {
    const request = `${projectUrl}/view/${payload.projectId}`;
    try {
        const response = yield call(api, request, httpRequest.PATCH, { ...payload }, 0, 0);
        const { data } = response.data || {};
        if (data) {
            yield put(createViewSuccess(data));
            yield put(
                clearCache({
                    cacheType: CACHE_TYPE.PROJECT_VIEWS,
                    key: payload.projectId,
                }),
            );
            yield put(
                destroyOneCache({
                    cacheType: CACHE_TYPE.USER_VIEWS,
                }),
            );
        }
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(createViewFail(error));
    }
}

/** The below will act to fetch all views that is linked to this specific device.
 * This will allow us map the timestamp for the speicic project. SO users can restart a project from
 * where they stopped even if they are logged in or not. The below can be paginated as well.
 * The payload here will actuall have the device_information.
 */
function* getViews({ payload }: { payload?: IGenericDocumentModifier }): any {
    const defaultUseCache = yield* isUseCacheEnabled();
    const { isSignedIn, user_id } = getJWtDetails();
    const query = isSignedIn ? `?user=${user_id}` : `?device_id=${deviceIdentifier}`;
    let initialResult: any = null;
    const cache: NotUniqueCacheValue = yield* getNotUniqueCacheByKey(
        CACHE_TYPE.USER_VIEWS,
        query as string, // TODO CHeck the dat expiration here
    );
    if (cache && cache.key) {
        initialResult = cache.value;
    }
    if (initialResult && defaultUseCache) {
        yield put(getViewsSuccess(initialResult));
    } else {
        /** The below should be for pagination => &limit200&page=1&sort=-createdAt */
        const requestUrl = `${viewUrl}${query}${payload?.payload}`;
        try {
            const response = yield call(api, requestUrl, httpRequest.GET, null, 2, 2000);
            const { data } = response.data;
            yield put(getViewsSuccess(data));
            yield put(
                setCache({
                    key: query,
                    type: CACHE_TYPE.USER_VIEWS,
                    value: data,
                    isUnique: true,
                }),
            );
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield put(getViewsFail(error));
        }
    }
}

/** This will work to fetch every view related to this specific project opened */
function* getOneProjectViews({ payload }: { payload?: IGetViewForOneProject }): any {
    const defaultUseCache = yield* isUseCacheEnabled();
    let initialResult: any = null;
    const cache: NotUniqueCacheValue = yield* getNotUniqueCacheByKey(
        CACHE_TYPE.PROJECT_VIEWS,
        payload?.projectId as string,
    );
    if (cache && cache.key) {
        initialResult = cache.value;
    }

    if (initialResult && defaultUseCache) {
        yield put(getViewsForProjectSuccess(initialResult));
    } else {
        // const query = isSignedIn ? `?user=${user_id}` : `?device_id=${deviceIdentifier}`;
        /** The below should be for pagination => &limit200&page=1&sort=-createdAt */
        const requestUrl = `${projectUrl}/views/${payload?.projectId}/all`;
        try {
            const response = yield call(api, requestUrl, httpRequest.GET, null, 2, 2000);
            const { data } = response.data;
            const parsedData = genericParseSingleDocument(data[0]);
            yield put(getViewsForProjectSuccess(parsedData));
            yield put(
                setCache({
                    key: payload?.projectId,
                    type: CACHE_TYPE.PROJECT_VIEWS,
                    value: parsedData,
                    isUnique: false,
                }),
            );
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield put(getViewsForProjectFail(error));
        }
    }
}

function* createViewWatcher() {
    yield takeLatest<any>(types.CREATE_VIEW, createView);
}

function* getViewsWatcher() {
    yield takeLatest<any>(types.GET_VIEWS, getViews);
}

function* getOneProjectViewWatcher() {
    yield takeLatest<any>(types.GET_ONE_VIEW, getOneProjectViews);
}

export default function* viewSaga() {
    yield all([getViewsWatcher(), createViewWatcher(), getOneProjectViewWatcher()]);
}
