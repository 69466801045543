import React, { Dispatch } from 'react';
import Avatar from '@mui/material/Avatar';
import verifiedIcon from '../../asset/images/icons/verified_badge.svg';
import { Box } from '@mui/material';
import { MOBILE_MQ } from '../../constants/global';
import { ProfileImageUpload } from '../ProfileImageUpload/ProfileImageUpload';
import CustomLazyLoadImage from '../Image/CustomLazyLoadImage';

interface UserProfileImageProps {
    profilePictureUrl?: string;
    isVerified?: boolean;
    style?: {
        avatar?: React.CSSProperties;
        verifiedIcon?: React.CSSProperties;
    };
    isHeader?: boolean;
    innerWidth?: number;
    isEditMode?: boolean;
    setPreview?: Dispatch<React.SetStateAction<string | null>>;
    preview?: string | null;
}

const UserProfileImage: React.FC<UserProfileImageProps> = ({
    profilePictureUrl,
    isVerified,
    style,
    isHeader,
    innerWidth,
    isEditMode,
    setPreview,
    preview,
}) => {
    // Default styles for the Avatar
    const defaultAvatarStyles: React.CSSProperties = {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        border: '1px solid white',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 2,
        flexShrink: 0,
    };

    // Merge default styles with custom styles
    const avatarStyles: React.CSSProperties = { ...defaultAvatarStyles, ...style?.avatar };
    const profile = profilePictureUrl;

    return (
        <>
            <Box style={avatarStyles}>
                {!isHeader && isEditMode && <ProfileImageUpload setPreview={setPreview!} />}
                <Avatar
                    src={preview || profile}
                    style={{ width: '100%', height: '100%', position: 'absolute', top: 0 }}
                />

                {isVerified && (
                    <div
                        style={{
                            position: 'absolute',
                            top: isHeader ? '10%' : innerWidth && innerWidth < MOBILE_MQ ? '35%' : '50%',
                            right: '15%',
                            transform: 'translate(50%, 50%)',
                            zIndex: 2,
                        }}>
                        <CustomLazyLoadImage
                            src={verifiedIcon}
                            alt="Verified"
                            style={{
                                width: isHeader ? '30px' : '50px',
                                height: isHeader ? '30px' : '50px',
                            }}
                        />
                    </div>
                )}
            </Box>
        </>
    );
};

export { UserProfileImage };
