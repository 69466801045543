import React from 'react';
import { StyleSheet } from 'react-native';
import { useThemeContext } from '../../hook/useThemeManager';
import { AppBar, Toolbar, Box, Typography, Icon, Link } from '@mui/material';
import arrow_right from '../../asset/images/icons/es_arrow_right.svg';
import { ColorScheme, theme } from '../../constants/theme';
import { FONT_ENUM } from '../../types/global/fonts.types';
import { ThspianFullLogo } from '../ThspianLogo/ThspianFullLogo';
import { ROUTES } from '../../types/global/routes.types';
import { NavigateOptions, useNavigate } from 'react-router-dom';
import { en_config } from '../../config';
import { Situation } from '../Situation/Situation';
import CustomLazyLoadImage from '../Image/CustomLazyLoadImage';

interface ISignUpHeader {
    label?: string;
    /** The route that the label will point to */
    route?: string;
    onClick?: () => void;
    navigateOptions?: NavigateOptions;
}

const EsSignupHeader: React.FC<ISignUpHeader> = ({ label, route, onClick, navigateOptions }) => {
    const { themeStyle } = useThemeContext();
    const navigate = useNavigate();
    const styles = dynamicStyles(themeStyle);

    const handleLinking = () => {
        if (onClick) {
            onClick();
            return;
        }
        if (route) {
            navigate(route, {
                ...navigateOptions,
            });
            return;
        }
    };

    return (
        <Box style={styles.container}>
            <AppBar position="static" style={styles.bg}>
                <Toolbar
                    sx={{
                        paddingInline: { xs: '16px', md: '24px', lg: '40px' },
                        paddingTop: { xs: '32px', sm: '40px' },
                        paddingBottom: { xs: '32px', sm: '40px' },
                    }}>
                    <Box display="flex" flexGrow={1}>
                        <Link
                            href={ROUTES.ESDISCOVERY}
                            sx={{
                                width: { xs: '125px', sm: '170px' },
                                height: { xs: '18px', sm: '24px' },
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                            }}>
                            <ThspianFullLogo width={'120px'} height={'40px'} />
                            {en_config.IS_BETA && <Situation label="beta" />}
                        </Link>
                    </Box>
                    <Link
                        onClick={handleLinking}
                        sx={{
                            '&:hover': { cursor: 'pointer' },
                            textDecoration: 'none',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        <Typography style={styles.text} sx={{ fontSize: { xs: '14px', sm: '16px' } }}>
                            {label ?? 'Sign in'}
                        </Typography>
                        <Icon
                            sx={{
                                width: { xs: '18px', sm: '24px' },
                                height: { xs: '18px', sm: '24px' },
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                            }}>
                            <CustomLazyLoadImage
                                src={arrow_right}
                                alt="arrow right"
                                style={{ width: '100%', height: '100%' }}
                            />
                        </Icon>
                    </Link>
                </Toolbar>
            </AppBar>
        </Box>
    );
};
const dynamicStyles = (colorScheme: ColorScheme) =>
    StyleSheet.create({
        container: {
            width: '100vw',
            position: 'absolute',
            top: 0,
        },
        bg: {
            backgroundColor: theme[colorScheme].transparent,
            boxShadow: 'none',
        },
        text: {
            color: theme[colorScheme].primary_orange,
            marginRight: 8,
            fontFamily: FONT_ENUM.MAIN_REGULAR,
        },
    });

export { EsSignupHeader };
