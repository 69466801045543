type Location = {
    longitude: number;
    latitude: number;
};

export type FilterItem = {
    value: string | boolean | number | number[] | string[] | Location | any;
    displayName: string;
    parentId: number;
    field: string;
};

export type FilterTypes = 'project' | 'contest' | 'talent' | 'select category'; // Add more filters here

export type Data = {
    title: string;
    list: FilterItem[];
    extraDetail?: string;
};

export interface DiscoveryFilter {
    categoryName: string;
    data: Data;
    id: number;
}

export type IFilter = {
    [key in FilterTypes]: DiscoveryFilter[];
};

export const filters: IFilter = {
    project: [
        {
            categoryName: 'Selected Creation Date',
            id: 0,
            data: {
                list: [
                    {
                        displayName: 'Any Time',
                        value: 'anyTime',
                        parentId: 0,
                        field: 'time_period',
                    },
                    {
                        displayName: 'Past Week',
                        value: 'pastWeek',
                        parentId: 0,
                        field: 'time_period',
                    },
                    {
                        displayName: 'Past Month',
                        value: 'pastMonth',
                        parentId: 0,
                        field: 'time_period',
                    },
                ],
                title: 'Created',
            },
        },
        {
            categoryName: 'Select Project Type',
            id: 1,
            data: {
                list: [
                    {
                        displayName: 'short',
                        value: 'short',
                        parentId: 1,
                        field: 'project_type',
                    },
                    {
                        displayName: 'commercial',
                        value: 'commercial',
                        parentId: 1,
                        field: 'project_type',
                    },
                    {
                        displayName: 'skit',
                        value: 'skit',
                        parentId: 1,
                        field: 'project_type',
                    },
                    {
                        displayName: 'reels',
                        value: 'reels',
                        parentId: 1,
                        field: 'project_type',
                    },
                    {
                        displayName: 'pitch',
                        value: 'pitch',
                        parentId: 1,
                        field: 'project_type',
                    },
                ],
                title: 'Project Type',
            },
        },
        {
            categoryName: 'Sort By',
            id: 2,
            data: {
                list: [
                    {
                        displayName: 'Acending',
                        value: '-1',
                        parentId: 2,
                        field: 'sort_by',
                    },
                    {
                        displayName: 'Descending',
                        value: '1',
                        parentId: 2,
                        field: 'sort_by',
                    },
                ],
                title: 'Sort Order',
            },
        },
    ],
    contest: [
        {
            categoryName: 'Select Contest Tag',
            id: 3,
            data: {
                list: [
                    {
                        displayName: 'action',
                        value: 'action',
                        parentId: 3,
                        field: 'tag',
                    },
                    {
                        displayName: 'advertisement',
                        value: 'advertisement',
                        parentId: 3,
                        field: 'tag',
                    },
                    {
                        displayName: 'comedy',
                        value: 'comedy',
                        parentId: 3,
                        field: 'tag',
                    },
                    {
                        displayName: 'drama',
                        value: 'drama',
                        parentId: 3,
                        field: 'tag',
                    },
                    {
                        displayName: 'feature',
                        value: 'feature',
                        parentId: 3,
                        field: 'tag',
                    },
                    {
                        displayName: 'short',
                        value: 'short',
                        parentId: 3,
                        field: 'tag',
                    },
                    {
                        displayName: 'monologue',
                        value: 'monologue',
                        parentId: 3,
                        field: 'tag',
                    },
                    {
                        displayName: 'sci-fi',
                        value: 'sci-fi',
                        parentId: 3,
                        field: 'tag',
                    },
                    {
                        displayName: 'make up',
                        value: 'make up',
                        parentId: 3,
                        field: 'tag',
                    },
                    {
                        displayName: 'rap',
                        value: 'rap',
                        parentId: 3,
                        field: 'tag',
                    },
                    {
                        displayName: 'music',
                        value: 'music',
                        parentId: 3,
                        field: 'tag',
                    },
                    {
                        displayName: 'fashiom',
                        value: 'fashiom',
                        parentId: 3,
                        field: 'tag',
                    },
                ],
                title: 'Tag',
            },
        },
        {
            categoryName: 'Select Expiration Date',
            id: 4,
            data: {
                list: [
                    {
                        displayName: 'Next 24 Hours',
                        value: 'next24hours',
                        parentId: 4,
                        field: 'expiration_date',
                    },
                    {
                        displayName: 'Next Week',
                        value: 'nextWeek',
                        parentId: 4,
                        field: 'expiration_date',
                    },
                    {
                        displayName: 'Next Month',
                        value: 'nextMonth',
                        parentId: 4,
                        field: 'expiration_date',
                    },
                    {
                        displayName: 'Any Time',
                        value: 'anyTime',
                        parentId: 4,
                        field: 'expiration_date',
                    },
                ],
                title: 'Expiring In',
            },
        },
        {
            categoryName: 'Contest Phase',
            id: 5,
            data: {
                list: [
                    {
                        displayName: 'submission',
                        value: 'submission',
                        parentId: 5,
                        field: 'contest_phase',
                    },
                    {
                        displayName: 'ideation',
                        value: 'ideation',
                        parentId: 5,
                        field: 'contest_phase',
                    },
                    {
                        displayName: 'pitch',
                        value: 'pitch',
                        parentId: 5,
                        field: 'contest_phase',
                    },
                    {
                        displayName: 'production',
                        value: 'production',
                        parentId: 5,
                        field: 'contest_phase',
                    },
                    {
                        displayName: 'judging',
                        value: 'judging',
                        parentId: 5,
                        field: 'contest_phase',
                    },
                    {
                        displayName: 'Audience Vote',
                        value: 'vote',
                        parentId: 5,
                        field: 'contest_phase',
                    },
                    {
                        displayName: 'none',
                        value: 'none',
                        parentId: 5,
                        field: 'contest_phase',
                    },
                ],
                title: 'Phase',
            },
        },
        {
            categoryName: 'Select Contest Type', // TODO CHange this to premium search
            id: 6,
            data: {
                list: [
                    {
                        displayName: 'Premium',
                        value: true,
                        parentId: 6,
                        field: 'premium',
                    },
                    {
                        displayName: 'Non-Premium',
                        value: false,
                        parentId: 6,
                        field: 'premium',
                    },
                ],
                title: 'Contest Type',
            },
        },
    ],
    talent: [
        {
            categoryName: 'Select Gender',
            id: 7,
            data: {
                list: [
                    {
                        displayName: 'Male',
                        value: 'male',
                        parentId: 7,
                        field: 'gender',
                    },
                    {
                        displayName: 'Female',
                        value: 'female',
                        parentId: 7,
                        field: 'gender',
                    },
                ],
                title: 'Gender',
            },
        },
        {
            categoryName: 'Select Location',
            id: 8,
            data: {
                list: [],
                title: 'Location',
            },
        },
        {
            categoryName: 'Select User Type',
            id: 9,
            data: {
                list: [
                    {
                        displayName: 'Actor',
                        value: 'actor',
                        parentId: 9,
                        field: 'user_type',
                    },
                    {
                        displayName: 'Casting director',
                        value: 'casting_director',
                        parentId: 9,
                        field: 'user_type',
                    },
                    {
                        displayName: 'Skit Maker',
                        value: 'skit maker',
                        parentId: 9,
                        field: 'user_type',
                    },
                    {
                        displayName: 'Screenwriter',
                        value: 'screen_writer',
                        parentId: 9,
                        field: 'user_type',
                    },
                    {
                        displayName: 'Artist',
                        value: 'artist',
                        parentId: 9,
                        field: 'user_type',
                    },
                    {
                        displayName: 'Actress',
                        value: 'actress',
                        parentId: 9,
                        field: 'user_type',
                    },
                    {
                        displayName: 'Child Actor',
                        value: 'child actor',
                        parentId: 9,
                        field: 'user_type',
                    },
                    {
                        displayName: 'filmmaker',
                        value: 'filmmaker',
                        parentId: 9,
                        field: 'user_type',
                    },
                ],
                title: 'User Type',
            },
        },
        {
            categoryName: 'Select Date Joined',
            id: 10,
            data: {
                list: [
                    {
                        displayName: 'Any Time',
                        value: 'anyTime',
                        parentId: 10,
                        field: 'time_period',
                    },
                    {
                        displayName: 'Past 24 Hours',
                        value: 'past24hours',
                        parentId: 10,
                        field: 'time_period',
                    },
                    {
                        displayName: 'Past Week',
                        value: 'pastWeek',
                        parentId: 10,
                        field: 'time_period',
                    },
                    {
                        displayName: 'Past Month',
                        value: 'pastMonth',
                        parentId: 10,
                        field: 'time_period',
                    },
                ],
                title: 'Joined',
                extraDetail: 'createdAt',
            },
        },
    ],
    'select category': [],
};
export const discoveryFilter = [
    {
        categoryName: 'Date Posted',
        data: {
            list: ['Any time', 'Past 24 hours', 'Past week', 'Past month'],
            title: 'Posted',
        },
    },
    {
        categoryName: 'Select Gender',
        data: {
            list: ['Male', 'Females'],
            title: 'Gender',
        },
    },
    {
        categoryName: 'Select Location',
        data: { title: 'Location', list: ['Abuja', 'Lagos', 'Kano', 'kaduna'] },
    },
    {
        categoryName: 'Select Genre',
        data: { title: 'Genre', list: ['Short', 'Feature'] },
    },
];

export const discoveryFilterMobile = [
    {
        categoryName: 'Date Posted',
        data: {
            list: ['Any time', 'Past 24 hours', 'Past week', 'Past month'],
            title: 'Posted',
        },
    },
    {
        categoryName: 'select location',
        data: { title: 'location', list: ['abuja', 'lagos', 'kano', 'kaduna'] },
    },
];

export const mainCategorySelector: DiscoveryFilter[] = [
    {
        categoryName: 'select category',
        id: 11,
        data: {
            list: [
                {
                    displayName: 'project',
                    value: 'project',
                    parentId: 11,
                    field: 'category',
                },
                {
                    displayName: 'talent',
                    value: 'talent',
                    parentId: 11,
                    field: 'category',
                },
                {
                    displayName: 'contest',
                    value: 'contest',
                    parentId: 11,
                    field: 'category',
                },
            ],
            title: 'category',
        },
    },
];
