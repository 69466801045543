import { Box, Button, Slider, Modal, Typography, IconButton } from '@mui/material';
import { Dispatch, SetStateAction, useState, useRef } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { ColorScheme, theme } from '../../constants/theme';
import { StyleSheet } from 'react-native';
import { useThemeContext } from '../../hook/useThemeManager';
import { FONT_ENUM } from '../../types/global/fonts.types';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import Compressor from 'compressorjs';
import { blobToDataURL } from '../ProfileHeaderUpload/helpers';
import { en_config } from '../../config';
interface IModalProps {
    src: string;
    modalOpen: boolean;
    setModalOpen: Dispatch<SetStateAction<boolean>>;
    setPreview: Dispatch<SetStateAction<string | null>>;
}
export const CropperModal = ({ src, modalOpen, setModalOpen, setPreview }: IModalProps) => {
    const [slideValue, setSlideValue] = useState(10);
    const handleSliderChange = (e: Event) => {
        e.preventDefault();
        if (e && e.target && (e.target as unknown as { value: number }).value) {
            setSlideValue((e.target as unknown as { value: number }).value);
        }
    };
    const cropRef = useRef<AvatarEditor>(null);
    const { themeStyle } = useThemeContext();
    const styles = dynamicStyles(themeStyle);
    const clearInput = () => {
        const uploadInput = document.getElementById('uploadInput') as HTMLInputElement;
        uploadInput.value = '';
    };

    //handle save
    const handleSave = async () => {
        if (cropRef) {
            try {
                const dataUrl = cropRef.current?.getImage().toDataURL('image/jpeg');
                const result = await fetch(dataUrl!);
                const blob = await result.blob();
            const imgSize = blob.size / 1024;
            const quality = imgSize < 500 ? en_config.MEDIA_CONFIG.compressionQualityLow : imgSize < 1000 ? en_config.MEDIA_CONFIG.compressionQualityMid : en_config.MEDIA_CONFIG.compressionQualityHigh;
            new Compressor(blob, {
                quality: quality,
                success(file) {
                    blobToDataURL(file)
                        .then(x => {
                            setPreview(x);
                        })
                        .finally(() => {
                            setModalOpen(false);
                            clearInput();
                        })
                },
            })
            } catch (e) {
                console.log(e);
            }
        }
    };

    return (
        <Modal sx={styles.modalStyle} open={modalOpen}>
            <Box
                sx={{
                    ...styles.boxStyle,
                    maxWidth: { xs: '100vw', sm: '70vw', md: '60vw', lg: '40vw', xl: '30vw' },
                    height: { xs: '100%', sm: '70vw', md: '60vw', lg: '40vw', xl: '30vw' },
                    borderRadius: { xs: 0, sm: 2 },
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        borderBottom: `2px solid ${theme[themeStyle].darkGray60}`,
                        padding: 1,
                        width: '100%',
                    }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                            onClick={() => {
                                setModalOpen(false);
                                clearInput();
                            }}
                            aria-label="cancel">
                            <ArrowBackIcon color="primary" />
                        </IconButton>
                        <Typography sx={{ ...styles.title, display: 'inline' }}> Edit Media </Typography>
                    </Box>
                    <Button
                        onClick={handleSave}
                        style={styles.saveButton}
                        sx={{
                            textDecoration: 'none',
                            '&:hover': {
                                cursor: 'pointer',
                                backgroundColor: theme[themeStyle].primary_orange,
                                color: theme[themeStyle].primary_white,
                            },
                            textTransform: 'none',
                            backgroundColor: theme[themeStyle].transparent,
                            transition: 'all 0.3s ease-in-out',
                        }}
                        size="small">
                        Save
                    </Button>
                </Box>
                <AvatarEditor
                    ref={cropRef}
                    image={src}
                    style={styles.canvasStyle}
                    borderRadius={100}
                    color={[0, 0, 0, 0.7]}
                    scale={slideValue / 10}
                    rotate={0}
                />

                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderTop: `2px solid ${theme[themeStyle].darkGray60}`,
                        padding: 1,
                    }}>
                    <RemoveCircleOutlineRoundedIcon sx={{ color: theme[themeStyle].primary_orange }} />
                    <Slider
                        min={10}
                        max={50}
                        sx={styles.slider}
                        size="medium"
                        defaultValue={slideValue}
                        value={slideValue}
                        onChange={handleSliderChange}
                    />
                    <AddCircleOutlineRoundedIcon sx={{ color: theme[themeStyle].primary_orange }} />
                </Box>
            </Box>
        </Modal>
    );
};

const dynamicStyles = (colorScheme: ColorScheme) =>
    StyleSheet.create({
        slider: {
            width: '50%',
            color: theme[colorScheme].primary_orange,
            marginRight: 2,
            marginLeft: 2,
        },
        boxStyle: {
            width: '100%',
            maxWidth: '40vw',
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            border: `2px solid ${theme[colorScheme].darkGray60}`,
            backgroundColor: theme[colorScheme].pure_black,
            height: '50vh',
        },
        canvasStyle: {
            border: `1px solid ${theme[colorScheme].primary_orange}`,
            width: 300,
            height: 300,
        },
        modalStyle: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        saveButton: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 100,
            border: `1px solid ${theme[colorScheme].primary_orange}`,
        },
        cancelButton: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            height: '40px',
            width: 100,
            borderRadius: 100,
            border: `1px solid ${theme[colorScheme].tag_white}`,
        },
        title: {
            fontFamily: FONT_ENUM.REGULAR_HEADER,
            color: theme[colorScheme].primary_white,
        },
    });
