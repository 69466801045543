import React, { useState } from 'react';
import { ThemeContext } from '../../../module/ThemeManager';
import { Box, Grid, Typography } from '@mui/material';
import { theme } from '../../../constants/theme';
import { ISearchGridProps, SearchGrid } from '../../EsCarousels/SearchGrid';
import { DiscoverySearchUnparsedResponse } from '../../../types/global/generic.types';
import { IWatchGrid, WatchGrid } from '../../EsCarousels/WatchGrid';
import { ActCarousel, IActCarousel } from '../../EsCarousels/ActCarousel';
import { IUserCarousel, UserCarousel } from '../../EsCarousels/UserCarousel';
import { IContest, IProject, UserSummary } from '../../../types/global/helper';
import { FONT_ENUM } from '../../../types/global/fonts.types';
import { isEmpty } from '../../../utils/lodash';
import { VideoThumbnail } from '../../VideoPlayer/VideoThumbnail';
import { EsTrendingCard } from '../../EsCards/EsTrendingCard';
import { UserCard } from '../../EsCards/NewUserCard';
import { IProjectSummary } from '../../../types/global/media.types';
import { useSelector } from 'react-redux';
import { CategorySuggestionShimmer } from '../../../pages/CategorySuggestionShimmer';
import { loadingDiscoverySelector } from '../../../selectors/selectors';
import { MainCategoryShimmer } from '../../../pages/MainCategoryShimmer/MainCategoryShimmer';

interface ISearchContainerProps extends IWatchGrid, IActCarousel, IUserCarousel, ISearchGridProps {
    data: DiscoverySearchUnparsedResponse;
    suggestedContest?: IContest[];
    suggestedTalent?: UserSummary[];
    suggestedProjects?: IProject[];
    handleProjectSuggestionClick?: (project: IProjectSummary) => void;
    handleContestSuggestionCLick?: (contest: IContest) => void;
    handleUserSuggestionClick?: (user: UserSummary) => void;
}
const SearchContainer: React.FC<ISearchContainerProps> = props => {
    const {
        data,
        suggestedContest,
        suggestedProjects,
        suggestedTalent,
        onActPressed,
        onClickActUserIcon,
        handleProjectSuggestionClick,
        handleContestSuggestionCLick,
        handleUserSuggestionClick,
    } = props;
    const [title, seTitle] = useState('');
    const isLoading = useSelector(loadingDiscoverySelector);

    const renderSuggestion = () => {
        switch (title) {
            case 'projects':
                return (
                    suggestedProjects &&
                    !isEmpty(suggestedProjects) &&
                    suggestedProjects.map((proj, index) => (
                        <VideoThumbnail
                            project={proj}
                            onClick={() =>
                                handleProjectSuggestionClick && handleProjectSuggestionClick(proj as IProjectSummary)
                            }
                            key={index.toString()}
                            isPlayingIconEnabled={false}
                        />
                    ))
                );
            case 'contests':
                return (
                    suggestedContest &&
                    !isEmpty(suggestedContest) &&
                    suggestedContest.map((contest, index) => (
                        <EsTrendingCard
                            trendingItem={contest}
                            onClickTrendingCard={() =>
                                handleContestSuggestionCLick && handleContestSuggestionCLick(contest)
                            }
                            key={index.toString()}
                        />
                    ))
                );
            case 'users':
                return (
                    suggestedTalent &&
                    !isEmpty(suggestedTalent) &&
                    suggestedTalent.map((user, index) => (
                        <UserCard
                            user={user}
                            onClickUserCard={() => handleUserSuggestionClick && handleUserSuggestionClick(user)}
                            key={index.toString()}
                        />
                    ))
                );
            default:
                return (
                    suggestedProjects &&
                    !isEmpty(suggestedProjects) &&
                    suggestedProjects.map((proj, index) => (
                        <VideoThumbnail
                            project={proj}
                            onClick={() =>
                                handleProjectSuggestionClick && handleProjectSuggestionClick(proj as IProjectSummary)
                            }
                            key={index.toString()}
                            isPlayingIconEnabled={false}
                        />
                    ))
                );
        }
    };

    return (
        <ThemeContext.Consumer>
            {({ themeStyle, device }) => {
                return (
                    <Grid
                        container
                        style={{ height: '100%' }}
                        sx={{
                            marginTop: { xs: '0px', sm: '40px' },
                            paddingLeft: { xs: '15px', sm: '40px' },
                            paddingRight: { xs: '15px', sm: '40px' },
                            marginLeft: { xs: '0px', sm: '65px' },
                        }}>
                        <Grid item xs={12} sm={title === 'users' ? 9 : 8}>
                            {!!data && !isLoading ? (
                                Object.keys(data).map((title, index) => {
                                    if (title === 'projects' && !isEmpty(data[title])) {
                                        // Change this issue
                                        seTitle(title);
                                        return (
                                            <WatchGrid
                                                categoryTitle="Projects"
                                                mediaData={data[title]}
                                                key={index}
                                                {...props}
                                            />
                                        );
                                    }

                                    if (title === 'acts' && data[title] && !isEmpty(data[title]) && !isLoading) {
                                        seTitle(title);
                                        return (
                                            <ActCarousel
                                                actsData={data[title] as IProject[]}
                                                title="Acts"
                                                key={index}
                                                onActPressed={onActPressed}
                                                onClickActUserIcon={onClickActUserIcon}
                                                {...props}
                                            />
                                        );
                                    }

                                    if (title === 'users' && !isEmpty(data[title])) {
                                        seTitle(title);
                                        return (
                                            <UserCarousel
                                                userData={data[title]}
                                                title="Talents"
                                                key={index}
                                                {...props}
                                            />
                                        );
                                    }

                                    if (title === 'contests' && !isEmpty(data[title])) {
                                        seTitle(title);
                                        return (
                                            <SearchGrid
                                                searchResult={data[title]}
                                                {...props}
                                                key={index}
                                                title="Contests"
                                            />
                                        );
                                    }
                                    return null;
                                })
                            ) : (
                                <MainCategoryShimmer />
                            )}
                        </Grid>
                        {!isLoading && !device.isMobile ? (
                            <Grid item xs={12} sm={title === 'users' ? 3 : 4}>
                                <Box
                                    sx={{
                                        backgroundColor: theme[themeStyle].deep_grey_lighter,
                                        borderRadius: 1,
                                        overflowX: 'hidden',
                                        marginLeft: 2,
                                        position: 'sticky',
                                    }}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            backgroundColor: theme[themeStyle].deep_grey,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            p: 2,
                                        }}>
                                        <Typography
                                            style={{
                                                fontFamily: FONT_ENUM.REGULAR_HEADER,
                                                color: theme[themeStyle].lightWhite,
                                                fontSize: 18,
                                                textTransform: 'capitalize',
                                            }}>
                                            Suggested {title}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ p: 2, overflowY: 'scroll' }}>{renderSuggestion()}</Box>
                                </Box>
                            </Grid>
                        ) : (
                            <CategorySuggestionShimmer />
                        )}
                    </Grid>
                );
            }}
        </ThemeContext.Consumer>
    );
};

export { SearchContainer };
