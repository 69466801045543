import React, { useState } from 'react';
import { StyleSheet, TextInputProps, View, Text } from 'react-native';
import { ColorScheme, theme } from '../../constants/theme';
import { ThemeContext } from '../../module/ThemeManager';
import { Fonts, GenericSize } from '../../module/types';
import { FONT_ENUM } from '../../types/global/fonts.types';
import { Box, FormLabel, TextFieldProps } from '@mui/material';
import TextField from '@mui/material/TextField';
import { CustomizedMultipleInput } from '../CustomizedMultipleInput/CustomizedMultipleInput';
import closeIcon from '../../asset/images/icons/es_x_close.svg';
import CustomLazyLoadImage from '../Image/CustomLazyLoadImage';

type TaskField = { task: string; validator: boolean };

type InputType = 'autoComplete' | 'simple';

export interface ITextInputProps extends TextInputProps {
    isErrorState?: boolean;
    errorMsg?: string;
    isFilledState?: boolean;
    mainContainerStyle?: any;
    isFocused?: boolean;
    mask?: any;
    isOTP?: boolean;
    fieldTasks?: boolean;
    fieldTaskList?: Array<TaskField>;
    onFocus?: (e: any) => void;
    onBlur?: (e: any) => void;
    onChange?: (e: any) => void;
    textFieldProps?: TextFieldProps;
    label?: string;
    textInputType?: InputType;
    enableClearMode?: boolean;
    onClearPress?: () => void;
    enableCustomButton?: boolean;
    customButton?: JSX.Element;
    instruction?: string;
    labelStyle?: React.CSSProperties;
}

const CustomTextField = (props: ITextInputProps) => {
    const {
        isErrorState,
        errorMsg,
        isFilledState,
        isOTP,
        fieldTasks,
        fieldTaskList,
        onFocus,
        onBlur,
        onChange,
        label,
        textFieldProps,
        textInputType,
        enableClearMode,
        onClearPress,
        enableCustomButton,
        customButton,
        instruction,
        labelStyle,
    } = props;
    const [isFocused, setIsFocused] = useState(false);

    const handleOnFocus = (e: any) => {
        setIsFocused(true);
        onFocus && onFocus(e);
    };

    const handleOnBlur = (e: any) => {
        setIsFocused(false);
        onBlur && onBlur(e);
    };

    const RenderInputType = textInputType === 'autoComplete' ? CustomizedMultipleInput : TextField;

    return (
        <ThemeContext.Consumer>
            {({ themeStyle, sizes }) => {
                const { genericSize, font } = sizes;
                const styles = dynamicStyle(themeStyle, genericSize, font);
                const isFilledStateColorStart = isFilledState
                    ? theme[themeStyle].tertiary_green
                    : theme[themeStyle].placeholderGray50;

                const isErrorStateColorStart = isErrorState ? theme[themeStyle].secondary_red : isFilledStateColorStart;

                const fieldTasksValidator = () => {
                    if (isFocused && fieldTasks && fieldTaskList && !isErrorState) {
                        return (
                            <View style={styles.fieldTaskContainer}>
                                {fieldTaskList.map((el, i) => (
                                    <Text
                                        key={i.toString()}
                                        style={el.validator ? styles.taskStyleFilled : styles.taskStyle}>
                                        {el.task} {fieldTaskList[fieldTaskList.length - 1] === el ? '' : ` - `}
                                    </Text>
                                ))}
                            </View>
                        );
                    }

                    if (instruction && isFocused) {
                        return (
                            <View style={styles.fieldTaskContainer}>
                                <Text
                                    style={{
                                        fontFamily: FONT_ENUM.REGULAR_HEADER,
                                        fontSize: 12,
                                        color: theme[themeStyle].tag_white,
                                    }}>
                                    {instruction}
                                </Text>
                            </View>
                        );
                    }
                    return null;
                };
                return (
                    <Box
                        sx={{
                            width: '100%',
                            // borderWidth: 1, // This was throwing an issue with chakra, so I commented it out
                        }}>
                        <FormLabel style={{ ...styles.text, ...labelStyle }}>{label}</FormLabel>
                        <View
                            style={{
                                borderColor: isFocused ? theme[themeStyle].highlight : isErrorStateColorStart,
                                width: '100%',
                                borderWidth: 1,
                                borderRadius: 10,
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                overflow: 'hidden',
                                flex: 1,
                                flexDirection: 'row',
                                backgroundColor: theme[themeStyle].gray_text,
                            }}>
                            <RenderInputType
                                variant="standard"
                                style={!isOTP ? styles.input : null}
                                inputProps={{
                                    style: {
                                        color: theme[themeStyle].lightWhite,
                                    },
                                }}
                                onFocus={handleOnFocus}
                                onBlur={handleOnBlur}
                                onChange={onChange}
                                {...textFieldProps}
                            />
                            {enableClearMode && (
                                <CustomLazyLoadImage
                                    src={closeIcon}
                                    alt="Close icon"
                                    style={{ height: '20px', width: '20px', marginRight: '10px', cursor: 'pointer' }}
                                    onClick={onClearPress}
                                />
                            )}
                            {enableCustomButton && (
                                <Box sx={{ marginRight: '10px', cursor: 'pointer' }}>{customButton}</Box>
                            )}
                        </View>
                        {isErrorState && !!errorMsg && (
                            <View style={styles.errHolder}>
                                <Text style={styles.errMsg}>{errorMsg}</Text>
                            </View>
                        )}
                        {fieldTasksValidator()}
                    </Box>
                );
            }}
        </ThemeContext.Consumer>
    );
};

CustomTextField.defaultProps = {
    placeHolder: 'Enter message here',
    errorMsg: 'Error msg here',
    isErrorState: false,
    isFilledState: false,
    textInputType: 'simple',
};

export { CustomTextField };

const dynamicStyle = (coloScheme: ColorScheme, genericSize: GenericSize, font: Fonts) =>
    StyleSheet.create({
        linearGradient: {
            width: '100%',
            borderRadius: 8, // <-- Outer Border Radius
        },
        innerContainer: {
            borderRadius: 8, // <-- Inner Border Radius
            flex: 1,
            margin: 2, // <-- Border Width
            backgroundColor: '#fff',
            justifyContent: 'center',
        },
        textinput: {
            color: theme[coloScheme].secondary_dark,
            minHeight: 50,
            fontFamily: FONT_ENUM.MEDIUM_TEXT,
            paddingHorizontal: genericSize._size10,
            outlineStyle: 'none',
            minWidth: genericSize._size400,
        },
        errMsg: {
            color: theme[coloScheme].secondary_red,
            fontFamily: FONT_ENUM.MEDIUM_TEXT,
            fontSize: 12,
        },
        errHolder: {
            paddingHorizontal: genericSize._size10,
        },
        countryPicker: {
            justifyContent: 'center',
        },
        fieldTaskContainer: { marginHorizontal: 2, marginTop: genericSize._size10, flexDirection: 'row' },
        taskStyle: {
            fontSize: 8,
            fontFamily: FONT_ENUM.MEDIUM_TEXT,
            color: theme[coloScheme].grey,
            textTransform: 'uppercase',
        },
        taskStyleFilled: {
            fontSize: 8,
            fontFamily: FONT_ENUM.BOLD_TEXT,
            color: theme[coloScheme].lightWhite,
            textTransform: 'uppercase',
        },
        input: {
            width: '100%',
            height: 50,
            paddingLeft: 14,
            paddingRight: 14,
            backgroundColor: theme[coloScheme].gray_text,
            paddingTop: 10,
            borderRadius: 8,
        },
        text: {
            maxWidth: 400,
            width: '100%',
            textAlign: 'left',
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: '400',
            color: theme[coloScheme].form_label,
            fontFamily: FONT_ENUM.MAIN_REGULAR,
            lineHeight: 2.5,
        },
    });
