import { IMetaData } from '../types/global/helper';

export const base64Converter = (base64String: string) => {
    const binaryString = atob(base64String.split(',')[1]); // Binary data string
    const blob = new Blob([binaryString], { type: 'image/jpeg' }); // Create a BLOB object
    return blob;
};

export const dataURIToBlob = (dataURI: string) => {
    const splitDataURI = dataURI.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
};

export const formatDuration = (durationInSeconds?: number): string => {
    if (durationInSeconds) {
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds % 3600) / 60);
        const seconds = Math.floor(durationInSeconds % 60);

        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');

        if (hours > 0) {
            return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
        } else {
            return `${formattedMinutes}:${formattedSeconds}`;
        }
    }
    return '-';
};

enum VideoOrientation {
    Horizontal = 'horizontal',
    Vertical = 'vertical',
    Square = 'square',
}

/** Detect video aspect ratio */
export function determineVideoOrientation(dimensions: IMetaData): VideoOrientation {
    if (!dimensions) {
        return VideoOrientation.Vertical;
    }
    const { width, height } = dimensions;

    if (width === height) {
        return VideoOrientation.Square;
    } else if (width > height) {
        return VideoOrientation.Horizontal;
    } else {
        return VideoOrientation.Vertical;
    }
}

// Example usage:
