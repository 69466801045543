import { buffers } from '@redux-saga/core';
import { takeLatest, put, call, actionChannel, select, all } from '@redux-saga/core/effects';

import { dispatchQueuedActions as dispatchAction, refreshTokenComplete, refreshTokenFail } from './action';
import { RefreshTokenPayload, types } from './types';
import { api } from '../../configurations/api';
import { refreshTokenUrl } from '../../configurations/api/url';
import { storeJwt, storeRefreshToken } from '../authentication';
import { setJwt, logout } from '../authentication/action';
import { globalErrorHandler } from '../error/saga';
import { httpRequest } from '../types';
import { getJWtDetails } from '../../utils/AppUtils';
import { navigate } from '../navigator/action';
import { ROUTES } from '../../types/global/routes.types';
import { SentryCapture } from '../../analytics/Sentry';

const buffer: any = buffers.sliding();

export function* refreshJWT(refresh?: string): any {
    try {
        const response = yield call(api, refreshTokenUrl, httpRequest.POST, { refreshToken: refresh }, 1, 5000, false);
        const { token, refreshToken } = response;
        return response.token && response.refreshToken ? { token, refreshToken } : null;
    } catch (error) {
        SentryCapture(error, 'error');
        yield put(refreshTokenFail('COULD NOT REFRESH TOKEN'));
        return null;
    }
}

function* refreshToken({ payload }: { payload: RefreshTokenPayload }): any {
    try {
        const { authentication } = yield select();
        const { token, refreshToken } = authentication;
        const { isSignedIn } = getJWtDetails(token);

        if (refreshToken) {
            const response = yield* refreshJWT(`jwt-refresh-token=${refreshToken}`);
            if (response) {
                // eslint-disable-next-line @typescript-eslint/no-shadow
                const { token, refreshToken } = response;
                if (token && refreshToken) {
                    yield call(storeJwt, token);
                    yield call(storeRefreshToken, refreshToken);
                    yield put(setJwt({ token, refreshToken }));
                    yield put(refreshTokenComplete());
                    yield put(dispatchAction());
                    if (payload && payload.isNavigate) {
                        yield put(navigate({ routes: payload.payload as string, isRefreshed: true }));
                    }
                } else {
                    throw new Error('NO JWT or refreshToken');
                }
            }
        } else if (!refreshToken && !isSignedIn) {
            yield put(refreshTokenComplete());
            yield put(navigate({ routes: ROUTES.ESLOGIN, isRefreshed: true }));
            if (payload && payload.isNavigate) {
                /** The below I took out because we wish that if refresh fails to navigate user to login screen */
                // yield put(navigate({ routes: payload.payload as string, isRefreshed: true }));
            } else {
                // yield put(navigate({ routes: ROUTES.ESLOGIN, isRefreshed: true }));
            }
        }
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield call(globalErrorHandler, error);
        yield put(refreshTokenComplete());
        yield put(logout());
    }
}

function* enqueueFailedAction() {
    yield actionChannel(types.ENQUEUE_FAILED_ACTIONS, buffer);
}

function* dispatchQueuedActions() {
    while (!buffer.isEmpty()) {
        const { payload } = yield call(buffer.take);
        yield put(payload);
    }
}

/** ******************************** WATCHERS ************************************* */

function* refreshTokenWatcher() {
    yield takeLatest<any>(types.REFRESH_TOKEN, refreshToken);
}

function* dispatchQueuedActionWatcher() {
    yield takeLatest(types.DISPATCH_QUEUED_ACTIONS, dispatchQueuedActions);
}

export default function* tokenRefreshSaga() {
    yield all([refreshTokenWatcher(), dispatchQueuedActionWatcher(), enqueueFailedAction()]);
}
