import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import {
    assignRedeemableCodeFail,
    assignRedeemableCodeSuccess,
    awardPrizeFail,
    awardPrizeSuccess,
    castVoteFail,
    castVoteSuccess,
    createAwardCategory as createAwardCategoryAction,
    createAwardCategoryFail,
    createAwardCategorySuccess,
    createBookmarkFail,
    createBookmarkSuccess,
    createContestFail,
    createContestSuccess,
    createHostFail,
    createHostSuccess,
    createPartnerFail,
    createPartnerSuccess,
    createScoreCategoryFail,
    createScoreCategorySuccess,
    deleteBookmarkFail,
    deleteBookmarkSuccess,
    deleteContestFail,
    deleteContestSuccess,
    deleteHostFail,
    deleteHostSuccess,
    deletePartnerFail,
    deletePartnerSuccess,
    downloadAssetFail,
    downloadAssetSuccess,
    getAllPartnersFail,
    getAllPartnersSuccess,
    getAllSubmissionFail,
    getAllSubmissionSuccess,
    getAwardCategoriesForContestFail,
    getAwardCategoriesForContestSuccess,
    getBookmarkFail,
    getBookmarkSuccess,
    getContestAwardsFail,
    getContestAwardsSuccess,
    getContestFail,
    getContestReferencesFail,
    getContestReferencesSuccess,
    getContestSuccess,
    getContests,
    getContestsFail,
    getContestsSuccess,
    getHostFail,
    getHostSuccess,
    getMySubmissionsFail,
    getMySubmissionsSuccess,
    getPartnerFail,
    getPartnerSuccess,
    getRecommendationFail,
    getRecommendationSuccess,
    getRedeemableCodesFail,
    getRedeemableCodesSuccess,
    getUserContestFail,
    getUserContestSuccess,
    getUserContestsFail,
    getUserContestsSuccess,
    initaiet2FAForVoteFail,
    initaiet2FAForVoteSuccess,
    publishContestFail,
    publishContestSuccess,
    rescindSubmissionFail,
    rescindSubmissionSuccess,
    searchHostFail,
    searchHostSuccess,
    searchPartnerFail,
    searchPartnerSuccess,
    sendWinnersEmailFail,
    sendWinnersEmailSucess,
    setVoting2Fa,
    setVotingFlow,
    submitProjectFail,
    submitProjectSuccess,
    unPublishContestFail,
    unPublishContestSuccess,
    updateContestFail,
    updateContestSuccess,
    updateHostFail,
    updateHostSuccess,
    updatePartnerFail,
    updatePartnerSuccess,
} from './actions';
import { globalErrorHandler } from '../error/saga';
import { api } from '../../configurations/api';
import {
    assignRedeemableCodeUrl,
    awardsCategoryUrl,
    awardsUrl,
    downloadContestAssetUrl,
    getContestMetricsUrl,
    getContestUrl,
    getMySubmissionsUrl,
    getRecommendationUrl,
    getRedeemableCodesUrl,
    getSavedCollectionUrl,
    hostUrl,
    partnerUrl,
    publishContestUrl,
    saveCollectionUrl,
    scoreCategoryUrl,
    submissionsUrl,
    unPublishContesturl,
    winnersEmailUrl,
} from '../../configurations/api/url';
import { httpRequest } from '../types';
import {
    IContest,
    IContestPrize,
    IGenericDocumentModifier,
    IReedemableCode,
    ISubmission,
} from '../../types/global/helper';
import {
    ISubmitProject,
    types,
    IGetContestPayload,
    IGetContests,
    IInitiate2FaForVote,
    ICastVote,
    ICreateBookmark,
    ICreateContestPayload,
    IGetUserContest,
    IGetUserContests,
    ICreateHost,
    IGetHost,
    IGetAllHosts,
    ICreatePartner,
    IGetPartner,
    IGetAllPartners,
    ISearchPayload,
    ISearchHostResponse,
    ISearchPartnerResponse,
    ICreateAwardCategory,
    IAssignRedeemableCode,
    IGetRedeemableCodes,
    IAwardPrize,
    ICreateWinnersEmail,
    IGetAllSubmissions,
    ICreateScoreCategory,
} from './types';
import { CACHE_TYPE, CacheValue, NotUniqueCacheValue } from '../cache/types';
import { getCacheByType, getCacheDuration, getNotUniqueCacheByKey, isUseCacheEnabled } from '../cache/saga';
import { destroyOneCache, setCache } from '../cache/action';
import { genericParseSingleDocument, parseGenericCollection, parseSingleContest } from '../../utils/responseProcessor';
import _ from 'lodash';
import { showToastMessage } from '../../utils/AppUtils';
import { IErrorType } from '../../constants/types';
import { setInAppNotification } from '../in-app-notification/actions';
import { SentryCapture } from '../../analytics/Sentry';
import { Action } from 'redux';
import { isEmpty } from '../../utils/lodash';
import { navigate } from '../navigator/action';
import { ROUTES } from '../../types/global/routes.types';
import { replaceRouteParam } from '../../utils/routesProcessor';
import { refreshUserDashboard } from '../account/actions';
import { getProject } from '../project/saga';

/** Create contest
 * Update Contest
 * Delete Contest
 *
 * Create host
 * Update Host
 * Delete Host
 * Get Host
 * Get All hosts
 *
 * Create partner
 * Update partner
 * Delete partner
 * Get Partner
 * Get all partners
 *
 *
 */

function* getContestReferences({ payload }: { payload: string[] }): any {
    try {
        const projectCalls = payload.map(id =>
            getProject({ payload: { id, skipAuthentication: true, withNavigate: false, skipMediaSet: true } }),
        );
        const projects = yield all(projectCalls);
        yield put(getContestReferencesSuccess(projects));
    } catch (error: any) {
        yield put(getContestReferencesFail(error));
    }
}

function* createScoreCategory({ payload }: { payload: ICreateScoreCategory }): Generator<any, any, any> {
    try {
        const response = yield call(api, scoreCategoryUrl, httpRequest.POST, payload);
        const { data } = response.data;
        yield put(createScoreCategorySuccess(data));
    } catch (error: any) {
        yield put(createScoreCategoryFail(error));
    }
}

function* getContestScoreBoard({ payload }: { payload: IGetAllSubmissions }): Generator<any, any, any> {
    const { contestId, sortBy, limit, page } = payload;
    let params = '';
    if (sortBy) {
        params += `?sortby=${sortBy}`;
    }
    if (limit) {
        params += `&limit=${limit}`;
    }

    if (page) {
        params += `&page=${page}`;
    }
    const request = `${getContestUrl}/${contestId}/score${params}`;
    try {
        const response = yield call(api, request, httpRequest.GET, {}, 2, 1000);
        const { data } = response;
        yield put(
            getAllSubmissionSuccess({
                submissions: data,
                details: payload,
            }),
        );
    } catch (error: any) {
        yield put(getAllSubmissionFail(error));
    }
}

function* sendConclusionEmail({ payload }: { payload: ICreateWinnersEmail }): Generator<any, any, any> {
    try {
        yield call(api, winnersEmailUrl, httpRequest.POST, payload);
        yield put(sendWinnersEmailSucess());
        yield* updateContest({
            payload: {
                documentId: payload.contestId,
                payload: {
                    winner_email_sent: !payload.is_test,
                },
            },
        });
        yield delay(1000);
        /** Fetch the contest again to update the new values */
        yield* getUserContest({
            payload: {
                contestId: payload.contestId,
                skipRefresh: true,
                withNavigate: false,
            },
        });
    } catch (error: any) {
        yield put(sendWinnersEmailFail(error));
    }
}

function* downloadAsset({ payload, resolve, reject }: any): Generator<any, any, any> {
    try {
        const response = yield call(
            api,
            `${downloadContestAssetUrl}`,
            httpRequest.POST,
            payload,
            0,
            1000,
            false,
            undefined,
            undefined,
            undefined,
            'blob',
        );
        yield put(downloadAssetSuccess(response));
        resolve(response);
    } catch (error: any) {
        SentryCapture(error, 'error');
        reject(error);
        yield put(downloadAssetFail(error));
    }
}
function* getAssignedCodes({ payload }: { payload: IGetRedeemableCodes }): Generator<any, any, any> {
    const defaultUseCache = yield* isUseCacheEnabled();
    const { contestId, limit = 100, page = 0 } = payload;
    let initialResult: any = null;
    const request = `${getRedeemableCodesUrl}?contest=${contestId}&page=${page}&limit=${limit}`;
    const cache: NotUniqueCacheValue = yield* getNotUniqueCacheByKey(CACHE_TYPE.REDEEMABLE_CODE, contestId);
    if (cache && cache.key) {
        initialResult = cache.value;
    }

    if (defaultUseCache && initialResult) {
        yield put(getRedeemableCodesSuccess(initialResult));
    } else {
        try {
            const response = yield call(api, request, httpRequest.GET, null, 2, 1000);
            const { data } = response.data;
            yield put(getRedeemableCodesSuccess(data));
            yield put(
                setCache({
                    key: contestId,
                    value: data,
                    type: CACHE_TYPE.REDEEMABLE_CODE,
                    isUnique: false,
                }),
            );
        } catch (error: any) {
            yield put(getRedeemableCodesFail(error));
        }
    }
}

function* assignCodes({
    payload,
    resolve,
    reject,
}: {
    payload: IAssignRedeemableCode;
    resolve: any;
    reject: any;
}): Generator<any, any, any> {
    const { contestId, userId } = payload;
    /** Create code First
     *
     */
    try {
        const response = yield call(api, getRedeemableCodesUrl, httpRequest.POST, { contest: contestId }, 0, 1000);
        const { data }: { data: IReedemableCode } = response;
        if (data) {
            /** Send to user */
            const userResponse = yield call(api, assignRedeemableCodeUrl, httpRequest.POST, {
                userId,
                redeemCodeId: data._id,
            });
            const redeemcodeObject = userResponse;
            yield put(assignRedeemableCodeSuccess(redeemcodeObject.data));
            yield put(
                destroyOneCache({
                    cacheType: CACHE_TYPE.REDEEMABLE_CODE,
                }),
            );
        }
        yield call(showToastMessage, 'Code sent successfully', 'success');
        resolve(response);
    } catch (error: any) {
        yield call(globalErrorHandler, error);
        yield put(assignRedeemableCodeFail(error));
        reject(error);
    }
}

function* createAwardCategory({ payload }: { payload: ICreateAwardCategory }): Generator<any, any, any> {
    try {
        const response = yield call(api, awardsCategoryUrl, httpRequest.POST, payload, 0, 0);
        const { data } = response.data;
        yield put(createAwardCategorySuccess(data));
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(createAwardCategoryFail(error));
    }
}

function* getAwardCategories({ payload }: { payload: IGenericDocumentModifier }): Generator<any, any, any> {
    try {
        const response = yield call(
            api,
            `${awardsCategoryUrl}?contest=${payload.documentId}`,
            httpRequest.GET,
            {},
            0,
            0,
        );
        const { data } = response.data;
        yield put(getAwardCategoriesForContestSuccess(data));
    } catch (error: any) {
        yield put(getAwardCategoriesForContestFail(error));
    }
}

function* getAwardPrizes({ payload }: { payload: IGenericDocumentModifier }): Generator<any, any, any> {
    try {
        const response = yield call(api, `${awardsUrl}?contest=${payload.documentId}`, httpRequest.GET, {}, 0, 0);
        const { data } = response.data;
        yield put(getContestAwardsSuccess(data));
    } catch (error: any) {
        yield put(getContestAwardsFail(error));
    }
}

function* awardPrize({ payload }: { payload: IAwardPrize }): Generator<any, any, any> {
    try {
        const response = yield call(api, `${awardsUrl}`, httpRequest.POST, payload, 0, 0);
        const { data } = response.data;
        yield put(awardPrizeSuccess(data));
        yield call(showToastMessage, 'Prize awarded successfully', 'success');
    } catch (error: any) {
        yield put(awardPrizeFail(error));
    }
}

function* searchHost({ payload }: { payload: ISearchPayload }): any {
    const defaultUseCache = yield* isUseCacheEnabled();
    const { searchTerm } = payload;
    let initialResult: any = null;

    const cache: NotUniqueCacheValue = yield* getNotUniqueCacheByKey(CACHE_TYPE.HOST_SEARCH, searchTerm);
    if (cache && cache.key) {
        initialResult = cache.value;
    }

    if (initialResult && defaultUseCache) {
        yield put(searchHostSuccess(initialResult));
    } else {
        try {
            const response = yield call(api, `${hostUrl}/search`, httpRequest.POST, payload, 2);
            const { data }: { data: ISearchHostResponse } = response;
            yield put(searchHostSuccess(data.result));
            yield put(
                setCache({
                    key: searchTerm,
                    value: data.result,
                    type: CACHE_TYPE.HOST_SEARCH,
                    isUnique: false,
                }),
            );
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield put(searchHostFail(error));
        }
    }
}

function* searchPartner({ payload }: { payload: ISearchPayload }): any {
    const defaultUseCache = yield* isUseCacheEnabled();
    const { searchTerm } = payload;
    let initialResult: any = null;

    const cache: NotUniqueCacheValue = yield* getNotUniqueCacheByKey(CACHE_TYPE.PARTNER_SEARCH, searchTerm);
    if (cache && cache.key) {
        initialResult = cache.value;
    }

    if (initialResult && defaultUseCache) {
        yield put(searchPartnerSuccess(initialResult));
    } else {
        try {
            const response = yield call(api, `${partnerUrl}/search`, httpRequest.POST, payload, 2);
            const { data }: { data: ISearchPartnerResponse } = response;
            yield put(searchPartnerSuccess(data.result));
            yield put(
                setCache({
                    key: searchTerm,
                    value: data.result,
                    type: CACHE_TYPE.PARTNER_SEARCH,
                    isUnique: false,
                }),
            );
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield put(searchPartnerFail(error));
        }
    }
}

function* createContest({ payload }: { payload: ICreateContestPayload }): Generator<any, any, any> {
    const { prizes } = payload;
    try {
        const response = yield call(
            api,
            getContestUrl,
            httpRequest.POST,
            payload,
            2,
            1000,
            !!payload.feature_image || !!payload.mobile_feature_image,
        );
        const { data } = response.data;
        if (prizes && !isEmpty(prizes)) {
            yield all(
                prizes.map(p =>
                    put(
                        createAwardCategoryAction({
                            ...p,
                            contest: data._id,
                        }),
                    ),
                ),
            );
        }
        yield put(createContestSuccess(data));
        yield put(destroyOneCache({ cacheType: CACHE_TYPE.USER_CONTESTS }));
        yield call(showToastMessage, 'Contest created successfully.', 'success');
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(createContestFail(error));
    }
}

function* publishContest({ payload }: { payload: IGenericDocumentModifier }): Generator<any, any, any> {
    try {
        const response = yield call(api, `${publishContestUrl}/${payload.documentId}`, httpRequest.PATCH, {}, 2, 1000);
        const { data } = response;
        yield put(publishContestSuccess(data));
        yield put(destroyOneCache({ cacheType: CACHE_TYPE.USER_CONTESTS }));
        yield call(showToastMessage, 'Contest published successfully.', 'success');
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(publishContestFail(error));
        yield call(globalErrorHandler, error);
    }
}

function* unPublishContest({ payload }: { payload: IGenericDocumentModifier }): Generator<any, any, any> {
    try {
        const response = yield call(
            api,
            `${unPublishContesturl}/${payload.documentId}`,
            httpRequest.PATCH,
            {},
            2,
            1000,
        );
        const { data } = response;
        yield put(unPublishContestSuccess(data));
        yield put(destroyOneCache({ cacheType: CACHE_TYPE.USER_CONTESTS }));
        yield call(showToastMessage, 'Contest Updated successfully.', 'success');
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(unPublishContestFail(error));
        yield call(globalErrorHandler, error);
    }
}

function* updateContest({ payload }: { payload: IGenericDocumentModifier }): Generator<any, any, any> {
    const { prizes } = payload.payload;
    try {
        const response = yield call(
            api,
            `${getContestUrl}/${payload.documentId}`,
            httpRequest.PATCH,
            payload.payload,
            0,
            0,
            !!payload.payload.feature_image || !!payload.payload.mobile_feature_image,
        );
        const { data } = response.data;
        yield put(updateContestSuccess(data));
        if (prizes && !isEmpty(prizes)) {
            yield all(
                prizes.map(
                    (p: IContestPrize) =>
                        !p._id &&
                        put(
                            createAwardCategoryAction({
                                ...p,
                                contest: data._id,
                            }),
                        ),
                ),
            );
        }
        yield put(destroyOneCache({ cacheType: CACHE_TYPE.USER_CONTESTS }));
        yield put(destroyOneCache({ cacheType: CACHE_TYPE.DISCOVERY }));
        yield call(showToastMessage, 'Contest Updated successfully.', 'success');
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield call(globalErrorHandler, error);
        yield put(updateContestFail(error));
    }
}

function* getUserContest({ payload }: { payload: IGetUserContest }): Generator<any, any, any> {
    let query = '?';
    if (payload.filter) query += `filter_by=${payload.filter}&`;
    if (payload.start) query += `start_date=${payload.start}&`;
    if (payload.end) query += `end_date=${payload.end}`;
    const key = `${payload.contestId}${query}`;
    const defaultUseCache = yield* isUseCacheEnabled();
    let initialResult: any = null;
    const cache: NotUniqueCacheValue = yield* getNotUniqueCacheByKey(CACHE_TYPE.USER_CONTESTS, key);
    const route = replaceRouteParam(ROUTES.ESCONTEST_ENGAGE, 'contestId', payload.contestId);

    if (cache && cache.key) {
        initialResult = cache.value;
    }

    if (initialResult && defaultUseCache) {
        yield put(getUserContestSuccess(initialResult));
        if (payload.withNavigate) {
            yield put(
                navigate({
                    routes: route,
                }),
            );
        }
    } else {
        try {
            const response = yield call(api, `${getContestMetricsUrl}${key}`, httpRequest.GET, null, 0, 0);
            const { data } = response;
            yield put(getUserContestSuccess(data));
            if (payload.withNavigate) {
                yield put(
                    navigate({
                        routes: route,
                    }),
                );
            }
            yield put(
                setCache({
                    key,
                    type: CACHE_TYPE.USER_CONTESTS,
                    value: data,
                    isUnique: false,
                }),
            );
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield put(getUserContestFail(error));
        }
    }
}

function* getUserContests({ payload }: { payload: IGetUserContests }): Generator<any, any, any> {
    const defaultUseCache = yield* isUseCacheEnabled();
    const cache: NotUniqueCacheValue = yield* getNotUniqueCacheByKey(CACHE_TYPE.USER_CONTESTS, 'all');
    let initialResult: any = null;
    if (cache && cache.key) {
        initialResult = cache.value;
    }

    if (initialResult && defaultUseCache) {
        yield put(getUserContestsSuccess(initialResult));
    } else {
        try {
            const response = yield call(
                api,
                `${getContestUrl}/user-contest?limit=100`,
                httpRequest.GET,
                null,
                2,
                1000,
                false,
            );
            const { data } = response.data;
            yield put(getUserContestsSuccess(data));
            yield put(
                setCache({
                    key: 'all',
                    type: CACHE_TYPE.USER_CONTESTS,
                    value: data,
                    isUnique: false,
                }),
            );
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield put(getUserContestsFail(error));
        }
    }
}

function* deleteContest({ payload }: { payload: IGenericDocumentModifier }): Generator<any, any, any> {
    try {
        yield call(api, `${getContestUrl}/${payload.documentId}`, httpRequest.DELETE, null, 0, 1000);
        yield put(deleteContestSuccess(payload));
        yield put(
            destroyOneCache({
                cacheType: CACHE_TYPE.USER_CONTESTS,
            }),
        );
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(deleteContestFail(error));
    }
}

function* createHost({ payload }: { payload: ICreateHost }): Generator<any, any, any> {
    try {
        const response = yield call(api, hostUrl, httpRequest.POST, payload, 0, 0, !!payload.profile_picture);
        const { data } = response.data;
        yield put(createHostSuccess(data));
        yield put(
            destroyOneCache({
                cacheType: CACHE_TYPE.HOST,
            }),
        );
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(createHostFail(error));
    }
}

function* updateHost({ payload }: { payload: IGenericDocumentModifier }): Generator<any, any, any> {
    try {
        const response = yield call(
            api,
            `${hostUrl}/${payload.documentId}`,
            httpRequest.PATCH,
            payload.payload,
            0,
            0,
            !!payload.payload.profile_picture,
        );
        const { data } = response.data;
        yield put(updateHostSuccess(data));
        yield put(
            destroyOneCache({
                cacheType: CACHE_TYPE.HOST,
            }),
        );
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(updateHostFail(error));
    }
}

function* deleteHost({ payload }: { payload: IGenericDocumentModifier }): Generator<any, any, any> {
    try {
        yield call(api, `${hostUrl}/${payload.documentId}`, httpRequest.DELETE, null, 0, 0);
        yield put(deleteHostSuccess(payload));
        yield put(
            destroyOneCache({
                cacheType: CACHE_TYPE.HOST,
            }),
        );
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(deleteHostFail(error));
    }
}

function* getHost({ payload }: { payload: IGetHost }): Generator<any, any, any> {
    let initialResult: any = null;
    const defaultUseCache = yield* isUseCacheEnabled();
    const cache: NotUniqueCacheValue = yield* getNotUniqueCacheByKey(CACHE_TYPE.HOST, payload.hostId);

    if (cache && cache.key) {
        initialResult = cache.value;
    }

    if (initialResult && defaultUseCache) {
        yield put(getHostSuccess(initialResult));
    } else {
        try {
            const response = yield call(api, `${hostUrl}/${payload.hostId}`, httpRequest.GET, null, 0, 0);
            const { data } = response.data;
            yield put(getHostSuccess(data));
            yield put(
                setCache({
                    key: payload.hostId,
                    type: CACHE_TYPE.HOST,
                    value: data,
                    isUnique: false,
                }),
            );
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield put(getHostFail(error));
        }
    }
}

function* getAllHost({ payload }: { payload: IGetAllHosts }): Generator<any, any, any> {
    let query = '?';
    if (payload.params) query += payload.params;

    const key = payload.params ?? 'all';
    const defaultUseCache = yield* isUseCacheEnabled();
    const cache: NotUniqueCacheValue = yield* getNotUniqueCacheByKey(CACHE_TYPE.HOST, key);

    let initialResult: any = null;
    if (cache && cache.key) {
        initialResult = cache.value;
    }

    if (defaultUseCache && initialResult) {
        yield put(getHostSuccess(initialResult));
    } else {
        try {
            const response = yield call(api, `${hostUrl}${query}`, httpRequest.GET, null, 0, 0);
            const { data } = response.data;
            yield put(getHostSuccess(data));
            yield put(
                setCache({
                    key: payload.params ?? 'all',
                    type: CACHE_TYPE.HOST,
                    value: data,
                    isUnique: false,
                }),
            );
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield put(getHostFail(error));
        }
    }
}

function* createPartner({ payload }: { payload: ICreatePartner }): Generator<any, any, any> {
    try {
        const response = yield call(api, partnerUrl, httpRequest.POST, payload, 0, 0, !!payload.profile_picture);
        const { data } = response.data;
        yield put(createPartnerSuccess(data));
        yield put(
            destroyOneCache({
                cacheType: CACHE_TYPE.PARTNER,
            }),
        );
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(createPartnerFail(error));
    }
}

function* updatePartner({ payload }: { payload: IGenericDocumentModifier }): Generator<any, any, any> {
    try {
        const response = yield call(
            api,
            `${partnerUrl}/${payload.documentId}`,
            httpRequest.PATCH,
            payload.payload,
            0,
            0,
            !!payload.payload.profile_picture,
        );
        const { data } = response.data;
        yield put(updatePartnerSuccess(data));
        yield put(
            destroyOneCache({
                cacheType: CACHE_TYPE.PARTNER,
            }),
        );
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(updatePartnerFail(error));
    }
}

function* delePartner({ payload }: { payload: IGenericDocumentModifier }) {
    try {
        yield call(api, `${partnerUrl}/${payload.documentId}`, httpRequest.DELETE, null, 0, 0);
        yield put(deletePartnerSuccess(payload));
        yield put(
            destroyOneCache({
                cacheType: CACHE_TYPE.PARTNER,
            }),
        );
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(deletePartnerFail(error));
    }
}

function* getPartner({ payload }: { payload: IGetPartner }): Generator<any, any, any> {
    let initialResult: any = null;
    const defaultUseCache = yield* isUseCacheEnabled();
    const cache: NotUniqueCacheValue = yield* getNotUniqueCacheByKey(CACHE_TYPE.PARTNER, payload.partnerId);

    if (cache && cache.key) {
        initialResult = cache.value;
    }

    if (defaultUseCache && initialResult) {
        yield put(getPartnerSuccess(initialResult));
    } else {
        try {
            const response = yield call(api, `${partnerUrl}/${payload.partnerId}`, httpRequest.GET, null, 0, 0);
            const { data } = response.data;
            yield put(getPartnerSuccess(data));
            yield put(
                setCache({
                    key: payload.partnerId,
                    type: CACHE_TYPE.HOST,
                    value: data,
                    isUnique: false,
                }),
            );
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield put(getPartnerFail(error));
        }
    }
}

function* getAllPartners({ payload }: { payload: IGetAllPartners }): Generator<any, any, any> {
    let query = '?';
    if (payload.params) query += payload.params;

    const key = payload.params ?? 'all';
    const defaultUseCache = yield* isUseCacheEnabled();
    const cache: NotUniqueCacheValue = yield* getNotUniqueCacheByKey(CACHE_TYPE.PARTNER, key);

    let initialResult: any = null;
    if (cache && cache.key) {
        initialResult = cache.value;
    }

    if (initialResult && defaultUseCache) {
        yield put(getAllPartnersSuccess(initialResult));
    } else {
        try {
            const response = yield call(api, `${partnerUrl}${query}`, httpRequest.GET, null, 0, 0);
            const { data } = response.data;
            yield put(getAllPartnersSuccess(data));
            yield put(
                setCache({
                    key,
                    type: CACHE_TYPE.PARTNER,
                    value: data,
                    isUnique: false,
                }),
            );
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield put(getAllPartnersFail(error));
        }
    }
}

/**TODO: Move all vote related saga away from here to the voting saga */
function* initiateVote({ payload, resolve, reject }: { payload: IInitiate2FaForVote; resolve: any; reject: any }): any {
    const url = `${submissionsUrl}/${payload.submission_id}/vote`;
    try {
        const response = yield call(api, url, httpRequest.POST, { phone_number: payload.phone_number }, 0, 0);
        const { data, message } = response || {};
        if (message) {
            /** Here the user received the messag to verifyt their phone */
            yield put(initaiet2FAForVoteSuccess(data));
            yield call(showToastMessage, message, 'success');
            resolve(message);
        } else {
            /** Here they ae already verified and the vote goes through successfully */
            yield put(
                setInAppNotification({
                    message: 'Vote Successful',
                    title: 'Voting Successful',
                }),
            );
            const parsedData = genericParseSingleDocument(response.data.data);
            yield put(castVoteSuccess(parsedData));
            yield put(
                destroyOneCache({
                    cacheType: CACHE_TYPE.USER_VOTES,
                }),
            );
            yield all([
                put(
                    setVotingFlow({
                        submissions: [],
                        showVotingModal: false,
                    }),
                ),
                put(setVoting2Fa(false)),
            ]);
            resolve(parsedData);
        }
    } catch (error: any) {
        SentryCapture(error, 'error');
        // Check for other error codes here as well
        if (error && error.data && error.data.message) {
            const payload: IErrorType = {
                data: {
                    message: error.data.message,
                    status: 'failed',
                },
                status: 403,
                statusText: error.data.message,
            };
            yield put(
                setInAppNotification({
                    message: error.data.message,
                    title: error.data.message,
                }),
            );
            yield put(initaiet2FAForVoteFail(payload));
        }
        yield all([
            put(
                setVotingFlow({
                    submissions: [],
                    showVotingModal: false,
                }),
            ),
            put(setVoting2Fa(false)),
        ]);
        reject(error);
    }
}

function* castVote({ payload }: { payload: ICastVote }): any {
    const url = `${submissionsUrl}/${payload.submission_id}/vote/verify`;
    try {
        const response = yield call(api, url, httpRequest.POST, { ...payload }, 0, 0);
        const { data } = response.data;
        const parsedData = genericParseSingleDocument(data);
        yield put(castVoteSuccess(parsedData));
        yield put(
            setInAppNotification({
                message: 'Vote Successful',
                title: 'Voting Successful',
            }),
        );
        yield put(
            destroyOneCache({
                cacheType: CACHE_TYPE.USER_VOTES,
            }),
        );

        yield all([
            put(
                setVotingFlow({
                    submissions: [],
                    showVotingModal: false,
                }),
            ),
            put(setVoting2Fa(false)),
        ]);
    } catch (error: any) {
        if (error.data && error.data.message && error.data.message.startsWith('The submission voter')) {
            yield put(
                setInAppNotification({
                    message: 'Vote Already Cast',
                    title: 'Vote Already Cast',
                }),
            );
        } else {
            yield call(globalErrorHandler, error);
            SentryCapture(error, 'error');
        }
        yield put(castVoteFail(error));
    }
}

function* getRecommendations(): any {
    try {
        const response = yield call(api, getRecommendationUrl, httpRequest.GET, {}, 2, 2000, false);
        const { data }: { data: IContest[] } = response.data;
        yield put(getRecommendationSuccess(data));
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(getRecommendationFail(error));
        yield call(globalErrorHandler, error);
    }
}

function* createBookmark({ payload }: { payload: ICreateBookmark }): any {
    try {
        const response = yield call(api, saveCollectionUrl, httpRequest.POST, payload, 1, 2000);
        const { data } = response.data;
        const parsedResponse = genericParseSingleDocument(data);
        yield put(createBookmarkSuccess(parsedResponse));
        yield put(
            destroyOneCache({
                cacheType: CACHE_TYPE.BOOKMARK,
            }),
        );
        yield call(showToastMessage, 'Bookmark Sucessful', 'success');
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(createBookmarkFail({ ...error, documentId: payload.details.model }));
        yield call(globalErrorHandler, error);
    }
}

function* deleteBookmark({ payload }: { payload: IGenericDocumentModifier }): any {
    try {
        yield call(api, `${saveCollectionUrl}/${payload.documentId}`, httpRequest.DELETE, payload, 1, 2000);
        yield put(deleteBookmarkSuccess(payload));
        yield put(
            destroyOneCache({
                cacheType: CACHE_TYPE.BOOKMARK,
            }),
        );
        yield call(showToastMessage, 'Bookmark Removed Successfully', 'info');
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(deleteBookmarkFail({ ...error, documentId: payload.documentId }));
        yield call(globalErrorHandler, error);
    }
}

function* getBookmarks(): any {
    const defaultUseCache = yield* isUseCacheEnabled();
    const defaultCacheDuration = yield* getCacheDuration();
    let initialResult: any = null;
    const cache: CacheValue = yield* getCacheByType(CACHE_TYPE.BOOKMARK);
    if (cache && cache.key) {
        initialResult = cache.value;
    }
    if (
        !!initialResult &&
        defaultUseCache &&
        ((Date.now() - Number(cache.key)) as unknown as number) < defaultCacheDuration
    ) {
        yield put(getBookmarkSuccess(initialResult));
    } else {
        try {
            const response = yield call(api, getSavedCollectionUrl, httpRequest.GET, null, 0, 0);
            const { data } = response.data;
            const parsedBookmark = parseGenericCollection(data, genericParseSingleDocument);
            yield put(getBookmarkSuccess(parsedBookmark));
            yield put(
                setCache({
                    key: Date.now(),
                    type: CACHE_TYPE.BOOKMARK,
                    value: parsedBookmark,
                    isUnique: true,
                }),
            );
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield put(getBookmarkFail(error));
        }
    }
}

function* getMySubmissions(): any {
    const defaultUseCache = yield* isUseCacheEnabled();
    const defaultCacheDuration = yield* getCacheDuration();
    let initialResult: any = null;
    const cache: NotUniqueCacheValue = yield* getCacheByType(CACHE_TYPE.USER_SUBMISSIONS);
    if (cache && cache.key) {
        initialResult = cache.value;
    }
    if (
        !!initialResult &&
        defaultUseCache &&
        ((Date.now() - Number(cache.key)) as unknown as number) < defaultCacheDuration
    ) {
        yield put(getMySubmissionsSuccess(initialResult));
    } else {
        try {
            const response = yield call(api, getMySubmissionsUrl, httpRequest.GET, {}, 2, 2000, false);
            const { data }: { data: ISubmission[] } = response.data;
            const parsedSubmissions = parseGenericCollection(
                data,
                genericParseSingleDocument,
                genericParseSingleDocument,
                ['contest', 'project'],
            );
            yield put(getMySubmissionsSuccess(parsedSubmissions));
            yield setCache({
                key: Date.now(),
                type: CACHE_TYPE.USER_SUBMISSIONS,
                value: data,
                isUnique: true,
            });
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield put(getMySubmissionsFail(error));
        }
    }
}

function* submitProject({ payload, resolve, reject }: { payload: ISubmitProject; resolve: any; reject: any }): any {
    try {
        const response = yield call(api, submissionsUrl, httpRequest.POST, payload, 2, 2000, false);
        const { data }: { data: ISubmission } = response.data;
        const parsedSubmission = genericParseSingleDocument(data);
        if (resolve) {
            resolve(parsedSubmission);
        }
        yield put(submitProjectSuccess(parsedSubmission));
        yield call(showToastMessage, 'Submission Successful', 'success');
        yield put(destroyOneCache({ cacheType: CACHE_TYPE.USER_SUBMISSIONS }));
        yield put(destroyOneCache({ cacheType: CACHE_TYPE.USER_PROJECTS }));
        yield put(refreshUserDashboard());
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield call(globalErrorHandler, error);
        if (reject) {
            reject(error);
        }
        yield put(submitProjectFail(error));
    }
}

function* rescindSubmission({ payload }: { payload: ISubmission }): any {
    try {
        yield call(api, `${submissionsUrl}/${payload._id}`, httpRequest.DELETE, payload, 2, 2000, false);
        yield put(rescindSubmissionSuccess(payload));
        yield call(showToastMessage, 'Submission Withdrawn', 'success');
        yield put(destroyOneCache({ cacheType: CACHE_TYPE.USER_SUBMISSIONS }));
        yield put(destroyOneCache({ cacheType: CACHE_TYPE.USER_PROJECTS }));
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(rescindSubmissionFail(error));
    }
}

function* getContest({ payload }: { payload: IGetContestPayload }): any {
    const defaultUseCache = yield* isUseCacheEnabled();
    let initialResult: any = null;
    const cache: NotUniqueCacheValue = yield* getNotUniqueCacheByKey(CACHE_TYPE.CONTEST, payload.contestId);
    if (cache && cache.key) {
        initialResult = cache.value;
    }
    if (initialResult && defaultUseCache) {
        const randomTag = _.random(0, initialResult.tag.length - 1, false);
        const isContestLive = initialResult.contest_phase === 'submission' || initialResult.contest_phase === 'vote';
        const selectedParam = isContestLive
            ? `?contest_phase=submission&limit=5`
            : `?tag=${initialResult.tag[randomTag]}&limit=5`;
        yield put(getContestSuccess({ data: initialResult }));
        yield put(getContests({ params: selectedParam }));
    } else {
        try {
            const response = yield call(
                api,
                `${getContestUrl}/${payload.contestId}${payload.params ? payload.params : ''}`,
                httpRequest.GET,
                null,
                2,
                1000,
            );
            const { data } = response.data;
            const parsedResponse = parseSingleContest(data);
            yield put(getContestSuccess({ data: parsedResponse }));
            const randomTag = _.random(0, parsedResponse.tag.length - 1, false);
            const isContestLive =
                parsedResponse.contest_phase === 'submission' || parsedResponse.contest_phase === 'vote';
            const selectedParam = isContestLive
                ? `?contest_phase=submission&limit=5`
                : `?tag=${parsedResponse.tag[randomTag]}&limit=5`;
            /** Get Similar Contests */
            yield put(getContests({ params: selectedParam }));
            if (payload.params) {
                /** If there is payload then clear search suggestion. Currently the payload being passed is ?search=true
                 * This should reset our search suggestion to get the most recent search result when the search box is tapped
                 * again
                 *
                 * TODO: Check for that params is specifically for search
                 */
                yield put(destroyOneCache({ cacheType: CACHE_TYPE.SEARCH_SUGGESTION }));
            }
            yield put(
                setCache({
                    key: payload.contestId,
                    value: parsedResponse,
                    type: CACHE_TYPE.CONTEST,
                    isUnique: false,
                }),
            );
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield call(globalErrorHandler, error);
            yield put(getContestFail(error));
        }
    }
}
/** Mind the s in thie spelling as in GetContests with an S
 * This will also return similar contests and recommendations
 */
function* getAllContests({ payload }: { payload: IGetContests }): any {
    const defaultUseCache = yield* isUseCacheEnabled();
    let initialResult: any = null;
    const cache: NotUniqueCacheValue = yield* getNotUniqueCacheByKey(
        CACHE_TYPE.CONTEST,
        (payload.params as string) ?? Date.now(),
    );
    if (cache && cache.key) {
        initialResult = cache.value;
    }
    if (initialResult && defaultUseCache) {
        yield put(getContestsSuccess({ data: initialResult, isFilitered: !!payload.params }));
    } else {
        try {
            const response = yield call(api, `${getContestUrl}${payload.params}`, httpRequest.GET, null, 2, 1000);
            const { data } = response.data;
            const parsedResponse = parseGenericCollection(data, genericParseSingleDocument);
            yield put(getContestsSuccess({ data: parsedResponse, isFilitered: !!payload.params }));
            yield put(
                setCache({
                    key: payload.params ?? Date.now(),
                    value: parsedResponse,
                    type: CACHE_TYPE.CONTEST,
                    isUnique: false,
                }),
            );
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield put(getContestsFail(error));
        }
    }
}

interface TaskAction extends Action {
    payload: ICreateContestPayload;
}

interface Modifier extends Action {
    payload: IGenericDocumentModifier;
}

interface ContestFetcher extends Action {
    payload: IGetUserContest;
}

interface ContestsFetcher extends Action {
    payload: IGetUserContests;
}

interface HostCreator extends Action {
    payload: ICreateHost;
}

interface PartnerCreator extends Action {
    payload: ICreatePartner;
}

interface HostGetter extends Action {
    payload: IGetHost;
}

interface AllHostGetter extends Action {
    payload: IGetAllHosts;
}

interface AllPartnerGetter extends Action {
    payload: IGetAllPartners;
}

interface PartnerGetter extends Action {
    payload: IGetPartner;
}

interface HostSearch extends Action {
    payload: ISearchPayload;
}

interface AwardCatgeory extends Action {
    payload: ICreateAwardCategory;
}

interface ContestReference extends Action {
    payload: string[];
}

function* getContestReferencesWatcher() {
    yield takeLatest<ContestReference>(types.GET_CONTEST_REFERENCES, getContestReferences);
}

function* getRecommendationsWatcher() {
    yield takeLatest(types.GET_RECOMMENDATION, getRecommendations);
}

function* getBookmarksWatcher() {
    yield takeLatest(types.GET_BOOKMARK, getBookmarks);
}

function* submitProjectWatcher() {
    yield takeLatest<any>(types.SUBMIT_PROJECT, submitProject);
}

function* getMySubmisisonsWatcher() {
    yield takeLatest(types.GET_MY_SUBMISSIONS, getMySubmissions);
}

function* getContestWatcher() {
    yield takeLatest<any>(types.GET_CONTEST, getContest);
}

function* getContestsWatcher() {
    yield takeLatest<any>(types.GET_CONTESTS, getAllContests);
}

function* rescindSubmissionWatcher() {
    yield takeLatest<any>(types.RESCIND_SUBMISSION, rescindSubmission);
}

function* initiateVoteWatcher() {
    yield takeLatest<any>(types.INITIATE_2FA_FOR_VOTE, initiateVote);
}

function* castVoteWatcher() {
    yield takeLatest<any>(types.VOTE_FOR_SUBMISSION, castVote);
}

function* createBookmarkWatcher() {
    yield takeLatest<any>(types.CREATE_BOOKMARK, createBookmark);
}
function* deleteBookmarksWatcher() {
    yield takeLatest<any>(types.DELETE_BOOKMARK, deleteBookmark);
}

function* createContestWatcher() {
    yield takeLatest<TaskAction>(types.CREATE_CONTEST, createContest);
}

function* updateContestWatcher() {
    yield takeLatest<Modifier>(types.UPDATE_CONTEST, updateContest);
}

function* deleteContestWatcher() {
    yield takeLatest<Modifier>(types.DELETE_CONTEST, deleteContest);
}

function* createHostWatcher() {
    yield takeLatest<HostCreator>(types.CREATE_HOST, createHost);
}

function* updateHostWatcher() {
    yield takeLatest<Modifier>(types.UPDATE_HOST, updateHost);
}

function* deleteHostWatcher() {
    yield takeLatest<Modifier>(types.DELETE_HOST, deleteHost);
}

function* getHostWatcher() {
    yield takeLatest<HostGetter>(types.GET_HOST, getHost);
}

function* geAllHostWatcher() {
    yield takeLatest<AllHostGetter>(types.GET_ALL_HOSTS, getAllHost);
}

function* createPartnerWather() {
    yield takeLatest<PartnerCreator>(types.CREATE_PARTNER, createPartner);
}

function* updatePartnerWather() {
    yield takeLatest<Modifier>(types.UPDATE_PARTNER, updatePartner);
}

function* delePartnerWather() {
    yield takeLatest<Modifier>(types.DELETE_PARTNER, delePartner);
}

function* getPartnerWather() {
    yield takeLatest<PartnerGetter>(types.GET_PARTNER, getPartner);
}

function* getAllPartnersWather() {
    yield takeLatest<AllPartnerGetter>(types.GET_ALL_PARTNERS, getAllPartners);
}

function* publishContestWatcher() {
    yield takeLatest<Modifier>(types.PUBLISH_CONTEST, publishContest);
}
function* unPublishContestWatcher() {
    yield takeLatest<Modifier>(types.UNPUBLISH_CONTEST, unPublishContest);
}

function* getUserContestWatcher() {
    yield takeLatest<ContestFetcher>(types.GET_USER_CONTEST, getUserContest);
}

function* getUserContestsWatcher() {
    yield takeLatest<ContestsFetcher>(types.GET_USER_CONTESTS, getUserContests);
}
function* searchHostWatcher() {
    yield takeLatest<HostSearch>(types.SEARCH_HOST, searchHost);
}

function* searchPartnerWatcher() {
    yield takeLatest<HostSearch>(types.SEARCH_PARTNER, searchPartner);
}

function* createAwardCategoryWatcher() {
    yield takeLatest<AwardCatgeory>(types.CREATE_AWARD_CATEGORY, createAwardCategory);
}

function* getAssignedCodesWatcher() {
    yield takeLatest<any>(types.GET_REDEEMABLE_CODE, getAssignedCodes);
}

function* assigRedeemableCodeWatcher() {
    yield takeLatest<any>(types.ASSIGN_REDEEMABLE_CODE, assignCodes);
}

function* downloadAssetWatcher() {
    yield takeLatest<any>(types.DOWNLOAD_ASSET, downloadAsset);
}

function* getAwardCategoriesWatcher() {
    yield takeLatest<any>(types.GET_AWARD_CATEGORIES_FOR_CONTEST, getAwardCategories);
}

function* getAwardPrizesWatcher() {
    yield takeLatest<any>(types.GET_AWARDED_PRIZES_FOR_CONTEST, getAwardPrizes);
}

function* awardPrizeWatcher() {
    yield takeLatest<any>(types.AWARD_PRIZE, awardPrize);
}

function* sendConclusionEmailWatcher() {
    yield takeLatest<any>(types.SEND_WINNERS_EMAIL, sendConclusionEmail);
}

function* getContestScoreBoardWatcher() {
    yield takeLatest<any>(types.GET_ALL_SUBMISSIONS, getContestScoreBoard);
}

function* createScoreCategoryWatcher() {
    yield takeLatest<any>(types.CREATE_SCORE_CATEGORY, createScoreCategory);
}

export default function* contestSaga() {
    yield all([
        getRecommendationsWatcher(),
        getBookmarksWatcher(),
        getMySubmisisonsWatcher(),
        submitProjectWatcher(),
        getContestWatcher(),
        getContestsWatcher(),
        rescindSubmissionWatcher(),
        initiateVoteWatcher(),
        castVoteWatcher(),
        createBookmarkWatcher(),
        deleteBookmarksWatcher(),
        createContestWatcher(),
        updateContestWatcher(),
        deleteContestWatcher(),
        createHostWatcher(),
        updateHostWatcher(),
        deleteHostWatcher(),
        getHostWatcher(),
        geAllHostWatcher(),
        createPartnerWather(),
        updatePartnerWather(),
        delePartnerWather(),
        getPartnerWather(),
        getAllPartnersWather(),
        publishContestWatcher(),
        unPublishContestWatcher(),
        getUserContestsWatcher(),
        getUserContestWatcher(),
        searchHostWatcher(),
        searchPartnerWatcher(),
        createAwardCategoryWatcher(),
        getAssignedCodesWatcher(),
        assigRedeemableCodeWatcher(),
        downloadAssetWatcher(),
        getAwardCategoriesWatcher(),
        getAwardPrizesWatcher(),
        awardPrizeWatcher(),
        sendConclusionEmailWatcher(),
        getContestScoreBoardWatcher(),
        createScoreCategoryWatcher(),
        getContestReferencesWatcher(),
    ]);
}
