export const es_strings = {
    signin: 'Ya tengo una cuenta',
    signup_text: 'Soy nuevo aquí',
    create_account: 'Crear cuenta',
    sign_in_now: 'Iniciar sesión ahora',
    create_an_account: 'Crear una cuenta',
    name: 'Nombre (nombre completo)',
    your_email_address: 'Tu dirección de correo electrónico',
    phone_number: 'Número de teléfono',
    create_a_password: 'Crear una contraseña',
    already_have_an_account: '¿Ya tienes una cuenta?',
    terms: 'Al iniciar sesión, aceptas los',
    conditions: 'Términos de uso de Thspian',
    splashScreenSubText: 'Audiciones. Películas. Reseñas',
    boxofix: 'Boxofix',
    sign_in: 'Iniciar sesión',
    password: 'Contraseña',
    new_to_thspian: '¿Nuevo en Thspian?',
    forgot_password: '¿Olvidaste tu contraseña?',
    select_country_code: 'Selecciona tu código de país',
    email_address: 'Dirección de correo electrónico',
    reset_password: 'Restablecer contraseña',
    enter_new_password: 'Ingresa nueva contraseña',
    confirm_password: 'Confirmar contraseña',
    submit: 'Enviar',
    reset_successful: 'Enlace de restablecimiento enviado',
    reset_link: 'Hemos enviado un correo electrónico a tu_correo con un enlace para restablecer tu contraseña',
    if_you_have_not:
        'Si no has recibido un correo electrónico después de 10 minutos, revisa tu carpeta de spam o intenta nuevamente.',
    confirm: 'Confirmar',
    enter_phone_code: 'Ingresa el código de verificación de seis (6) dígitos enviado a tu teléfono',
    email_error: 'el correo electrónico no es válido',
    phone_error: 'el número de teléfono no es válido',
    name_error: 'por favor ingresa tu nombre y apellido',
    password_confirm_error: 'las contraseñas no coinciden, por favor inténtalo nuevamente',
    existing_account:
        'Ya existe una cuenta con ese correo electrónico o número de teléfono, por favor inicia sesión o ¿olvidaste tu contraseña?',
    sign_in_with_email: 'Iniciar sesión con correo electrónico',
    sign_in_with_phone_number: 'Iniciar sesión con número de teléfono',
    phone_sign_in_error_msg: 'número de teléfono no válido',
    email_sign_in_error_msg: 'correo electrónico no válido',
    resend_code: 'Reenviar código',
    similar_movies: 'Películas similares',
    watch: 'Ver',
    trailer: 'TRÁILER',
    summary: 'Resumen',
    release_date: 'Fecha de estreno',
    director: 'Director',
    writer: 'Escritor',
    budget: 'Presupuesto',
    opening_weekend: 'Fin de semana de estreno',
    gross: 'Recaudación',
    add_review: 'Agregar una reseña',
    like_this: 'Agregar a lista de seguimiento',
    share: 'Compartir',
    see_all_reviews: 'Ver todas las reseñas de usuarios',
    cast: 'Elenco',
    user_review: 'Reseñas de usuarios',
    boxofix_rating: 'Calificación de Boxofix',
    your_rating: 'Tu calificación',
    rate_this: 'Calificar esto',
    liked: 'Agregado a lista de seguimiento',
    search: 'Buscar',
    searchPlaceHolder: 'Buscar películas, premios y actores',
    movies: 'Películas',
    actors: 'Actores y Actrices',
    awards: 'Premios y Eventos',
    talent: 'Talentos',
    search_talents: 'Buscar Talentos',
    type_message: 'Escribe tu mensaje aquí...',
    contribute: 'Contribuir a esta página',
    edit_page: 'Editar página',
    select_audition_role: 'Seleccionar un rol de audición, por ejemplo, Video Vixen...',
    select_audition_location: 'Seleccionar una ubicación...',
    age: 'Edad',
    required_media: 'Medios requeridos',
    gender: 'Género',
    character_description: 'Descripción del personaje',
    apply_role: 'Aplicar para el rol',
    profile: 'Perfil',
    watchlist: 'Lista de seguimiento',
    force_signin:
        'Inicia sesión para acceder a las funciones de BOXOFIX, incluyendo roles de casting, últimas actualizaciones de películas, reseñar películas y más',
    sign_in_sign_up: 'Iniciar sesión / Registrarse',
    dismiss: 'Descartar',
    default_button_name: 'Título del botón',
    account: 'Cuenta',
    my_profile: 'Perfil de Casting',
    productions: 'Producciones',
    contributions: 'Contribuciones',
    trial: '¡Prueba gratuita de 7 días con cualquier plan!',
    unlimited: 'Publicaciones de casting ilimitadas',
    secure: 'Acceso a talentos y elencos',
    t_four: 'Soporte prioritario 24/7',
    no_ad: 'Sin límites de aplicación',
    preference: 'Preferencia',
    filter: 'Filtrar',
    no_card: '¡Ups! Aún no tienes tarjeta',
    add_card_sub: 'Agrega una nueva tarjeta para habilitar las transacciones en Boxofix',
    add_new_card: 'Agregar nueva tarjeta',
    wallet: 'Billetera',
    delete_card: 'Eliminar tarjeta',
    new_card: 'Nueva tarjeta agregada',
    card_already_exists: 'La tarjeta ya existe',
    action_cancelled: 'La acción fue cancelada',
    next_billing_date: 'Próxima fecha de facturación',
    payment_method: 'Método de pago',
    last_payment: 'Último pago',
    change: 'Cambiar',
    cancel_subscription: 'Cancelar suscripción',
    cancel_warning: 'Tu suscripción actual será cancelada, si continúas',
    go_back: 'Regresar',
    proceed: 'Continuar',
    subscription_details: 'Detalles de la suscripción',
    subscription_cancel: 'Tu suscripción de #plan ha sido cancelada con éxito. Tu plan seguirá vigente hasta #date',
    cancelBackButton: 'Volver a Cuentas',
    subscription_action: 'Acción de suscripción',
    subscription_caution: '¿Deseas continuar?',
    subscription_success:
        'Te has suscrito correctamente a Boxofix. Cualquier suscripción activa será desactivada y reemplazada',
    subscribe: 'Suscribirse',
    max_application: 'Máximo de solicitudes alcanzado.',
    max_app_body:
        'Has alcanzado el máximo permitido de solicitudes con la suscripción Regular, siéntete libre de actualizar.',
    no_subscription: 'Sin Suscripción',
    no_sub_body: 'No tienes ninguna suscripción activa',
    no_actor_profile_body: 'Por favor completa tu perfil de talento para solicitar audiciones',
    no_actor_profile: 'Sin Perfil de Talento',
    complete_profile: 'Completar perfil',
    save_role: 'Guardar Rol',
    apply: 'Aplicar',
    applicants: 'solicitantes',
    production_description: 'Descripción de la Producción',
    casting_roles: 'Roles de Casting',
    job_details: 'Detalles del Trabajo',
    language: 'Idioma',
    expires: 'Vence',
    rehearsal_date: 'Fecha de Ensayo',
    tags: 'Etiquetas',
    compensation: 'Compensación',
    tbd: 'Por determinar',
    casting_agency: 'Agencia de Casting',
    attachments: 'Adjuntos',
    similar_roles: 'Roles Similares',
    saved: 'Guardado',
    applied: 'Aplicado',
    add_new_festival: 'Agregar nuevo premio / festival',
    search_movies: 'Buscar Películas, Programas de TV ...',
    search_actors: 'Buscar Actores, Actrices, Personalidades de TV ...',
    search_awards: 'Buscar Eventos, Festivales, Premios ...',
    boxofix_page: 'Página de Boxofix',
    boxofix_page_description:
        'Crea una biografía de película, incluyendo el elenco y equipo, premios, problemas y noticias sobre el proyecto.',
    casting_call: 'Convocatoria de Casting',
    casting_call_description:
        'Publica un aviso de audición. Llega a miles de nuevos talentos y pone en marcha tu proyecto',
    actors_biography: 'Biografía de Actor',
    actors_biography_description:
        'Crea la biografía de un actor o actriz, incluyendo trabajos anteriores, premios, fotos, logros, etc.',
    talent_profile: 'Perfil de Talento',
    talent_profile_description:
        'Crea tu perfil de talento para que las agencias de casting y directores puedan encontrarte',
    search_crew: 'Buscar personas, roles de equipo como electricista ...',
    search_award: 'Buscar categoría de premio, personas como Mejor Película ...',
    login_or_signup: 'Iniciar sesión / Registrarse',
    firstName: 'Nombre de pila',
    lastName: 'Apellido',
    upload_video: 'Cargar video',
    apply_filter: 'Aplicar filtro',
    clear_filters: 'Limpiar filtros',
    all_filters: 'Todos los filtros',
    page_not_found: 'Parece que estás perdido en el espacio.',
    back: 'Volver',
    my_rating: 'Mi calificación',
    continue: 'Continuar',
    contest_description: 'Descripción del concurso',
    judges: 'Jueces',
    create_contest: 'Crear concurso',
    more: 'Más',
    no_submissions: 'No hay presentaciones aún',
    intro: 'Introducción',
    edit_bio: 'Editar biografía',
    joined_this_contest: 'se unió a este concurso',
    recent_activities: 'Actividades recientes',
    commented_on_this_video: 'comentó en este video',
    coming_soon: 'Próximamente',
    live_contest: 'Concurso en vivo',
    my_videos: 'Mis videos',
    recent_submissions: 'Presentaciones recientes',
    recommendations: 'Recomendaciones',
    top_performers: 'Mejores participantes',
    recently_watched: 'Visto recientemente',
    statistics: 'Estadísticas',
    total_no_of_votes: 'Número total de votos',
    submissions: 'Presentaciones',
    duration: 'Duración',

    edit_profile: 'Editar perfil',
    add_cover: 'Agregar portada',
    create_project: 'Crear proyecto',
    comedy: 'Comedia',
    pg_13: 'PG-13',
    friends: 'amigos',
    protected_page: 'Esta página está protegida por Google reCAPTCHA para asegurarse de que no eres un robot.',
    learn_more: 'Obtén más información.',
    set_new_password: 'Establecer nueva contraseña',
    password_change_success: '¡Cambio de contraseña exitoso!',
    password_changed: '¡Tu contraseña ha sido cambiada! Serás redirigido a la pantalla de inicio de sesión',
    reset_link_sent: 'Te hemos enviado un enlace de restablecimiento',
    email_me: 'Envíame un correo electrónico',
    enter_email_to_reset_password:
        'Ingresa la dirección de correo electrónico asociada a tu cuenta y te enviaremos un enlace para restablecer tu contraseña',
    current_password: 'Contraseña actual',
    settings: 'Configuraciones',
    change_password: 'Cambiar contraseña',
    use_secure_password:
        'Al cambiar tu contraseña, te recomendamos encarecidamente que uses una contraseña segura que no utilices en ningún otro lugar.',
    notifications: 'Notificaciones',
    subscriptions: 'Suscripciones',
    recommended_videos: 'Videos recomendados',
    mentions: 'Menciones',
    shared_content: 'Contenido compartido',
    filter_by: 'Filtrar por',
    reset: 'Restablecer',
    show_results: 'Mostrar resultados',
    see_all: 'Ver todo',
    menu: 'MENÚ',
    personal: 'PERSONAL',
    username: 'Nombre de usuario',
    date_of_birth: 'Fecha de nacimiento',
    sign_up: 'Registrarse',
    otp_verify_vote: 'Enviaremos un OTP a tu número para verificar tu voto',
    questions_contact_us: '¿Preguntas? Contáctanos',
    playlist: 'Lista de reproducción',
    cookie_preference: 'Preferencia de cookies',
    drop_to_create_project: 'Deja aquí para crear un proyecto',
    faq: 'Preguntas frecuentes',
    help_center: 'Centro de ayuda',
    privacy_policy: 'Política de privacidad',
    shop: 'Tienda',
    terms_of_use: 'Condiciones de uso',
    recent_projects: 'proyectos recientes',
    submission_status: 'estado de presentación',
    my_projects: 'Mis Proyectos',
    select_judges: 'Seleccionar Jueces',
    requirements: 'Requisitos',
    prize: 'Premio',
    drop_file_to_upload: 'Arrastra el archivo aquí para subirlo',
    trending: 'Tendencias',
};
