import produce from 'immer';

import { Discovery, IDiscoveryInitialState, IFilterResponse, ISearchPayload, ISearchResponse, types } from './types';
import { ILeaveCommentResponse, types as projectTypes } from '../project/types';
import { IContest, IGenericDocumentModifier, IProject, UserSummary } from '../../types/global/helper';
import { ILikeResponse } from '../account/types';
import { getIdForIbjects } from '../../utils/AppUtils';

const initialState: IDiscoveryInitialState = {
    loadingDiscovery: false,
    searchTerm: '',
    discovery: [],
    errMsg: null,
};

const reducer = (state = initialState, action: any) => {
    const { type, payload } = action;
    switch (type) {
        case types.GET_DISCOVERY:
            return produce(state, draftState => {
                draftState.loadingDiscovery = true;
            });
        case types.GET_DISCOVERY_SUCCESS:
            return produce(state, draftState => {
                const { discovery } = payload as Discovery;
                draftState.discovery = discovery;
                draftState.loadingDiscovery = false;
            });
        case types.GET_DISCOVERY_FAIL:
            return produce(state, draftState => {
                draftState.errMsg = payload;
                draftState.loadingDiscovery = false;
            });

        case types.SEARCH_DISCOVERY:
            return produce(state, draftState => {
                const { searchTerm } = payload as ISearchPayload;
                draftState.searching = true;
                draftState.searchTerm = searchTerm;
            });
        case types.SEARCH_DISCOVERY_SUCCESS:
            return produce(state, draftState => {
                const { searchResult, allSearchResults } = payload as ISearchResponse;
                draftState.searching = false;
                draftState.searchResults = searchResult;
                draftState.allSearchResults = allSearchResults;
            });
        case types.SEARCH_DISCOVERY_FAIL:
            return produce(state, draftState => {
                draftState.errMsg = payload;
                draftState.searching = false;
            });
        case types.FILTER:
            return produce(state, draftState => {
                draftState.loadingDiscovery = true;
            });
        case types.FILTER_SUCCESS:
            return produce(state, draftState => {
                const { data, type, currentPage, result, suggestion } = payload as IFilterResponse;
                // const mergedResults = mergeObjectArrays(result, draftState.allSearchResults);
                draftState.searchResultsInView = result;
                draftState.filterdResult = data;
                draftState.selectedFilterCategory = type;
                draftState.loadingDiscovery = false;
                draftState.currentResultPage = currentPage;
                if (type === 'contest') {
                    draftState.suggestedContest = suggestion as IContest[];
                }

                if (type === 'project') {
                    draftState.suggestedProjects = suggestion as IProject[];
                }

                if (type === 'talent') {
                    draftState.suggestedTalents = suggestion as UserSummary[];
                }
            });
        case types.FILTER_FAIL:
            return produce(state, draftState => {
                draftState.loadingDiscovery = false;
                draftState.errMsg = payload;
            });
        case types.SET_VIEW_ALL:
            return produce(state, draftState => {
                draftState.searchResultsInView = draftState.allSearchResults;
            });
        case projectTypes.LIKE_A_PROJECT_SUCCESS:
            return produce(state, draftState => {
                const { like_origin, data } = payload as ILikeResponse;
                if (like_origin === 'home') {
                    const categoryIndex = draftState.discovery.findIndex(
                        category => category.categoryTitle === 'top submissions',
                    );
                    if (categoryIndex !== -1) {
                        const projectIndex = draftState.discovery[categoryIndex].categoryDetails.findIndex(
                            (submission: any) => submission.project._id === data.project._id,
                        );
                        if (projectIndex !== -1) {
                            //@ts-ignore
                            draftState.discovery[categoryIndex].categoryDetails[projectIndex].project.total_likes += 1;
                            //@ts-ignore
                            draftState.discovery[categoryIndex].categoryDetails[projectIndex].project.user_like = [
                                data,
                            ];
                            //@ts-ignore
                            draftState.discovery[categoryIndex].categoryDetails[projectIndex].project.is_user_liked =
                                true;
                        }
                    }
                }
            });
        case projectTypes.DELETE_A_LIKE_SUCCESS:
            return produce(state, draftState => {
                const signal = payload.payload;
                /** I passed additionalPaylaod here as project so I can adjust the lik_count directly here for the discovery page
                 * Please see AppLayout to see where this was triggered
                 */
                const { additionalPayload } = payload as IGenericDocumentModifier;
                if (signal === 'home') {
                    const categoryIndex = draftState.discovery.findIndex(
                        category => category.categoryTitle === 'top submissions',
                    );

                    if (categoryIndex !== -1) {
                        const projectIndex = draftState.discovery[categoryIndex].categoryDetails.findIndex(
                            (submission: any) => submission.project._id === getIdForIbjects(additionalPayload),
                        );

                        if (projectIndex !== -1) {
                            //@ts-ignore
                            draftState.discovery[categoryIndex].categoryDetails[projectIndex].project.total_likes -= 1;
                            //@ts-ignore
                            draftState.discovery[categoryIndex].categoryDetails[projectIndex].project.user_like = [];
                            //@ts-ignore
                            draftState.discovery[categoryIndex].categoryDetails[projectIndex].project.is_user_liked =
                                false;
                        }
                    }
                }
            });
        case projectTypes.LEAVE_COMMENT_SUCCESS:
            return produce(state, draftState => {
                const { comment } = payload as ILeaveCommentResponse;
                const categoryIndex = draftState.discovery.findIndex(
                    category => category.categoryTitle === 'top submissions',
                );
                if (categoryIndex !== -1) {
                    const projectIndex = draftState.discovery[categoryIndex].categoryDetails.findIndex(
                        (submission: any) => submission.project._id === comment.project?._id,
                    );
                    if (projectIndex !== -1) {
                        //@ts-ignore
                        draftState.discovery[categoryIndex].categoryDetails[projectIndex].project.total_comments += 1;
                    }
                }
            });
        case projectTypes.DELETE_COMMENT_SUCCESS:
            return produce(state, draftState => {
                const categoryIndex = draftState.discovery.findIndex(
                    category => category.categoryTitle === 'top submissions',
                );
                if (categoryIndex !== -1) {
                    const projectIndex = draftState.discovery[categoryIndex].categoryDetails.findIndex(
                        (submission: any) =>
                            getIdForIbjects(submission.project) === getIdForIbjects(payload.payload.project),
                    );
                    if (projectIndex !== -1) {
                        //@ts-ignore
                        draftState.discovery[categoryIndex].categoryDetails[projectIndex].project.total_comments -= 1;
                    }
                }
            });
        default:
            return state;
    }
};

export { reducer };
