import jwtDecode from 'jwt-decode';
import isNumber from 'lodash/isNumber';
import { useDispatch } from 'react-redux';

import { store } from '../redux/store';
import { ToastPosition, TypeOptions, toast } from 'react-toastify';
import moment from 'moment';
import CryptoJS from 'crypto-js';
import {
    IContest,
    IGlobalConfigurationMediaControl,
    IProject,
    IUserSummary,
    UserSummary,
} from '../types/global/helper';
import dayjs from 'dayjs';
import { IActSummary } from '../types/global/media.types';
import produce from 'immer';
import { IUserProfile } from '../redux/user/actions';
import { SentryCapture } from '../analytics/Sentry';
import { isEmpty } from './lodash';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { SITE_URL } from '../configurations/api/url';
import { ILink } from '../redux/links/types';

dayjs.extend(relativeTime);
dayjs.extend(utc);

const secretKey = CryptoJS.enc.Utf8.parse('b75524255a7f54d2726a951bb39204df'); // Convert the key to a WordArray
const iv = CryptoJS.enc.Utf8.parse('1583288699248111');

export interface IVideoEncryptor {
    [key: string]: string;
}

export function useDispatchPromise() {
    const dispatch = useDispatch();
    const callDispatch = (props: any) =>
        new Promise((resolve, reject) => {
            dispatch({ ...props, resolve, reject });
        });

    return callDispatch;
}

export const getJWtDetails = (jwt = null) => {
    const { authentication, user } = store.getState();
    const storedToken = jwt || authentication.token;
    try {
        if (storedToken) {
            const { id, exp } = jwtDecode(storedToken) as any;
            const currentUtcTimestamp = moment().valueOf();
            const timeDifference = exp * 1000 - currentUtcTimestamp;
            return {
                tokenExists: true,
                user_id: id,
                isSignedIn: new Date(exp * 1000) > new Date(Date.now()),
                token: storedToken,
                timeTillTokenExpiration: `${moment.duration(timeDifference).humanize()} left`,
                user: user.userProfile as IUserProfile,
            };
        }
        return { tokenExists: false, user_id: '', isSignedIn: false, user: null };
    } catch (error) {
        SentryCapture(error, 'error');
    }

    return { tokenExists: false, user_id: '', isSignedIn: false, user: null };
};

export const restoreInitialState = (state: any, initialState: any): any => {
    return produce(state, () => {
        // Resetting the state to its initial values
        return { ...initialState };
    });
};

export const catchAsync =
    (fn: any) =>
    (...args: any) => {
        fn(...args).catch((error: any) => console.log(error));
    };

export const isConversionRequired = () => {
    const { tokenExists, user_id } = getJWtDetails();
    return { isConversion: tokenExists, user_id };
};

export const formSignInString = (buttonText: string) => {
    const { isSignedIn } = getJWtDetails();
    if (isSignedIn) {
        return buttonText;
    }
    return `Sign in to ${buttonText.toLowerCase()}`;
};

export const getUserTalentProfileDetails = () => {
    const { talentProfile } = store.getState();
    const { currentUserTalentProfile } = talentProfile;
    let fullFields = 0;
    let percentageComplete = 0;
    const emptyFields: string[] = [];

    if (!isEmpty(currentUserTalentProfile)) {
        const numberOfFields = Object.keys(currentUserTalentProfile);
        numberOfFields.forEach(field => {
            if (!isEmpty(currentUserTalentProfile[field]) || isNumber(currentUserTalentProfile[field])) {
                fullFields += 1;
            } else {
                emptyFields.push(field);
            }
        });
        percentageComplete = (fullFields * 100) / numberOfFields.length;
    } else {
        return { profilePresent: false, percentageComplete: '0%', emptyFields: [] };
    }

    return {
        profilePresent: !isEmpty(currentUserTalentProfile),
        percentageComplete: `${percentageComplete.toFixed()}%`,
        emptyFields,
    };
};

export const showToastMessage = (
    toastMessage: JSX.Element | string = 'Notification here',
    toastType: TypeOptions = 'info',
    autoClose?: number | false,
    onClose?: (arg?: any) => void,
    onOpen?: (arg?: any) => void,
    customIcon?: JSX.Element | false,
    toastPosition?: ToastPosition,
    hideProgressBar?: boolean,
    draggable?: boolean,
    pauseOnHover?: boolean,
    closeOnClick?: boolean,
) => {
    const toastId = toast(toastMessage, {
        position: toastPosition ?? toast.POSITION.TOP_RIGHT,
        theme: 'dark',
        type: toastType,
        autoClose: autoClose ?? 5000,
        hideProgressBar: hideProgressBar,
        closeOnClick: closeOnClick,
        pauseOnHover: pauseOnHover,
        draggable: draggable,
        onClose: ({ uuid }: any) => onClose && onClose(uuid),
        onOpen: ({ uuid }: any) => onOpen && onOpen(uuid),
        /**icon: ({theme, type}) =>  <img src="url"/> */
        icon: customIcon,
        style: {
            zIndex: 999,
        },
    });

    return toastId;
};

export const formatTimeWithTimezone = (time: string, format: string = 'MMM D h:mm A') => {
    return moment(time).format(format);
};

export const formatTimeWithToDay = (time: string, format: string = 'MMM D, YYYY') => {
    return dayjs(time).format(format);
};

export const checkDateValidity = (date: string) => {
    return moment(date).isValid();
};

export const formatNumberWithK = (number: number) => {
    if (!number) {
        return '0';
    }
    if (Math.abs(number) >= 1000) {
        const formattedNumber = (Math.sign(number) * (Math.abs(number) / 1000)).toFixed(1);
        return formattedNumber.replace(/\.0$/, '') + 'K';
    }
    return number.toString();
};

export const daysLeftFromToday = (targetDate?: string) => {
    if (!targetDate) return null;
    const parsedTargetDate = moment(targetDate, 'YYYY-MM-DD');
    const currentDate = moment();
    const differenceInMilliseconds = parsedTargetDate.diff(currentDate);
    const daysLeft = Math.ceil(moment.duration(differenceInMilliseconds).asDays());
    return daysLeft;
};

export const formatDate = (targetDate?: string, dateFormat: string = 'YYYY') => {
    if (!targetDate) return '';
    const year = moment(targetDate).format(dateFormat);
    return year;
};

export const createFullName = (user: UserSummary) => {
    return `${user && user.firstName ? user.firstName : ''} ${user && user.lastName ? user.lastName : ''}`;
};

export const createUserUrl = (user: IUserSummary | IUserProfile) => {
    if (!user || !user.userName) return '';
    return `${SITE_URL}/${user.userName}`;
};

export const convertMinuteSecondsDays = (time: any) => {
    const currentTime = dayjs();
    const eventStartTime = dayjs.utc(time).local();
    return eventStartTime.from(currentTime, true);
};

export const encryptAndGenerateVideoId = (valuesObj: IVideoEncryptor) => {
    const combinedValues = JSON.stringify(valuesObj);
    const encrypted = CryptoJS.AES.encrypt(combinedValues, secretKey, { iv: iv }).toString();
    const videoId = encrypted.slice(0, 11);
    return { encryptedData: encrypted, videoId };
};

export const decryptVideoId = (videoId: string) => {
    const encryptedData = videoId + '0'.repeat(32 - videoId.length); // Padding to ensure proper decryption
    const decrypted = CryptoJS.AES.decrypt(encryptedData, secretKey, { iv: iv });
    const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedString);
};
export const randomizeArrayOrder = (arr: Array<any>) => {
    const shuffledArray = [...arr];
    function compareRandom() {
        return Math.random() - 0.5;
    }
    shuffledArray.sort(compareRandom);
    return shuffledArray;
};

export const hasNonEmptyArrayField = (object: any) => {
    for (const key in object) {
        if (object.hasOwnProperty(key)) {
            const value = object[key];
            if (Array.isArray(value) && value.length > 0) {
                return true; // Found a non-empty array field, return true
            }
        }
    }
    return false; // No non-empty array fields found, return false
};

export const filterOutNotAllowed = (obj: any, ...notAllowed: any) => {
    const newObj: any = {};
    Object.keys(obj).forEach(el => {
        if (!notAllowed.includes(el)) {
            newObj[el] = obj[el];
        }
    });
    return newObj;
};

export const onlyTheseFields = (obj: any, ...allowed: any) => {
    const newObj: any = {};
    Object.keys(obj).forEach(el => {
        if (allowed.includes(el)) {
            newObj[el] = obj[el];
        }
    });
    return Object.values(newObj).flat();
};

export const generateShareLink = (
    type: 'project' | 'user' | 'contest' | 'social',
    doc: IUserSummary | IProject | IContest | IActSummary | ILink,
) => {
    const url = window.location.origin;
    let link = '';
    switch (type) {
        case 'project':
            const project = doc as IProject;
            link = `${url}/watch?id=${project.video_id}`;
            break;
        case 'user':
            const user = doc as IUserSummary;
            link = `${url}/@${user.userName}`;
            break;
        case 'contest':
            const contest = doc as IContest;
            link = `${url}/contest=${contest._id}`;
            break;
        case 'social':
            const document = doc as ILink;
            link = `${url}/links?id=${document.unique_id}`;
            break;
        default:
            break;
    }
    const tempInput = document.createElement('input');
    tempInput.value = link;
    document.body.appendChild(tempInput);
    tempInput.select();
    tempInput.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(link);
    showToastMessage('Link copied', 'success');
    return link;
};

export const makeArrayUniqueAndClean = (array: any[]) => {
    const uniqueArray = [];
    const uniqueIds = new Set();

    for (const obj of array) {
        if (!!obj && !uniqueIds.has(obj._id)) {
            uniqueIds.add(obj._id);
            uniqueArray.push(obj);
        }
    }

    return uniqueArray;
};

export function makePositive(number: number) {
    // Check if the number is negative
    if (number < 0) {
        // If negative, convert it to positive
        return -number;
    } else {
        // If non-negative, return as is
        return number;
    }
}

export function convertSecondsToTime(seconds: number) {
    if (seconds < 60) {
        return `0:${Math.floor(seconds).toString().padStart(2, '0')}`;
    } else {
        let hours = Math.floor(seconds / 3600);
        let remainingSeconds = seconds % 3600;

        if (remainingSeconds < 60) {
            return `${hours}:00:${Math.floor(remainingSeconds).toString().padStart(2, '0')}`;
        } else {
            let minutes = Math.floor(remainingSeconds / 60);
            let remainingSecs = Math.floor(remainingSeconds % 60);
            return `${hours}:${minutes.toString().padStart(2, '0')}:${remainingSecs.toString().padStart(2, '0')}`;
        }
    }
}

export function replacePageValue(url: string, newPageValue: number) {
    const regex = /(\?|&)page=\d+/;
    const replacement = `$1page=${newPageValue}`;
    const updatedUrl = url.replace(regex, replacement);
    return updatedUrl;
}

export function cutoffAndAddPlus(number: number, cutOffValue: number) {
    if (typeof number !== 'number' || isNaN(number)) {
        return 'Please provide a valid number';
    }

    if (number > cutOffValue) {
        return `${cutOffValue}+`;
    }
    return number;
}
interface TextPosition {
    top: number;
    left: number;
    width: number;
    height: number;
}

export function findTextPosition(containerSelector: string, searchText: string): TextPosition | null {
    const container = document.querySelector<HTMLElement>(containerSelector);
    if (!container) {
        console.error('Container element not found');
        return null;
    }

    function traverseNodes(node: Node): TextPosition | null {
        if (node.nodeType === Node.TEXT_NODE && node.textContent?.includes(searchText)) {
            const range = document.createRange();
            range.selectNodeContents(node);
            const text = node.textContent as string;
            const index = text.indexOf(searchText);

            range.setStart(node, index);
            range.setEnd(node, index + searchText.length);

            const rect = range.getBoundingClientRect();
            return {
                top: rect.top,
                left: rect.left,
                width: rect.width,
                height: rect.height,
            };
        }

        if (node.childNodes && node.childNodes.length > 0) {
            for (const childNode of Array.from(node.childNodes)) {
                const found = traverseNodes(childNode);
                if (found) {
                    return found;
                }
            }
        }

        return null;
    }

    const position = traverseNodes(container);
    if (!position) {
        console.error('Text not found in the container');
    }

    return position;
}

/** This is a hack for now to fetch the ide for documents that sometimes
 * return a string instead of populating. Will need to fix or find a permannent solution
 */
export const getIdForIbjects = (document: any) => {
    if (!document) return '';
    if (typeof document === 'string') {
        return document;
    }
    return document._id;
};

export function bytesToMB(bytes: number) {
    return (bytes / (1024 * 1024)).toFixed(0);
}

export function parseFileFormats(data: IGlobalConfigurationMediaControl) {
    const supportedFormats: any = {};

    if (data && data.supported_file_formats && Array.isArray(data.supported_file_formats)) {
        data.supported_file_formats.forEach(format => {
            if (format.base && format.extension && Array.isArray(format.extension)) {
                supportedFormats[format.base] = format.extension;
            }
        });
    }

    return supportedFormats;
}

export const getFontSize = (name?: string) => {
    const nameLength = name ? name.length : 4;
    console.log(nameLength);
    if (nameLength <= 12) {
        return 18; // Font size for shorter names
    } else {
        // Adjust the calculation based on your requirements

        console.log(Math.floor(18 / nameLength) * 18);
        return Math.floor(18 / nameLength) * 5; // Font size decreases as name length increases
    }
};

export const moveItemToIndexZero = (arr: any[], index: number) => {
    if (index >= 0 && index < arr.length) {
        const item = arr.splice(index, 1)[0];
        arr.unshift(item);
    } else {
        console.log('Index out of range.');
    }
    return arr;
};

export function getFilteredRoutes(routes: any, pathsToHide: any) {
    const filteredRoutes = Object.values(routes).filter(route => !pathsToHide.includes(route));
    return filteredRoutes;
}

export const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
export const isAndroid = /Android/.test(navigator.userAgent);

// utils.ts
// browser.enum.ts

export enum Browser {
    CHROME = 'Google Chrome',
    FIREFOX = 'Mozilla Firefox',
    SAFARI = 'Apple Safari',
    IE = 'Internet Explorer',
    UNKNOWN = 'Unknown',
}

/**
 * Utility function to detect the browser.
 * @returns {Browser} The name of the detected browser.
 */
export function detectBrowser(): Browser {
    const userAgent = window.navigator.userAgent;

    if (userAgent.indexOf('Chrome') !== -1) {
        return Browser.CHROME;
    } else if (userAgent.indexOf('Firefox') !== -1) {
        return Browser.FIREFOX;
    } else if (userAgent.indexOf('Safari') !== -1) {
        return Browser.SAFARI;
    } else if (userAgent.indexOf('MSIE') !== -1 || userAgent.indexOf('Trident/') !== -1) {
        return Browser.IE;
    }

    return Browser.UNKNOWN;
}

export function ensureHttps(url: string) {
    if (!url) return;
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
        return `https://${url}`;
    }
    return url;
}

export const formQueryParams = (...params: Record<string, string>[]): string => {
    if (params && params.length === 0) {
        return '';
    }

    let queryString = '?';
    if (params) {
        params.forEach(param => {
            if (typeof param === 'object' && param !== null) {
                for (const key in param) {
                    if (param.hasOwnProperty(key) && param[key] !== undefined) {
                        queryString += `${encodeURIComponent(key)}=${encodeURIComponent(param[key])}&`;
                    }
                }
            }
        });
    }

    return queryString.length > 1 ? queryString.slice(0, -1) : '';
};
